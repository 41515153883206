const nameOptions = [
  'Main',
  'Fax',
  'Inmate Voicemail'
];

angular
  .module('relcore.facility')
  .directive('facilityAnis', () => ({
    restrict: 'E',
    scope: {
      facility: '=?',
      onSave: '=?',
      onEdit: '=?',
      gridOptions: '=',
      editAnis: '=',
      savingAnis: '='
    },
    template: require('./list.html').default,
    controller: ['$scope', '_', function($scope, _) {
      $scope.isEditing = (row) => $scope.editAnis.indexOf(row) > -1
      $scope.isSaving = (row) => $scope.savingAnis.indexOf(row) > -1

      $scope.nameAutoCompleteOptions = {
        minimumChars: 1,
          data: function (searchText) {
            searchText = searchText.toUpperCase();

            return _.filter(nameOptions, function (name) {
              return name.toUpperCase().startsWith(searchText);
            });
          }
      };
    }]
  }));