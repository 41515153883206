angular
  .module('relcore.mobile-device')
  .directive('viewLinkButton', () =>
    ({
      restrict: 'E',
      scope: {
        ticket: '='
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns || []).indexOf(column) > -1
      }],
      template: require('./view-link-button.html').default
    })
  );
