angular
  .module('relcore.facility')
  .directive('facilityNumbers', () => ({
    restrict: 'E',
    scope: {
      facility: '=?',
      onSave: '=?',
      onEdit: '=?',
      gridOptions: '=',
      editNumbers: '=',
      savingNumbers: '='
    },
    template: require('./list.html').default,
    controller: ['$scope', function($scope) {
      $scope.isEditing = (row) => $scope.editNumbers.indexOf(row) > -1
      $scope.isSaving = (row) => $scope.savingNumbers.indexOf(row) > -1
    }]
  }));