angular
  .module('relcore.api-user')
  .directive('apiUserAdd', function() {
    return {
      restrict: 'E',
      scope: {
        facilities: '=',
        apiRoles: '=',
        adding: '=',
        onAdd: '='
      },
      controller: ['$scope', function($scope) {
        const newUser = {
          username: '',
          password: '',
          roleIds: [],
          roleNames: []
        };

        $scope.apiUser = Object.assign({}, newUser);
        $scope.rolesToAdd = [];
        $scope.add = function() {
          let facility = $scope.apiUser.facility;
          $scope
            .onAdd({
              userName: $scope.apiUser.username,
              password: $scope.apiUser.password,
              roleIds: $scope.rolesToAdd.map(r => r.id),
              roleNames: $scope.rolesToAdd.map(r => r.name),
              facilityId: facility != null ? JSON.stringify(facility.id) : null
            })
            .then(function() {
              $scope.apiUser = Object.assign({}, newUser);
            });
        };
      }],
      template: require('./api-user-add.html').default
    }
  })
