import { ILegacyUserAction } from "../../actions/legacy-user";
import { LegacyUser } from '../../models/LegacyUser';

export interface ILegacyUserListState {
  users: LegacyUser[]
  loading: boolean
  error: any
}

angular
  .module('relcore.user')
  .service('legacyUserListReducer', function() {
    var initialState = {
      users: [],
      loading: false,
      error: null
    };

    return (state = initialState, action: ILegacyUserAction) => {
      switch (action.type) {
        case 'legacy-users/loading':
          return {
            ...state,
            loading: true
          };
        case 'legacy-users/load-failed':
          return {
            ...state,
            loading: false,
            error: action.error
          };
        case 'legacy-users/loaded':
          return {
            ...state,
            loading: false,
            error: null,
            users: action.legacyUsers
          };
      };

      return state
    };
  });
