import * as validation from '@reliance/forms-js/lib/validation';
import { Account } from '@reliance/types/models/Account';
import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-calculate';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { react2angular } from 'react2angular';
import { IServiceErrorResponse } from "relcore-central";
import ErrorMessage from '../../common/ErrorMessage';
import { Input, Select } from '../../form/render';
import { CashPaymentForm } from './CashPaymentForm';
import { CheckPaymentForm } from './CheckPaymentForm';
import { CreditCardPaymentForm } from './CreditCardPaymentForm';
import { GeneralCreditDebitForm } from './GeneralCreditDebitForm';
import { MoneyOrderPaymentForm } from './MoneyOrderPaymentForm';
import { MoneyTransferPaymentForm } from './MoneyTransferPaymentForm';
import { PaymentType } from "./payment-factory";
import { IPaymentResult, IPaymentService } from "./payment-service";

type AddPaymentModalProps = {
  open: boolean
  account: Account
  paymentService: IPaymentService
  excludeTypes: [PaymentType]
  title?: string
  onClose(success?: boolean): void
}

function promiseDebounce (exec: (...args: any[]) => Promise<any>, interval: number): () => ReturnType<typeof exec> {
  let handle: NodeJS.Timeout;
  let resolves: Array<(value?: unknown) => void> = [];

  return async (...args: unknown[]) => {
    clearTimeout(handle);
    handle = setTimeout(
      () => {
        const result = exec(...args);
        resolves.forEach(resolve => resolve(result));
        resolves = [];
      },
      interval
    );

    return new Promise(resolve => resolves.push(resolve));
  };
}

function getCardExpirationParts(expiration: string) {
  var expMonth, expYear;
  if (expiration && expiration.indexOf('/') > -1) {
    const expParts = expiration.split('/');
    expMonth = expParts[0];
    expYear = expParts[1];
  }
  return {
    expMonth,
    expYear
  };
}

// Split up the expiration into month and year fields
const expirationDecorator = createDecorator(
  {
    field: 'paymentMethod.info.cardExpiration',
    updates: {
      'paymentMethod.info.expMonth': (cardExpiration) => getCardExpirationParts(cardExpiration).expMonth,
      'paymentMethod.info.expYear': (cardExpiration) => '20' + getCardExpirationParts(cardExpiration).expYear
    },
  }
);

// Calculate the fee on amount change
const feeDecorator = (getFeesFunction: (amount: number) => Promise<number>) => createDecorator(
  {
    field: ['amount', 'type'],
    updates: {
      fee: (changedValue, allValues: any) => {
        if (!(allValues.amount > 0 && allValues.type == PaymentType.CREDIT_CARD)) {
          return 0;
        }

        return getFeesFunction(allValues.amount);
      }
    }
  }
);

// Calculate the total on fee change
const totalDecorator = createDecorator(
  {
    field: ['fee', 'type'],
    updates: {
      total: (changedValue, allValues: any) => {
        if (!(allValues.fee > 0 && allValues.amount > 0 && allValues.type == PaymentType.CREDIT_CARD)) {
          return 0;
        }

        return (Math.round(
          (parseFloat(allValues.fee) + parseFloat(allValues.amount)) * 100
        ) / 100).toFixed(2);
      }
    }
  }
);

const AddPaymentModal: React.FC<AddPaymentModalProps> = (props) => {
  const onSubmit = (payment) => {
    return props.paymentService.postPayment(payment)
      .then((result: IPaymentResult) => {
        if (!result.success) {
          const errorMessage = result.message ? result.message : 'An unexpected error occurred';
          return { [FORM_ERROR]: errorMessage };
        } else {
          props.onClose(true);
          return null;
        }
      })
      .catch((err: IServiceErrorResponse) => {
        return { [FORM_ERROR]: err };
      });
  };

  const getFeesFunction = promiseDebounce(props.paymentService.getFees, 500);

  return (
    <Modal show={props.open} onHide={props.onClose}>
      <FinalForm
        initialValues={{
          ani: props.account.ani
        }}
        onSubmit={onSubmit}
        decorators={[
          expirationDecorator,
          feeDecorator(getFeesFunction),
          totalDecorator
        ]}
        mutators={{
          resetPaymentDataOnTypeChange: (args, formState, tools) => {
            tools.changeValue(formState, '', () => ({
              type: formState.lastFormState.values.type,
              amount: formState.lastFormState.values.amount
            }));
          },
          loadBilling: (args, state, utils) => {
            if (props.account.billing) {
              utils.changeValue(state, 'paymentMethod.info.nameOnCard', () => props.account.billing.firstName + ' ' + props.account.billing.lastName);
              utils.changeValue(state, 'paymentMethod.info.address', () => (props.account.billing.address1 + ' ' + props.account.billing.address2).trim());
              utils.changeValue(state, 'paymentMethod.info.city', () => props.account.billing.city);
              utils.changeValue(state, 'paymentMethod.info.state', () => props.account.billing.state);
              utils.changeValue(state, 'paymentMethod.info.zip', () => props.account.billing.zip);
            }
          }
        }}
        render={({ handleSubmit, form, submitting, pristine, values, hasValidationErrors, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{props.title ?? 'Payment'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Select
                name="type"
                label="Type"
                validationRules={[
                  validation.required
                ]}
                options={
                  Object.values(PaymentType)
                    .filter((t) => props.excludeTypes == null || props.excludeTypes.indexOf(t) == -1)
                    .sort((a,b) => a.localeCompare(b))
                    .map(t => ({
                      value: t,
                      text: t
                    }))
                } />
              <FormSpy
                subscription={{ values: true}}
                onChange={props => {
                  if (values.type != undefined && props.values.type != values.type) {
                    // Reset the previous payment type data on the type changing
                    setTimeout(() => form.mutators.resetPaymentDataOnTypeChange(), 100);
                  }
                }} />

              <div className="row">
                <div className="col-md-4">
                  <Input
                    name="amount"
                    placeholder="0.00"
                    label="Amount"
                    validationRules={[
                      validation.required,
                      validation.numeric,
                      validation.minValue(0.01)
                    ]} />
                </div>
                <div className="col-md-4">
                  <Input
                    name="fee"
                    label="Fees"
                    readOnly={true} />
                </div>
                <div className="col-md-4">
                  <Input
                    name="total"
                    label="Total"
                    readOnly={true} />
                </div>
              </div>

              {(values['type'] === PaymentType.GENERAL_CREDIT || values['type'] === PaymentType.GENERAL_DEBIT) &&
                <GeneralCreditDebitForm />
              }

              {values['type'] === PaymentType.CASH &&
                <CashPaymentForm />
              }

              {values['type'] === PaymentType.CHECK &&
                <CheckPaymentForm />
              }

              {values['type'] === PaymentType.CREDIT_CARD &&
                <CreditCardPaymentForm
                  currentValues={values}
                  account={props.account}
                  form={form} />
              }

              {values['type'] === PaymentType.MONEY_ORDER &&
                <MoneyOrderPaymentForm />
              }

              {values['type'] === PaymentType.MONEY_TRANSER &&
                <MoneyTransferPaymentForm />
              }

              {submitError &&
                <ErrorMessage
                  error={submitError}
                  httpGenericMessage={submitError.message}
                  httpCodeMessages={{
                    400: '{message}', // Use the API-supplied message
                    402: 'The payment failed or was declined',
                    404: 'Saved credit card was not found',
                    409: 'There is a payment hold on this account',
                    423: 'Required signature form has not been completed',
                  }} />
              }
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => props.onClose()} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting || pristine || hasValidationErrors}>
                {submitting ? 'Submitting' : 'Submit'}
              </Button>
            </Modal.Footer>
          </form>
        )}>
      </FinalForm>
    </Modal>
  )
}

angular
  .module('relcore.account')
  .component('addPaymentModal', react2angular(
    AddPaymentModal,
    ['open', 'account', 'paymentService', 'excludeTypes', 'title', 'onClose']
  ));