import { IRootScope } from '../models/root-scope';

angular
  .module('relcore.directives')
  .directive('ifPermissionKeep', ['$animate', '$compile', '$rootScope', function($animate, $compile, $rootScope: IRootScope) {
    return {
      multiElement: true,
      transclude: 'element',
      priority: 600,
      terminal: true,
      restrict: 'A',
      $$tlb: true,
      link: function ($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;

        let getBlockNodes = function (nodes) {
          var node = nodes[0];
          var endNode = nodes[nodes.length - 1];
          var blockNodes = [node];

          do {
            node = node.nextSibling;
            if (!node) break;
            blockNodes.push(node);
          } while (node !== endNode);

          return angular.element(blockNodes);
        };

        $scope.$watch($attr.ifPermissionKeep, function ifPermissionKeepWatchAction(value) {
          let oneOfPermissionsArray;
          if (typeof value === 'string') {
            oneOfPermissionsArray = value.split(',');
          } else {
            oneOfPermissionsArray = value;
          }

          let userHasOneOfPermissions = $rootScope.user && $rootScope.user.hasOneOfPermissions(oneOfPermissionsArray);

          if (userHasOneOfPermissions) {
            if (!childScope) {
              $transclude(function (clone, newScope) {
                childScope = newScope;
                clone[clone.length++] = $compile.$$createComment('end ngIfPermissionKeep', $attr.ngIf);
                // Note: We only need the first/last node of the cloned nodes.
                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                // by a directive with templateUrl when its template arrives.
                block = {
                  clone: clone
                };
                $animate.enter(clone, $element.parent(), $element);
              });
            }
          } else {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }
            if (block) {
              previousElements = getBlockNodes(block.clone);
              $animate.leave(previousElements).done(function (response) {
                if (response !== false) previousElements = null;
              });
              block = null;
            }
          }
        });
      }
    };
  }]);