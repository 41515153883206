import React from 'react';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { formatCurrency } from '../format/Currency';
import { formatDate } from '../format/DateTime';
import { formatDuration } from '../format/Duration';
import { formatPhoneNumber } from '../format/PhoneNumber';
import { ICardAccountActivity } from '../models/card-account';
import BaseHtmlTable from '../table/BaseHtmlTable';

type CardAccountActivityTableProps = {
  data?: ICardAccountActivity[]
  paginatedData?: IPaginatedResponse<ICardAccountActivity>
}

const CardAccountActivityTable = (props: CardAccountActivityTableProps) => {
  const data = props.data ? props.data : props.paginatedData.data;

  const columns = [
    { key: 'date', name: 'Date', style: {width: 130 } },
    { key: 'amount', name: 'Amount', style: {textAlign: 'right'}, cellStyle: {textAlign: 'right'} },
    { key: 'runningTotal', name: 'Running Total'},
    { key: 'enteredBy', name: 'Entered By' },
    { key: 'description', name: 'Description' }
  ];

  let runningTotal = 0;
  const rows = data.reverse().map(d => {
    runningTotal += d.amount;
    return {
      _meta: {
        style: {
          color: d.amount > 0 ? 'green' : undefined
        }
      },
      id: d.id,
      date: formatDate(d.dateSource.date),
      amount: formatCurrency(d.amount),
      enteredBy: d.enteredBy,
      description: d.sciDynCdr == null ? d.description : `${d.description} (${formatDate(d.sciDynCdr.setupDate.date)}, ${formatPhoneNumber(d.sciDynCdr.calledNumber)}, ${formatDuration(d.sciDynCdr.iTalkDur)})`,
      runningTotal: formatCurrency(runningTotal)
    }
  }).reverse();

  return (
    <BaseHtmlTable
      baseKey="card-account-activity"
      columns={columns}
      rows={rows}
      />
  )
}

export default CardAccountActivityTable;
