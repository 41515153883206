import { maxLength, minLength, required } from '@reliance/forms-js/lib/validation';
import { Destination } from '@reliance/types/models/SpeedDial';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Form } from 'react-final-form';
import { TextInput } from '../form/TextInput';

type AddEditModalProps = {
  destination?: Destination
  onSave(destination: Destination): Promise<void>
  onClose(): void
}

const AddEditModal: React.FC<AddEditModalProps> = (props) => {
  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={props.destination}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        render={({ handleSubmit, submitting, hasValidationErrors }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <TextInput
                label="Text (EN)"
                validationRules={[required, minLength(1), maxLength(50)]}
                name="textEN" />

              <TextInput
                label="Text (ES)"
                validationRules={[required, minLength(1), maxLength(50)]}
                name="textES" />

              <TextInput
                label="Destination"
                validationRules={[required, minLength(1), maxLength(50)]}
                name="destination" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || hasValidationErrors}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  )
}

export default AddEditModal;