export interface IPaymentFactory {
  createNewPayment(): IPayment
  createNewPaymentMethod(type: string): IPaymentMethod
  getPaymentMethod(type: string): IPaymentMethod
}

export interface IPayment {
  amount: number
  paymentMethod: IPaymentMethod
  ani?: string;
  fee?: number;
  type: PaymentType;
}

export interface IPaymentMethod {
  readonly info: IPaymentMethodInfo
}

export type IPaymentMethodInfo = ICreditCardInfo | ICashInfo | ICheckInfo |
  IMoneyOrderInfo | IMoneyTransferInfo | object;

export type ICreditCardInfo = INewCreditCardInfo | ISavedCreditCardInfo;

export interface INewCreditCardInfo extends INamed, ISecurityCoded {
  nameOnCard: string
  address: string
  city: string
  state: string
  cardNumber: string
  expMonth: string
  expYear: string
  zip: string
  save: boolean
}

export interface ISavedCreditCardInfo extends ISecurityCoded {
  savedCreditCardId: string
}

export interface ICheckInfo {
  bankName: string
  checkNumber: string
  routingNumber: string
  accountNumber: string
  idType: string
  idNumber: string
  idState: string
}

export interface ICashInfo extends INamed {
}

export interface IMoneyOrderInfo extends INamed {
  moneyOrderId: string
}

export interface IMoneyTransferInfo extends INamed {
  transferAgent: {
    id: number
    name: string
  };
  transferNumber: string
}

export enum PaymentType {
  CREDIT_CARD = 'Credit Card Payment',
  CASH = 'Cash Payment',
  CHECK = 'Check Payment',
  MONEY_TRANSER = 'Money Transfer',
  MONEY_ORDER = 'Money Order',
  GENERAL_DEBIT = 'General Debit',
  GENERAL_CREDIT = 'General Credit'
}

export enum CreditCardPaymentType {
  NEW_CREDIT_CARD = 'New Credit Card',
  SAVED_CREDIT_CARD = 'Saved Credit Card'
}

interface ISecurityCoded {
  cardSecurityCode: string
}

interface INamed {
  firstName: string
  lastName: string
}

class GeneralCreditDebitMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: DebitCreditInfo;

  constructor(type: PaymentType) {
    this.type = type;
    this.info = { explanation: '' };
  }
}

export type DebitCreditInfo = {
  explanation: string;
}

class CashPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: ICashInfo;

  constructor() {
    this.type = PaymentType.CASH;
    this.info = new CashInfo();
  }
}

class CashInfo implements ICashInfo {
  firstName: string;
  lastName: string;
}

class CheckPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: ICheckInfo;

  constructor() {
    this.type = PaymentType.CHECK;
    this.info = new CheckInfo();
  }
}

class CheckInfo implements ICheckInfo {
  bankName: string;
  checkNumber: string;
  routingNumber: string;
  accountNumber: string;
  idType: string;
  idNumber: string;
  idState: string;
}

class NewCreditCardPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: INewCreditCardInfo;

  constructor() {
    this.type = CreditCardPaymentType.NEW_CREDIT_CARD;
    this.info = new NewCreditCardInfo();
  }
}

class NewCreditCardInfo implements INewCreditCardInfo {
  nameOnCard: string;
  address: string;
  city: string;
  state: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  zip: string;
  save: boolean;
  firstName: string;
  lastName: string;
  cardSecurityCode: string;
}

class SavedCreditCardPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: ISavedCreditCardInfo;

  constructor() {
    this.type = CreditCardPaymentType.SAVED_CREDIT_CARD;
    this.info = new SavedCreditCardInfo();
  }
}

class SavedCreditCardInfo implements ISavedCreditCardInfo {
  savedCreditCardId: string;
  cardSecurityCode: string;
}

class MoneyTransferPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: IMoneyTransferInfo;

  constructor() {
    this.type = PaymentType.MONEY_TRANSER;
    this.info = new MoneyTransferInfo();
  }
}

class MoneyTransferInfo implements IMoneyTransferInfo {
  transferAgent: {
    id: number,
    name: string
  };
  transferNumber: string;
  firstName: string;
  lastName: string;
}

class MoneyOrderPaymentMethod implements IPaymentMethod {
  readonly type: string;
  readonly info: IMoneyOrderInfo;

  constructor() {
    this.type = PaymentType.MONEY_ORDER;
    this.info = new MoneyOrderInfo();
  }
}

class MoneyOrderInfo implements IMoneyOrderInfo {
  moneyOrderId: string;
  firstName: string;
  lastName: string;
}

class Payment implements IPayment {
  amount: number;
  paymentMethod: IPaymentMethod;
  ani?: string;
  fee?: number;
  type: PaymentType;
}

class PaymentFactory implements IPaymentFactory {
  cachedMethods: {[name: string]: IPaymentMethod}

  constructor() {
    this.cachedMethods = {};
  }

  createNewPayment(): IPayment {
    return new Payment();
  }

  createNewPaymentMethod(type: string): IPaymentMethod {
    switch(type) {
      case PaymentType.GENERAL_CREDIT:
      case PaymentType.GENERAL_DEBIT:
        return new GeneralCreditDebitMethod(type);
      case PaymentType.CASH:
        return new CashPaymentMethod();
      case PaymentType.CHECK:
        return new CheckPaymentMethod();
      case PaymentType.MONEY_ORDER:
        return new MoneyOrderPaymentMethod();
      case PaymentType.MONEY_TRANSER:
        return new MoneyTransferPaymentMethod();
      case CreditCardPaymentType.NEW_CREDIT_CARD:
        return new NewCreditCardPaymentMethod();
      case CreditCardPaymentType.SAVED_CREDIT_CARD:
        return new SavedCreditCardPaymentMethod();
      default:
        return null;
    }
  }

 getPaymentMethod(type: string): IPaymentMethod {
   let method: IPaymentMethod = this.cachedMethods[type];
   if (method) {
     return method;
   } else {
     this.cachedMethods[type] = this.createNewPaymentMethod(type);
     return this.cachedMethods[type];
   }
  }
}

angular
  .module('relcore.account')
  .value('MoneyTransferAgents', [
    { name: 'MoneyGram', id: 1},
    { name: 'Western Union', id: 2}
  ])
  .factory('PaymentFactory', () => {
    return new PaymentFactory();
  });
