angular
  .module('relcore.support')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('phone-support-inbox', {
        controller: 'PhoneSupportInboxController',
        url: '/support/phone',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Phone Support Inbox',
          parent: 'authenticated'
        },
        template: require('./phone.html').default
      }
    )
  ])
  .controller('PhoneSupportInboxController',
    ['$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams', '$stateParams', 'alertService',
    function($rootScope, $scope, $state, relianceApi, NgTableParams, $stateParams, alertService) {
      $rootScope.title = 'Phone Support Inbox';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'dateRequested': 'asc'
        },
        filter: {}
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index("/inmate-support-request", params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
          });
        }
      });

      $scope.archive = data =>
        relianceApi.put(`/inmate-support-request/${data.id}`, { archived: true })
          .then((function() {
            alertService.success('The request was archived', true);
            $scope.gridOptions.reload();
          }), err => alertService.error('An unexpected error occurred while archiving', true))
      ;
    }
  ]);
