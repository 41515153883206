angular
  .module('relcore.mobile-device')
  .service('mobileDeviceViewReducer', function() {
    var initialState = {
    };

    return (state = initialState, action) => {
      switch (action.type) {
      };

      return state
    };
  });
