angular
  .module('relcore.api-role')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('api-role-view', {
        controller: 'APIRoleViewController',
        template: require('./api-role-edit-view.html').default,
        url: '/api-role/view/:id',
        parent: 'authenticated',
        params: { apiRole: null },
        ncyBreadcrumb: {
          label: '{{apiRole.name}}',
          parent: 'api-role-search'
        }
      })
  ])
  .controller('APIRoleViewController',
    ['$rootScope', '$scope', '$stateParams', 'store', 'ReduxMapToScope', 'apiRoleViewActions',  'relianceApi', 'alertService',
    function($rootScope, $scope, $stateParams, store, ReduxMapToScope, roleActions, relianceApi, alertService) {
      $rootScope.title = 'Loading...';

      ReduxMapToScope($scope, function(state) {
        $scope.apiRole = state.apiRole.view.apiRole;

        if ($scope.apiRole != null) {
          $rootScope.title = `${$scope.apiRole.id}`;
          $scope.model = {
            apiRole : $scope.apiRole
          }
        }
      });

      // Load the role
      if ($stateParams.apiRole != null) {
        store.dispatch(roleActions.setAPIRole($stateParams.apiRole));
      } else {
        store.dispatch(roleActions.loadAPIRole($stateParams.id));
      }

      $scope.saving = false;
      $scope.save = function () {
        $scope.saving = true;
        let changes = $scope.apiRole;
        return relianceApi.put(`/api-role/${changes.id}`, changes)
          .then((function(res) {
            $scope.saving = false;
            $scope.apiRole = Object.assign({}, changes);
            return alertService.success('The api role was saved', true);
          }), err => alertService.error('An error occurred while saving', true));
      };
    }
 ]);
