angular
  .module('relcore.filters')
  .filter('accountName', () =>
    function(value) {
      if (!((value.firstName != null) && (value.lastName != null))) { return ''; }

      const personalName = `${value.firstName} ${value.lastName}`;
      if ((value.companyName == null) || (value.companyName === '')) {
        return personalName;
      } else if ((value.companyName != null) && (value.firstName === '') && (value.lastName === '')) {
        return value.companyName;
      } else {
        return `${value.companyName} (${personalName})`;
      }
    }
);
