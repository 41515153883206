angular
  .module('relcore.inmate-device')
  .directive('inmateDevices', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        gridOptions: '=',
        columns: '=?'
      },
      template: require('./inmate-devices.html').default,
      controller: ['$scope', function($scope) {
        $scope.showColumn = name => ($scope.columns == null) || ($scope.columns.indexOf(name) > -1)
      }]
    })
  );
