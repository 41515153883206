angular
  .module('relcore.inmate')
  .directive('supportMessageSend', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        message: '=',
        sending: '=',
        onSend: '='
      },
      controller: ['$scope', function($scope) {
        $scope.send = () => $scope.onSend($scope.message);
      }],
      template: require('./support-message-send.html').default
    })
  );
