import { IApiResponse } from '../common/reliance-api-service';
import { IConfigurationText, IConfigurationTextService } from "./configuration-text-service";

interface IConfigurationTextScope {
  newConfigurationText: IConfigurationText
  oldConfigurationText: IConfigurationText
  selectedLanguage: string
  savePending: boolean
  saved: boolean
  error: string

  add(configText: IConfigurationText): void
  update(configText: IConfigurationText): void
  close(): void
}

angular
  .module('relcore.configuration-text')
  .controller('ConfigurationTextUpdateController', ['$scope', 'configurationTextService', 'facility', 'configurationText', '$uibModalInstance', ($scope: IConfigurationTextScope, configurationTextService:IConfigurationTextService, facility, configurationText, $uibModalInstance) => {
      $scope.newConfigurationText = {
        key: '',
        content: '',
        language: '',
        facilityId: facility.id
      };

      if (configurationText) {
        $scope.oldConfigurationText = {...configurationText, facilityId: facility.id};
      }

      $scope.selectedLanguage = '';

      $scope.add = () => {
        $scope.savePending = true;
        configurationTextService.add($scope.newConfigurationText)
          .then(resolve, reject);
      };

      $scope.update = () => {
        $scope.savePending = true;
        configurationTextService.update($scope.oldConfigurationText)
          .then(resolve, reject);
      };

      const resolve = (response: IApiResponse<any>) => {
        $scope.savePending = false;
        if (!response.data || !response.data.success) {
          $scope.error = 'An unexpected error occurred';
        } else {
          $scope.error = null;
          $uibModalInstance.close({saved: true});
        }
      };

      const reject = (err: any) => {
        if (err.status === 400) {
          $scope.error = 'Please verify the inputs are valid and try again';
        } else {
          $scope.error = 'An unexpected error occurred';
        }
        $scope.savePending = false;
      };

      $scope.close = () => $uibModalInstance.close({ saved: false });
    }
  ]);