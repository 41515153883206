import { Destination } from '@reliance/types/models/SpeedDial';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ConfirmModal from '../common/dialogs/ConfirmModal';
import { IPaginatedResponse } from '../common/reliance-api-service';
import BaseHtmlTable from '../table/BaseHtmlTable';
import AddEditModal from './AddEditModal';

type DestinationTableProps = {
  destinations: IPaginatedResponse<Destination>
  onRemove(destination: Destination): Promise<void>
  onEditSave(destination: Partial<Destination>): Promise<void>
}

const DestinationTable: React.FC<DestinationTableProps> = (props) => {
  const { destinations } = props;

  const [showRemove, setShowRemove] = useState<Destination>(null);
  const [showEdit, setShowEdit] = useState<Destination>(null);

  if (destinations == null) {
    return null;
  }

  const columns = [
    { key: 'textEN', name: 'Text (EN)' },
    { key: 'textES', name: 'Test (ES)' },
    { key: 'destination', name: 'Destination' },
    { key: 'options', name: '' }
  ];

  const rows = destinations.data.map(destination => ({
    ...destination,
    options: (
      <div>
        <Button onClick={() => setShowRemove(destination)} size="sm" variant="outline-danger">Remove</Button>
        <Button onClick={() => setShowEdit(destination)} size="sm" variant="outline-info" className="ml-2">Edit</Button>
        <ConfirmModal
          key={`remove-speed-dial-destination-${destination.id}`}
          show={showRemove == destination}
          title="Remove Destination"
          acceptText="Remove"
          onAccept={() => props.onRemove(destination)}
          onClose={() => setShowRemove(null)}>
          Are you sure you want to remove "{destination.destination}"?
        </ConfirmModal>
        {showEdit == destination &&
          <AddEditModal
            destination={destination}
            onSave={props.onEditSave}
            onClose={() => setShowEdit(null)}
          />
        }
      </div>
    )
  }));

  return (
    <BaseHtmlTable
      baseKey="speed-dial-destination"
      columns={columns}
      rows={rows}
      />
  );
}

export default DestinationTable;