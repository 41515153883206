angular
  .module('relcore.cs-record')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('cs-records', {
        controller: 'csRecordController',
        url: '/cs-record',
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: {
          label: 'Customer Service Record'
        }
      }
    )

  ])
  .controller('csRecordController',
    ['$scope', '$uibModal', '$filter', 'NgTableParams', 'relianceApi', 'csRecordService', 'UserService', 'moment', 'alertService',
    function($scope, $uibModal, $filter, NgTableParams, relianceApi, csRecordService, UserService, moment, alertService) {
      $scope.users = [];

      $scope.typeId = "";
      $scope.spokeWith = "";
      $scope.comments = "";
      $scope.doNotClear = false;

      UserService.getUsers()
        .then(data => $scope.users = data);

      $scope.recordGridOptions = new NgTableParams({
          page: 1,
          count: 10,
          sorting: { 'date': 'desc' },
          filter: {
            date: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
          }
          }
        }, {
          total: 0,
          getData(params) {
            return csRecordService.getByAni($scope.ani, params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(data) {
                params.total(data.total);
                return data.results;
            });
          }
        });

      $scope.save = function(form) {
        const result = csRecordService.postNewRecord($scope.typeId, $scope.spokeWith, $scope.comments, $scope.ani);

        const successCallback = function() {
          alertService.success("New customer service record added.");
          $scope.recordGridOptions.reload();

          if (!$scope.doNotClear) {
            $scope.typeId = "";
            $scope.spokeWith = "";
            $scope.comments = "";
            if (form != null) {
              return form.$setPristine();
            }
          }
        };

        const errorCallback = () => alertService.error("An error occurred while adding customer service record.");

        result.then(successCallback, errorCallback);
      };
    }
  ]);
