import React from 'react';
import { react2angular } from 'react2angular';
import { CPCChangelog } from './CPC';

// If we want a Reliance changelog, we can set up a config value to pick which component to use
const ChangelogComponent: React.FC<any> = true ? CPCChangelog : CPCChangelog;

angular
  .module('relcore.changelog')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('changelog', {
        component: 'changelog',
        url: '/changelog',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Changelog'
        }
      }
    )
  ])
  .component('changelog', react2angular(
    ChangelogComponent
  ));