angular
  .module('relcore.schedule')
  .directive('schedule', () =>
    ({
      restrict: 'E',
      scope: {
        editMode: '=?',

        // Edit mode = true
        currentSchedule: '=?',
        updating: '=?',
        onNewSchedule: '=?',

        // Edit mode = false
        startDay: '=?',
        lastDay: '=?',

        searching: '=?',
        availability: '=?',
        onBlockSelect: '=?'
      },
      controller: ['$scope', 'moment', 'relianceApi', function($scope, moment, relianceApi) {
        let day, dayBlocks, time;
        $scope.editMode = $scope.editMode === true;

        const calculateMinMaxTimes = function() {
          if ($scope.availability === null) {
            return { min: 800, max: 1700 };
          }

          const dates = $scope.availability.map(a => moment(a.reservationStartDate.date));
          const times = dates.map(a => parseInt(`${a.hour()}${(`00${Math.floor((a.minute()/60)*100)}`).slice(-2)}`));
          const earliest = times.reduce((prev, cur) => (prev === null) || (cur < prev ? cur : prev));
          const latest = times.reduce((prev, cur) => (prev === null) || (cur > prev ? cur : prev));

          return { min: earliest, max: latest };
        };

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const timeToNumeric = function(time) {
          const parts = time.split(':');
          if ((parseInt(parts[0]) === 23) && (parseInt(parts[1]) === 59)) {
            parts[0] = 24;
            parts[1] = 0;
          }
          return parseInt(`${parts[0]}${(`00${(parts[1]/60)*100}`).slice(-2)}`);
        };
        const numericToTime = function(numeric) {
          let minutes = ((numeric % 100) / 100) * 60;
          let hours = Math.floor(numeric / 100);
          let seconds = 0;
          if (hours === 24) {
            hours = 23;
            minutes = 59;
            seconds = 59;
          }
          return `${hours}:${(`00${minutes}`).slice(-2)}:${(`00${seconds}`).slice(-2)}`;
        };

        // Build array of which blocks should be selected
        const buildScheduleData = function(schedule) {
          const scheduleData = [];
          if ((schedule.entries == null)) { return scheduleData; }
          let entry:any;
          for (entry of Array.from(schedule.entries)) {
            let start = timeToNumeric(entry.start);
            const stop = timeToNumeric(entry.stop);

            if ((scheduleData[days[entry.day]] == null)) {
              scheduleData[days[entry.day]] = [];
            }

            while (true) {
              scheduleData[days[entry.day]].push(start);
              start += 50;
              if (start >= stop) { break; }
            }
          }
          return scheduleData;
        };

        if ($scope.editMode) {
          $scope.minMax = { min: 0, max: 2400 };
          $scope.days = days;
        } else {
          $scope.minMax = calculateMinMaxTimes();
          $scope.days = [];

          // Build list of days to display
          const numDays = $scope.lastDay.diff($scope.startDay, 'days') + 1;
          const firstDate = moment($scope.startDay);
          for (let i = 0, end = numDays, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
            $scope.days.push(moment(firstDate));
            firstDate.add(1, 'days');
          }
        }

        $scope.colSize = `col-sm-${Math.floor(12/$scope.days.length)}`;

        const scheduleBlocks = {};
        const scheduleData = buildScheduleData($scope.currentSchedule);
        for (day of Array.from($scope.days)) {
          dayBlocks = {};
          time = $scope.minMax.min;
          while (true) {
            dayBlocks[time] = {
              open: true,
              closed: false,
              selected: (scheduleData[day] != null ? scheduleData[day].indexOf(time) : undefined) > -1,
              day,
              time
            };
            time += 50;
            if (time >= $scope.minMax.max) { break; }
          }
          scheduleBlocks[day] = dayBlocks;
        }

        $scope.blocks = scheduleBlocks;
        $scope.selected = [];

        const calculateSchedule = function() {
          const schedule = [];
          for (day in $scope.blocks) {
            dayBlocks = $scope.blocks[day];
            let current = null;
            for (time in dayBlocks) {
              const block = dayBlocks[time];
              if (block.selected) {
                if (current === null) {
                  current = { day: days.indexOf(day), start: numericToTime(block.time) };
                }
                current.stop = numericToTime(block.time + 50);
              } else {
                if (current !== null) {
                  schedule.push(current);
                  current = null;
                }
              }
            }

            // If we never hit a non-selected block since starting a time range, it went to the end of the day and needs to be added still
            if (current !== null) {
              schedule.push(current);
              current = null;
            }
          }
          return schedule;
        };

        $scope.onBlockSelect = function(block) {
          let existingIdx;
          if ((existingIdx = $scope.selected.indexOf(block)) > -1) {
            $scope.selected.splice(existingIdx, 1);
            return block.selected = false;
          } else {
            $scope.selected.push(block);
            return block.selected = true;
          }
        };

        return $scope.updateSchedule = () =>
          $scope.onNewSchedule({
            type: $scope.currentSchedule.type,
            entries: calculateSchedule()
          })
        ;
      }
      ],
      template: require('./schedule.html').default
    })
  );
