import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';

type ConfirmModalProps = {
  title: string
  show: boolean
  acceptText?: string
  rejectText?: string
  onAccept(): Promise<any>
  onClose(): void
}

const ConfirmModal: React.FC<ConfirmModalProps> = props => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            {props.children}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onClose}
          disabled={submitting}>
          {props.rejectText || 'Cancel'}
        </Button>
        <Button
          onClick={() => {
            setSubmitting(true);
            props
              .onAccept()
              .then(() => setSubmitting(false))
              .catch(() => setSubmitting(false))
              .finally(props.onClose);
          }}
          variant="primary"
          type="submit"
          disabled={submitting}>
          {props.acceptText || 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;