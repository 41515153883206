import { email, maxLength, minLength, required, usPhoneNumber } from '@reliance/forms-js/lib/validation';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { Select } from '../form/Select';
import { TextInput } from '../form/TextInput';
import { IInmate } from '../inmate/inmate-service';
import { EventNotificationActionType } from './event-notification-factory';

export type AddAlertActionData = {
  type: EventNotificationActionType
  destination: string
  destinationName: string
}

export type AddAlertData = {
  inmateId?: string
  outsideParty?: string
  message: string
  actions: [AddAlertActionData]
}

type TextAlertAddModalProps = {
  inmates: IInmate[]
  onSubmit(data: AddAlertData): Promise<any>
  onClose(): void
}

const TextAlertAddModal: React.FC<TextAlertAddModalProps> = (props) => {
  return (
    <Modal show={true} onHide={props.onClose} dialogClassName="modal-xxl">
      <Form
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          actions: [{ type: 'Email', destination: '', destinationName: '' }]
        }}
        onSubmit={props.onSubmit}
        render={({ handleSubmit, form, submitting, hasValidationErrors, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Add Text Message Alert</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col>
                  <TextInput
                    name="message"
                    label="Text Message Keyword/Phrase"
                    validationRules={[required, minLength(4)]} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Select
                    name="inmateId"
                    label="Inmate">
                    <option value="">-- All Inmates --</option>
                    {props.inmates.map(inmate =>
                      <option key={`text-alert-add-inmate-${inmate.id}`} value={inmate.id}>{inmate.firstName} {inmate.lastName} (#{inmate.account})</option>
                    )}
                  </Select>
                </Col>

                <Col>
                  <TextInput
                    name="outsideParty"
                    label="Outside Party #"
                    placeholder="NNN-NNN-NNNN"
                    validationRules={[usPhoneNumber, maxLength(14)]} />
                </Col>
              </Row>

              <FieldArray name="actions">
                {({ fields }: FieldArrayRenderProps<AddAlertActionData, HTMLElement>) => (
                  <div>
                    {fields.map((name, index) => (
                      <Row key={`text-alert-add-action-${name}`}>
                        <Col>
                          <Select
                            name={`${name}.type`}
                            label="Action"
                            validationRules={[required]}>
                            <option>Email</option>
                            <option>Text</option>
                            <option>Call</option>
                          </Select>
                        </Col>

                        <Col>
                          <TextInput
                            name={`${name}.destination`}
                            label="Destination"
                            placeholder={fields.value[index].type == 'Email' ? 'you@example.com' : 'NNN-NNN-NNNN'}
                            validationRules={[
                              required,
                              maxLength(fields.value[index].type == 'Email' ? 150 : 14),
                              (fields.value[index].type == 'Email' ? email : usPhoneNumber)
                            ]} />
                        </Col>

                        <Col>
                          <TextInput
                            name={`${name}.destinationName`}
                            label="Destination Name"
                            placeholder="Officer John Smith"
                            validationRules={[required, maxLength(50)]} />
                        </Col>

                        <Col style={{maxWidth: 75}}>
                          {index > 0 &&
                            <FormGroup>
                              <Button variant="secondary" type="button" onClick={() => fields.remove(index)} style={{marginTop: 28}}>
                                X
                              </Button>
                            </FormGroup>
                          }
                        </Col>
                      </Row>
                    ))}
                    <Button
                      disabled={fields.length >= 5}
                      variant="secondary"
                      type="button"
                      onClick={() => fields.push({ type: EventNotificationActionType.Email, destination: '', destinationName: '' })}>
                      Add Action
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={props.onClose} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting || pristine || hasValidationErrors}>
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Modal.Footer>
        </form>
      )} />
    </Modal>
  );
}

export default TextAlertAddModal;