angular
  .module('relcore.online-order')
  .directive('onlineOrders', () =>
    ({
      restrict: 'E',
      scope: {
        columns: '=?',
        showFilter: '=',
        gridOptions: '=',
        onViewDetails: '=?'
      },
      controller: ['$rootScope', '$scope', function($rootScope, $scope) {
        $scope.facilities = $rootScope.facilities;
        $scope.showFilters = ($scope.showFilter == null) || ($scope.showFilter === true);
        return $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }
      ],
      template: require('./online-orders.html').default
    })
  );
