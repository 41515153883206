angular.module('relcore.search')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('search', {
        controller: 'SearchController',
        template: require('./search.html').default,
        url: '/search',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Search'
        }
      }
    )
  ])
  .controller('SearchController',
    ['$rootScope', '$scope', '$filter', 'SearchService', 'NgTableParams',
    function($rootScope, $scope, $filter, SearchService, NgTableParams) {
      $rootScope.title = 'Customer Search';

      $scope.model = {
        firstName: '',
        lastName: '',
        state: '?',
        email: '',
        dob: '',
        creditCard: ''
      };

      $scope.results = [];
      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100
      }, {
        total: 0,
        getData(params) {
          let data = $scope.results;
          params.total($scope.results.length);

          if (params.sorting()) {
            data = $filter('orderBy')(data, params.orderBy());
          }

          return data.slice((params.page()-1)*params.count(), params.page() * params.count());
        }
      });

      $scope.reset = () => $scope.model.state = '?';

      return $scope.search = () => {
        $scope.loading = true;
        SearchService.search(
          $scope.model.firstName,
          $scope.model.lastName,
          $scope.model.email,
          $scope.model.state,
          $scope.model.creditCard,
          $scope.model.dob
        ).then(function(results) {
          $scope.results = results.data;
          $scope.loading = false;
          $scope.gridOptions.reload();
        });
      }
    }
  ]);
