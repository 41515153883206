import React from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import StateGroupedCheckboxes, { toggleCheckAllForState } from '../facility/StateGroupedCheckboxes';
import { IFacility } from '../models/facility';
import { ReferenceMaterial } from '../models/ReferenceMaterial';

type CopyModalFormData = {
  ids: number[]
  facilityIds: number[]
}

type CopyModalProps = {
  referenceMaterial: ReferenceMaterial[]
  facilities: IFacility[]
  onSave(formData: CopyModalFormData)
  onClose(): void
}

const CopyModal: React.FC<CopyModalProps> = (props) => {
  const initialValues: CopyModalFormData = {
    ids: props.referenceMaterial.map(r => r.id),
    facilityIds: []
  }
  return (
    <Modal show={true} onHide={props.onClose} dialogClassName="modal-full">
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        mutators={{
          toggleCheckAllForState
        }}
        render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Accordion defaultActiveKey="facilities">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="facilities">
                        {values.facilityIds.length > 0 ? `${values.facilityIds.length} facilities selected` : 'Select facilities'}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="facilities">
                        <Card.Body>
                          <StateGroupedCheckboxes
                            fieldName={'facilityIds'}
                            form={form}
                            facilities={props.facilities}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {props.referenceMaterial.length} reference material records will be copied to {values.facilityIds.length} facilities
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || values.facilityIds.length === 0}
                type="submit">
                Copy
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default CopyModal;