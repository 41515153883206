angular
  .module('relcore.cs-record')
  .service('csRecordService',
  	['$http', '$q', 'CacheFactory', 'relianceApi', 'CsRecord', 'config', 'User',
    function($http, $q, CacheFactory, relianceApi, CsRecordModel, config, UserModel) {

      const getByAni = function(ani, page, perPage, filter, orderBy) {
        let url = `/cs-record/by-account?ani=${ani}&page=${page}&perPage=${perPage}`;

        if (orderBy != null) {
          url += `&orderBy=${encodeURIComponent(orderBy)}`;
        }

        if (filter.date != null) {
          url += `&startDate=${filter.date.startDate.format('YYYY/MM/DD HH:mm:ss')}`;
          url += `&endDate=${filter.date.endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if (filter.user != null) {
          url += `&userId=${filter.user.id}`;
        }
        if (filter.contactType||('' !== '')) {
          url += `&contactTypeId=${filter.contactType}`;
        }
        if (filter.spokeWith != null) {
          url += `&spokeWith=${filter.spokeWith}`;
        }

        return relianceApi.get(url)
          .then(function(result) {
            const { data } = result;
            const records = [];
            for (let record of Array.from(data.results)) {
            	  records.push(new CsRecordModel(record));
            }
            data.results = records;
            return data;
        });
      };

      const postNewRecord = (typeId, spokeWith, comments, ani) =>
        relianceApi.post("/cs-record", {
          typeId,
          spokeWith,
          comments,
          ani
        })
      ;

      return {
        getByAni,
        postNewRecord
      };
    }

    ]);
