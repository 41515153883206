
export interface IEventNotificationTrigger {
  id?: number;
  name: string;
  eventType: string;
  actions: Array<IEventNotificationAction>;
  conditions: Array<IEventNotificationCondition>;
  status: string;
  maxEventDateTimePeriod: string;
  facilityId?: number;
}

export interface IEventNotificationAction {
  id?: number;
  actionType: EventNotificationActionType;
  destination: string;
  destinationName: string;
  includeRecording: boolean;
  actionTemplateId?: number;
  newActionTemplateName?: string;
  newActionTemplateContent?: string;
}

export interface IEventNotificationActionTemplate {
  id?: number;
  name?: string;
  template?: string;
}

export interface IEventNotificationCondition {
  id?: number;
  metadataPath: string;
  comparator: ConditionComparator;
  value: string;
  status: string;
}

export enum ConditionComparator {
  GT = '>',
  GTEQ = '>=',
  LT = '<',
  LTEQ = '<=',
  EQ = '=',
  STARTS_WITH = 'Starts With',
  ENDS_WITH = 'Ends With',
  CONTAINS = 'Contains',
  NOT_EMPTY = 'Not Empty',
}

export enum EventNotificationActionType {
  Email = 'Email',
  Text = 'Text',
  Call = 'Call'
}

class DefaultPREATrigger implements IEventNotificationTrigger {
  name: string;
  eventType: string;
  actions: Array<IEventNotificationAction>;
  conditions: Array<IEventNotificationCondition>;
  status: string;
  maxEventDateTimePeriod: string;
  facilityId?: number;

  constructor() {
    this.eventType = 'cdr.processed';
    this.status = 'Enabled';
    this.actions = [];
    this.conditions = [
      {
        metadataPath: 'variable_call_type',
        comparator: ConditionComparator.EQ,
        value: 'prea-report',
        status: 'Enabled'
      }
    ];
    this.maxEventDateTimePeriod = 'P1W';
  }
}

export const newPREATrigger = () => new DefaultPREATrigger;

class DefaultPREATriggerAction implements IEventNotificationAction {
  actionType: EventNotificationActionType;
  destination: string;
  destinationName: string;
  includeRecording: boolean;
  actionTemplateId?: number;
  newActionTemplateName?: string;
  newActionTemplateContent?: string;

  constructor() {
    this.actionType = EventNotificationActionType.Email;
    this.includeRecording = true;
    this.destination = null;
    this.destinationName = null;
  }
}

export const newPREATriggerAction = () => new DefaultPREATriggerAction;

class DefaultTextMessageAlertTrigger implements IEventNotificationTrigger {
  name: string;
  eventType: string;
  actions: Array<IEventNotificationAction>;
  conditions: Array<IEventNotificationCondition>;
  status: string;
  maxEventDateTimePeriod: string;
  facilityId?: number;

  constructor() {
    this.eventType = 'reliancetelephone.connect.text-message';
    this.status = 'Enabled';
    this.actions = [];
    this.conditions = [];
    this.maxEventDateTimePeriod = 'P1W';
  }
}

export const newTextMessageAlertTrigger = () => new DefaultTextMessageAlertTrigger;
