import { IAlertService } from '../../alert/alert-service';
import { IFacilitySpecialCondition } from '../../models/facility-special-condition';

angular
  .module('relcore.condition')
  .directive('conditionsList', () => {
    return {
      restrict: 'E',
      scope: {
        facilityId: '=?',
        ani: '=?',
        noShow: '=?',
        onFetch: '&',
        onLoad: '&',
        onError: '&',
        onDownload: '&',
        showDelete: '=?'
      },
      template: require('./list.html').default,
      controller: 'ConditionListController'
    };
  })
  .controller('ConditionListController',
    ['$scope', '$uibModal', 'relianceApi', 'NgTableParams', 'UserService', 'alertService', 'eventService', 'moment',
    function($scope, $uibModal, relianceApi, NgTableParams, userService, alertService:IAlertService, eventService:IEventService, moment) {
      $scope.users = [];
      userService
        .getUsers(false)
        .then(users => $scope.users = users);

      if (typeof $scope.onFetch === 'undefined') {
        $scope.onFetch = () => {
          eventService.onFetch('Special Conditions');
        };
      }

      if (typeof $scope.onLoad === 'undefined') {
        $scope.onLoad = () => {
          eventService.onFetch('Special Conditions');
        };
      }

      if (typeof $scope.onError === 'undefined') {
        $scope.onError = () => {
          eventService.onError('Special Conditions');
        };
      }

      $scope.showColumn = (column: string) => {
        return $scope.noShow == null || $scope.noShow.indexOf(column) == -1;
      };

      $scope.conditionGridOptions = new NgTableParams({
        page: 1,
        count: 2000,
        sorting: {
          'created.date': 'desc'
        },
        filter: {
          'created.date': {
            startDate: moment().subtract(10, 'year'),
            endDate: moment().endOf('day')
          },
          'ani': $scope.ani,
          'id': $scope.id
        }
      }, {
        total: 0,
        counts: [100,200,500,1000,2000],
        getData(params) {
          $scope.onFetch();
          const filter = {...params.filter()};
          filter.facilityId = $scope.facilityId;
          return relianceApi.index('/condition', params.page(), params.count(), filter, params.orderBy())
            .then((function(res) {
              params.total(res.data.total);
              // Signal that fetching is complete
              $scope.onLoad();
              $scope.conditions = res.data.results;
              return $scope.conditions;
            }), err => {
              // Signal that error occurred
              $scope.onError();
              alertService.error('An error occurred while loading the special conditions', true);
            });
        }
      });

      $scope.addCondition = function(facility = null) {
        const dialog = $uibModal.open({
          template: require('../create/create.html').default,
          controller: 'ConditionCreateController',
          resolve: {
            ani() { return $scope.ani; },
            facility() { return facility; }
          }
        });
        dialog.result.then(() => $scope.conditionGridOptions.reload());
      };

      $scope.delete = function(condition: IFacilitySpecialCondition) {
        const dialog = $uibModal.open({
          template: require('../remove/remove.html').default,
          controller: 'ConditionRemoveController',
          resolve: {
            condition() { return condition; }
          }
        });
        dialog.result.then(() => $scope.conditionGridOptions.reload());
      };

      $scope.download = function(condition: IFacilitySpecialCondition) {
        $scope.onDownload({conditions: [condition]});
      }

      $scope.downloadAll = function() {
        $scope.onDownload({conditions: $scope.conditions});
      }
    }
  ]);
