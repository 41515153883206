angular
  .module('relcore.credit-card')
  .directive('onlineCreditCardShortDetails', () => {
    return {
      restrict: 'E',
      scope: {
        creditCard: '='
      },
      template: require('./online-credit-card-short-details.html').default
    }
  });
