angular
  .module('relcore.facility')
  .factory('Facility', () => {
    return class Facility {
      id;
      title;
      name;
      state;

      constructor(data) {
        this.title = '';
        this.loadFromData(data);
      }

      loadFromData(data) {
        for (var k in data) {
          this[k] = data[k];
        }
        this.title = `${this.name} (${this.state})`;
      }
    }
  });
