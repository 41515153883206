import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { IRatePlan } from '../models/rate';

export interface IRatePlanService {
  retrieveAll(includeFacilities?:boolean): Promise<IRatePlan[]>
}

angular
  .module('relcore.rating')
  .service('ratePlanService', ['relianceApi',
    function(relianceApi:IRelianceApi): IRatePlanService {
      const retrieveAll = function(includeFacilities = false) {
        return relianceApi.get('/rate', {includeFacilities})
          .then(function(response:IApiResponse<IPaginatedResponse<IRatePlan>>) {
            return response.data.data as IRatePlan[];
          }
        );
      };

      return {
        retrieveAll
      }
    }
  ]);
