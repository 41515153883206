angular
  .module('relcore.api-user')
  .service('apiUserViewActions', ['relianceApi', function(relianceApi) {
    const clear = () => {
      return { type: 'api-user/view/clear' }
    };

    const setAPIUser = (apiUser) => {
      return { type: 'api-user/view/set-api-user', apiUser };
    };

    const loadAPIUser = (id) => {
      return dispatch => {
        dispatch({ type: 'api-user/view/loading' });

        return relianceApi.get(`/api-credential/${id}`)
          .then(
            (res => dispatch(setAPIUser(res.data))),
            (err => dispatch({ type: 'api-user/view/loading-failed', error: err }))
          );
      }
    };

    return {
      clear,
      setAPIUser,
      loadAPIUser
    };
  }]);
