import { IApiResultsResponse, IRelianceApi } from '../common/reliance-api-service';
import { IUser } from '../models/auth';

export interface IUserService {
  getUsers(activeOnly?: boolean): Promise<Array<IUser>>;
}

angular
  .module('relcore.user')
  .service('UserService',
    ['relianceApi', 'User', function(relianceApi: IRelianceApi, UserModel) {
      const getUsers = async function(activeOnly: boolean = true) {
        return relianceApi.get(`/staff?activeOnly=${activeOnly}`)
          .then(function(response: IApiResultsResponse<IUser>) {
            const users = [];
            for (let user of response.data.results) {
              users.push(new UserModel(user));
            }
            return users;
          });
      };

      return {
        getUsers
      };
    }
  ]);
