angular
  .module('relcore.account')
  .directive('accounts', function() {
    return {
      restrict: 'E',
      scope: {
        columns: '=?',
        showFilter: '=',
        gridOptions: '=',
        onViewDetails: '=?'
      },
      controller: ['$rootScope', '$scope', function($rootScope, $scope) {
        $scope.showFilters = ($scope.showFilter == null) || ($scope.showFilter === true);
        return $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }
      ],
      template: require('./accounts.html').default
    };
  });
