angular
  .module('relcore.online-user')
  .directive('onlineUserLinkedNumbers', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        unlinkNumber: '='
      },
      controller: ['$scope', function($scope) {} ],
      template: require('./online-user-linked-numbers.html').default
    })
  );
