angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('mobile-device-monitoring', {
      controller: 'MobileDeviceMonitoringController',
      template: require('./monitoring.html').default,
      url: '/mobile-device/monitoring',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Device Monitoring' }
    })

  ])
  .controller('MobileDeviceMonitoringController', [
    '$rootScope', '$scope', '$state', 'mobileDeviceService', 'facilityService', 'alertService', 'NgTableParams', 'moment',
    function($rootScope, $scope, $state, mobileDeviceService, facilityService, alertService, NgTableParams, moment) {
      $rootScope.title = 'Device Monitoring';
      $scope.lastContactThreshold = 10; // In minutes
      $scope.batteryLevelThreshold = 20; // Percent
      $rootScope.$watch('facilities', function(newList) {
        $scope.facilities = [{ id: 'Assigned', name: 'Assigned' }, { id: 'Unassigned', name: 'Unassigned' }];
        $scope.facilities = $scope.facilities.concat(newList).filter(f => f.featureTexting !== false);
      });

      const processHighlighting = data =>
        data.map(function(d) {
          if (d.batteryLevel <= ($scope.batteryLevelThreshold/100)) {
            d.highlightClass = 'warning';
          }

          if (moment().diff(d.lastContact.date, 'seconds') >= ($scope.lastContactThreshold*60)) {
            d.highlightClass = 'danger';
          }

          return d;
        })
      ;

      $scope.gridOptions = new NgTableParams({
        count: 5000,
        sorting: {
          lastContact: 'asc'
        },
        filter: {
          includeAppAssignments: true,
          includeInmates: true,
          applications: 'Connect - Messaging'
        }
      }, {
        counts: null,
        getData(params) {
          if (params.filter().facilityIds) {
            return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.total);
                return processHighlighting(response.data);
              });
          } else {
            return [];
          }
        }
      });

      $scope.highlightRow = function(d) {
        if (moment().diff(d.lastContact.date, 'seconds') >= ($scope.lastContactThreshold*60)) {
          return 'danger';
        }

        if (d.batteryLevel <= ($scope.batteryLevelThreshold/100)) {
          return 'warning';
        }

        return '';
      };

      $scope.onViewDetails = device => $state.go('mobile-device-view', {id:device.id, device});
    }
  ]);
