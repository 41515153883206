import { maxLength, numeric, required } from '@reliance/forms-js/lib/validation';
import React, { useCallback, useState } from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { useDropzone } from 'react-dropzone';
import { Form } from 'react-final-form';
import StateGroupedCheckboxes, { toggleCheckAllForState } from '../facility/StateGroupedCheckboxes';
import { TextInput } from '../form/TextInput';
import { IFacility } from '../models/facility';

type AddModalProps = {
  facilities: IFacility[]
  onSave(formData: FormData)
  onClose(): void
}

const AddModal: React.FC<AddModalProps> = (props) => {
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState<FormData>(null);

  const onDrop = useCallback(acceptedFiles => {
    let i = 0;
    const newFormData = new FormData();
    acceptedFiles.map(file =>
      newFormData.append(`assetFile[${i++}]`, file)
    );
    setFormData(newFormData);
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1
  });

  return (
    <Modal show={true} onHide={props.onClose} dialogClassName="modal-full">
      <Form
        initialValues={{
          name: '',
          category: null,
          localPath: null,
          sortWeight: null,
          facilityIds: [],
          asset: {
            category: 'Reference Material'
          }
        }}
        keepDirtyOnReinitialize={true}
        onSubmit={data => {
          formData.set('name', data.name);
          formData.set('sortWeight', data.sortWeight);
          if (data.category) {
            formData.set('category', data.category);
          }
          if (data.localPath) {
            formData.set('localPath', data.localPath);
          }
          if (data.asset.category) {
            formData.set('asset[category]', data.asset.category);
          }
          formData.delete('facilityIds[]');
          formData.delete('asset[facilityIds][]');
          data.facilityIds.map(id => {
            formData.append('facilityIds[]', id);
            formData.append('asset[facilityIds][]', id);
          });
          return props.onSave(formData);
        }}
        mutators={{
          toggleCheckAllForState
        }}
        render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Accordion>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="facilities">
                        {values.facilityIds.length > 0 ? `${values.facilityIds.length} facilities selected` : 'Select facilities'}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="facilities">
                        <Card.Body>
                          <StateGroupedCheckboxes
                            fieldName={'facilityIds'}
                            form={form}
                            facilities={props.facilities}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={3}>
                  <TextInput
                    label="Name"
                    validationRules={[required, maxLength(50)]}
                    name="name" />
                </Col>
                <Col lg={3}>
                  <TextInput
                    label="Category"
                    validationRules={[required, maxLength(20)]}
                    name="category" />
                </Col>
                <Col lg={3}>
                  <TextInput
                    label="Local Path"
                    name="localPath"
                    validationRules={[required, maxLength(250)]} />
                </Col>
                <Col lg={3}>
                  <TextInput
                    label="Sort Weight"
                    validationRules={[required, numeric]}
                    name="sortWeight" />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      variant="outline-secondary"
                      type="button"
                      onClick={() => false}> {/* Root element already has a click listener */}
                      Open File Dialog
                    </Button>
                  </div>
                  {files.length > 0 &&
                    <div className="mt-4">
                      <strong>File added:</strong>
                      <ListGroup>
                        {files.map(f =>
                          <ListGroup.Item
                            variant="info"
                            key={`file-${f.name}`}>
                            {f.name}
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </div>
                  }
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || files.length === 0 || values.facilityIds.length === 0}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default AddModal;