import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { StringTranslation } from '../models/StringTranslation';
import BaseHtmlTable from '../table/BaseHtmlTable';

type ListProps = {
  translations: StringTranslation[]
  onEdit: (translation: StringTranslation) => void
}

export const List: React.FC<ListProps> = (props) => {
  let columns = [
    { key: 'englishString', name: 'English' },
    { key: 'spanishString', name: 'Spanish' },
    { key: 'options', name: 'Options' }
  ];

  let rows = props.translations.map(t => ({
    ...t,
    spanishString: (t.translations||[]).filter(t => t.language == 'es').pop()?.languageString ?? '',
    options: <><Button onClick={() => props.onEdit(t)}>Edit</Button></>
  }));

  return (
    <BaseHtmlTable
      baseKey="translation"
      columns={columns}
      rows={rows}
      />
  );
}