import { Station } from '@reliance/types/models/Station/Station';
import { AxiosResponse } from 'axios';
import { useAsync } from 'react-async-hook';
import api from '../common/api';

export const useStations = (filters?: object) => {
  return useAsync<AxiosResponse<Station[], any>>(
    () => api.get<Station[]>('/station', { params: filters }),
    [filters]
  );
};