import { ActiveInactive } from './enums';
import { IFacility } from './facility';
import { IFacilityNumber } from './facility-number';
import { IStation } from './station';

export interface IFacilityAnalogGateway {
  id?: number
  name: string
  model: string
  ipAddress: string
  facility?: IFacility
  facilityId?: number
  analogGatewayPorts: IAnalogGatewayPort[]
  sipAuthUsername: string | null
  sipAuthPassword: string | null

  // Generated in memory in parts of the app
  fxsPorts?: IAnalogGatewayPort[]
  fxoPorts?: IAnalogGatewayPort[]
}

export enum AnalogGatewayPortType {
  FXO = 'FXO',
  FXS = 'FXS'
}

export interface IAnalogGatewayPort {
  id?: number
  type: AnalogGatewayPortType|string
  hardwarePort: number
  name: string
  status: ActiveInactive|string,
  stationId?: number
  facilityNumberId?: number
  station?: IStation
  facilityNumber?: IFacilityNumber
}