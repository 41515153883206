angular
  .module('relcore.inmate')
  .directive('inmates', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        onDetails: '=',
        columns: '=?',
        facilities: '=?'
      },
      template: require('./inmates.html').default,
      controller: ['$scope', function($scope) {
        $scope.showColumn = name => ($scope.columns == null) || ($scope.columns.indexOf(name) > -1);
      }]
    })
  );
