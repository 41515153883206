import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { IMobileDeviceGroup } from './mobile-device-group';

interface IGroupDeleteCtrl extends ng.IScope {
  mobileDeviceGroup: IMobileDeviceGroup
  error?: string
  deleting: boolean
  delete(): Promise<any>
  close(): void
}

angular
  .module('relcore.mobile-device-group')
  .controller('DeviceGroupDeleteCtrl', ['$scope', 'relianceApi', '$uibModalInstance', 'mobileDeviceGroup', function($scope:IGroupDeleteCtrl, relianceApi: IRelianceApi, $uibModalInstance, mobileDeviceGroup:IMobileDeviceGroup) {


    // TODO: Before this can be used, the API needs to handle the request to delete (which has cascading effects)
    return;


    $scope.deleting = false;
    $scope.mobileDeviceGroup = mobileDeviceGroup;

    $scope.delete = function() {
      $scope.deleting = true;
      return relianceApi
        .delete(`/mobile-device/group/${mobileDeviceGroup.id}`)
        .then((function(res:IApiResponse<any>) {
          $scope.deleting = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({saved: true});
          }
        }), (function(err) {
          $scope.error = 'An unexpected error occurred';
          $scope.deleting = false;
        }));
    };

    $scope.close = () => $uibModalInstance.close({saved: false});
  }]);
