import React from 'react';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { Field } from 'react-final-form';

type HiddenProps = {
  name: string;
  value: string;
}

const Hidden: React.FC<HiddenProps> = (props) =>
  <Field
    name={props.name}>
    {({ input }) =>
      <FormGroup>
        <input
          type="hidden"
          {...input}
        />
      </FormGroup>
    }
  </Field>

export default Hidden;