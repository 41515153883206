angular
  .module('relcore.api-role')
  .service('apiRolesActions', ['relianceApi', function(relianceApi) {
    return {
      load: () => {
        return dispatch => {
          dispatch({ type: 'api-roles/loading' });

          return relianceApi.get('/api-role', {})
            .then(
              (res => dispatch({ type: 'api-roles/loaded', apiRoles: res.data })),
              (err => dispatch({ type: 'api-roles/load-failed', error: err }))
            );
        };
      }
    };
  }]);
