import { ICall } from "../call";

angular
  .module('relcore.call')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('calls', {
        controller: 'callController',
        url: '/call',
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: {
          label: 'Call List'
        }
      }
    )
  ])
  .controller('callController',
    ['$scope', '$uibModal', '$filter', '$compile', 'DTOptionsBuilder', 'DTColumnBuilder', 'relianceApi', 'callService', 'moment',
    function($scope, $uibModal, $filter, $compile, DTOptionsBuilder, DTColumnBuilder, relianceApi, callService, moment) {
      $scope.callDetailsTableInstance = {};
      $scope.callDetailsTableOptions = DTOptionsBuilder
        .fromFnPromise(() => {
          return callService.getByAni($scope.ani)
            .then((response) => {
              $scope.count = response.results.length;
              $scope.charges = response.results.reduce((accumulator: number, current: ICall) => {
                return (accumulator + current.totalCharge) * 100 / 100;
              }, 0.0);
              return response.results;
            });
          }
      )
      .withOption('lengthChange', false)
      .withDisplayLength(100)
      .withOption('createdRow', row => {
          $compile(angular.element(row).contents())($scope);
      })
      .withBootstrap()
      .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

      const filterRenderer = (name: string) => (data: any) => $filter(name)(data);
      const durationRenderer = (timeInMinutes: number) => {return timeInMinutes + " min"};
      const taxesRenderer = (totalTaxes: number, type: string, full: ICall) => {
        let content = "<span uib-tooltip-html=\"'City Tax: $ " + full.cityTax
          + "<br>County Tax: $" + full.countyTax
          + "<br>State Tax: $" + full.stateTax
          + "<br>To State Tax: $" + full.toStateTax
          + "<br>Federal Tax: $" + full.fedTax
          + "<br>FUSF Tax: $" + full.usfTax
          + "<br>Total Tax: $" + full.totalTaxes
          + "'\">" + $filter('currency')(totalTaxes) + "</span>";
        return content;
      };

      $scope.callDetailsTableColumns = [
          DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(filterRenderer('datetimeYYYYMMDDHHmm')),
          DTColumnBuilder.newColumn('invoice').withTitle('Invoice'),
          DTColumnBuilder.newColumn('duration').withTitle('Duration').renderWith(durationRenderer),
          DTColumnBuilder.newColumn('type').withTitle('Type'),
          DTColumnBuilder.newColumn('fromNumber').withTitle('From').renderWith(filterRenderer('tel')),
          DTColumnBuilder.newColumn('facility').withTitle('Facility').renderWith(filterRenderer('facilityTitle')),
          DTColumnBuilder.newColumn('charge').withTitle('Charge').renderWith(filterRenderer('currency')),
          DTColumnBuilder.newColumn('totalTaxes').withTitle('Taxes').renderWith(taxesRenderer),
          DTColumnBuilder.newColumn('totalCharge').withTitle('Total').renderWith(filterRenderer('currency')),
        ];
    }]
  );