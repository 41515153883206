import * as validation from '@reliance/forms-js/lib/validation';
import React from 'react';
import { Input } from '../../form/render';

export const GeneralCreditDebitForm: React.FC = (props: any) =>
  <div>
    <Input
      name="paymentMethod.info.explanation"
      label="Explanation"
      validationRules={[
        validation.required
      ]} />
  </div>