angular
  .module('relcore.text-message')
  .directive('textMessages', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?',
        facilities: '=',
        onShowStatus: '=?',
        loadImages: '=?'
      },
      template: require('./text-messages.html').default,
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }]
    })
  );
