import { IConfig } from '../common/config';

angular.module('relcore.mobile-device').config(['$stateProvider', $stateProvider =>
  $stateProvider.state('mobile-device-app-management', {
    url: '/mobile-device/app-management',
    parent: 'authenticated',
    ncyBreadcrumb: { label: 'Mobile Device App Management' },
    controller: ['$rootScope', '$scope', '$filter', 'mobileDeviceService', 'facilityService', 'alertService', 'NgTableParams', '$state', 'config',
      function($rootScope, $scope, $filter, mobileDeviceService, facilityService, alertService, NgTableParams, $state, config:IConfig) {
        $rootScope.title = 'Mobile Device App Management';
        $scope.selected = [];
        $scope.facilities = [];
        $scope.availableApps = {
          messaging: config.appBundleIds.messaging != null,
          admin: config.appBundleIds.admin != null,
          kiosk: config.appBundleIds.kiosk != null,
          info: config.appBundleIds.info != null
        };
        facilityService.retrieveAll((err, facilities) => $scope.facilities = facilities.filter(f => f.featureTexting));

        $scope.updateSelected = function() {
          const ids = $scope.selected.map(d => d.id);
          mobileDeviceService.updateApps(ids)
            .then(
              (function(result) {
                const { data } = result;

                if (data.notUpdatedDeviceIds.length === 0) {
                  alertService.success('Devices will update momentarily', true);
                } else {
                  alertService.warning(`The following devices could not be updated at this time: ${data.notUpdatedDeviceIds.join(' ')}`, true);
                }
              }),
              (() => alertService.error('An error occurred while updating the devices', true))
            );
        };

        $scope.loadMessaging = function(refresh = false) {
          if (!refresh && $scope.messagingDeviceListGridOptions != null) { return; }

          if ($scope.messagingDeviceListGridOptions) {
            $scope.messagingDeviceListGridOptions.reload();
          }

          $scope.messagingDeviceListGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              'facility.name': 'asc',
              name: 'asc'
            },
            filter: {
              includeAppAssignments: true,
              applications: 'Connect - Messaging',
              facilityIds: $rootScope.facilitiesToDisplay
            }
          }, {
            counts: [100, 200, 500],
            getData(params) {
              return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.selected = [];
                  return result.data;
              });
            }
          });
        };

        $scope.loadKioskDeviceList = function(refresh = false) {
          if (!refresh && $scope.kioskDeviceListGridOptions != null) { return; }

          if ($scope.kioskDeviceListGridOptions) {
            $scope.kioskDeviceListGridOptions.reload();
          }

          $scope.kioskDeviceListGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              'facility.name': 'asc',
              name: 'asc'
            },
            filter: {
              includeAppAssignments: true,
              applications: 'Connect - Visitation'
            }
          }, {
            counts: [100, 200, 500],
            getData(params) {
              return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.selected = [];
                  return result.data;
              });
            }
          });
        };

        $scope.loadAdminDeviceList = function(refresh = false) {
          if (!refresh && $scope.adminDeviceListGridOptions != null) { return; }

          if ($scope.adminDeviceListGridOptions) {
            $scope.adminDeviceListGridOptions.reload();
          }

          $scope.adminDeviceListGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              'facility.name': 'asc',
              name: 'asc'
            },
            filter: {
              includeAppAssignments: true,
              applications: 'Connect - Admin'
            },
          }, {
            counts: [100, 200, 500],
            getData(params) {
              return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.selected = [];
                  return result.data;
              });
            }
          });
        };

        $scope.loadInfoDeviceList = function(refresh = false) {
          if (!refresh && $scope.infoDeviceListGridOptions != null) { return; }

          if ($scope.infoDeviceListGridOptions) {
            $scope.infoDeviceListGridOptions.reload();
          }

          $scope.infoDeviceListGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              'facility.name': 'asc',
              name: 'asc'
            },
            filter: {
              includeAppAssignments: true,
            },
          }, {
            counts: [100, 200, 500],
            getData(params) {
              return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.selected = [];
                  return result.data;
              });
            }
          });
        };

        $scope.viewDevice = device => $state.go('mobile-device-view', {id:device.id, device});
      }
    ],
    template: require('./app-management.html').default
  }
)]);
