import { IGenericApiResponse, IPaginatedApiResponse } from '../common/reliance-api-service';
import { ITransaction, ITransactionService } from "./transaction-service";

interface IStateParams {
  ani: string
}

angular
  .module('relcore.transaction')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('billing', {
        controller: 'billingController',
        url: '/billing/:ani',
        params: {ani: null},
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: {
          label: 'Billing List'
        }
      })
  ])
  .controller('billingController',
    ['$scope', '$stateParams', 'transactionService', 'DTColumnBuilder', 'DTColumnDefBuilder', 'DTOptionsBuilder', '$filter',
      function($scope, $stateParams: IStateParams, transactionService: ITransactionService, DTColumnBuilder, DTColumnDefBuilder, DTOptionsBuilder, $filter) {
        $scope.billingTableInstance = {};

        const filterRenderer = (name: string) => (data: any) => $filter(name)(data);
        const amountRenderer = (amount: string) => {
          if (!amount) return '';
          return amount.charAt(0) === '-' ? `($${parseFloat(amount.slice(1)).toFixed(2)})` : `$${parseFloat(amount).toFixed(2)}`;
        };
        const balanceRenderer = (balance: number) => {
          const format = Intl.NumberFormat('en-US', {
              style: 'currency', currency: 'USD'
            }
          );
          const formatted = format.format(balance);
          return formatted.charAt(0) === '-' ? `(${formatted.slice(1)})` : formatted;
        };
        const typeRender = (typeId: number, type: string, full: ITransaction) => {
          return typeId == 0 ? `${full.count} Unbilled Call Records` : $filter('transactionSubType')(typeId);
        };

        $scope.billingTableColumns = [
          DTColumnBuilder.newColumn('date.date').withTitle('Date').renderWith(filterRenderer('datetimeFormat')),
          DTColumnBuilder.newColumn('transactionSubType').withTitle('Type').renderWith(typeRender),
          DTColumnBuilder.newColumn('amount').withTitle('Amount').renderWith(amountRenderer),
          DTColumnBuilder.newColumn('balance').withTitle('Balance').renderWith(balanceRenderer)
        ];

        $scope.billingTableColumnDefs = [
          DTColumnDefBuilder.newColumnDef([0]).withOption('type', 'date').notSortable(),
          DTColumnDefBuilder.newColumnDef([1]).notSortable(),
          DTColumnDefBuilder.newColumnDef([2]).notSortable(),
          DTColumnDefBuilder.newColumnDef([3]).notSortable()
        ];

        $scope.billTableOptions = DTOptionsBuilder
          .fromFnPromise(() => {
            return transactionService.getByANI($stateParams.ani)
              .then((response: IGenericApiResponse<ITransaction>) => {
                let data = transactionService.isPaginated(response) ? (response as IPaginatedApiResponse<ITransaction>).data.data : response.data;
                let items = (data as ITransaction[]);
                let nonInvoiced = [];
                let toDisplay = [];
                items.forEach((item) => {
                  if (item.transactionSubType == 0) {
                    nonInvoiced.push(item);
                  } else {
                    toDisplay.push(item);
                  }
                });
                if (nonInvoiced.length > 0) {
                  const totalCharges = nonInvoiced.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.amount);
                  }, 0);
                  toDisplay.unshift({id: 0, transactionSubType: 0, date: {date: Date()}, amount: '', balance: totalCharges, count: nonInvoiced.length});
                }
                return toDisplay;
              });
          })
          .withOption('lengthChange', false)
          .withOption('order', []) // Disable initial sorting. Displays by ordering of the data array
          .withDisplayLength(200)
          .withBootstrap()
          .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");
  }]);