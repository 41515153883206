
angular
  .module('relcore.housing-unit')
  .directive('housingUnitEdit', () =>
    ({
      restrict: 'E',
      scope: {
        housingUnit: '=',
        mobileDeviceGroups: '=',
        saving: '=',
        onSave: '='
      },
      template: require('./housing-unit-edit.html').default,
      controller: ['$scope', function($scope) {
        $scope.newUnit = Object.assign({}, $scope.housingUnit);
        $scope.save = () => $scope.onSave($scope.newUnit);
      }]
    })
  );
