angular
  .module('relcore.cs-record')
  .factory('CsRecord',
    ['User', function(UserModel) {
      return class CsRecord {
        id;
        date;
        spokeWith;
        comments;
        contactType;
        staffUser;
        user;

        constructor(data) {
          this.loadFromData = this.loadFromData.bind(this);
          this.loadFromData(data);
        }

        loadFromData(data) {
          data = data || {};

          this.id = data.id || null;
          this.date = data.date || '';
          this.spokeWith = data.spokeWith || '';
          this.comments = data.comments || '';
          this.contactType = (() => { switch (data.contactType || 6) {
           case 1: return 'Inbound Call';
           case 2: return 'Outbound Call';
           case 3: return 'Inbound Mail';
           case 4: return 'Outbound Mail';
           case 5: return 'Walk-in';
           case 6: return 'Other';
           case 7: return 'Verification-Attorney';
           default: return '';
          } })();

          if (data.staffUser) {
            this.staffUser = data.staffUser;
          } else if (data.user) {
            this.user = new UserModel(data.user);
          }
        }
      }
  }]);