import { maxLength, minLength, required } from '@reliance/forms-js/lib/validation';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Form } from 'react-final-form';
import { TextInput } from '../form/TextInput';
import { StringTranslation } from '../models/StringTranslation';

type EditFormData = {
  englishString: string,
  language: string,
}

type EditModalProps = {
  translation: StringTranslation
  onSave(formData: EditFormData)
  onClose(): void
}

const EditModal: React.FC<EditModalProps> = (props) => {
  let translation = {
    ...props.translation,
    translations: {}
  };

  // Massage the child translations into a keyed dictionary
  let translationsMap = {};
  props.translation.translations.map(t => translationsMap[t.language] = t.languageString);
  translation.translations = translationsMap;

  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={translation}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <TextInput
                label="English"
                validationRules={[required, minLength(1), maxLength(5000)]}
                name="englishString" />
              <TextInput
                label="Spanish"
                validationRules={[required, minLength(1), maxLength(5000)]}
                name="translations[es]" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || hasValidationErrors}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default EditModal;