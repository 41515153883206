import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Modal from 'react-bootstrap/esm/Modal';
import { Field, Form } from 'react-final-form';
import { ITypeValue } from '../phone-card-service';

export type AddInventoryData = {
  typeValueId: number
  quantity: number
}

type AddModalProps = {
  typeValues: ITypeValue[]
  onSubmit(data: AddInventoryData): Promise<any>
  onClose(): void
}

const AddModal: React.FC<AddModalProps> = (props) =>
  <Modal show={true} onHide={props.onClose}>
    <Form
      onSubmit={props.onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Inventory</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormGroup>
              <label>Value</label>
              <Field className="form-control" name="typeValueId" component="select">
                <option />
                {props.typeValues.sort((a,b) => a.value.description.localeCompare(b.value.description)).map(tv =>
                  <option key={`inventory-add-tv-${tv.id}`} value={tv.id}>{tv.value.description}</option>
                )}
              </Field>
            </FormGroup>

            <FormGroup>
              <label>Quantity</label>
              <Field
                style={{maxWidth: 100}}
                className="form-control"
                name="quantity"
                component="input"
                type="number"
                placeholder="Quantity"
                max={1000}
                required />
            </FormGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={props.onClose} disabled={submitting || pristine}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={submitting || pristine}>
              {submitting ? 'Generating' : 'Generate'}
            </Button>
          </Modal.Footer>
      </form>
    )} />
  </Modal>

export default AddModal;