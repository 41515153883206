import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Tab from 'react-bootstrap/esm/Tab';
import { react2angular } from 'react2angular';
import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { Select } from '../form/Select';
import { IFacility } from '../models/facility';
import { TaxTable, TaxTableEntityType } from '../models/TaxTable';

type FacilityTaxesTabProps = {
  facility: IFacility
  relianceApi: IRelianceApi
}

const FacilityTaxesTab: React.FC<FacilityTaxesTabProps> = (props) => {
  const [taxRates, setTaxRates] = useState<TaxTable[]>([]);

  useEffect(() => {
    props.relianceApi
      .index<IApiResponse<TaxTable[]>>('/tax/table')
      .then((res) => setTaxRates(res.data))
      .then(() => setTaxRates(taxRates.sort((a,b) => a.entityName.localeCompare(b.entityName))));
  }, []);

  const cityTaxes = taxRates.filter(r => r.entityType == TaxTableEntityType.City);
  const stateTaxes = taxRates.filter(r => r.entityType == TaxTableEntityType.State);
  const fedTaxes = taxRates.filter(r => r.entityType == TaxTableEntityType.Fed);
  const fusfTaxes = taxRates.filter(r => r.entityType == TaxTableEntityType.FUSF);

  return (
    <Tab eventKey="taxes" title="Taxes">
    {/* <div className="card rounded-bottom border-top-0"> */}
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Select
                name="cityTaxId"
                label="City Tax">
                <option></option>
                {cityTaxes.map((tax) =>
                  <option
                    value={tax.id}
                    key={`facility-city-tax-${tax.id}`}>
                    {tax.entityName} ({tax.rate.toFixed(2)}%)
                  </option>
                )}
              </Select>
              <Select
                name="stateTaxId"
                label="State Tax">
                <option></option>
                {stateTaxes.map((tax) =>
                  <option
                    value={tax.id}
                    key={`facility-state-tax-${tax.id}`}>
                    {tax.entityName} ({tax.rate.toFixed(2)}%)
                  </option>
                )}
              </Select>
            </Col>
            <Col>
              <Select
                name="fedTaxId"
                label="Federal Tax">
                <option></option>
                {fedTaxes.map((tax) =>
                  <option
                    value={tax.id}
                    key={`facility-fed-tax-${tax.id}`}>
                    {tax.entityName} ({tax.rate.toFixed(2)}%)
                  </option>
                )}
              </Select>
              <Select
                name="fusfTaxId"
                label="FUSF Tax">
                <option></option>
                {fusfTaxes.map((tax) =>
                  <option
                    value={tax.id}
                    key={`facility-fusf-tax-${tax.id}`}>
                    {tax.entityName} ({tax.rate.toFixed(2)}%)
                  </option>
                )}
              </Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Tab>
  );
};

angular
  .module('relcore.facility')
  .component('facilityTaxesTab', react2angular(FacilityTaxesTab, ['facility']));

export default FacilityTaxesTab;