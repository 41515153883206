angular
  .module('relcore.api-role')
  .value('api_role_permissions', [
    'asset_manage',
    'api_credential_manage',
    'api_log_search',
    'block_manage',
    'cloud_storage_manage',
    'commission_rate_manage',
    'condition_manage',
    'directbill_account_manage',
    'directbill_customer_search',
    'add blocks',
    'billprocess',
    'view blocks',
    'remove blocks',
    'add private numbers',
    'view private numbers',
    'remove private numbers',
    'call_manage_cdr',
    'call_route_manage',
    'call_search',
    'call_setup',
    'card_account_manage',
    'cdr_search',
    'cdr_recording',
    'changelog_view',
    'commissary_manage',
    'commissary_transfer_manage',
    'commissary_transfer_search',
    'connect_id_scan_failure',
    'connect_test_call_request',
    'credit_card_create',
    'credit_card_charge_search',
    'credit_card_search',
    'credit_card_saved_search',
    'customer_service_record_manage',
    'email_send',
    'diagnostic',
    'enable visitation',
    'enable voicemail',
    'event_notification_manage',
    'external_outbound_location_data',
    'external_outbound_phone_card',
    'external_outbound_reliance_account',
    'field_outbound_originating_circuit',
    'field_outbound_originating_line',
    'field_outbound_raw_digits',
    'field_outbound_termination_reason',
    'facility_analog_gateway_manage',
    'facility_ani_manage',
    'facility_equipment_manage',
    'facility_manage',
    'facility_manage_billing',
    'facility_manage_geographical',
    'facility_manage_taxes',
    'facility_manage_features',
    'facility_manage_trust_accounts',
    'facility_note_manage',
    'facility_number_manage',
    'facility_search',
    'facility_stats',
    'facility_user_manage',
    'facility_user_log_search',
    'facility_systems_manage',
    'field_inbound_box_number',
    'field_inbound_date',
    'field_inbound_first_accessed',
    'field_inbound_last_accessed',
    'field_outbound_inmate_name',
    'field_outbound_start_date',
    'field_outbound_phone_name',
    'field_outbound_destination',
    'field_outbound_duration',
    'field_outbound_phone_card',
    'field_outbound_call_type',
    'housing_unit_manage',
    'inmates_add',
    'inmates_edit',
    'inmates_edit_housing',
    'inmates_edit_usage',
    'inmates_view',
    'inmate_manage',
    'inmate_search',
    'inmate_import',
    'inmate_charge_search',
    'inmate_activity_search',
    'inmate_activity_manage',
    'inmate_activity_load_phone_card',
    'inmate_account_payment_search',
    'inmate_account_payment_create',
    'inmate_communication_policy_manage',
    'inmate_contact_search',
    'inmate_contact_create',
    'inmate_contact_app_invite',
    'inmate_device_manage',
    'inmate_device_rental_process_fees',
    'inmate_device_blacklist_manage',
    'inmate_mail_receive',
    'inmate_note_manage',
    'inmate_notification_manage',
    'inmate_request_search',
    'inmate_request_create',
    'inmate_request_form_manage',
    'inmate_support_request_manage',
    'invoice_manage',
    'itds_manage',
    'itds_migrate',
    'kiosk_configuration',
    'kiosk_home',
    'live_status_view',
    'manage devices',
    'manage inmate notifications',
    'manage_admin_roles',
    'manage_admin_users',
    'mobile_device_manage',
    'mobile_device_report_in',
    'mobile_device_app_manage',
    'mobile_device_profile_manage',
    'mobile_device_purchase_manage',
    'online_order_search',
    'online_order_create',
    'online_user_manage',
    'online_user_settings',
    'online_user_resend_activation',
    'online_user_create',
    'online_user_verify',
    'online_user_search',
    'online_user_connected_accounts',
    'online_user_connected_devices',
    'office_call_manage',
    'payment_create',
    'phonecard_inventory_manage',
    'phonecard_manage',
    'phonecard_search',
    'provisioning_profile_manage',
    'private_number_manage',
    'rate_manage',
    'reference_material_manage',
    'reference_material_search',
    'repair_ticket_manage',
    'report_online_purchase',
    'report_phone_card_details',
    'report_inmate_account_charges',
    'report_text_message_accounting',
    'report_text_message_cost_recovery',
    'report_text_message_county_detail',
    'report_text_message_facility_summary',
    'report_video_call_cost_recovery',
    'report_inmate_balance_audit',
    'report_inmate_purchase',
    'salt_manage',
    'schedule_manage',
    'schedule_search',
    'search',
    'service_rate_manage',
    'shipping_ticket_manage',
    'shipping_ticket_search',
    'sip_user_manage',
    'sms_voice_number_manage',
    'sms_voice_number_search',
    'speed_dial_manage',
    'staff_list',
    'staff_manage',
    'staff_user_manage',
    'staff_user_log_search',
    'station_manage',
    'subscription_manage',
    'switch_control',
    'sync_search',
    'tax_manage',
    'text_message_available_facilities',
    'text_message_available_inmates',
    'text_message_bulk_amqp_publish',
    'text_message_fund_request',
    'text_message_send',
    'text_message_search',
    'text_message_staff_send',
    'text_message_status_search',
    'translation_manage',
    'user_change_own_password',
    'view phone card detail report',
    'view phone controls',
    'view reports',
    'view tools',
    'view acknowledgements',
    'visitation_manage',
    'visitation_online_manage',
    'visitation_search',
    'visitation_recordings',
    'voicemail_search'
  ]);
