angular
  .module('relcore.block')
  .factory('Block', ['Facility', function(FacilityModel) {
    return class BlockModel {
      blockDate;
      unblockDate;
      reason;
      facility;
      requestedBy;
      type;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        if (data == null) { data = {}; }
        this.blockDate = data.blockDate;
        this.unblockDate = data.unblockDate;
        this.reason = data.reason;
        if (data.facility != null) {
          this.facility = new FacilityModel(data.facility);
        }
        this.requestedBy = data.requestedBy;
        this.type = (() => { switch (data.type) {
          case 0: return 'Override';
          case 1: return 'Collect Block';
          case 2: return 'Recording Block';
          case 3: return 'County Block';
          case 4: return 'Range Block';
          default: return data.type;
        } })();
      }
    }
  }]);