export interface IAlertService {
  alerts: Array<any>
  onChange(cb:Function): any
  info(message: string, clearPrevious?: boolean): void
  success(message: string, clearPrevious?: boolean): void
  warning(message: string, clearPrevious?: boolean): void
  error(message: string, clearPrevious?: boolean): void
  addAlert(message: string, severity: string, clearPrevious?: boolean): void
  clear(alert: any): void
  clearAll(): void
}

angular
  .module('relcore.alert')
  .service('alertService', ['Alert', function(Alert): IAlertService {
    let alerts = [];
    let onChangeCb = null;

    const clearAll = function() {
      if (alerts.length > 0) { console.log(`Clearing ${alerts.length} alerts`); }
      alerts = [];
      if (onChangeCb != null) { return onChangeCb(alerts); }
    };

    const add = function(msg, sev, clearPrev) {
      if (clearPrev == null) { clearPrev = false; }
      console.log(`Adding ${sev} alert: ${msg}`);
      if (clearPrev) { clearAll(); }
      alerts.push(new Alert(msg, sev));
      if (onChangeCb != null) { return onChangeCb(alerts); }
    };

    return {
      alerts,

      onChange(cb) {
        return onChangeCb = cb;
      },

      info(message, clearPrevious) {
        if (clearPrevious == null) { clearPrevious = false; }
        return add(message, 'info', clearPrevious);
      },

      success(message, clearPrevious) {
        if (clearPrevious == null) { clearPrevious = false; }
        return add(message, 'success', clearPrevious);
      },

      warning(message, clearPrevious) {
        if (clearPrevious == null) { clearPrevious = false; }
        return add(message, 'warning', clearPrevious);
      },

      error(message, clearPrevious) {
        if (clearPrevious == null) { clearPrevious = false; }
        return add(message, 'danger', clearPrevious);
      },

      addAlert(message, severity, clearPrevious) {
        if (clearPrevious == null) { clearPrevious = false; }
        return add(message, severity, clearPrevious);
      },

      clear(alert) {
        return alerts.splice(alerts.indexOf(alert), 1);
      },

      clearAll
    };
  }
  ]);