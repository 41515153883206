import React from 'react';
import { resolveEntity } from '../format/ApiLog';
import { ApiLog } from '../models/ApiLog';
import BaseHtmlTable from '../table/BaseHtmlTable';
import EntityLink from './EntityLink';

type ChangesTableProps = {
  log: ApiLog
}

const renderValue = (value: any) => {
  if (!Array.isArray(value) && typeof value == 'object') {
    if (value['entityId'] != null) {
      return <EntityLink log={value}>{resolveEntity(value)}</EntityLink>;
    } else {
      return JSON.stringify(value);
    }
  }

  return value;
}

const ChangesTable: React.FC<ChangesTableProps> = (props) => {
  const columns = [
    { key: 'property', name: 'Property' },
    { key: 'oldValue', name: 'Old Value' },
    { key: 'newValue', name: 'New Value' }
  ];

  const logJson = JSON.parse(props.log.json);
  const changes: {[field: string]: any[]} = (logJson||{})['changes'];
  if (changes == null) {
    return null;
  }

  const rows = Object.keys(changes).map(field => ({
    property: field,
    oldValue: renderValue(changes[field][0] || ''),
    newValue: renderValue(changes[field][1] || '')
  }));

  return (
    <BaseHtmlTable
      columns={columns}
      rows={rows}
      baseKey={'api-log-change'}
      showPagination={false}
      />
  );
}

export default ChangesTable;