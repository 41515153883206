import { Facility } from '@reliance/types/models/Facility';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { react2angular } from 'react2angular';
import { IAlertService } from '../alert/alert-service';
import { IRelianceApi } from '../common/reliance-api-service';

type MigrateItdsModalProps = {
  show: boolean
  facility: Facility
  relianceApi: IRelianceApi
  alertService: IAlertService
  onClose(migrated?: boolean): void
};

const MigrateItdsModal: React.FC<MigrateItdsModalProps> = (props) => {
  const [migrating, setMigrating] = useState(false);

  const migrate = () => {
    setMigrating(true);

    props.relianceApi.post(`/itds/migrate-facility/${props.facility.id}`).then(() => {
      setMigrating(false);
      props.alertService.success('The facility was successfully migrated');
      props.onClose(true);
    });
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Body>
        This will migrate all inmates at the facility from the legacy ITDS accounts to the wallet-integrated system.
        <br/><br/>
        This operation cannot be undone. Only proceed when the jail is ready to migrate.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onClose(false)} disabled={migrating}>
          Cancel
        </Button>
        <Button variant="primary" type="button" onClick={migrate} disabled={migrating}>
          {migrating ? 'Migrating...' : 'Migrate'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

angular
  .module('relcore.facility')
  .component('migrateItdsModal', react2angular(MigrateItdsModal, ['facility', 'relianceApi', 'alertService', 'onClose', 'show']));

export default MigrateItdsModal;