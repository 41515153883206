angular
  .module('relcore.directives')
  .directive('smartUislider', ['$parse', $parse =>
    ({
      restrict: 'A',
      link(scope, element:any, attrs, ngModel) {
        element.removeAttr('smart-uislider data-smart-uislider');

        const slider = element.slider({
          min: attrs.sliderMin,
          max: attrs.sliderMax,
          value: attrs.sliderValue,
          selection: attrs.sliderSelection,
          handle: attrs.sliderHandle
        });

        slider.on('slideStop', function(e) {
          scope[attrs.ngModel] = e.value;
          scope.$apply();
        });
      }
    })
  ]);