angular
  .module('relcore.station')
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider
      .state('stations-view', {
        controller: 'StationViewController',
        template: require('./view.html').default,
        url: '/station/view/:stationId',
        parent: 'authenticated',
        params: { station: null },
        ncyBreadcrumb: {
          label: '{{station.facility.name}} / {{station.name}}'
        }
      }
    )
  }])
  .controller('StationViewController',
    ['$rootScope', '$scope', '$state', '$filter', 'stationService', 'NgTableParams', '$stateParams', 'alertService', 'mobileDeviceService', 'housingUnitService',
    function($rootScope, $scope, $state, $filter, stationService, NgTableParams, $stateParams, alertService, mobileDeviceService, housingUnitService) {
      $scope.stationId = $stateParams.stationId;
      $scope.devices = [];
      $scope.housingUnits = [];

      const setStation = function(station) {
        $scope.station = station;
        $rootScope.title = $scope.station.name;

        // Load mobile devices for assignment
        mobileDeviceService.retrieveAll(1, 2000, {
          applications: 'Connect - Visitation,Connect - Admin',
          facilityId: $scope.station.facility.id
          }).then(res =>
          $scope.devices = res.data.filter(d => (d.id === ($scope.station.mobileDevice != null ? $scope.station.mobileDevice.id : undefined)) || (d.station === null))
        );

        return housingUnitService.index(1, 1000, '', {facilityId: $scope.station.facility.id}).then(res => $scope.housingUnits = res.data.data);
      };

      if ($stateParams.station != null) {
        setStation($stateParams.station);
      } else {
        stationService.getById($stateParams.stationId)
          .then(station => setStation(station));
      }

      $scope.unassign = function() {
        const onSuccess = () => alertService.success("Station unassigned successfully.", true);

        const onError = function(response) {
          if (response.status === 503) {
            alertService.warning("Station unassigned successfully, but could not clear the site cache.", true);
          } else {
            alertService.error("Station failed to be unassigned.", true);
          }
        };

        stationService.unassignStation($scope.stationId)
          .then(onSuccess, onError);
      };

      $scope.save = function() {
        const onSuccess = function() {
          alertService.success("Station updated successfully.", true);
          $rootScope.title = $scope.station.name;
        };

        const onError = function(response) {
          if (response.status === 503) {
            alertService.warning("Changes were saved, but could not clear the site cache.", true);
          } else {
            alertService.error("Changes failed to save.", true);
          }
        };

        const changes = {
          name: $scope.station.name,
          userId: $scope.station.userId,
          password: $scope.station.password,
          status: $scope.station.status,
          domain: $scope.station.domain,
          type: $scope.station.type,
          defaultExtension: $scope.station.defaultExtension,
          featureMode: $scope.station.featureMode,
          blurVideoBackground: JSON.parse($scope.station.blurVideoBackground),
          mobileDeviceId: '',
          housingUnitId: null
        };

        if ($scope.station.housingUnit != null) {
          changes.housingUnitId = $scope.station.housingUnit.id.toString();
        }

        if ($scope.station.mobileDevice != null) {
          changes.mobileDeviceId = $scope.station.mobileDevice.id.toString();
        }

        stationService.updateStation($scope.stationId, changes)
          .then(onSuccess, onError);
      };
    }
  ]);
