angular
  .module('relcore.station')
  .service('stationService',
    ['$http', '$q', 'CacheFactory', 'relianceApi', 'config', 'Station',
    function($http, $q, CacheFactory, relianceApi, config, StationModel) {

      const getByFacility = id =>
        relianceApi.get(`/station?facilityId=${id}`)
          .then(function(results) {
            const { data } = results;
            const stations = [];
            for (let station of Array.from(data)) {
              stations.push(new StationModel(station));
              data.results = stations;
            }
            return data;
        })
      ;

      const getByHousingUnit = id =>
        relianceApi.get(`/station?housingUnitId=${id}`)
          .then(function(results) {
            const { data } = results;
            const stations = [];
            for (let station of Array.from(data)) {
              stations.push(new StationModel(station));
              data.results = stations;
            }
            return data;
        })
      ;

      const getById = id =>
        relianceApi.get(`/station/by-id?id=${id}`)
          .then(result => new StationModel(result.data))
      ;

      const postNewStation = station =>
        relianceApi.post("/station", {
          name: station.name,
          domain: station.domain,
          userContext: station.userContext,
          userId: station.userId,
          password: station.password || '',
          type: station.type,
          status: station.status,
          facilityId: station.facility.id,
          tollAllow: station.tollAllow,
          housingUnitId: (station.type === 'Inmate') && (station.housingUnit !== null) ? station.housingUnit.id : null,
          pairedDevice: station.pairedDevice,
          deviceId: station.deviceId,
          defaultExtension: station.defaultExtension
          })
      ;

      // Updates the stations information
      //
      // @param integer id
      // @param object changes Object of key/value changes to make to the station
      // @return s Promise
      const updateStation = (id, changes) => relianceApi.put(`/station/${id}`, changes);

      // Unassign a device from the station
      //
      // @param integer id
      // @return s Promise
      const unassignStation = id =>
        updateStation(id, {
          mobileDeviceId: '-1'
        })
      ;

      return {
        getByFacility,
        getByHousingUnit,
        getById,
        postNewStation,
        updateStation,
        unassignStation
      };
    }
  ]);
