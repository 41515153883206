angular
  .module('relcore.directives')
  .directive('relStates', ['states', states =>
    ({
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      template: require('./states-directive.html').default,
      link: (scope:any) => {
        states.unshift({ country: '', name: '-- State --', abbr: '?' });
        scope.states = states;
      }
    })
  ]);
