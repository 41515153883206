interface Language {
  code: string
  name: string
}

interface ILanguageService {
  getAll(): Array<Language>
  getNameByCode(code: string): string 
}

class LanguageService implements ILanguageService {
  languages: Array<Language>;

  constructor(languages: Array<Language>) {this.languages = languages}

  getAll(): Language[] {
    return this.languages;
  }
  
  getNameByCode(code: string): string {
    const found = this.languages.find(item => item.code == code);
    return found ? found.name : '';
  }
}

angular
  .module('relcore.common')
  .value('languages', [
    { code: 'EN-US', name: 'English (U.S.)'},
    { code: 'EN-GB', name: 'English (British)'},
    { code: 'ES', name: 'Spanish'},
    { code: 'FR', name: 'French'},
    { code: 'DE', name: 'German'},
    { code: 'NL', name: 'Dutch'},
    { code: 'PT', name: 'Portuguese'}
  ]);


angular
  .module('relcore.common')
  .service('languageService', ['languages', (languages: Array<Language>) => {
      return new LanguageService(languages);
    }
  ]);