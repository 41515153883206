angular
  .module('relcore.alert')
  .directive('relAlerts', function() {
    return {
      template: require('./alerts.html').default,
      controller: 'AlertsController'
    };
  })
  .controller('AlertsController',
    ['$scope', 'alertService', function($scope, alertService) {
      $scope.alerts = alertService.alerts;

      const onChange = alerts => {
        $scope.alerts = alerts;
      };

      $scope.close = alert => alertService.clear(alert);

      alertService.onChange(onChange);
    }
  ]);
