angular
  .module('relcore.mobile-device')
  .controller('shippingTicketDeviceReceivedController', [
    '$scope', '$uibModalInstance', 'relianceApi', 'ticket', 'device', function($scope, $uibModalInstance, relianceApi, ticket, device) {
      $scope.ticketDevice = device
      $scope.model = { notes: '' }
      $scope.submitting = false
      $scope.close = () => $uibModalInstance.close({saved: false})
      $scope.submit = () => {
        $scope.submitting = true
        relianceApi.put(`/shipping-ticket/${ticket.id}/device/${$scope.ticketDevice.mobileDevice.id}/received`, {
            notes: $scope.model.notes
          })
          .then(() => {
            $scope.submitting = false
            $uibModalInstance.close({saved: true})
          }, err => {
            $scope.submitting = false
          })
      }
    }
  ])
