import { Facility } from '@reliance/types/models/Facility';
import React, { useMemo, useState } from 'react';
import { react2angular } from 'react2angular';
import api from '../common/api';
import { useSpeedDialDestinations } from '../hooks/SpeedDial';
import AddEditModal from './AddEditModal';
import DestinationTable from './DestinationTable';

type DestinationsProps = {
  facility: Facility
}

const Destinations: React.FC<DestinationsProps> = (props) => {
  const filters = useMemo(() => ({
    facilityId: props.facility.id
  }), [props.facility]);
  const destinations = useSpeedDialDestinations(filters);

  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState(null);

  if (destinations.loading) {
    return (
      <div className="jarviswidget jarviswidget-color-blueDark">
        <div className="row">
          <div className="col-md-12">
            <h3>Loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  const addEditDestination = (destination) => {
    if (destination.id) {
      return api.put(`/speed-dial/${destination.id}`, destination)
        .then(() => {
          destinations.execute();
        })
        .catch(() => {
          setError('An error occurred while adding the destination');
        });
    } else {
      return api.post('/speed-dial', {
        ...destination,
        facilityId: props.facility.id
      })
        .then(() => {
          setShowAddModal(false);
          destinations.execute();
        })
        .catch(() => {
          setError('An error occurred while adding the destination');
        });
    }
  }

  const removeDestination = (destination) => {
    return api.delete(`/speed-dial/${destination.id}`)
      .then(() => {
        destinations.execute();
      })
      .catch(() => {
        setError('An error occurred while removing the destination');
      });
  }

  return (
    <div className="jarviswidget jarviswidget-color-blueDark">
      <div className="row">
        <div className="col-md-12">
          {destinations.error &&
            <div className='alert alert-error'>An unexpected error occurred while loading the speed dial destinations</div>
          }

          {error &&
            <div className='alert alert-error'>{error}</div>
          }

          <button className="btn btn-primary" onClick={() => setShowAddModal(true)}>Add Destination</button>
          <br/><br/>

          <DestinationTable
            destinations={destinations.result}
            onEditSave={addEditDestination}
            onRemove={removeDestination}
            />

          {showAddModal &&
            <AddEditModal
              onClose={() => setShowAddModal(false)}
              onSave={addEditDestination}
              />
          }
        </div>
      </div>
    </div>
  );
}

angular
  .module('relcore.speed-dial')
  .component('speedDialDestinations', react2angular(Destinations, ['facility']));

export default Destinations;