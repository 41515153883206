angular
  .module('relcore.directives')
  .directive('relDropzone', ['config', 'dropzone', (config, Dropzone) =>
    ({
      scope: {
        onError: '='
      },
      link(scope:any, element, attrs) {
        element.addClass('dropzone');

        const options = {
          url: '',
          maxFiles: 0
        };
        options.url = config.api.url + "/office-call/import";
        options.maxFiles = attrs.maxFiles;

        // create a Dropzone for the element with the given options
        Dropzone.autoDiscover = false;
        const dropzone = new Dropzone(element[0], options);
        dropzone.on('sending', (file, xhr, formData) => xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`));
        dropzone.on('complete', file => dropzone.removeAllFiles());
        if (scope.onError != null) {
          dropzone.on('error', scope.onError);
        }
      }
    })
  ]);