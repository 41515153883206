angular
  .module('relcore.inmate')
  .directive('supportMessageRead', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        reading: '=',
        onRead: '='
      },
      controller: ['$scope', function($scope) {
        $scope.read = $scope.onRead;
      }],
      template: require('./support-message-read.html').default
    })
  );
