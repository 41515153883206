angular.module('relcore.filters').filter('inmateBalance', ['$filter', $filter =>
  function(value) {
    if (value.communicationBalance != null) {
      return $filter('currency')(value.communicationBalance) + ' (' + $filter('currency')(value.messagingBalance[0].balance) + ')';
    } else if (value.messagingBalance != null) {
      return $filter('currency')(value.messagingBalance[0].balance);
    } else {
      return $filter('currency')(0);
    }
  }

]);
