angular
  .module('relcore.sms-voice-number')
  .directive('smsVoiceNumbers', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }],
      template: require('./sms-voice-numbers.html').default
    })
  );
