import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import { IRelianceApi } from 'relcore-central';
import api from '../common/api';
import ConfirmModal from '../common/dialogs/ConfirmModal';
import ErrorMessage from '../common/ErrorMessage';
import { useInvoiceBatches } from '../hooks/Invoice';
import Table from './Table';

type SearchProps = {
  relianceApi: IRelianceApi
}

const Search: React.FC<SearchProps> = () => {
  const filters = useMemo(() => ({}), []);
  const invoiceBatches = useInvoiceBatches(filters);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [invoiceError, setInvoiceError] = useState(Error);

  return (
    <div className="jarviswidget jarviswidget-color-blueDark">
      <div className="row">
        <div className="col-md-12">
          {invoiceError &&
            <ErrorMessage
              httpGenericMessage="An unexpected error occurred while running invoicing" />
          }

          <Button
            onClick={() => setShowConfirmModal(true)}
            variant="primary"
            type="submit">
            Run Invoicing
          </Button>

          {showConfirmModal &&
            <ConfirmModal
              show={true}
              title="Run Invoicing"
              onAccept={() =>
                api
                  .post('/invoice/batch', {})
                  .then(() => invoiceBatches.execute())
                  .catch(setInvoiceError)
              }
              onClose={() => setShowConfirmModal(false)}
              acceptText="Run">
              Are you sure you want to run invoicing?
            </ConfirmModal>
          }

          {invoiceBatches.loading ?
            <h3>Loading...</h3> :
            <Table invoiceBatches={invoiceBatches.result} />
          }
        </div>
      </div>
    </div>
  );
}

export default Search;

angular
  .module('relcore.invoice')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('invoice-batches', {
        controller: 'InvoiceBatchesController',
        url: '/invoice',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Invoicing'
        },
        template: require('./search.html').default
      }
    )
  ])
  .controller('InvoiceBatchesController',
    ['$scope', 'relianceApi',
    function($scope, relianceApi:IRelianceApi) {
      $scope.relianceApi = relianceApi;
    }]
  )
  .component('invoiceSearch', react2angular(Search, ['relianceApi']));