import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { IUser } from '../models/auth';
import { IStaffUser } from '../models/staff-user';

export interface IStaffUserUpdate extends IStaffUser {
  apiRoles?: number[]
  legacyUserId?: number
  legacyUser?: Partial<IUser>
}

export interface IStaffUserUpdateResponse {
  success: boolean
  staffUser?: IStaffUser
}

export interface IUpdateErrorResponse {
  status: number
  data:
    {
      error:
        {
          code: number
          message?: string
          details?: string
        }
    }
}

export interface IStaffUserService  {
  getAllStaffUsers(status?: string): Promise<Array<IStaffUser>>;
  getStaffUser(id: number): Promise<IStaffUser>;
  addStaffUser(newUser: IStaffUserUpdate): Promise<IStaffUserUpdateResponse>;
  editStaffUser(userId: number, change: IStaffUserUpdate): Promise<IStaffUserUpdateResponse>;
  resetPassword(emailAddress: string): Promise<IStaffUserUpdateResponse>;
}

class StaffUserService implements IStaffUserService {
  api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  async getAllStaffUsers(status: string = 'Enabled'): Promise<Array<IStaffUser>> {
    return this.api.index('/staff-user', 0, 0, {status: status})
      .then((response: IApiResponse<Array<IStaffUser>>) => {
        return response.data;
      });
  }

  async getStaffUser(id: number): Promise<IStaffUser> {
    return this.api.get(`/staff-user/${id}`)
      .then((response: IApiResponse<IStaffUser>) => {
        return response.data;
      });
  }

  async addStaffUser(newUser: IStaffUserUpdate): Promise<IStaffUserUpdateResponse> {
    return this.api.post(`/staff-user`, newUser)
      .then((response: IApiResponse<IStaffUserUpdateResponse>) => {
        return response.data;
      });
  }

  async editStaffUser(userId: number, edits: IStaffUserUpdate): Promise<IStaffUserUpdateResponse> {
    let changes = {...edits, legacyUserId: null};
    const user = edits.legacyUser;
    if (user) {
      changes.legacyUserId = user.id;
    }
    return this.api.put(`/staff-user/${userId}`, changes)
      .then((response: IApiResponse<IStaffUserUpdateResponse>) => {
        return response.data;
      });
  }

  async resetPassword(emailAddress: string): Promise<IStaffUserUpdateResponse> {
    return this.api.post(`/staff-user/password/reset`, { emailAddress: emailAddress });
  }
}

angular
  .module('relcore.staff-user')
  .service('StaffUserService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new StaffUserService(relianceApi);
    }
  ])