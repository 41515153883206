angular
  .module('relcore.filters')
  .filter('callType', () =>
    function(code) {
      switch(code) {
        case 1:
          return 'Local';
        case 2:
          return 'In State';
        case 3:
          return 'International';
        case 6:
          return 'Out of State';
        case 7:
          return 'Video Call/Other';
        default: return '';
      }
    }
);
