angular
  .module('relcore.note')
  .directive('closeTicketPanel', () =>
    ({
      restrict: 'E',
      scope: {
        onClose: '=?',
        newNote: '=?',
        headingText: '=?',
      },
      controller: ['$scope', function($scope) {
        $scope.showAdd = ($scope.showAdd === null) || ($scope.showAdd === true);
        $scope.headingText = $scope.headingText || 'Manually Close Ticket';
      }
      ],
      template: require('./close-ticket-panel.html').default
    })
  );
