angular
  .module('relcore.filters')
  .filter('fullName', () =>
    function(value) {
      if (!((value != null) && (value.firstName != null) && (value.lastName != null))) { return ''; }

      if (value.middleName != null) {
        return `${value.firstName} ${value.middleName} ${value.lastName}`;
      } else {
        return `${value.firstName} ${value.lastName}`;
      }
    }
);
