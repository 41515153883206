import { ITaxRate, ITaxService } from "./tax-service";

interface ITaxRateHistoryScope extends ng.IScope {
  loading: boolean
  history: ITaxRate[]
  saving: boolean
  error: string
  cancel: () => void
}

angular
  .module('relcore.tax')
  .controller('TaxRateHistoryController', ['$scope', 'zipCode', 'TaxService', '$uibModalInstance',
    ($scope: ITaxRateHistoryScope, zipCode: string, TaxService: ITaxService, $uibModalInstance) => {
      $scope.loading = true;

      TaxService
        .getTaxRates({ zipCode })
        .then(history => {
          $scope.loading = false;
          $scope.history = history;
          $scope.$digest();
        })
        .catch(error => {
          $scope.error = 'An error occurred while retrieving the history';
          $scope.loading = false;
          $scope.$digest();
        });

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    }
  ]);