import { InvoiceBatch } from '@reliance/types/models/Invoice/InvoiceBatch';
import React from 'react';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { formatDate } from '../format/DateTime';
import BaseHtmlTable from '../table/BaseHtmlTable';

type TableProps = {
  invoiceBatches: IPaginatedResponse<InvoiceBatch>
}

const Table: React.FC<TableProps> = (props) => {
  const { invoiceBatches } = props;

  const columns = [
    { key: 'id', name: 'Batch ID' },
    { key: 'batchDate', name: 'Date' },
    { key: 'createdByName', name: 'Created By' },
    { key: 'createdBy', name: 'User' }
  ];

  const rows = invoiceBatches.data.map(b => ({
    ...b,
    batchDate: formatDate(b.batchDate.date),
    createdBy: b.createdBy.username
  }));

  return (
    <BaseHtmlTable
      baseKey="invoice-batch"
      columns={columns}
      rows={rows}
      showPagination={false}
      />
  );
}

export default Table;