import { RatePlan } from '@reliance/types/models/ServiceRate';
import { FORM_ERROR } from 'final-form';
import React, { useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import api from '../common/api';
import { useRatePlans } from '../hooks/ServiceRate';
import AddEditRatePlanModal from './AddEditRatePlanModal';
import RatePlanTable from './RatePlanTable';

type MainProps = {

}

const Main: React.FC<MainProps> = (props) => {
  const filters = useMemo(() => ({}), []);
  const ratePlans = useRatePlans(filters);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [editRatePlan, setEditRatePlan] = useState<RatePlan|null>(null);

  return (
    <>
      <h1 className="page-title txt-color-blueDark">
        <i className="fas fa-sm fa-fw fa-calculator"></i> Service Rates&nbsp;
        {ratePlans.loading && <i className="fas fa-spin fa-spinner"></i>}
      </h1>

      <Button onClick={() => setShowAddEdit(true)} className="mb-3">
        Add Rate Plan
      </Button>

      {showAddEdit &&
        <AddEditRatePlanModal
          ratePlan={editRatePlan}
          onSave={(ratePlanData) =>
            ratePlanData.id ?
              api.put(`/service-rate/plan/${ratePlanData.id}`, ratePlanData)
                .then(() => {
                  setShowAddEdit(false);
                  ratePlans.execute();
                })
                .catch((err) => ({ [FORM_ERROR]: err })) :
              api.post(`/service-rate/plan`, ratePlanData)
                .then(() => {
                  setShowAddEdit(false);
                  ratePlans.execute();
                })
                .catch((err) => ({ [FORM_ERROR]: err }))
          }
          onClose={() => {
            setShowAddEdit(false);
            setEditRatePlan(null);
          }}
          />
      }

      {!ratePlans.loading &&
        <RatePlanTable
          onEdit={(ratePlan) => {
            setEditRatePlan(ratePlan);
            setShowAddEdit(true);
          }}
          ratePlans={ratePlans}
          />
      }
    </>
  );
}

angular
  .module('relcore.service-rate')
  .component('serviceRates', react2angular(Main))
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('service-rates', {
          template: `
            <service-rates />
          `,
          url: '/service-rate',
          parent: 'authenticated',
          ncyBreadcrumb: {
            label: 'Service Rates'
          }
        }
      )
  ]);