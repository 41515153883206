angular
  .module('relcore.inmate-device-blacklist')
  .directive('inmateDeviceBlacklistAdd', () =>
    ({
      restrict: 'E',
      template: require('./inmate-device-blacklist-add.html').default,
      scope: {
        inmate: '='
      },
      controller: ['$scope', 'inmateDeviceBlacklistService', function($scope, inmateDeviceBlacklistService) {
        $scope.checking = true;
        $scope.isBlacklisted = false;
        $scope.blacklist = {
          inmateId: $scope.inmate.id,
          notes: ''
        };
        $scope.error = null;
        $scope.adding = false;
        $scope.removing = false;

        inmateDeviceBlacklistService.getIsBlacklisted($scope.inmate.id)
          .then((function(response) {
            $scope.checking = false;
            $scope.isBlacklisted = response.data;
            $scope.error = null;
          }), (function(err) {
            $scope.checking = false;
            $scope.error = err;
          }));

        $scope.remove = function() {
          $scope.removing = true;
          return inmateDeviceBlacklistService.deleteByInmate($scope.inmate.id)
            .then((function() {
              $scope.removing = false;
              $scope.isBlacklisted = false;
            }), function(err) {
              $scope.removing = false;
              $scope.error = err;
              $scope.isBlacklisted = true;
            });
        };

        $scope.add = function() {
          $scope.adding = true;
          return inmateDeviceBlacklistService.post($scope.blacklist)
            .then((function() {
              $scope.adding = false;
              $scope.adding = false;
              $scope.isBlacklisted = true;
            }), (function(err) {
              $scope.adding = false;
              $scope.error = err;
              $scope.isBlacklisted = false;
            }));
        };
      }
      ]
    })
  );
