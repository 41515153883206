import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { IMobileDeviceGroup } from './mobile-device-group';

interface IMobileDeviceGroupEdit {
  name: string
}

interface IGroupEditCtrl extends ng.IScope {
  error?: string
  saving: boolean
  changes: IMobileDeviceGroupEdit
  reset(): void
  save(): Promise<any>
  close(): void
}

angular
  .module('relcore.mobile-device-group')
  .controller('DeviceGroupEditCtrl', ['$scope', 'relianceApi', '$uibModalInstance', 'mobileDeviceGroup', function($scope:IGroupEditCtrl, relianceApi: IRelianceApi, $uibModalInstance, mobileDeviceGroup:IMobileDeviceGroup) {
    $scope.changes = {
      name: mobileDeviceGroup.name
    };

    $scope.save = function() {
      $scope.saving = true;
      return relianceApi
        .put(`/mobile-device/group/${mobileDeviceGroup.id}`, $scope.changes)
        .then((function(res:IApiResponse<any>) {
          $scope.saving = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({saved: true});
          }
        }), (function(err) {
          if (err.status === 400) {
            $scope.error = 'Please verify the inputs are valid and try again';
          } else {
            $scope.error = 'An unexpected error occurred';
          }
          $scope.saving = false;
        }));
    };

    $scope.close = () => $uibModalInstance.close({saved: false});
  }]);
