import { CreditCardType } from '@reliance/types/enums/CreditCardType';
import { IApiErrorResponse, IApiResponse, IRelianceApi, IServiceErrorResponse } from 'relcore-central';
import {
  DebitCreditInfo,
  ICashInfo,
  ICheckInfo, ICreditCardInfo, IMoneyOrderInfo, IMoneyTransferInfo, INewCreditCardInfo, IPayment,
  ISavedCreditCardInfo, PaymentType
} from './payment-factory';

export interface IPaymentService {
  getFees(amount: number): Promise<number>;
  postPayment(payment: IPayment): Promise<IPaymentResult | IServiceErrorResponse>
}

export interface IPaymentResult {
  success: boolean
  subTotal: number
  transactionFee?: number
  message?: string
}

const creditCardTypeRegEx: Record<CreditCardType, RegExp> = {
  [CreditCardType.VISA]: new RegExp('^4[0-9]{12}([0-9]{3})?$'),
  [CreditCardType.MASTER_CARD]: new RegExp('^(2|5)[1-5][0-9]{14}$'),
  [CreditCardType.AMERICAN_EXPRESS]: new RegExp('^3[47][0-9]{13}$'),
  [CreditCardType.DISCOVER_CARD]: new RegExp('^6011[0-9]{12}$'),
};

export function getCreditCardType(cardNumber: string): CreditCardType {
  for (const type in creditCardTypeRegEx) {
    const regEx = creditCardTypeRegEx[type];
    if (regEx && regEx.test(cardNumber)) {
      return type as CreditCardType;
    }
  }
  return null;
}

class PaymentService implements IPaymentService {
  api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
    this.getFees = this.getFees.bind(this);
  }

  async getFees(amount: number): Promise<number> {
    return this.api.get('/payment/fees', { amount })
      .then((result: IApiResponse<number>) => result.data)
      .catch((err: IApiErrorResponse) => {
        return Promise.reject(err.data?.error || {});
      });
  }

  async postPayment(payment: IPayment): Promise<IPaymentResult | IServiceErrorResponse> {
    const request = this.mapPaymentToRequest(payment);
    const uri = request.baseUri;
    return this.api.post(uri, request.toJSON())
      .then((result: IApiResponse<IPaymentResult>) => {
        return result.data;
      })
      .catch((err: IApiErrorResponse) => {
        return Promise.reject(err.data?.error || {});
      });
  }

  private mapPaymentToRequest(payment: IPayment): PaymentRequest {
    switch (payment.type) {
      case PaymentType.CREDIT_CARD:
        let paymentInfo = payment.paymentMethod.info as ICreditCardInfo;
        if ((paymentInfo as ISavedCreditCardInfo).savedCreditCardId) {
          return new SavedCreditCardRequest(payment);
        } else {
          return new NewCreditCardRequest(payment);
        }
      case PaymentType.CHECK:
        return new CheckRequest(payment);
      case PaymentType.CASH:
        return new CashRequest(payment);
      case PaymentType.GENERAL_CREDIT:
        return new GeneralCreditRequest(payment);
      case PaymentType.GENERAL_DEBIT:
        return new GeneralDebitRequest(payment);
      case PaymentType.MONEY_TRANSER:
        return new MoneyTransferRequest(payment);
      case PaymentType.MONEY_ORDER:
        return new MoneyOrderRequest(payment);
      default:
        return null;
    }
  }
}

abstract class PaymentRequest {
  payment: IPayment;

  constructor(payment: IPayment) {
    this.payment = payment;
  }

  abstract baseUri: string
  abstract toJSON(): object;
}

class SavedCreditCardRequest extends PaymentRequest {
  baseUri = '/payment/credit-card'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as ISavedCreditCardInfo;

    return {
      ani: payment.ani,
      amount: payment.amount,
      cardSecurityCode: info.cardSecurityCode,
      savedCreditCardId: info.savedCreditCardId
    }
  }
}

class NewCreditCardRequest extends PaymentRequest {
  baseUri = '/payment/credit-card'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as INewCreditCardInfo;

    return {
      ani: payment.ani,
      amount: payment.amount,
      cardSecurityCode: info.cardSecurityCode,
      newCreditCard: {
        nameOnCard: info.nameOnCard,
        cardNumber: info.cardNumber,
        address: info.address,
        city: info.city,
        state: info.state,
        zip: info.zip,
        expMonth: info.expMonth,
        expYear: info.expYear,
        save: info.save
      }
    }
  }
}

class CheckRequest extends PaymentRequest {
  baseUri = '/payment/check'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as ICheckInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        bank: info.bankName,
        routingNumber: info.routingNumber,
        accountNumber: info.accountNumber,
        checkNumber: info.checkNumber,
        idNumber: info.idNumber,
        idType: info.idType,
        idState: info.idState
      }
    };
  }
}

class CashRequest extends PaymentRequest {
  baseUri = '/payment/cash'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as ICashInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        paidByFirstName: info.firstName,
        paidByLastName: info.lastName
      }
    };
  }
}

class GeneralDebitRequest extends PaymentRequest {
  baseUri = '/payment/general-debit'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as DebitCreditInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        explanation: info.explanation
      }
    };
  }
}

class GeneralCreditRequest extends PaymentRequest {
  baseUri = '/payment/general-credit'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as DebitCreditInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        explanation: info.explanation
      }
    };
  }
}

class MoneyTransferRequest extends PaymentRequest {
  baseUri = '/payment/money-transfer'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as IMoneyTransferInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        transferNumber: info.transferNumber,
        transferAgent: info.transferAgent,
        paidByFirstName: info.firstName,
        paidByLastName: info.lastName
      }
    };
  }
}

class MoneyOrderRequest extends PaymentRequest {
  baseUri = '/payment/money-order'

  toJSON(): object {
    const payment = this.payment;
    const info = payment.paymentMethod.info as IMoneyOrderInfo;

    return {
      payment: {
        ani: payment.ani,
        amount: payment.amount,
        paidByFirstName: info.firstName,
        paidByLastName: info.lastName
      }
    };
  }
}

angular
  .module('relcore.account')
  .service('PaymentService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new PaymentService(relianceApi);
  }]);
