import { required } from '@reliance/forms-js/lib/validation';
import { Account } from '@reliance/types/models/Account';
import { FormApi } from 'final-form';
import React from 'react';
import { Select } from '../../form/render';
import { NewCreditCardForm } from './NewCreditCardForm';
import { SavedCreditCardForm } from './SavedCreditCardForm';

type CreditCardPaymentFormProps = {
  account: Account,
  form: FormApi<any>
  currentValues
}

export const CreditCardPaymentForm: React.FC<CreditCardPaymentFormProps> = (props) =>
  <div>
    <div className="row">
      <div className="col-md-12">
        <Select
          name="savedOrNew"
          label="Credit Card"
          validationRules={[
            required
          ]}
          options={[
            { text: 'Saved Credit Card', value: 'saved' },
            { text: 'New Credit Card', value: 'new' }
          ]} />
      </div>
    </div>

    {props.currentValues['savedOrNew'] == 'saved' &&
      <SavedCreditCardForm account={props.account} />
    }

    {props.currentValues['savedOrNew'] == 'new' &&
      <NewCreditCardForm
        currentValues={props.currentValues}
        form={props.form} />
    }
  </div>