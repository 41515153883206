import { maxLength, minLength, minValue, numeric, required } from '@reliance/forms-js/lib/validation';
import { ServiceType } from '@reliance/types/enums/ServiceType';
import { RatePlan } from '@reliance/types/models/ServiceRate';
import { FORM_ERROR } from 'final-form';
import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import Checkbox from '../form/Checkbox';
import { TextInput } from '../form/TextInput';

type AddEditRatePlanModalProps = {
  ratePlan?: RatePlan
  onSave(ratePlan: RatePlan): Promise<any>
  onClose(): void
}

type FormData = RatePlan & {
  enableSms: boolean;
  smsRate: number|undefined;
  smsDirection: 'Inbound'|'Outbound'|undefined;

  enableMms: boolean;
  mmsRate: number|undefined;
  mmsDirection: 'Inbound'|'Outbound'|undefined;

  enableSupportMessage: boolean;
  supportMessageRate: number|undefined;
  supportMessageDirection: 'Inbound'|'Outbound'|undefined;

  enableRentalFee: boolean;
  rentalFeeRate: number|undefined;
}

const massageData = (data: any) => {
  let formData = data as FormData;

  // Clear out the rates to make sure that if the user removed any, that we don't resend them
  formData.rates = [];

  if (formData.enableSms) {
    data.rates.push({
      serviceType: ServiceType.SMS,
      // direction: formData.smsDirection, // Unless we decide to split inbound vs outbound, we'll leave it as both directions by default
      rate: parseFloat(formData.smsRate as any)
    });
  }

  if (formData.enableMms) {
    data.rates.push({
      serviceType: ServiceType.MMS,
      // direction: formData.mmsDirection, // Unless we decide to split inbound vs outbound, we'll leave it as both directions by default (also not applicable for MMS)
      rate: parseFloat(formData.mmsRate as any)
    });
  }

  if (formData.enableRentalFee) {
    data.rates.push({
      serviceType: ServiceType.RENTAL_FEE,
      rate: parseFloat(formData.rentalFeeRate as any)
    });
  }

  if (formData.enableSupportMessage) {
    data.rates.push({
      serviceType: ServiceType.SUPPORT_MESSAGE,
      direction: 'Outbound', // Unless we decide to charge inmates for messages from support, we'll leave it as just outbound from the inmate
      rate: parseFloat(formData.supportMessageRate as any)
    });
  }

  return data;
}

const AddEditRatePlanModal: React.FC<AddEditRatePlanModalProps> = (props) => {
  const existingRates = props.ratePlan?.rates || [];

  // Detect which current rates are specified
  const enableSms = existingRates.filter(r => r.serviceType == ServiceType.SMS).length > 0;
  const smsRate = enableSms ? existingRates.filter(r => r.serviceType == ServiceType.SMS)[0].rate : 0;
  const enableMms = existingRates.filter(r => r.serviceType == ServiceType.MMS).length > 0;
  const mmsRate = enableMms ? existingRates.filter(r => r.serviceType == ServiceType.MMS)[0].rate : 0;
  const enableSupportMessage = existingRates.filter(r => r.serviceType == ServiceType.SUPPORT_MESSAGE).length > 0;
  const supportMessageRate = enableSupportMessage ? existingRates.filter(r => r.serviceType == ServiceType.SUPPORT_MESSAGE)[0].rate : 0;
  const enableRentalFee = existingRates.filter(r => r.serviceType == ServiceType.RENTAL_FEE).length > 0;
  const rentalFeeRate = enableRentalFee ? existingRates.filter(r => r.serviceType == ServiceType.RENTAL_FEE)[0].rate : 0;

  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={{
          ...props.ratePlan,
          enableSms,
          smsRate,
          enableMms,
          mmsRate,
          enableSupportMessage,
          supportMessageRate,
          enableRentalFee,
          rentalFeeRate
        }}
        validate={(values) => {
          if (!values.enableSms && !values.enableMms && !values.enableRentalFee && !values.enableSupportMessage) {
            return { [FORM_ERROR]: 'At least one rate must be specified' };
          }
          return null;
        }}
        keepDirtyOnReinitialize={true}
        onSubmit={(data) => props.onSave(massageData(data))}
        render={({ handleSubmit, submitting, hasValidationErrors, values, error, dirty }) =>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {props.ratePlan &&
              <Alert variant="danger">Modifying rate plans is not supported due to date range reporting complications</Alert>
            }

            <TextInput
              label="Name"
              validationRules={[required, minLength(1), maxLength(50)]}
              name="name" />

            <Row>
              <Col>
                <Checkbox
                  label="SMS"
                  name="enableSms" />
              </Col>
              {values.enableSms &&
                <>
                  <Col>
                    <TextInput
                      label="Rate"
                      placeholder="rate"
                      validationRules={[required, numeric, minValue(0)]}
                      name="smsRate" />
                  </Col>
                  {/* <Col>
                    <Select
                      name="smsDirection"
                      label="Direction">
                      <option>Any</option>
                      <option value="Outbound">Outbound (from inmate)</option>
                      <option value="Inbound">Inbound (to inmate)</option>
                    </Select>
                  </Col> */}
                </>
              }
            </Row>

            <Row>
              <Col>
                <Checkbox
                  label="MMS"
                  name="enableMms" />
              </Col>
              {values.enableMms &&
                <>
                  <Col>
                    <TextInput
                      label="Rate"
                      placeholder="rate"
                      validationRules={[required, numeric, minValue(0)]}
                      name="mmsRate" />
                  </Col>
                  {/* <Col>
                    <Select
                      name="mmsDirection"
                      label="Direction">
                      <option>Any</option>
                      <option value="Outbound">Outbound (from inmate)</option>
                      <option value="Inbound">Inbound (to inmate)</option>
                    </Select>
                  </Col> */}
                </>
              }
            </Row>

            <Row>
              <Col>
                <Checkbox
                  label="Support Messages"
                  name="enableSupportMessage" />
              </Col>
              {values.enableSupportMessage &&
                <>
                  <Col>
                    <TextInput
                      label="Rate"
                      placeholder="rate"
                      validationRules={[required, numeric, minValue(0)]}
                      name="supportMessageRate" />
                  </Col>
                  {/* <Col>
                    <Select
                      name="supportMessageDirection"
                      label="Direction">
                      <option>Any</option>
                      <option value="Outbound">Outbound (from inmate)</option>
                      <option value="Inbound">Inbound (to inmate)</option>
                    </Select>
                  </Col> */}
                </>
              }
            </Row>

            <Row>
              <Col>
                <Checkbox
                  label="Rental Fee"
                  name="enableRentalFee" />
              </Col>
              {values.enableRentalFee &&
                <>
                  <Col>
                    <TextInput
                      label="Rate"
                      placeholder="rate"
                      validationRules={[required, numeric, minValue(0)]}
                      name="rentalFeeRate" />
                  </Col>
                </>
              }
            </Row>

            {dirty && error &&
              <Alert variant="danger">{error}</Alert>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              type="button"
              disabled={submitting}
              onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={submitting || hasValidationErrors || props.ratePlan != null}
              type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      } />
    </Modal>
  );
}

export default AddEditRatePlanModal;
