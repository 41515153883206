angular
  .module('relcore.report')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('video-call-cost-recovery-summary-report', {
        controller: 'VideoCallCostRecoverySummaryReportController',
        url: '/report/video-call/cost-recovery-summary',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'authenticated'
        },
        template: require('./cost-recovery-summary.html').default
      });
  }])
  .controller('VideoCallCostRecoverySummaryReportController',
    ['$rootScope', '$scope', '$state', 'relianceApi', 'facilityService', 'NgTableParams', '$stateParams', 'moment',
    function($rootScope, $scope, $state, relianceApi, facilityService, NgTableParams, $stateParams, moment) {
      $rootScope.title = 'Video Calling Cost Recovery Summary Report';

      $scope.total = 0;
      $scope.costRecoveryFee = 0;
      $scope.dateRange = {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day')
      };
      $scope.runLock = true;
      $scope.loaded = false;

      $scope.gridOptions = new NgTableParams({
          sorting: {
            'facility.name': 'asc'
          }
        },
        {
          counts: [],
          getData: (params) => {
            if ($scope.dateRange.startDate == null || $scope.runLock) {
              return [];
            }


            let queryParams = {
              startDate: $scope.dateRange.startDate.format('YYYY/MM/DD 00:00:00'),
              endDate: $scope.dateRange.endDate.format('YYYY/MM/DD 23:59:59')
            };

            return relianceApi.get("/report/video-call/cost-recovery", queryParams)
              .then((response) => {
                $scope.loaded = true;
                $scope.total = response.data.total;
                $scope.costRecoveryFee = response.data.costRecoveryFee;
                return response.data.results;
              });
          }
        }
      );

      $scope.run = () => {
        $scope.runLock = false;
        $scope.gridOptions.reload();
      };
      $scope.reset = () => $scope.loaded = false;
    }]);
