angular
  .module('relcore.api-role')
  .directive('apiRoleSearch', () =>
    ({
      restrict: 'E',
      scope: {
        fields: '=',
        searching: '=',
        criteria: '=',
        onSearch: '=',
        onReset: '='
      },
      controller: ['$scope', function($scope) {
        $scope.search = () => $scope.onSearch($scope.criteria);
        $scope.showField = field => ($scope.fields == null) || ($scope.fields.indexOf(field) > -1);
      }
      ],
      template: require('./api-role-search.html').default
    })
  );
