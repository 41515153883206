interface IMobileDeviceOperationsScope {
  device
  onChange
  updateApps
  pushConfig
  restart
  shutdown
  updateOs
  poll
  resetAppLock
  cancelPendingCommands
  cancelAppUpdates
  syncInfo
  compareOsVersions
  toggleTestMode

  state: IMobileDeviceOperationsState
}

interface IMobileDeviceOperationsState {
  togglingTestMode: boolean
  updatingApps: boolean
  pushingConfig: boolean
  restarting: boolean
  shuttingDown: boolean
  updatingOs: boolean
  polling: boolean
  resettingAppLock: boolean
  cancellingPendingCommands: boolean
  cancellingAppUpdates: boolean
  syncingInfo: boolean
}

angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceOperations', function() {
    return {
      scope: {
        'device': '=',
        'onChange': '='
      },
      template: require('./mobile-device-operations.html').default,
      controller: 'MobileDeviceOperationsController'
    };
  })
  .controller('MobileDeviceOperationsController', ['$scope', 'mobileDeviceService', 'alertService', function($scope: IMobileDeviceOperationsScope, mobileDeviceService, alertService) {
    $scope.state = {
      togglingTestMode: false,
      updatingApps: false,
      pushingConfig: false,
      restarting: false,
      shuttingDown: false,
      updatingOs: false,
      polling: false,
      resettingAppLock: false,
      cancellingPendingCommands: false,
      cancellingAppUpdates: false,
      syncingInfo: false,
    }

    const enableTestMode = function() {
      $scope.state.togglingTestMode = true;
      return mobileDeviceService.update([$scope.device.id], {status: 'Test Mode'})
        .then((function() {
          $scope.state.togglingTestMode = false;
          alertService.success('Test mode enabled successfully', true);
          $scope.onChange();
        }), (function() {
          $scope.state.togglingTestMode = false;
          alertService.error('An error occurred while enabling test mode', true);
        }));
    };

    const disableTestMode = function() {
      $scope.state.togglingTestMode = true;
      mobileDeviceService.update([$scope.device.id], {status: 'reset'})
        .then((function() {
          $scope.state.togglingTestMode = false;
          alertService.success('Test mode disabled successfully', true);
          $scope.onChange();
        }), (function() {
          $scope.state.togglingTestMode = false;
          alertService.error('An error occurred while disabling test mode', true);
        }));
    };

    $scope.toggleTestMode = function() {
      if ($scope.device.status === 'Test Mode') {
        disableTestMode();
      } else {
        enableTestMode();
      }
    };

    $scope.state.togglingTestMode = false;

    $scope.updateApps = function() {
      $scope.state.updatingApps = true;
      mobileDeviceService.updateApps([$scope.device.id])
        .then((function(result) {
          const { data } = result;
          $scope.state.updatingApps = false;

          if (data.updatingDeviceIds.length === 1) {
            alertService.success('Apps will be updated shortly', true);
          } else if (data.upToDateDeviceIds.length === 1) {
            alertService.success('Apps are up-to-date', true);
          } else {
            alertService.error('Device could not be updated at this time', true);
          }
        }), (function() {
          $scope.state.updatingApps = false;
          alertService.error('An error occurred while updating the apps', true);
        }));
    };

    $scope.pushConfig = function() {
      $scope.state.pushingConfig = true;
      mobileDeviceService.pushConfig($scope.device.id)
        .then((function() {
          $scope.state.pushingConfig = false;
          alertService.success('Config will be pushed to the device shortly', true);
        }), (function() {
          $scope.state.pushingConfig = false;
          alertService.error('An error occurred while pushing the config to the device', true);
        }));
    };

    $scope.restart = function() {
      $scope.state.restarting = true;
      mobileDeviceService.restart($scope.device.id)
        .then((function() {
          $scope.state.restarting = false;
          alertService.success('Device will restart shortly', true);
        }), (function() {
          $scope.state.restarting = false;
          alertService.error('An error occurred while sending the restart command', true);
        }));
    };

    $scope.shutdown = function() {
      $scope.state.shuttingDown = true;
      mobileDeviceService.shutdown($scope.device.id)
        .then((function() {
          $scope.state.shuttingDown = false;
          alertService.success('Device will power off shortly', true);
        }), (function() {
          $scope.state.shuttingDown = false;
          alertService.error('An error occurred while sending the shutdown command', true);
        }));
    };

    $scope.updateOs = function() {
      $scope.state.updatingOs = true;
      mobileDeviceService.updateOs($scope.device.id)
        .then((function() {
          $scope.state.updatingOs = false;
          alertService.success('Any available OS updates will begin shortly', true);
        }), (function() {
          $scope.state.updatingOs = false;
          alertService.error('An error occurred while sending the OS update command', true);
        }));
    };

    $scope.poll = function() {
      $scope.state.polling = true;
      mobileDeviceService.poll($scope.device.id)
        .then((function() {
          $scope.state.polling = false;
          alertService.success('Push notification will be sent to device shortly', true);
        }), (function() {
          $scope.state.polling = false;
          alertService.error('An error occurred when trying to poll the device', true);
        }));
    };

    $scope.resetAppLock = function() {
      $scope.state.resettingAppLock = true;
      mobileDeviceService.resetAppLock($scope.device.id)
        .then((function() {
          $scope.state.resettingAppLock = false;
          alertService.success('App lock will be reset shortly', true);
        }), (function() {
          $scope.state.resettingAppLock = false;
          alertService.error('An error occurred when trying to reset Single App Mode', true);
        }));
    };

    $scope.cancelPendingCommands = function() {
      $scope.state.cancellingPendingCommands = true;
      mobileDeviceService.cancelPendingCommands($scope.device.id)
        .then((function() {
          $scope.state.cancellingPendingCommands = false;
          alertService.success('Pending commands cancelled', true);
        }), (function() {
          $scope.state.cancellingPendingCommands = false;
          alertService.error('An error occurred when cancelling the pending commands', true);
        }));
    };

    $scope.cancelAppUpdates = function() {
      $scope.state.cancellingAppUpdates = true;
      mobileDeviceService.cancelAppUpdates($scope.device.id)
        .then((function() {
          $scope.state.cancellingAppUpdates = false;
          alertService.success('App updates cancelled', true);
        }), (function() {
          $scope.state.cancellingAppUpdates = false;
          alertService.error('An error occurred when cancelling the app updates', true);
        }));
    };

    $scope.syncInfo = function() {
      $scope.state.syncingInfo = true;
      mobileDeviceService.syncInfo($scope.device.id)
        .then((function() {
          $scope.state.syncingInfo = false;
          alertService.success('Device info will be synced shortly', true);
        }), (function() {
          $scope.state.syncingInfo = false;
          alertService.error('An error occurred when syncing device info', false);
        }));
    };

    $scope.compareOsVersions = function(a, b) {
      const aComponents = a.split('.').map(Number);
      const bComponents = b.split('.').map(Number);

      for (let i of [0, 1, 2]) {
        if ((aComponents[i] != null ? aComponents[i] : 0) > (bComponents[i] != null ? bComponents[i] : 0)) { return 1; }
        if ((bComponents[i] != null ? bComponents[i] : 0) > (aComponents[i] != null ? aComponents[i] : 0)) { return -1; }
      }

      return 0;
    };
  }
  ]);
