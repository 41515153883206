angular
  .module('relcore.card')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('card-change-value', {
        template: require('./change-value.html').default,
        url: '/card/change-value',
        parent: 'authenticated',
        controller: 'CardChangeValueController',
        ncyBreadcrumb: {
          label: 'Change Card Balance'
        }
      }
    )
  ])
  .controller('CardChangeValueController',
    ['$scope', '$rootScope', '$q', 'auth', 'relianceApi', 'alertService',
    function($scope, $rootScope, $q, auth, relianceApi, alertService) {
      let errorHandler;
      const reset = function(form = null) {
        $scope.model = {};
        $scope.model.name = '';
        $scope.model.description = '';
        $scope.model.pin = '';
        $scope.model.value = '';
        $scope.model.fixed = 'false';

        if (form != null) {
          form.$setPristine();
          form.$submitted = false;
        }
      };

      $rootScope.title = 'Change Value';
      reset();

      $scope.changeValue = function(form) {
        if (!form.$valid) { return; }

        const name = auth.currentUser().firstName + ' ' + auth.currentUser().lastName;
        $scope.changing = true;
        return relianceApi.put(`/phone-card/change-value.json/${$scope.model.pin}/${$scope.model.value}?name=${name}&description=${$scope.model.description}&fixed=${$scope.model.fixed}`)
          .then(results => {
            $scope.changing = false;
            successHandler(form, results.data, results.status);
          },
          results => {
            $scope.changing = false;
            errorHandler(results.data, results.status);
          }
        );
      };

      var successHandler = function(form, data, status) {
        if (status === 200) {
          reset(form);
          alertService.success('The value change was successful', true);
        }
        else {
          // TODO: Log to errbit
          alertService.error('Something broke! The change failed', true);
        }
      };

      return errorHandler = function(data, status) {
        if (status === 400) {
          switch (data.error.error) {
            case 'value_invalid': alertService.error('The value supplied is invalid', true); break;
            case 'card_invalid': alertService.error(`The card, ${data.error.card}, supplied is invalid`, true); break;
            case 'card_not_found': alertService.error(`The card, ${data.error.card}, does not exist`, true); break;
          }
          return;
        }
        else {
          // TODO: Log to errbit
         alertService.error('Something broke! The change failed', true);
        }
      };
    }
  ]);
