import { CreditCard } from '@reliance/types/models/CreditCard';
import React from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import Card from 'react-bootstrap/esm/Card';
import { IPaginatedResponse } from 'relcore-central';
import { creditCardType } from '../format/CreditCardType';
import { formatPhoneNumber } from '../format/PhoneNumber';
import BaseHtmlTable from '../table/BaseHtmlTable';

type ListProps = {
  creditCards: UseAsyncReturn<IPaginatedResponse<CreditCard>>
}

const List: React.FC<ListProps> = (props) => {
  const columns = [
    { key: 'source', name: 'Source' },
    { key: 'ani', name: 'ANI' },
    { key: 'emailAddress', name: 'Email Address' },
    { key: 'cardType', name: 'Type' },
    { key: 'firstSix', name: 'First 6' },
    { key: 'lastFour', name: 'Last 4' },
    { key: 'nameOnCard', name: 'Cardholder' },
  ];

  const rows = (props.creditCards.result?.data || []).map((card) => ({
    ...card,
    ani: card.account ? <a href={`/account/${card.account.ani}`}>{formatPhoneNumber(card.account.ani)}</a> : '',
    cardType: creditCardType(card.cardType),
    emailAddress: card.onlineUser ? <a href={`/online-user/view/${card.onlineUser.id}`}>{card.onlineUser.emailAddress}</a> : ''
  }));

  return (
    <Card>
      <Card.Body>
        <BaseHtmlTable
          baseKey="credit-card-list"
          columns={columns}
          rows={rows}
          />
      </Card.Body>
    </Card>
  );
}

export default List;
