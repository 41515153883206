import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import { react2angular } from 'react2angular';
import { IAlertService } from '../../alert/alert-service';
import { IServiceErrorResponse } from '../../common/reliance-api-service';
import { formatCurrency } from '../../format/Currency';
import BaseHtmlTable from '../../table/BaseHtmlTable';
import { IPhoneCardInventory, IPhoneCardService, ITypeValue } from "../phone-card-service";
import AddModal, { AddInventoryData } from './AddModal';

angular
  .module('relcore.card')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('card-inventory', {
        template: `<card-inventory />`,
        url: '/card/inventory',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Card Inventory'
        }
      }
    )
  ]);

type InventoryProps = {
  phoneCardService: IPhoneCardService
  alertService: IAlertService
}

const Inventory: React.FC<InventoryProps> = (props) => {
  const [inventory, setInventory] = useState<IPhoneCardInventory[]>([]);
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [availableCardTypeValues, setAvailableCardTypeValues] = useState<ITypeValue[]>([]);
  const [availableCardTypeValuesLoading, setAvailableCardTypeValuesLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const load = () => {
    setInventoryLoading(true);
    props.phoneCardService
      .getAvailableInventory()
      .then(setInventory)
      .then(() => setInventoryLoading(false));

    setAvailableCardTypeValuesLoading(true);
    props.phoneCardService
      .getAllCardTypeValues()
      .then(setAvailableCardTypeValues)
      .then(() => setAvailableCardTypeValuesLoading(false));
  }

  useEffect(() => {
    load();
  }, []);

  const columns = [
    { key: 'unitPrice', name: 'Unit Price' },
    { key: 'description', name: 'Description' },
    { key: 'quantity', name: 'Quantity' }
  ];
  const rows = inventory.map(i => ({
    unitPrice: formatCurrency(i.typeValue.value.unitPrice),
    description: i.typeValue.value.description,
    quantity: i.count
  }));

  const generateInventory = (data: AddInventoryData) => {
    return props.phoneCardService
      .generateInventory(data.typeValueId, data.quantity)
      .then(res => {
        setShowModal(false);
        props.alertService.success('The inventory was successfully generated', true);
        load();
      })
      .catch((error: IServiceErrorResponse) => {
        props.alertService.error(error.message || 'An error occurred while generating inventory', true);
        throw error;
      });
  }

  return (
    <>
      <h1 className="page-title txt-color-blueDark">
        <i className="fas fa-sm fa-fw fa-list-alt"></i> Phone Cards &nbsp;
        {inventoryLoading && <span><i className="fas fa-spin fa-spinner"></i></span>}
      </h1>
      <Card>
        <Card.Header>
          <Button variant="primary" onClick={() => setShowModal(true)} disabled={availableCardTypeValuesLoading}>
            <i className="fas fa-plus-circle" /> Add Inventory &nbsp;
            {availableCardTypeValuesLoading && <span><i className="fas fa-spin fa-spinner"></i></span>}
          </Button>
        </Card.Header>
        <Card.Body>
          {!inventoryLoading &&
            <BaseHtmlTable
              baseKey="card-inventory"
              rows={rows}
              columns={columns} />
          }
        </Card.Body>
      </Card>
      {showModal && availableCardTypeValues &&
        <AddModal
          typeValues={availableCardTypeValues}
          onClose={() => setShowModal(false)}
          onSubmit={generateInventory}
          />
      }
    </>
  )
}

angular
  .module('relcore.card')
  .component('cardInventory', react2angular(Inventory, [], ['phoneCardService', 'alertService']));

export default Inventory;