angular
  .module('relcore.facility')
  .service('facilityUserViewActions', ['relianceApi', function(relianceApi) {
    const clear = () => {
      return { type: 'facility-user/view/clear' }
    };

    const setFacilityUser = (facilityUser) => {
      return { type: 'facility-user/view/set-facility-user', facilityUser };
    };

    const loadFacilityUser = (id) => {
      return dispatch => {
        dispatch({ type: 'facility-user/view/loading' });

        return relianceApi.get(`/facility-user/${id}`)
          .then(
            (res => dispatch(setFacilityUser(res.data))),
            (err => dispatch({ type: 'facility-user/view/loading-failed', error: err }))
          );
      }
    };

    return {
      clear,
      setFacilityUser,
      loadFacilityUser
    };
  }]);
