import { Customer } from '@reliance/types/models/AuthorizeNet/Customer';
import React from 'react';
import { react2angular } from 'react2angular';

type CustomerInformationProps = {
  customer: Customer
}

export const CustomerInformation: React.FC<CustomerInformationProps> = (props) =>
  <section className="card">
    <div className="card-header">
      Authorize.net Customer Information
    </div>
    <div className="card-body">
      <div>
        Reliance Telephone:
        {props.customer.authorizeTelephoneCustomerId}
        {props.customer.authorizeTelephoneCustomerId == null &&
          <em>(missing)</em>
        }
      </div>
      <div>
        Reliance Systems:
        {props.customer.authorizeSystemsCustomerId}
        {props.customer.authorizeSystemsCustomerId == null &&
          <em>(missing)</em>
        }
      </div>
    </div>
  </section>

angular
  .module('relcore.authorizenet')
  .component('authorizeNetCustomerInformation', react2angular(CustomerInformation, ['customer']));