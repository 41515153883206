angular
  .module('relcore.common')
  .value('states', [
    { country: 'United States', name: 'Alabama', abbr: 'AL' },
    { country: 'United States', name: 'Alaska', abbr: 'AK' },
    { country: 'United States', name: 'Arizona', abbr: 'AZ' },
    { country: 'United States', name: 'Arkansas', abbr: 'AR' },
    { country: 'United States', name: 'California', abbr: 'CA' },
    { country: 'United States', name: 'Colorado', abbr: 'CO' },
    { country: 'United States', name: 'Connecticut', abbr: 'CT' },
    { country: 'United States', name: 'Delaware', abbr: 'DE' },
    { country: 'United States', name: 'District of Columbia', abbr: 'DC' },
    { country: 'United States', name: 'Florida', abbr: 'FL' },
    { country: 'United States', name: 'Georgia', abbr: 'GA' },
    { country: 'United States', name: 'Hawaii', abbr: 'HI' },
    { country: 'United States', name: 'Idaho', abbr: 'ID' },
    { country: 'United States', name: 'Illinois', abbr: 'IL' },
    { country: 'United States', name: 'Indiana', abbr: 'IN' },
    { country: 'United States', name: 'Iowa', abbr: 'IA' },
    { country: 'United States', name: 'Kansas', abbr: 'KS' },
    { country: 'United States', name: 'Kentucky', abbr: 'KY' },
    { country: 'United States', name: 'Louisiana', abbr: 'LA' },
    { country: 'United States', name: 'Maine', abbr: 'ME' },
    { country: 'United States', name: 'Maryland', abbr: 'MD' },
    { country: 'United States', name: 'Massachusetts', abbr: 'MA' },
    { country: 'United States', name: 'Michigan', abbr: 'MI' },
    { country: 'United States', name: 'Minnesota', abbr: 'MN' },
    { country: 'United States', name: 'Mississippi', abbr: 'MS' },
    { country: 'United States', name: 'Missouri', abbr: 'MO' },
    { country: 'United States', name: 'Montana', abbr: 'MT' },
    { country: 'United States', name: 'Nebraska', abbr: 'NE' },
    { country: 'United States', name: 'Nevada', abbr: 'NV' },
    { country: 'United States', name: 'New Hampshire', abbr: 'NH' },
    { country: 'United States', name: 'New Jersey', abbr: 'NJ' },
    { country: 'United States', name: 'New Mexico', abbr: 'NM' },
    { country: 'United States', name: 'New York', abbr: 'NY' },
    { country: 'United States', name: 'North Carolina', abbr: 'NC' },
    { country: 'United States', name: 'North Dakota', abbr: 'ND' },
    { country: 'United States', name: 'Ohio', abbr: 'OH' },
    { country: 'United States', name: 'Oklahoma', abbr: 'OK' },
    { country: 'United States', name: 'Oregon', abbr: 'OR' },
    { country: 'United States', name: 'Pennsylvania', abbr: 'PA' },
    { country: 'United States', name: 'Rhode Island', abbr: 'RI' },
    { country: 'United States', name: 'South Carolina', abbr: 'SC' },
    { country: 'United States', name: 'South Dakota', abbr: 'SD' },
    { country: 'United States', name: 'Tennessee', abbr: 'TN' },
    { country: 'United States', name: 'Texas', abbr: 'TX' },
    { country: 'United States', name: 'Utah', abbr: 'UT' },
    { country: 'United States', name: 'Vermont', abbr: 'VT' },
    { country: 'United States', name: 'Virginia', abbr: 'VA' },
    { country: 'United States', name: 'Washington', abbr: 'WA' },
    { country: 'United States', name: 'West Virginia', abbr: 'WV' },
    { country: 'United States', name: 'Wisconsin', abbr: 'WI' },
    { country: 'United States', name: 'Wyoming', abbr: 'WY' },
    { country: 'Canada', name: 'Alberta', abbr: 'AB' },
    { country: 'Canada', name: 'British Columbia', abbr: 'BC' },
    { country: 'Canada', name: 'Manitoba', abbr: 'MB' },
    { country: 'Canada', name: 'New Brunswick', abbr: 'NB' },
    { country: 'Canada', name: 'Newfoundland and Labrador', abbr: 'NL' },
    { country: 'Canada', name: 'Northwest Territories', abbr: 'NT' },
    { country: 'Canada', name: 'Nova Scotia', abbr: 'NS' },
    { country: 'Canada', name: 'Nunavut', abbr: 'NU' },
    { country: 'Canada', name: 'Ontario', abbr: 'ON' },
    { country: 'Canada', name: 'Prince Edward Island', abbr: 'PE' },
    { country: 'Canada', name: 'Quebec', abbr: 'QC' },
    { country: 'Canada', name: 'Saskatchewan', abbr: 'SK' },
    { country: 'Canada', name: 'Yukon', abbr: 'YT' }
  ]);

angular
  .module('relcore.common')
  .service('statesByAbbreviation', ['states', function(states) {
    const grouped = {};
    let state:any;
    for (state of Array.from(states)) {
      if ((grouped[state.abbr] == null)) { grouped[state.abbr] = []; }
      grouped[state.abbr] = state;
    }
    return grouped;
  }
]);
