import React from 'react';
import { react2angular } from 'react2angular';
import { Currency } from '../../format/Currency';
import { Date } from '../../format/Date';
import { CardType, ICardAccount } from '../../models/card-account';
import { SwitchStatus } from './SwitchStatus';

type HeaderProps = {
  searching?: boolean
  searchingNumber?: string
  cardAccount: ICardAccount
}

export const Header = (props: HeaderProps) => {
  const { cardAccount, searching, searchingNumber } = props;

  if (searching || cardAccount == null) {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-md-7 col-lg-6">
          <h1 className="page-title txt-color-blueDark">
            <i className="fas fa-sm fa-fw fa-list-alt"></i>&nbsp;
            Loading {searchingNumber}...
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-7 col-md-7 col-lg-6">
        <h1 className="page-title txt-color-blueDark">
          <i className="fas fa-sm fa-fw fa-list-alt"></i>&nbsp;
          {cardAccount.account}&nbsp;
          {/* <button className="btn btn-sm btn-primary" ng-click="showAddActivity()"><i className="fas fa-lg fa-dollar-sign"/></button>&nbsp; */}
          <br/>
          <small>{CardType[cardAccount.type]}</small>
        </h1>
      </div>
      <div className="col-xs-12 col-sm-5 col-md-5 col-lg-6">
        <ul className="sparks">
          <li className="sparks-info">
            <h5>Balance <span><Currency value={cardAccount.currentValue} /></span></h5>
          </li>
          <li className="sparks-info" ng-if="cardAccount.type == 'Phone Card'">
            <h5>Initial <span><Currency value={cardAccount.initialValue} /></span></h5>
          </li>
          <li className="sparks-info" ng-if="cardAccount.type == 'Phone Card'">
            <h5>Used <span><Currency value={cardAccount.initialValue - cardAccount.currentValue} /></span></h5>
          </li>
          {/* <li className="sparks-info" ng-if="cardAccount.type == 'Phone Card' && cdrData && cdrData.data">
            <h5>Calls <span>{cdrData.data.length}</span></h5>
          </li> */}
          {cardAccount.phoneCard && cardAccount.phoneCard.dateExpires &&
            <li className="sparks-info">
              <h5>Expires <span><Date value={cardAccount.phoneCard.dateExpires} /></span></h5>
            </li>
          }
          <li className="sparks-info d-print-none">
            <h5>
              Switch 1<br/>
              <SwitchStatus switchNumber={1} status={cardAccount.switchStatus1} />
            </h5>
          </li>
          <li className="sparks-info d-print-none">
            <h5>
              Switch 3<br/>
              <SwitchStatus switchNumber={3} status={cardAccount.switchStatus3} />
            </h5>
          </li>
        </ul>
      </div>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('cardHeader', react2angular(Header, ['cardAccount']));
