angular
  .module('relcore.facility')
  .controller('FacilityUserAddController', ['$scope', 'ReduxMapToScope', 'store', 'apiRolesActions', 'relianceApi', '$uibModalInstance', function($scope, ReduxMapToScope, store, apiRolesActions, relianceApi, $uibModalInstance) {
      const newUser = {
        username: '',
        password: '',
        roleIds: [],
        facilityIds: []
      };

      ReduxMapToScope($scope, state => {
        $scope.apiRoles = state.facilityUser.list.apiRoles;
      });

      store.dispatch(apiRolesActions.load());

      $scope.facilityUser = Object.assign({}, newUser);
      $scope.selectedFacilities = [];
      $scope.add = function() {
        $scope.adding = true;
        $scope.facilityUser.facilityIds = $scope.selectedFacilities.map(f => f.id);
        relianceApi.post(`/facility-user`, $scope.facilityUser)
          .then((function(res) {
            $scope.adding = false;
            if (!res.data || !res.data.success) {
              $scope.error = 'An unexpected error occurred';
            } else {
              $scope.error = null;
              $uibModalInstance.close({saved: true});
            }
          }), (function(err) {
            if (err.status === 400) {
              $scope.error = 'Please verify the inputs are valid and try again';
            } else {
              $scope.error = 'An unexpected error occurred';
            }
            $scope.adding = false;
          }));
      };

      $scope.close = () => $uibModalInstance.close({saved: false});

      $scope.allFacilities = false;
      $scope.handleAllFacilitiesCheck = () => {
        if ($scope.allFacilities) {
          $scope.facilityUser = Object.assign({}, $scope.facilityUser, {
            facilityIds: []
          });
        }
      };
    }]
  );
