import { IEventNotificationAction, IEventNotificationTrigger } from "../event-notification/event-notification-factory";
import { IEventNotificationService } from "../event-notification/event-notification-service";

class EmailActionUpdateWrapper {
  emailActionUpdate: EmailActionUpdate

  constructor(triggerId: number, action: IEventNotificationAction) {
    this.emailActionUpdate = new EmailActionUpdate(triggerId, action.id, action.destinationName, action.destination);
  }
}

class EmailActionUpdate {
  triggerId: number
  actionId: number
  name: string
  email: string

  constructor(triggerId: number, actionId: number, name: string, email: string) {
    this.triggerId = triggerId;
    this.actionId = actionId;
    this.name = name;
    this.email = email;
  }
}

angular
  .module('relcore.event-notification')
  .directive('preaConfigurations', () => ({
      restrict: 'E',
      scope: {
        facilityId: '=',
        name: '=',
        address: '=',
        onAdd: '&',
        onAdding: '&',
        onEdit: '&',
        onDelete: '&',
        onDeleting: '&'
      },
      template: require('./prea-configurations.html').default,
      controller: 'PREAConfigurationController'
    })
  )
  .controller('PREAConfigurationController',
    ['$scope', 'eventNotificationService', 'DTColumnBuilder', 'DTColumnDefBuilder', 'DTOptionsBuilder', '$filter', '$compile', '$uibModal',
      function($scope, eventNotificationService: IEventNotificationService, DTColumnBuilder, DTColumnDefBuilder, DTOptionsBuilder, $filter, $compile, $uibModal) {
        $scope.preaTriggersTableInstance = {};

        const editButtonRenderer = (actionUpdate: EmailActionUpdate) => {
          return `<button class='btn btn-default' ng-click='edit(${actionUpdate.triggerId},${actionUpdate.actionId},"${actionUpdate.name}","${actionUpdate.email}")'>Edit</button>`;
        };

        const deleteButtonRenderer = (actionUpdate: EmailActionUpdate) => {
          return `<a href='javascript:void(0);' class='btn btn-danger btn-xs' ng-click='delete(${actionUpdate.triggerId},${actionUpdate.actionId})' ng-style='{cursor: ${$scope.cursor}}'><i class='fas fa-trash-alt'></i></a>`;
        };

        $scope.preaTriggersTableColumns = [
          DTColumnBuilder.newColumn('emailActionUpdate.name').withTitle('Recepient Name'),
          DTColumnBuilder.newColumn('emailActionUpdate.email').withTitle('Recepient Email'),
          DTColumnBuilder.newColumn('emailActionUpdate').renderWith(editButtonRenderer),
          DTColumnBuilder.newColumn('emailActionUpdate').renderWith(deleteButtonRenderer)
        ];

        $scope.preaTriggersTableColumnDefs = [
          DTColumnDefBuilder.newColumnDef([2]).withOption('className', 'text-center'),
          DTColumnDefBuilder.newColumnDef([3]).withOption('className', 'text-center')
        ];

        $scope.preaTriggersTableOptions = DTOptionsBuilder
        .fromFnPromise(async () => {
          return eventNotificationService.getPREATrigger($scope.facilityId)
            .then((response: IEventNotificationTrigger) => {
              const rows = eventNotificationService.getEmailActions(response).map((action) => {
                return new EmailActionUpdateWrapper(response.id, action);
              });

              return rows;
            });
        })
        .withOption('lengthChange', false)
        .withOption('searching', false)
        .withOption('paging', false)
        .withOption('info', false)
        .withOption('order', []) // Disable initial sorting. Displays by ordering of the data array
        .withDisplayLength(200)
        .withOption('createdRow', row => {
          $compile(angular.element(row).contents())($scope);
        })
        .withBootstrap();

        $scope.add = () => $scope.onAdd();

        $scope.adding = (context: string) => $scope.onAdding(context);

        $scope.deleting = (context: string) => $scope.onDeleting(context);

        $scope.edit = (triggerId: number, actionId: number, name: string, email: string) => {
          $scope.onEdit({
            triggerId: triggerId,
            actionId: actionId,
            name: name,
            email: email
          });
        };

        $scope.delete = (triggerId: number, actionId: number) => {
          $scope.onDelete({
            triggerId: triggerId,
            actionId: actionId
          });
        };

        $scope.$on('preaChange', (event, data) => {
          $scope.preaTriggersTableInstance.reloadData();
          event.preventDefault();
        });

        $scope.cursor = $scope.deleting() ? 'not-allowed' : 'auto';
  }]);