angular
  .module('relcore.note')
  .directive('closeTicketButton', () =>
    ({
      restrict: 'E',
      scope: {
        onClose: '=?',
        closing: '=?',
        newNote: '=',
      },
      controller: ['$scope', function($scope) {
        $scope.showToolbar = $scope.showToolbar !== false;
        $scope.buttonText = 'Close ticket';
        $scope.close = () => $scope.onClose($scope.newNote);
      }
      ],
      template: require('./close-ticket-button.html').default
    })
  );
