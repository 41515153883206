import React, { useState } from 'react';
import { react2angular } from 'react2angular';

type SearchProps = {
  accountNumber: string
  searching: boolean
  onSearch(accountNumber: string): void
}

const Search = (props: SearchProps) => {
  const [accountNumber, setAccountNumber] = useState(props.accountNumber || '');

  return (
    <div className="d-print-none">
      <h1 className="page-title txt-color-blueDark">
        <i className="fas fa-sm fa-fw fa-search"></i> Card Account
        {props.searching &&
          <span>
            &nbsp; <i className="fas fa-spin fa-spinner"></i>
          </span>
        }
      </h1>

      <form onSubmit={(e) => {
        e.preventDefault();
        props.onSearch(accountNumber);
      }}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <label>Account Number</label>
                <input type="text" className="form-control" required value={accountNumber} onChange={e => { setAccountNumber(e.target.value); e.preventDefault(); }} />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-12">
                <button className="btn btn-primary" type="submit" disabled={props.searching}>
                  <i className="fas fa-search"></i> &nbsp;
                  {props.searching ? "Searching..." : "Search"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('cardSearch', react2angular(Search, ['onSearch', 'searching', 'accountNumber']));

export default Search;
