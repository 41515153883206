angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceApps', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      template: require('./mobile-device-apps.html').default
    })
  );
