import { IStaffUser } from '../models/staff-user';
import { IStaffUserService } from "./staff-user-service";
import { IScope } from 'angular';

interface IStaffUserMainController extends IScope {
  add(): void

  staffUserTableInstance: any
  staffUserTableColumns: Array<any>
  staffUserTableColumnDefs: Array<any>
  staffUserTableOptions: any
  reloadTable: boolean
}

angular
  .module('relcore.staff-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('staff-user-search', {
        controller: 'StaffUserSearchController',
        url: '/staff-user',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Staff Users'
        },
        template: require('./search.html').default
      }
    )
  ])
  .controller('StaffUserSearchController',
    ['$rootScope', '$scope', 'alertService', 'DTColumnBuilder', 'DTColumnDefBuilder',
     'DTOptionsBuilder', '$compile', '$uibModal', 'StaffUserService',

    function($rootScope, $scope: IStaffUserMainController, alertService, DTColumnBuilder,
      DTColumnDefBuilder, DTOptionsBuilder, $compile, $uibModal,
      StaffUserService: IStaffUserService) {

        $rootScope.title = 'Staff Users';

        $scope.$watch('staffUserTableInstance', () => {
          if (!$scope.reloadTable) return;
          const table = $scope.staffUserTableInstance;
          if (table instanceof Object && table.hasOwnProperty('DataTable')) {
            table.reloadData();
            $scope.reloadTable = false;
          }
        });

        $scope.add = () => {
          const dialog = $uibModal.open({
            template: require('./add.html').default,
            controller: 'StaffUserAddController'
          });
          dialog.result.then((result) => {
            if (result.saved) {
              // $scope.reloadTable = true;
              $scope.staffUserTableInstance.reloadData();
              alertService.success('The staff user was successfully added', true);
            }
          });
        };

        $scope.staffUserTableInstance = {};

        const editButtonRenderer = (id: number) => {
          return `<a ui-sref="staff-user-view({id: ${id}})"><button class="btn btn-default">View</button></a>`;
        };

        $scope.staffUserTableColumns = [
          DTColumnBuilder.newColumn('lastName').withTitle('Last Name'),
          DTColumnBuilder.newColumn('firstName').withTitle('First Name'),
          DTColumnBuilder.newColumn('emailAddress').withTitle('Email Address'),
          DTColumnBuilder.newColumn('id').renderWith(editButtonRenderer)
        ];

        $scope.staffUserTableColumnDefs = [
          DTColumnDefBuilder.newColumnDef([3]).withOption('className', 'text-center')
        ];

        $scope.staffUserTableOptions = DTOptionsBuilder
          .fromFnPromise(() =>
            StaffUserService.getAllStaffUsers()
              .then((staffUsers: Array<IStaffUser>) => {
                return staffUsers;
              })
              .catch(() => {
                alertService.error('An error occurred while loading the staff users', true);
              })
          )
          .withOption('lengthChange', false)
          .withOption('searching', false)
          .withOption('paging', false)
          .withOption('info', false)
          .withOption('order', []) // Disable initial sorting. Displays by ordering of the data array
          .withDisplayLength(200)
          .withOption('createdRow', row => {
            $compile(angular.element(row).contents())($scope);
          })
          .withBootstrap();
    }
  ]);
