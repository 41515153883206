angular
  .module('relcore.reducers')
  .service('onlineUserViewReducer', function() {
    var initialState = {
      loading: false,
      onlineUser: null,

      inmateAccountPayments: [],
      inmateAccountPaymentsTotal: 0
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'online-user/view/clear':
          return {
            ...state,
            onlineUser: null,
            loading: false
          };
        break;

        case 'online-user/view/loading':
          return {
            ...state,
            onlineUser: null,
            loading: true
          };
        break;

        case 'online-user/view/set-online-user':
          return {
            ...state,
            onlineUser: action.onlineUser,
            loading: false
          };
        break;
      };

      return state
    };
  });
