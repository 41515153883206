angular
  .module('relcore.api-role')
  .directive('apiRolePermissionsSelect', ['api_role_permissions', apiRolePermissions =>
    ({
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      template: require('./api-role-permissions-select.html').default,
      link: (scope:any) => {
        scope.apiRolePermissions = apiRolePermissions;
      }
    })
  ]);