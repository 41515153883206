import { RatePlan as ServiceRatePlan } from '@reliance/types/models/ServiceRate';
import api from '../common/api';
import { IConfig } from '../common/config';
import { EnabledDisabled } from '../models/enums';
import { IFacility } from '../models/facility';
import { IRatePlan } from '../models/rate';
import { IRatePlanService } from '../rate-plan/rate-plan-service';

interface IFacilityAddCtrl extends ng.IScope {
  error?: string
  adding: boolean
  newFacility: IFacility
  inmateImportOptions: [string,string]
  reset(): void
  add(): Promise<IFacility>
  onAdd?(facility:IFacility): void
  close(): void
  ratePlans: IRatePlan[]
  serviceRatePlans: ServiceRatePlan[]
}

angular
  .module('relcore.facility')
  .controller('FacilityAddController', ['$scope', 'relianceApi', 'config', '$uibModalInstance', 'ratePlanService', function($scope:IFacilityAddCtrl, relianceApi, config:IConfig, $uibModalInstance, ratePlanService:IRatePlanService) {
      $scope.inmateImportOptions = config.inmateImportModes;

      $scope.newFacility = {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        timezone: '',
        latitude: null,
        longitude: null,
        ratePlanId: null,
        serviceRatePlanId: null,
        features: [],
        featureCommissaryTransfer: false,
        featureTexting: false,
        textingLocalStatus: EnabledDisabled.Disabled,
        communicationsEnabled: true,
        audioCallingEnabled: false,
        videoCallingEnabled: false,
        inmateImportMode: Object.keys($scope.inmateImportOptions)[0]
      };

      $scope.ratePlans = [];
      ratePlanService.retrieveAll().then(function(ratePlans) {
        $scope.ratePlans = ratePlans;
        $scope.$apply();
      });

      $scope.serviceRatePlans = [];
      api.get('/service-rate/plan').then((rates) => {
        $scope.serviceRatePlans = rates.data;
        $scope.$apply();
      });

      $scope.add = function() {
        $scope.adding = true;
        return relianceApi.post(`/facility`, $scope.newFacility)
          .then((function(res) {
            $scope.adding = false;
            if (!res.data || !res.data.success) {
              $scope.error = 'An unexpected error occurred';
            } else {
              $scope.error = null;
              $uibModalInstance.close({saved: true});
            }
          }), (function(err) {
            if (err.status === 400) {
              $scope.error = 'Please verify the inputs are valid and try again';
            } else {
              $scope.error = 'An unexpected error occurred';
            }
            $scope.adding = false;
          }));
      };

      $scope.close = () => $uibModalInstance.close({saved: false});
    }]
  );

