import { IRelianceApi } from '../common/reliance-api-service';

export interface ITextMessage {
  id: number
}

export enum TextMessageDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound"
}

export enum TextMessageSentBy {
  INMATE    = "Inmate",
  CUSTOMER  = "Customer",
  SYSTEM    = "System",
  STAFF     = "Staff"
}

export interface ITextMessageService {
  index(filter?: object, orderBy?: string): Promise<any>
  postStaffMessage(data: any): Promise<any>
  putProcessUnreadSupportRequests(inmateId: number): Promise<any>
  getTextMessageStatus(textMessageId: number, filter?: object): Promise<any>
  getTextMessages(fiter?: object, page?: number, perPage?: number): Promise<any>
  sendTextMessage(destination: string, message: string): Promise<any>
}

class TextMessageService implements ITextMessageService {
   api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  index(filter: object = {}, orderBy: string) {
    const params = {};

    for (let i in filter) {
      if (filter[i] != null) { params[i] = filter[i]; }
    }

    if (orderBy) {
      params["orderBy"] = orderBy;
    }

    return this.api.get("/text-message", params);
  }

  postStaffMessage(data: object): Promise<any> {
    return this.api.post("/text-message/staff-message", data);
  }

  putProcessUnreadSupportRequests(inmateId: number): Promise<any> {
    return this.api.put("/text-message/process-unread-support-requests", {inmateId});
  }

  getTextMessageStatus(textMessageId: number, filter: object = {}): Promise<any> {
    return this.api.get("/text-message/status", {...filter, textMessageId: textMessageId});
  }

  getTextMessages(filter: object = {}, page: number = -1, perPage: number = 0): Promise<any> {
    return this.api.get("/text-message", {...filter, page: page, perPage: perPage});
  }

  sendTextMessage(destination: string, message: string): Promise<any> {
    return this.api.post("/text-message", {destination: destination, message: message});
  }
}

angular
  .module('relcore.mobile-device')
  .service('textMessageService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new TextMessageService(relianceApi);
    }
  ]);
