angular
  .module('relcore.api-role')
  .service('apiRoleViewReducer', function() {
    var initialState = {
      loading: false,
      apiRole: null
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'api-role/view/clear':
          return {
            ...state,
            apiRole: null,
            loading: false
          };

        case 'api-role/view/loading':
          return {
            ...state,
            apiRole: null,
            loading: true
          };

        case 'api-role/view/set-api-role':
          return {
            ...state,
            apiRole: action.apiRole,
            loading: false
          };
      };

      return state
    };
  });
