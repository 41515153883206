import xmlButPrettier from 'xml-but-prettier';

export function formatJson(json: string) {
  let obj = JSON.parse(json);
  for (var key in obj) {
    if (typeof obj[key].indexOf == 'function' && obj[key].indexOf('<?xml') === 0) {
      obj[key] = xmlButPrettier(obj[key]);
    }
  }
  return JSON.stringify(obj, null, 4)
    .replace(/\\n/g, "\n")
    .replace(/\\t/g, "    ")
    .replace(/\\"/g, '"');
}