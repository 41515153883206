import * as plist from 'plist';
import { IMobileDeviceCommand } from '../models/MobileDeviceCommand';

const getFriendlyName = (identifier: string) => {
  let name = identifier
  .replace('com.reliancetelephone.', '')
  .replace('com.cpctexting.', '')
    .split('.')
    .pop();
  switch (name) {
    case 'connect-kiosk': return 'Kiosk';
    case 'connect-messaging': return 'Messaging';
    case 'ConnectInfo': return 'Info';
    case 'ConnectAdmin': return 'Admin';
    case 'ChirpingInfo': return 'Info';
    case 'restrictions-profile': return 'Restrictions';
    case 'notifications-profile': return 'Notifications';
    case 'le-root-cert-profile': return 'ISRG X1 Root';
  }
  // Title case
  return name
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');;
}

angular
  .module('relcore.filters')
  .filter('mobileDeviceCommandInfo', () =>
    function(command: IMobileDeviceCommand) {
      const commandPayload = JSON.parse(command.payload || '');
      let responsePayload: any = {};
      if (command.response) {
        responsePayload = plist.parse(command.response) as any;
      }
      const identifier = commandPayload.Identifier || commandPayload._Identifier || responsePayload.Identifier;

      switch (command.requestType) {
        case 'InstallProfile':
          const installData = plist.parse(commandPayload.Payload) as any;
          if (installData.PayloadIdentifier.indexOf('lock') > -1) {
            return getFriendlyName(installData.PayloadIdentifier.replace('-lock-profile', '')) + ' Lock';
          } else {
            return getFriendlyName(installData.PayloadIdentifier);
          }

        case 'RemoveProfile':
          const payload = JSON.parse(command.payload);
          if (payload.Identifier.indexOf('lock') > -1) {
            return getFriendlyName(payload.Identifier.replace('-lock-profile', '')) + ' Lock';
          } else {
            return getFriendlyName(payload.Identifier);
          }

        case 'InstallProvisioningProfile':
          return commandPayload._ProfileName || '';

        case 'InstallApplication':
          if (identifier) {
            return getFriendlyName(identifier);
          }
          break;

        case 'RemoveApplication':
          if (identifier) {
            return getFriendlyName(identifier);
          }
          break;

        case 'ProfileList':
          if (command.response) {
            const listData = plist.parse(command.response) as any;
            if (listData) {
              return `${(listData.ProfileList||[]).length} profiles`;
            }
          }
          break;

        case 'InstalledApplicationList':
          if (command.response) {
            const listData = plist.parse(command.response) as any;
            if (listData) {
              let appList = listData.InstalledApplicationList.map(app =>
                `${getFriendlyName(app.Identifier)}@${app.ShortVersion}`
              );
              return appList.join(', ');
            }
          }
          break;
      }

      return '';
    }
);
