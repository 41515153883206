angular
  .module('relcore.condition')
  .service('conditionService',
    ['$http', '$q', 'CacheFactory', 'relianceApi', 'Condition', 'config',
    function($http, $q, CacheFactory, relianceApi, ConditionModel, config) {
      const accountConditionCache = CacheFactory('accountConditionCache');
      accountConditionCache.setOptions({
        maxAge: 60 * 30 * 1000, // 30 minutes
        deleteOnExpire: 'aggressive'
      });

      const getByAni = function(ani, refreshCache) {
        if (refreshCache == null) { refreshCache = false; }
        const deferred = $q.defer();

        if (refreshCache) { accountConditionCache.remove(ani); }

        let conditions = accountConditionCache.get(ani);
        if (conditions != null) {
          deferred.resolve(conditions);
        } else {
          relianceApi.get(`/condition?ani=${ani}`)
            .then(function(result) {
              const { data } = result;
              conditions = [];
              for (let condition of Array.from(data.results)) {
                conditions.push(new ConditionModel(condition));
              }
              accountConditionCache.put(ani, conditions);
              return deferred.resolve(conditions);
          });
        }

        return deferred.promise;
      };

      const getByFacility = function(facilityId, refreshCache) {
        if (refreshCache == null) { refreshCache = false; }
        return relianceApi.get(`/condition?facilityId=${facilityId}`)
          .then(function(response) {
            const { data } = response;
            const conditions = [];
            for (let condition of Array.from(data.results)) {
              conditions.push(new ConditionModel(condition));
            }
            return data.results = conditions;
        });
      };

      const getById = function(id) {
        const deferred = $q.defer();
        relianceApi.get(`/condition?id=${id}`)
          .then(function(results) {
            const { data } = results;
            return deferred.resolve(new ConditionModel(data.results[0]));
        });
        return deferred.promise;
      };

      const create = function(condition) {
        const deferred = $q.defer();

        relianceApi.post(`/condition/${condition.ani}/${condition.condition.condition}?description=${condition.description}&facilityId=${(condition.facility != null ? condition.facility.id : undefined)}`)
          .then((function(results) {
            const { data } = results;
            if (data.duplicate != null) {
              return deferred.reject({ data: 'duplicate', status: 200, id: data.id });
            }

            return getById(data.id)
              .then(condition => deferred.resolve(condition));
          }), deferred.reject);

        return deferred.promise;
      };

      const remove = condition => relianceApi.delete(`/condition/${condition.id}?description=${condition.removedDescription}`);

      return {
        cache: accountConditionCache,
        getByAni,
        getById,
        create,
        remove,
        getByFacility
      };
    }
  ]);