angular
  .module('relcore.user')
  .service('legacyUserReducer', [
    'legacyUserListReducer',
    'Redux',
    function(legacyUserListReducer, Redux) {
      return Redux.combineReducers({
        list: legacyUserListReducer
      });
    }
  ]);
