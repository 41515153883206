import moment from 'moment';
import { IAlertService } from '../alert/alert-service';
import { IConfig } from '../common/config';
import { IDialogResult, IDialogService } from '../common/dialogs/dialogs';
import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { IConfigurationText, IConfigurationTextService } from '../configuration-text/configuration-text-service';
import { IConfiguration } from '../configuration/configuration';
import { IEventNotificationTrigger } from '../event-notification/event-notification-factory';
import { IEventNotificationService } from '../event-notification/event-notification-service';
import { IFacilityAnalogGateway } from '../models/analog-gateway';
import { ICallRouteMapping } from '../models/call-route';
import { IFacilityNumber } from '../models/facility-number';
import { IFacilitySpecialCondition } from '../models/facility-special-condition';
import * as speedDialDestinations from './facility-view.speed-dial-destinations';
import * as textMessageAlerts from './facility-view.text-message-alerts';

angular
  .module('relcore.facility')
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('facilities-view', {
        url: '/facilities/view/:id',
        template: require('./facility-view.html').default,
        controller: 'FacilityViewController',
        data: {
          redirect: 'facilities-view.details'
        },
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: '{{facility.name}}',
          parent: 'facilities'
        },
      })
      // This and subsequent states are child states of
      // the main facilities-view state above and represent
      // tabbed content such as 'Notes', 'Devices', 'Inmates', etc.
      // These states are needed to allow deep-linking to individual
      // tabs so that they can be navigated to directly
      .state('facilities-view.details', {
        url: '/details',
        views: {
          'Details@facilities-view': {
            template: require('./facility-view.details.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Details'
        }
      })
      .state('facilities-view.notes', {
        url: '/notes',
        views: {
          'Notes@facilities-view': {
            template: require('./facility-view.notes.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Notes'
        }
      })
     .state('facilities-view.devices', {
        url: '/devices',
        views: {
          'Devices@facilities-view': {
            template: require('./facility-view.devices.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Devices'
        }
      })
      .state('facilities-view.inmates', {
        url: '/inmates',
        views: {
          'Inmates@facilities-view': {
            template: require('./facility-view.inmates.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Inmates'
        }
      })
      .state('facilities-view.housing-units', {
        url: '/housing-units',
        views: {
          'Housing Units@facilities-view': {
            controller: 'FacilityViewHousingUnitsController',
            template: require('./facility-view.housing-units.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Housing Units'
        }
      })
      .state('facilities-view.stations', {
        url: '/stations',
        views: {
          'Stations@facilities-view': {
            template: require('./facility-view.stations.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Stations'
        }
      })
      .state('facilities-view.config', {
        url: '/config',
        views: {
          'Config@facilities-view': {
            template: require('./facility-view.config.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Config'
        }
      })
      .state('facilities-view.alerts', {
        url: '/alerts',
        views: {
          'Alerts@facilities-view': {
            template: require('./facility-view.alerts.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Alerts'
        }
      })
      .state('facilities-view.special-conditions', {
        url: '/special-conditions',
        views: {
          'Special Conditions@facilities-view': {
            controller: 'ConditionListController',
            template: require('./facility-view.special-conditions.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Special Conditions'
        }
      })
      .state('facilities-view.blocks', {
        url: '/blocks',
        views: {
          'Blocks@facilities-view': {
            template: require('./facility-view.blocks.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Blocks'
        }
      })
      .state('facilities-view.equipment', {
        url: '/equipment',
        views: {
          'Equipment@facilities-view': {
            template: require('./facility-view.equipment.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Equipment'
        }
      })
      .state('facilities-view.visits', {
        url: '/visits',
        views: {
          'Visits@facilities-view': {
            template: require('./facility-view.visits.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Visits'
        }
      })
      .state('facilities-view.schedules', {
        url: '/schedules',
        views: {
          'Schedules@facilities-view': {
            template: require('./facility-view.schedules.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Schedules'
        }
      })
      .state('facilities-view.salt-minions', {
        url: '/salt-minions',
        views: {
          'Salt Minions@facilities-view': {
            template: require('./facility-view.salt-minions.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Salt Minions'
        }
      })
      .state('facilities-view.analog-gateways', {
        url: '/analog-gateways',
        views: {
          'Analog Gateways@facilities-view': {
            template: require('./facility-view.analog-gateways.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Analog Gateways'
        }
      })
      .state('facilities-view.numbers', {
        url: '/numbers',
        views: {
          'Numbers@facilities-view': {
            template: require('./facility-view.numbers.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Numbers'
        }
      })
      .state('facilities-view.routing', {
        url: '/routing',
        views: {
          'Routing@facilities-view': {
            template: require('./facility-view.routing.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Routing'
        }
      })
      .state('facilities-view.configuration-texts', {
        url: '/configuration-texts',
        views: {
          'Text Configurations@facilities-view': {
            controller: 'ConfigurationTextListController',
            template: require('./facility-view.configuration-texts.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Text Configuration'
        }
      })
      .state('facilities-view.device-groups', {
        url: '/device-groups',
        views: {
          'Device Groups@facilities-view': {
            controller: 'FacilityViewDeviceGroupsController',
            template: require('./facility-view.device-groups.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Device Groups'
        }
      })
      .state('facilities-view.visit-booths', {
        url: '/visitation-booths',
        views: {
          'Visit Booths@facilities-view': {
            template: require('./facility-view.visitation-booths.html').default
          }
        },
        ncyBreadcrumb: {
          label: 'Visit Booths'
        }
      })
      .state('facilities-view.text-message-alerts', textMessageAlerts.state)
      .state('facilities-view.speed-dial-destinations', speedDialDestinations.state)
    }
  ])
  .config(['$compileProvider', $compileProvider => {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
  }])
  .controller('FacilityViewController',
    ['$rootScope', '$scope', '$filter', '$state', 'auth', 'facilityService', 'stationService', 'configurationService', 'housingUnitService', 'blockService', 'facilityEquipmentService', 'languageService', 'configurationTextService', 'alertService', 'relianceApi', 'eventService', 'eventNotificationService', 'DialogService', 'config', 'NgTableParams', '$stateParams', '$uibModal',
    function($rootScope, $scope, $filter, $state, auth, facilityService, stationService, configurationService, housingUnitService, blockService, facilityEquipmentService, languageService:ILanguageService, configurationTextService:IConfigurationTextService , alertService: IAlertService, relianceApi:IRelianceApi, eventService:IEventService, eventNotificationService:IEventNotificationService, DialogService: IDialogService, config:IConfig, NgTableParams, $stateParams, $uibModal) {
      $scope.refresh = () => {
        facilityService.getById($stateParams.id)
          .then(function(facility) {
            $scope.facility = facility;
            $rootScope.title = facility.name;
        });
      };
      $scope.refresh();

      $scope.relianceApi = relianceApi;

      $scope.isFetching = eventService.isFetching;

      $scope.isSaving = eventService.isSaving;

      $scope.isAdding = eventService.isAdding;

      $scope.isDeleting = eventService.isDeleting;

      $scope.onFetch = eventService.onFetch;

      $scope.onFetched = eventService.onFetched;

      $scope.onSave = eventService.onSave;

      $scope.onSaved = eventService.onSaved;

      $scope.onAdd = eventService.onAdd;

      $scope.onAdded = eventService.onAdded;

      $scope.onDelete = eventService.onDelete;

      $scope.onDeleted = eventService.onDeleted;

      $scope.onError = eventService.onError;

      $scope.$on('$stateChangeSuccess', function(event, toState, toParams) {
        // Redirect to 'Details' tab as default facility view
        if (toState.name == 'facilities-view'){
          event.preventDefault();
          $state.go(toState.data.redirect, toParams);
        }

       // Otherwise, highlight the selected tab
       $scope.activeTabIndex = $state.current.name;
      });

      // The tabbed content of the facility being viewed. This array is iterated
      // over to generate the tabs. The content of these tabs are injected
      // into a shared view port in the parent template.
      $scope.tabs = [
        { heading: 'Details', route: 'facilities-view.details' },
        {
          heading: 'Notes',
          route: 'facilities-view.notes',
          style: 'fas fa-fw fa-lg fa-pencil-alt'
        },
        {
          heading: 'Devices',
          route: 'facilities-view.devices',
          style: 'fas fa-fw fa-lg fa-mobile',
          permissions: ['mobile_device_manage']
        },
        {
          heading: 'Inmates',
          route: 'facilities-view.inmates',
          style: 'fas fa-fw fa-lg fa-users',
          permissions: ['inmate_manage', 'inmate_search']
        },
        {
          heading: 'Housing Units',
          route: 'facilities-view.housing-units',
          style: 'fas fa-fw fa-lg fa-cubes',
          permissions: ['housing_unit_manage']
        },
        {
          heading: 'Stations',
          route: 'facilities-view.stations',
          style: 'fas fa-fw fa-lg fas fa-tablet',
          permissions: ['station_manage']
        },
        {
          heading: 'Config',
          route: 'facilities-view.config',
          style: 'fas fa-fw fa-lg fas fa-cogs'
        },
        {
          heading: 'Alerts',
          route: 'facilities-view.alerts',
          style: 'fas fa-fw fa-lg fas fa-bell'
        },
        {
          heading: 'Special Conditions',
          route: 'facilities-view.special-conditions',
          style: 'fas fa-fw fa-lg fa-gift',
          permissions: ['condition_manage']
        },
        {
          heading: 'Blocks',
          route: 'facilities-view.blocks',
          style: 'fas fa-fw fa-lg fas fa-lock',
          permissions: ['block_manage']
        },
        {
          heading: 'Equipment',
          route: 'facilities-view.equipment',
          style: 'fas fa-fw fa-lg fas fa-cubes',
          permissions: ['facility_equipment_manage']
        },
        {
          heading: 'Visits',
          route: 'facilities-view.visits',
          style: 'fas fa-fw fa-lg fa-users'
        },
        {
          heading: 'Visit Booths',
          route: 'facilities-view.visit-booths',
          style: 'fas fa-fw fa-lg fa-person-booth',
          permissions: ['visitation_manage']
        },
        {
          heading: 'Schedules',
          route: 'facilities-view.schedules',
          style: 'fas fa-fw fa-lg fa-calendar',
          permissions: ['schedule_manage']
        },
        {
          heading: 'Salt Minions',
          route: 'facilities-view.salt-minions',
          style: 'fas fa-fw fa-lg fa-server',
          permissions: ['salt_manage']
        },
        {
          heading: 'Analog Gateways',
          route: 'facilities-view.analog-gateways',
          style: 'fas fa-fw fa-lg fa-microchip',
          permissions: ['facility_analog_gateway_manage']
        },
        {
          heading: 'Numbers',
          route: 'facilities-view.numbers',
          style: 'fas fa-fw fa-lg fa-tty',
          permissions: ['facility_number_manage']
        },
        {
          heading: 'Routing',
          route: 'facilities-view.routing',
          style: 'fas fa-fw fa-lg fa-code-branch',
          permissions: ['call_route_manage']
        },
        {
          heading: 'Text Configurations',
          route: 'facilities-view.configuration-texts',
          style: 'fas fa-fw fa-lg fa-file-alt'
        },
        {
          heading: 'Device Groups',
          route: 'facilities-view.device-groups',
          style: 'fas fa-fw fa-lg fa-object-group'
        },
        textMessageAlerts.tab,
        speedDialDestinations.tab
      ];

      // Filter the tabs based on whether user has one of the permissions
      // given by the tab. This will result in only the tabs with the content
      // that the user is allowed to manage to be displayed in the UI
      let user = auth.currentUser();
      $scope.tabs = $scope.tabs.filter((tab) => {
        return tab.permissions == null || user.hasOneOfPermissions(tab.permissions);
      });

      // Tab selection triggers a state change that ultimately
      // displays the contents of the selected tab. The appropriate
      // content is loaded into each tabbed view
      $scope.showTab = function(route: string) {
        $state.go(route, {
          facility: $scope.facility
        });
        switch (route) {
          case 'facilities-view.notes':
            $scope.loadFacilityNotes(false);
            break;
          case 'facilities-view.devices':
            $scope.loadDevices(false);
            break;
          case 'facilities-view.inmates':
            $scope.loadInmates(false);
            break;
          case 'facilities-view.stations':
            $scope.loadStations(false);
            break;
          case 'facilities-view.config':
            $scope.loadConfig(false);
            break;
          case 'facilities-view.blocks':
            $scope.loadBlocks(false);
            break;
          case 'facilities-view.equipment':
            $scope.loadEquipment(false);
            break;
          case 'facilities-view.visits':
            $scope.loadVisitations();
            break;
          case 'facilities-view.schedules':
            $scope.loadSchedules();
            break;
          case 'facilities-view.salt-minions':
            $scope.loadSaltMinions();
            break;
          case 'facilities-view.analog-gateways':
            $scope.loadAnalogGateways();
            break;
          case 'facilities-view.numbers':
            $scope.loadNumbersAndAnis();
            break;
          case 'facilities-view.routing':
            $scope.loadCallRouting();
            break;
          case 'facilities-view.configuration-texts':
            $scope.loadConfigurationTexts();
            break;
          default:
        }
      };

      $scope.loadFacilityNotes = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.facilityNoteGridOptions != null)) { return; }

        $scope.facilityNoteGridOptions = new NgTableParams({
            count: 100,
            sorting: { date: 'desc' },
            filter: { facilityId: $scope.facility.id }
          },
          {
            counts: [100, 200, 500],
            getData(params) {
              const orderBy = params.orderBy().map(o =>
                o
                  .replace(/date$/, 'dateEntered')
                  .replace(/user$/, 'userName')
              );
              $scope.onFetch('Notes');
              return relianceApi.index('/facility-note', params.page(), params.count(), params.filter(), orderBy)
                .then(function(res:IApiResponse<IPaginatedResponse<any>>) {
                  params.total(res.data.total);
                  $scope.onFetched('Notes');
                  return res.data.data.map(function(d) {
                    d.date = d.dateEntered;
                    d.user = d.userName;
                    return d;
                  });
                },
                function(err) {
                  $scope.onError('Notes');
                  alertService.error('An error occurred while loading facility notes', true);
                }
              );
            }
          }
        );
      };

      const resetNote = () => {
        $scope.newNote = { note: '' };
      };

      resetNote();

      $scope.addNote = function(note) {
        note.entityId = $scope.facility.id;
        $scope.onAdd('Notes');
        return relianceApi.post("/facility-note", { note })
          .then(function() {
              $scope.onAdded('Notes');
              $scope.loadFacilityNotes(true);
              resetNote();
              alertService.success('The note was successfully added', true);
            },
            function(err) {
              $scope.onError('Notes');
              alertService.error('An error occurred while adding the note', true);
            }
          );
      };

      $scope.loadSchedules = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.schedules != null)) { return Promise.resolve(); }

        $scope.onFetch('Schedules');
        return relianceApi.get(`/schedule?facilityId=${$scope.facility.id}`)
          .then(function(res:any) {
              $scope.onFetched('Schedules');
              return $scope.schedules = res.data;
            },
            function(err) {
              $scope.onError('Schedules');
              alertService.error('An unexpected error occurred while retrieving the facility schedules', true);
            }
          );
      };

      $scope.createSchedule = (newSchedule) => {
        $scope.onAdd('Schedules');
        relianceApi.post("/schedule", {
            facilityId: $scope.facility.id,
            type: newSchedule.type,
            entries: newSchedule.entries
          }
        ).then(() => {
            $scope.onAdded('Schedules');
            alertService.success('The schedule was successfully updated', true);
          },
          (err) => {
            $scope.onError('Schedules');
            alertService.error('An unexpected error occurred while updating the schedule', true);
          }
        );
      };

      $scope.addSchedule = (newSchedule) => {
        $scope.schedules.push(newSchedule);
      };

      $scope.loadConfig = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.blockGridOptions != null)) { return; }

        $scope.configurationGridOptions = new NgTableParams({
          page: 1,
          count: 500,
          sorting: {
            'created.date': 'desc'
          }
          }, {
            total: 0,
            getData(params) {
              $scope.onFetch('Config');
              return configurationService.getByFacility($scope.facility.id)
                .then(function(data) {
                    params.total(data.length);

                    if (params.filter()) {
                      data = $filter('filter')(data, params.filter());
                    }
                    if (params.sorting()) {
                      data = $filter('orderBy')(data, params.orderBy());
                    }

                    $scope.onFetched('Config');
                    return data.slice((params.page()-1)*params.count(),
                      params.page() * params.count());
                  },
                  () => {
                    $scope.onError('Config');
                    alertService.error('An unexpected error occurred while loading config', true);
                  }
                );
            }
          }
        );
      };

      $scope.configDetails = configuration => {
        $state.go('configurations-view', {
            configId:configuration.id,
            config:configuration
          }
        );
      };

      $scope.loadStations = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.stationGridOptions != null)) { return; }

        housingUnitService.index(1, 1000, '', {
            facilityId: $scope.facility.id
          }
        ).then((res) => $scope.housingUnits = res.data.data);

        $scope.stationGridOptions = new NgTableParams({
            page: 1,
            sorting: {
              'created.date': 'desc'
            }
          },
          {
            total: 0,
            counts: [],
            getData(params) {
              $scope.onFetch('Stations');
              return stationService.getByFacility($scope.facility.id)
                .then(function(data) {
                  if (params.filter()) {
                    data = $filter('filter')(data, params.filter());
                  }
                  if (params.sorting()) {
                    data = $filter('orderBy')(data, params.orderBy());
                  }
                  $scope.onFetched('Stations');
                  return data;
                },
                function(err) {
                  $scope.onError('Stations');
                  alertService.error('An error occurred while loading stations', true);
                }
              );
            }
          }
        );
      };

      $scope.loadInmates = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.inmateGridOptions != null)) { return; }

        $scope.inmateGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: { 'lastName': 'asc', 'firstName': 'asc'},
          filter: { facilityId: $scope.facility.id, status: 'Enabled' }
        }, {
          counts: [100,200,500],
            getData(params) {
              $scope.onFetch('Inmates');
              return relianceApi.index('/inmate', params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(res:IApiResponse<IPaginatedResponse<any>>) {
                  params.total(res.data.total);
                  $scope.onFetched('Inmates');
                  return res.data.data;
                },
                function(err) {
                  $scope.onError('Inmates');
                  alertService.error('An error occurred while loading inmates', true);
                }
              );
            }
        });
      };

      $scope.loadDevices = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.mobileDeviceGridOptions != null)) { return; }

        $scope.mobileDeviceGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              name: 'asc'
            }
          },
          {
            counts: [100,200,500],
            getData(params) {
              $scope.onFetch('Devices');
              return relianceApi
                .index('/mobile-device', params.page(), params.count(), { facilityId: $scope.facility.id }, params.orderBy())
                .then(function(res:IApiResponse<IPaginatedResponse<any>>) {
                  params.total(res.data.total);
                  $scope.onFetched('Devices');
                  return res.data.data;
                },
                function(err) {
                  $scope.onError('Devices');
                  alertService.error('An error occurred while loading devices', true);
                }
              );
            }
          }
        );
      };

      $scope.loadBlocks = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.blockGridOptions != null)) { return; }

        $scope.blockGridOptions = new NgTableParams({
            page: 1,
            count: 100,

            sorting: { 'blockDate': 'desc' },
            filter: {
              blockDate: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
              },
              unblockDate: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
              }
            }
          }, {
            counts: [100,500,1000],
            getData(params) {
              $scope.onFetch('Blocks');
              if ($scope.ani != null) {
                return blockService.getByPhoneNumber($scope.ani, params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(data) {
                    params.total(parseInt(data.total));
                    $scope.onFetched('Blocks');
                    return data.blocks;
                });
              } else {
                  return blockService.getByFacility($scope.facility.id, params.page(), params.count(), params.filter(), params.orderBy())
                  .then(function(data) {
                      params.total(parseInt(data.total));
                      $scope.onFetched('Blocks');
                      return data.blocks;
                    },
                    () => {
                      $scope.onError('Blocks');
                      alertService.error('An error occurred while loading blocks', true);
                    }
                  );
              }
            }
        });
      };

      $scope.loadEquipment = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.blockGridOptions != null)) { return; }

        $scope.equipmentGridOptions = new NgTableParams({
          page: 1,
          sorting: { 'created.date': 'desc' }
          }, {
            total: 0,
            counts: [],
            getData(params) {
              $scope.onFetch('Equipment');
              return facilityEquipmentService.getByFacility($scope.facility.id)
                .then(function(data) {
                  params.total(data.length);

                  if (params.filter()) {
                    data = $filter('filter')(data, params.filter());
                  }
                  if (params.sorting()) {
                    data = $filter('orderBy')(data, params.orderBy());
                  }

                  $scope.onFetched('Equipment');
                  return data.slice((params.page()-1)*params.count(),
                    params.page() * params.count());
                },
                () => {
                  $scope.onError('Equipment');
                  alertService.error('An error occurred while loading equipment', true);
                }
              );
            }
          }
        );
      };

      $scope.newEquipment = {
        name: null,
        serialNumber: null,
        description: null,
        type: null
      };
      $scope.addEquipment = function() {
        $scope.onAdd('Equipment');
        const result = facilityEquipmentService.postNewFacilityEquipment($scope.facility.id, $scope.newEquipment.name, $scope.newEquipment.serialNumber, $scope.newEquipment.description, $scope.newEquipment.type);

        const successCallback = function() {
          alertService.success("New Equipment was added successfully", true);
          $scope.equipmentGridOptions.reload();
          $scope.onAdded('Equipment');
          $scope.newEquipment = {
            name: null,
            serialNumber: null,
            description: null,
            type: null
          };
        };

        const errorCallback = () => {
          $scope.onError('Equipment');
          alertService.error("An error occured while adding new equipment", true);
        };

        result.then(successCallback, errorCallback);
      };

      $scope.equipmentDetails = equipment => {
        $state.go('facility-equipments-view', {
            equipmentId:equipment.id,
            equipment
          }
        );
      };

      $scope.loadVisitations = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.visitationGridOptions != null)) { return; }

        $scope.visitationGridOptions = new NgTableParams({
            page: 1,
            count: 25,
            sorting: {
              startDate: 'desc'
            },
            filter: {
              facilityId: $scope.facility.id
            }
          },
          {
            counts: [25, 50, 100],
            getData(params) {
              $scope.onFetch('Visits');
              return relianceApi.index('/visitation', params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result:IApiResponse<IPaginatedResponse<any>>) {
                  params.total(result.data.total);
                  $scope.onFetched('Visits');
                  return result.data.data;
                },
                function(err) {
                  $scope.onError('Visits');
                  alertService.error('An error occurred while loading visits', true);
                }
              );
            }
          }
        );
      };

      $scope.loadSaltMinions = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.saltMinionGridOptions != null)) { return; }

        $scope.saltMinionGridOptions = new NgTableParams({
            filter: {
              facilityId: $scope.id
            }
          },
          {
            getData(params) {
              $scope.onFetch('Salt Minions');
              return relianceApi.index('/salt/minion', params.page(), params.count(), params.filter(), params.orderBy())
                .then((result:any) => {
                  $scope.onFetched('Salt Minions');
                  result.data;
                },
                (err) => {
                  $scope.onError('Salt Minions');
                  alertService.error('An error occurred while loading salt minions', true);
                }
              );
            }
          }
        );
      };

      $scope.loadSaltMinions();

      // Facility analog gatways
      $scope.loadAnalogGateways = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.analogGatewayGridOptions != null)) { return; }

        $scope.analogGatewayGridOptions = new NgTableParams({
            filter: {
              facilityId: $scope.facility.id
            }
          },
          {
            getData(params) {
              $scope.onFetch('Analog Gateways');
              return relianceApi.index('/facility-analog-gateway', null, -1, params.filter(), params.orderBy())
                .then((result:IApiResponse<IPaginatedResponse<IFacilityAnalogGateway>>) => {
                  $scope.onFetched('Analog Gateways');
                  return result.data.data.map(d => ({
                    ...d,
                    fxoPorts: d.analogGatewayPorts.filter(p => String(p.type) == 'FXO'),
                    fxsPorts: d.analogGatewayPorts.filter(p => String(p.type) == 'FXS')
                  }));
                },
                (err) => {
                  $scope.onError('Analog Gateways');
                  alertService.error('An error occurred while loading analog gateways', true);
                }
              );
            }
          }
        );
      };

      $scope.showAnalogGatewayAddEditView = (editGateway) => {
        const dialog = $uibModal.open({
            size: 'lg',
            template: require('../facility-analog-gateway/create-edit-modal.html').default,
            controller: 'FacilityAnalogGatewayCreateController',
            resolve: {
              facility() { return $scope.facility; },
              editGateway() { return editGateway; }
            }
          }
        );
        dialog.result.then(() => $scope.analogGatewayGridOptions.reload());
      };

      $scope.deleteAnalogGateway = (gateway) => {
        if (window.confirm('Are you sure to want to delete this gateway?')) {
          $scope.onDelete('Analog Gateways');
          relianceApi.delete(`/facility-analog-gateway/${gateway.id}`)
            .then(function(res) {
              $scope.onDeleted('Analog Gateways');
              alertService.success('The gateway was deleted', true);
              $scope.analogGatewayGridOptions.reload();
            },
            function(err) {
              $scope.onError('Analog Gateways');
              alertService.error('An error occurred while deleting the gateway', true);
            }
          );
        }
      };

      $scope.configureAnalogGateway = (gateway) => {
        let minion = $scope.saltMinionGridOptions ? $scope.saltMinionGridOptions.data.filter(m => m.id.contains('relcore')).pop() : null;

        if (!minion) {
          alertService.error('Unable to send the commands to the gateway');
          return;
        }

        $scope.onSave('Analog Gateways');
        relianceApi.put(`/salt/minion/${minion}/state/freeswitch.rt-switch.patton`, {
          'keywordArgs': {
            'refresh_pillar': true,
            'sync_mods': 'modules',
            'pillar': {
              'patton_smartnode': {
                'target': gateway.name
              }
            }
          }
        }).then(
          () => {
            $scope.onSaved('Analog Gateways');
            alertService.success('The gateway will be configured shortly', true)
          },
          (err) => {
            $scope.onError('Analog Gateways');
            alertService.error('An unexpected error occurred when configuring the gateway');
          }
        );
      };

      $scope.loadNumbersAndAnis = (refresh) => {
        $scope.onFetch('Numbers');
        Promise
          .all([
            $scope.loadNumbers(refresh),
            $scope.loadAnis(refresh)
          ])
          .then(() => {
            $scope.onFetched('Numbers');
            $scope.$apply();
          }, () => {
            $scope.onError('Numbers');
            $scope.$apply();
          });
      };

      // Facility numbers
      $scope.loadNumbers = function(refresh = false) {
        if (!refresh && ($scope.numberGridOptions != null)) { return Promise.resolve(); }

        let loadPromise = relianceApi.get(`/facility/${$scope.facility.id}/number`);

        $scope.numberGridOptions = new NgTableParams({}, {
          getData() {
            return loadPromise
              .then((result:IApiResponse<any>) => {
                return result.data;
              },
              (err) => {
                alertService.error('An error occurred while loading numbers', true);
              }
            );
          }
        });

        return loadPromise;
      };

      $scope.editNumbers = [];
      $scope.editNumber = (row) => $scope.editNumbers.push(row);

      $scope.addNumber = () => {
        let row = {active: true};
        $scope.numberGridOptions.data.unshift(row);
        $scope.editNumber(row);
      };

      $scope.savingNumbers = [];
      $scope.saveNumber = (row) => {
        $scope.savingNumbers.push(row);

        $scope.onSave('Numbers');
        let promise;
        if (row.id) {
          promise = relianceApi.put(`/facility/${$scope.facility.id}/number/${row.id}`, row)
        } else {
          promise = relianceApi.post(`/facility/${$scope.facility.id}/number`, row)
        }

        promise
          .then(function (res) {
            $scope.savingNumbers.splice($scope.savingNumbers.indexOf(row), 1);
            $scope.editNumbers.splice($scope.editNumbers.indexOf(row), 1);
            alertService.success('The number was saved', true);
            $scope.onSaved('Numbers');
            $scope.numberGridOptions.reload();
          },
          function (err) {
            $scope.onError('Numbers');
            alertService.error('An error occurred while saving the number', true);
          }
        );
      };

      // Facility ANIs
      $scope.loadAnis = function(refresh = false) {
        if (!refresh && ($scope.aniGridOptions != null)) { return Promise.resolve(); }

        let loadPromise = relianceApi.get(`/facility-ani?facilityId=${$scope.facility.id}`);

        $scope.hasInfoCenterNumber = true;
        $scope.aniGridOptions = new NgTableParams({}, {
          getData() {
            return loadPromise
              .then((result:IApiResponse<IPaginatedResponse<any>>) => {
                $scope.hasInfoCenterNumber = result.data.data.findIndex(n => n.name == 'Info Center') > -1;
                return result.data.data;
              },
              (err) => {
                alertService.error('An error occurred while loading ANIs', true);
              }
            );
          }
        });

        return loadPromise;
      };

      $scope.addInfoCenterNumber = () => {
        const dialog = $uibModal.open({
          template: require('../facility-number/buy-info-center-modal.html').default,
          controller: 'FacilityNumberBuyInfoController',
          resolve: {
            facility() { return $scope.facility; },
            basisNumber() { return $scope.aniGridOptions.data[0]; }
          }
        });
        dialog.result.then(() => $scope.loadAnis(true));
      };

      $scope.editAnis = [];
      $scope.editAni = (row) => $scope.editAnis.push(row);

      $scope.addAni = () => {
        let row = {};
        $scope.aniGridOptions.data.unshift(row);
        $scope.editAni(row);
      };

      $scope.savingAnis = [];
      $scope.saveAni = (row) => {
        $scope.savingAnis.push(row);

        let data = {
          ...row,
          facilityId: $scope.facility.id
        };

        $scope.onSave('Numbers');
        let promise;
        if (row.id) {
          promise = relianceApi.put(`/facility-ani/${row.id}`, data)
        } else {
          promise = relianceApi.post(`/facility-ani`, data)
        }

        promise
          .then(function (res) {
            $scope.savingAnis.splice($scope.savingAnis.indexOf(row), 1);
            $scope.editAnis.splice($scope.editAnis.indexOf(row), 1);
            alertService.success('The ANI was saved', true);
            $scope.onSaved('Numbers');
            $scope.aniGridOptions.reload();
          },
          function (err) {
            $scope.onError('Numbers');
            alertService.error('An error occurred while saving the ANI', true);
          }
        );
      };

      // Call routing
      $scope.loadCallRouting = (refresh = false) => {
        if (!refresh && $scope.callRouteGridOptions != null) { return; }

        $scope.onFetch('Routing');
        relianceApi
          .get(`/call/route/mapping`, { page: null, facilityId: $scope.facility.id, includeLocationData: true })
          .then((result:IApiResponse<IPaginatedResponse<ICallRouteMapping>>) => {
            $scope.callRouteBasisNumber = result.data.supportingData.facilityBasisNumber;
            $scope.callRouteLocations = result.data.supportingData.locations;
            $scope.callRouteFacilityNumbers = Object.values(result.data.supportingData.facilityNumbers).map((fn:IFacilityNumber) => ({
                ...fn,
                ani: '+1'+fn.ani
              }
            ));

            let data = result.data.data;

            // Sort by most restrictive origination first, then destination
            data.sort((a,b) => {
              if (
                (
                  (a.billingCategory == null && b.billingCategory == null) ||
                  (a.billingCategory != null && b.billingCategory != null)
                ) &&
                a.origination.length == b.origination.length &&
                a.destination.length == b.destination.length
              ) {
                return 0;
              }

              // Priority for billing category being set
              if (a.billingCategory != null && b.billingCategory == null) {
                return 1;
              } else if (a.billingCategory == null && b.billingCategory != null) {
                return -1;
              }

              // Priority for more restrictive origination
              if (a.origination.length > b.origination.length) {
                return 1;
              } else if (a.origination.length < b.origination.length) {
                return -1;
              }

              // Lastly, priority for more restrictive destination
              if (a.destination.length > b.destination.length) {
                return 1;
              }

              return -1;
            });

            $scope.onFetched('Routing');
            $scope.callRouteMappings = data.reverse();
            $scope.callRouteGridOptions.reload();
          },
          (err) => {
            $scope.onError('Routing');
            alertService.error('An error occurred loading call-routing', true);
          }
        );

        $scope.callRouteGridOptions = new NgTableParams({}, {
          counts: null,
          getData(params) {
            let data = $scope.callRouteMappings;

            if (params.filter()) {
              // Deep copy to avoid modifying the underlying ng-table filter structure
              let filter = JSON.parse(JSON.stringify(params.filter()));

              // Deal with nested properties
              Object.keys(filter).forEach(function(k) {
                if (k.indexOf('.') === -1) {
                  return;
                }
                let result = {};
                let parts = k.split('.');
                let nextSegment = result;
                while (parts.length > 1) {
                  nextSegment = (nextSegment[parts.shift()] = {});
                }
                nextSegment[parts.shift()] = filter[k];
                delete filter[k];
                filter = Object.assign({}, filter, result);
              });

              data = $filter('filter')(data,filter);
            }

            return data;
          }
        });
      };

      $scope.showCallRouteMappingAddEditView = (editRouteMapping) => {
        const dialog = $uibModal.open({
          template: require('../call/call-route-mapping-create-edit-modal.html').default,
          controller: 'CallRouteMappingCreateEditController',
          resolve: {
            facility() { return $scope.facility; },
            facilityNumbers() { return $scope.callRouteFacilityNumbers; },
            editRouteMapping() { return editRouteMapping; }
          }
        });
        dialog.result.then(() => $scope.callRouteGridOptions.reload());
      };

      // Text configurations
      $scope.loadConfigurationTexts = (refresh: boolean = false) => {
        $scope.languages = languageService.getAll();
        $scope.selectedLanguage = 'EN-US';

        if (!refresh && $scope.configurationTextsGridOptions) { return; }

        $scope.configurationTextsGridOptions = new NgTableParams(
          {},
          {
            getData() {
              $scope.onFetch('Text Configurations');
              return configurationTextService.getConfigurationTexts($scope.facility.id, $scope.selectedLanguage)
                .then((result: IApiResponse<Array<IConfigurationText>>) => {
                  $scope.onFetched('Text Configurations');
                  result.data.forEach((item) => {
                      item.languageName = languageService.getNameByCode(item.language);
                    }
                  );
                  return result.data;
                },
                (err) => {
                  $scope.onError('Text Configurations');
                  alertService.error('An error occurred while loading configuration texts', true);
                }
              );
            }
          }
        );
      };

      $scope.onSelectedLanguage = (language: string) => {
        $scope.selectedLanguage = language;
        $scope.configurationTextsGridOptions.reload();
      };

      $scope.showConfigurationTextAddView = () => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('../configuration-text/add-modal.html').default,
          controller: 'ConfigurationTextUpdateController',
          resolve: {
            facility() { return $scope.facility; },
            configurationText() { return null; }
          }
        });
        dialog.result.then(() => $scope.configurationTextsGridOptions.reload());
      };

      $scope.showConfigurationTextEditView = (configurationText: IConfigurationText) => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('../configuration-text/edit-modal.html').default,
          controller: 'ConfigurationTextUpdateController',
          resolve: {
            facility() { return $scope.facility; },
            configurationText() { return configurationText; }
          }
        });
        dialog.result.then(() => $scope.configurationTextsGridOptions.reload());
      };

      $scope.onStationDetails = station => {
        $state.go('stations-view', {
            stationId:station.id,
            station
          }
        );
      };

      $scope.onDeviceDetails = device => {
        $state.go('mobile-device-view', {
            id: device.id,
            mobileDevice: device
          }
        );
      };

      $scope.onInmateDetails = inmate => {
        $state.go('inmates-view', {
            id: inmate.id,
            inmate
          }
        );
      };

      $scope.onSaltMinionDetails = minion => {
        $state.go('salt-minion-view', {
            id: minion.id
          }
        );
      };

      $scope.save = function(changes) {
        $scope.onSave('Details');
        relianceApi.put(`/facility/${$scope.facility.id}`, changes)
          .then((function(res) {
            $scope.onSaved('Details');
            $scope.facility = Object.assign({}, changes);
            alertService.success('The facility was saved', true);
          }),
          function(err) {
            $scope.onError('Details');
            alertService.error('An error occurred while saving', true);
          }
        );
      };

      $scope.pushStationConfig = function() {
        $scope.onSave('Stations');
        return relianceApi.put(`/mobile-device/push-station-config?facilityId=${$scope.facility.id}`)
          .then(function() {
            $scope.onSaved('Stations');
            alertService.success('The config will be sent to the devices shortly', true);
          },
          function() {
            $scope.onError('Stations');
            alertService.error('An error occurred when pushing station configs', true);
          }
        );
      };

      $scope.showFacilityProxy = false;
      $scope.facilityProfile = null;
      $scope.toggleProxyEdit = () => {
        $scope.showFacilityProxy = !$scope.showFacilityProxy;

        if (!$scope.showFacilityProxy) {
          return;
        }

        if ($scope.facilityProfile == null) {
          $scope.onFetch('facility-profile');
          relianceApi.get(`/mobile-device-profile/by-identifier/facility-profile?facilityId=${$scope.facility.id}`)
            .then((res:IApiResponse<any>) => {
                $scope.onFetched('facility-profile');
                $scope.facilityProfile = res.data;
                $scope.$apply();
              },
              (err) => {
                $scope.onError('facility-profile');
                if (err && err.status == '404' && err.data.error.message.indexOf('Profile not found') > -1) {
                  $scope.facilityProfile = null;
                }
                $scope.$apply();
              }
          );
        }
      };

      $scope.saveFacilityProxy = proxySettings => {
        $scope.onSave('facility-profile');
        relianceApi.post('/mobile-device-profile/facility', {
            proxyServer: proxySettings.server,
            proxyPort: proxySettings.port,
            proxyUsername: proxySettings.username,
            proxyPassword: proxySettings.password,
            facilityId: $scope.facility.id
          }
        ).then((res:IApiResponse<any>) => {
            $scope.onSaved('facility-profile');
            $scope.facilityProfile = res.data.profile;
            alertService.success('The proxy was successfully configured', true);
            $scope.$apply();
          },
          (err) => {
            $scope.onError('facility-profile');
            alertService.error('An unexpected error occurred while configuring the proxy', true);
            $scope.$apply();
          }
        );
      };

      $scope.deleteFacilityProxy = () => {
        $scope.onDelete('facility-profile');
        let facilityId = $scope.facility.id;
        let payloadId = $scope.facilityProfile.payloadIdentifier;
        relianceApi.delete(`/mobile-device-profile/facility/${facilityId}?payloadIdentifier=${payloadId}`)
          .then((res:IApiResponse<any>) => {
              $scope.onDeleted('facility-profile');
              $scope.showFacilityProxy = false;
              $scope.facilityProfile = null;
              alertService.success('The proxy was successfully deleted', true);
              $scope.$apply();
            },
            (err) => {
              $scope.onError('facility-profile');
              if (err && err.status == '404' && err.data.error.message.indexOf('Profile not found') > -1) {
                $scope.facilityProfile = null;
              }
              alertService.error('An unexpected error occurred while deleting the proxy', true);
              $scope.$apply();
            }
          );
      };

      $scope.showProxyDeleteConfirmDialog = () => {
        const dialog = DialogService.getConfirmDialog('Removing the proxy requires close coordination with devices and the network. Improper removal may result in devices becoming unreachable. Are you sure you want to remove it?',
          'Remove Proxy');
        const modal = dialog.show();
        modal.result.then(() => {$scope.deleteFacilityProxy()});
      };

      $scope.downloadCSV = (conditions: Array<IFacilitySpecialCondition>) => {
        if (!conditions || conditions.length == 0)
          return;

        let gotHeader = false;
        let csv: string = conditions.reduce((content: string, item: IFacilitySpecialCondition) => {
          const [header, row] = relianceApi.convertObjToCSV(item);
          let entry = `${row}`;

          if (!gotHeader) {
            entry = `${header}\n${entry}`;
            gotHeader = true;
          }

          return content && content !== '' ? `${content}\n${entry}` : entry;
        }, '');

        const blob = new Blob([csv], {type: 'text/csv'});
        const url = (window.URL || (<any>window).webkitURL).createObjectURL(blob);
        const link = angular.element(document.querySelector("#downloadLink"));

        link.attr('href', url);
        (<any>link).click();

        const message = conditions.length == 1 ? `Downloaded special condition ${conditions[0].id}`
          : `Downloaded all special conditions`;

        alertService.success(message);
      }

      $scope.notifications = {};
      $scope.notifications.prea = {
        config: {
          triggerId: null,
          templateId: null,
          emailAction: {
            name: null,
            address: null
          }
        }
      };

      $scope.getPREATrigger = () => {
        eventNotificationService.getPREATrigger($scope.facility.id)
          .then((response: IEventNotificationTrigger) => {
            $scope.preaTrigger = response;
          });
      };

      $scope.createPREATriggerAndOrAction = () => {
        $scope.onAdd('PREA');
        // If there is an existing PREA trigger for this facility, set trigger info in the config
        // parameter to let the service know to just create an action
        if ($scope.preaTrigger && $scope.preaTrigger.actions.length > 0) {
          $scope.notifications.prea.config.triggerId = $scope.preaTrigger.id;
          // Assume all actions for the trigger use the same template, so just pick the first action
          $scope.notifications.prea.config.templateId = $scope.preaTrigger.actions[0].actionTemplate.id;
        }

        eventNotificationService.createPREATriggerAndOrAction($scope.notifications.prea.config,
          $scope.facility
        )
        .then(() => {
          $scope.onAdded('PREA');
          alertService.success('The PREA trigger action was successfully added');
          $scope.$apply();
          $scope.$broadcast('preaChange');
        })
        .catch(() => {
          $scope.onError('PREA');
          alertService.error('An error occurred while creating PREA trigger');
          $scope.apply();
        });
      };

      $scope.editPREAAction = (triggerId: number, actionId: number, name: string, email: string) => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('../event-notification/prea-configuration-edit-modal.html').default,
          controller: 'PREAConfigurationUpdateController',
          resolve: {
            triggerId() { return triggerId; },
            actionId() { return actionId; },
            name() { return name; },
            email() { return email; }
          }
        });
        dialog.result.then(() => {
          $scope.$broadcast('preaChange');
        });
      };

      $scope.showPREAActionDeleteConfirmDialog = (triggerId: number, actionId: number) => {
        const dialog = DialogService.getConfirmDialog('Are you sure you want to remove this PREA recipient?',
          'Remove PREA recipient');
        const modal = dialog.show();
        modal.result.then(() => {
          $scope.deletePREAAction(triggerId, actionId);
        });
      };

      $scope.deletePREAAction = (triggerId: number, actionId: number) => {
        $scope.isDeleting('PREA');
        eventNotificationService.deleteAction(triggerId, actionId)
          .then(() => {
            $scope.onDeleted('PREA');
            alertService.success('The PREA trigger action was successfully deleted', true);
            $scope.$apply();
            $scope.$broadcast('preaChange');
          })
          .catch(() => {
            $scope.onError('PREA');
            alertService.error('An error occurred while deleting PREA trigger action');
            $scope.apply();
          });
      };

      $scope.showAddFacilityConfigDialog = () => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('../configuration/add-modal.html').default,
          controller: 'FacilityConfigController',
          resolve: {
            facilityId() {return $scope.facility.id},
            configuration() { return {}; }
          }
        });
        dialog.result.then((result: IDialogResult) => {
          if (result.saved) {
            alertService.success('Successfully added config', true);
            $scope.configurationGridOptions.reload();
          }
        });
      };

      $scope.showEditFacilityConfigDialog = (configuration: IConfiguration) => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('../configuration/edit-modal.html').default,
          controller: 'FacilityConfigController',
          resolve: {
            facilityId() { return $scope.facility.id; },
            configuration() { return configuration; }
          }
        });
        dialog.result.then((result: IDialogResult) => {
          if (result.saved) {
            alertService.clearAll();
            $scope.configurationGridOptions.reload();
          }
        });
      };
    }
  ]);
