import { IConfig } from '../common/config';

angular
  .module('relcore.api-user')
  .directive('apiUserSearch', ['config', (config:IConfig) =>
    ({
      restrict: 'E',
      scope: {
        fields: '=',
        facilities: '=',
        searching: '=',
        criteria: '=',
        onSearch: '=',
        onReset: '='
      },
      controller: ['$scope', function($scope) {
        $scope.search = () => $scope.onSearch($scope.criteria);
        $scope.showField = field => ($scope.fields == null) || ($scope.fields.indexOf(field) > -1);
        $scope.userTypes = config.apiCredentialSearchUserTypes;
      }
      ],
      template: require('./api-user-search.html').default
    })
  ]);
