interface IMobileDeviceViewControllerScope {
  id?: number
  device?: any
  purchase?: any
  noPurchase?: boolean
  timeSinceLastContact?: number
  timeAgoSinceLastContact?: string
  timeSinceLastMdmContact?: number
  timeAgoSinceLastMdmContact? : string
  inspection: any

  refreshing: boolean
  refresh(): void

  creatingRepairTicket: boolean
  repairTicket: any
  onCreateRepairTicket(): void
  onViewRepairTicketDetails(ticket:any): void

  newNote: any
  addNote(newNote:any): void

  reportInsGridOptions?: any
  loadReportIns(refresh:boolean): void

  historyGridOptions?: any
  loadHistory(refresh:boolean): void

  appGridOptions?: any
  loadApps(refresh:boolean): void

  profileAssignmentsGridOptions?: any
  loadProfileAssignments(refresh:boolean): void

  provisioningProfileGridOptions?: any
  loadProvisioningProfiles(refresh:boolean): void

  loadAllProfileContent(refresh:boolean): void

  deviceNoteGridOptions?: any
  loadDeviceNotes(refresh:boolean): void

  repairTicketGridOptions?: any
  loadRepairTickets(refresh:boolean): void

  shippingTicketGridOptions?: any
  loadShippingTickets(refresh:boolean): void

  commandGridOptions?: any
  loadCommands(refresh:boolean): void

  networkTestResultsGridOptions?: any
  loadNetworkTestResults(refresh:boolean): void

  enableMobileDeviceProfile(deviceId:number, profileId:number): void
  disableMobileDeviceProfile(deviceId:number, profileId:number): void

  updatingAppLogLevels: boolean
  updateAppLogLevels(): void

  startingNetworkTest: boolean
  startNetworkTest(networkTest:any): void
}

angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider
      .state('mobile-device-view', {
        controller: 'MobileDeviceViewController',
        template: require('./view.html').default,
        url: '/mobile-device/view/:id',
        parent: 'authenticated',
        params: { mobileDevice: null },
        ncyBreadcrumb: {
          label: '{{device.name}}',
          parent: 'mobile-devices'
        }
      })
  }])
  .controller('MobileDeviceViewController',
    ['$rootScope', '$scope', '$state', 'mobileDeviceService', 'mobileDeviceHistoryService', 'relianceApi', 'NgTableParams', '$stateParams', 'alertService', 'moment', 'store', 'serverActions',
    function($rootScope, $scope:IMobileDeviceViewControllerScope, $state, mobileDeviceService, mobileDeviceHistoryService, relianceApi, NgTableParams, $stateParams, alertService, moment, store, serverActions) {
      $scope.id = $stateParams.id;
      $scope.device = $stateParams.mobileDevice;
      $scope.noPurchase = null;
      if ($stateParams.mobileDevice != null) { $rootScope.title = $stateParams.mobileDevice.name; }

      $scope.loadReportIns = function(refresh = false) {
        if (!refresh && $scope.reportInsGridOptions != null) { return; }

        if ($scope.reportInsGridOptions != null) {
          return $scope.reportInsGridOptions.reload();
        }

        $scope.reportInsGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            dateReported: 'desc'
          },
          filter: {
            deviceId: $scope.id
          }
        }, {
          counts: [100, 200, 500],
          getData(params) {
            return relianceApi.index("/mobile-device/report-in", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        });
      };

      const setDevice = function(device) {
        $scope.device = device;
        $scope.id = device.id;
        $rootScope.title = device.name;

        device.lastContact = null;

        if (device.lastContact) {
          $scope.timeSinceLastContact = moment().diff(moment.tz(device.lastContact.date, device.lastContact.timezone).local(), 'seconds');
          $scope.timeAgoSinceLastContact = moment.tz(device.lastContact.date, $scope.device.lastContact.timezone).fromNow();
        }

        let lastMdmContact = device.lastMdmContact;
        if(lastMdmContact) {
          $scope.timeSinceLastMdmContact = moment().diff(moment.tz(lastMdmContact.date, lastMdmContact.timezone).local(), 'seconds');
          $scope.timeAgoSinceLastMdmContact = moment.tz(lastMdmContact.date, lastMdmContact.timezone).fromNow();
        }

        loadPurchase();
      };

      const loadDevice = function(id) {
        return mobileDeviceService.getById($stateParams.id).then(setDevice);
      }

      if ($stateParams.device) {
        setDevice($stateParams.device);
      } else {
        loadDevice($stateParams.id);
      }

      $scope.loadHistory = function(refresh = false) {
        if (!refresh && $scope.historyGridOptions != null) { return; }

        if ($scope.historyGridOptions) {
          $scope.historyGridOptions.reload();
        }

        $scope.historyGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            historyDate: 'desc'
          },
          filter: {
            deviceId: $scope.device.id
          }
        }, {
          counts: [100,500,1000],
          getData(params) {
            return mobileDeviceHistoryService.index(params.filter(), params.orderBy(), params.page(), params.count())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadApps = function(refresh = false) {
        if (!refresh && $scope.appGridOptions != null) { return; }

        if ($scope.appGridOptions) {
          $scope.appGridOptions.reload();
        }

        $scope.appGridOptions = new NgTableParams({
          page: 1,
          count: 100
        }, {
          counts: [],
          getData(params) {
            return relianceApi.get(`/mobile-device-app/by-device/${$scope.device.id}`)
              .then(function(response) {
                params.total(response.data.length);
                return response.data;
              });
          }
        });
      };

      $scope.loadAllProfileContent = function(refresh = false) {
        $scope.loadProfileAssignments(refresh);
        $scope.loadProvisioningProfiles(refresh);
      };

      $scope.loadProfileAssignments = function(refresh = false) {
        if (!refresh && $scope.profileAssignmentsGridOptions != null) { return; }

        if ($scope.profileAssignmentsGridOptions) {
          $scope.profileAssignmentsGridOptions.reload();
        }

        $scope.profileAssignmentsGridOptions = new NgTableParams({
          page: 1,
          count: 100
        }, {
          counts: [100,500],
          getData(params) {
            return relianceApi.get(`/mobile-device-profile-assignment?deviceId=${$scope.device.id}`)
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadProvisioningProfiles = function(refresh = false) {
        if (!refresh && $scope.provisioningProfileGridOptions != null) { return; }

        if ($scope.provisioningProfileGridOptions) {
          $scope.provisioningProfileGridOptions.reload();
        }

        $scope.provisioningProfileGridOptions = new NgTableParams({
        }, {
          counts: [],
          getData(params) {
            return relianceApi.get(`/provisioning-profile/by-device/${$scope.device.id}`)
              .then(function(response) {
                params.total(response.data.total);
                return response.data;
              });
          }
        });
      };

      $scope.loadDeviceNotes = function(refresh = false) {
        if (!refresh && $scope.deviceNoteGridOptions != null) { return; }

        if ($scope.deviceNoteGridOptions) {
          $scope.deviceNoteGridOptions.reload();
        }

        return $scope.deviceNoteGridOptions = new NgTableParams({
          count: 100,
          sorting: { date: 'desc' },
          filter: { deviceId: $scope.device.id }
        }, {
          counts: [100, 200, 500],
          getData(params) {
            const orderBy = params.orderBy().map(o =>
              o
                .replace(/date$/, 'dateEntered')
                .replace(/user$/, 'userName')
            );
            return relianceApi.index('/mobile-device-note', params.page(), params.count(), params.filter(), orderBy)
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data.map(function(d) {
                  d.date = d.dateEntered;
                  d.user = d.userName;
                  return d;
                });
              });
          }
        });
      };

      const resetNote = () => $scope.newNote = { note: '' };
      resetNote();

      $scope.addNote = function(note) {
        note.entityId = $scope.device.id;
        relianceApi.post("/mobile-device-note", { note })
          .then((function() {
            $scope.loadDeviceNotes(true);
            alertService.success('The note was successfully added', true);
            resetNote();
          }), (function(data){
            const $msg = data.data.error.message ? data.data.error.message : 'An error occurred while adding the note';
            alertService.error($msg, true);
          }));
      };

      $scope.loadRepairTickets = function(refresh = false) {
        if (!refresh && $scope.repairTicketGridOptions != null) { return; }

        if ($scope.repairTicketGridOptions) {
          $scope.repairTicketGridOptions.reload();
        }

        $scope.repairTicketGridOptions = new NgTableParams({
          page: 1,
          count: 50
        }, {
          counts: [50,100,500],
          getData(params) {
            return relianceApi.get(`/repair-ticket?deviceId=${$scope.device.id}`)
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadShippingTickets = function(refresh = false) {
        if (!refresh && $scope.shippingTicketGridOptions != null) { return; }

        if ($scope.shippingTicketGridOptions) {
          $scope.shippingTicketGridOptions.reload();
        }

        $scope.shippingTicketGridOptions = new NgTableParams({
          page: 1,
          count: 50
        }, {
          counts: [50,100,500],
          getData(params) {
            return relianceApi.get(`/shipping-ticket?deviceId=${$scope.device.id}`)
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadCommands = function(refresh = false) {
        if (!refresh && $scope.commandGridOptions != null) { return; }

        if ($scope.commandGridOptions) {
          $scope.commandGridOptions.reload();
        }

        $scope.commandGridOptions = new NgTableParams({
          page: 1,
          count: 50,
          sorting: { id: 'desc' },
          filter: { mobileDeviceId: $scope.device.id }
        }, {
          counts: [50,100,500],
          getData(params) {
            return relianceApi.index("/mobile-device-command", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      var loadPurchase = function() {
        relianceApi.get(`/mobile-device-purchase/by-device/${$scope.device.id}`)
          .then((function(response) {
            $scope.purchase = response.data;
            $scope.noPurchase = false;
          }), function(err) {
            if (err.status === 404) {
              $scope.noPurchase = true;
            } else {
              alertService.error('An error occurred while loading purchase information', true);
            }
          })
      }

      $scope.loadNetworkTestResults = function(refresh = false) {
        if (!refresh && $scope.networkTestResultsGridOptions != null) { return; }

        if ($scope.networkTestResultsGridOptions) {
          $scope.networkTestResultsGridOptions.reload();
        }

        $scope.networkTestResultsGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          filter: {deviceIds: $scope.device.id},
          sorting: {dateStarted: 'desc'}
        }, {
          counts: [100, 500],
          getData(params) {
            return relianceApi.index("/network-test/result", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              })
              .catch(function(err) {
                alertService.error("An error occurred while fetching network test results", true);
              });
          }
        });
      };

      $scope.enableMobileDeviceProfile = function(deviceId:number, profileId:number) {
        relianceApi.put(`/mobile-device/profile/${deviceId}`, {profileId})
          .then((function() {
            $scope.loadProfileAssignments(true);
            alertService.success('The profile will be installed shortly', true);
          }), (function() { alertService.error('An error occurred while enabling the profile', true) }))
      }

      $scope.disableMobileDeviceProfile = function(deviceId, profileId) {
        relianceApi.delete(`/mobile-device/${deviceId}/profile/${profileId}`)
          .then((function() {
            $scope.loadProfileAssignments(true);
            alertService.success('The profile will be removed shortly', true);
          }), (function() { alertService.error('An error occurred while disabling the profile', true) }))
      }

      $scope.onViewRepairTicketDetails = ticket => $state.go('repair-ticket-view', { id: ticket.id, ticket });

      $scope.inspection = {
        failureReason: '',
        processing: false,
        onPass() {
          $scope.inspection.processing = true;
          relianceApi.put(`/mobile-device/${$scope.device.id}/inspection`, { result: 'Passed' })
            .then((function() {
              $scope.inspection.processing = false;
              alertService.success('The device is now ready to be put back into inventory', true);
              loadDevice($scope.device.id);
            }), (function() {
              $scope.inspection.processing = false;
              alertService.error('An error occurred while storing the inspection result', true);
            }));
        },
        onFail() {
          $scope.inspection.processing = true;
          relianceApi.put(`/mobile-device/${$scope.device.id}/inspection`, { result: 'Failed', failureReason: $scope.inspection.failureReason })
            .then((function() {
              $scope.inspection.processing = false;
              alertService.success('The device is flagged to be repaired and a ticket was created', true);
              loadDevice($scope.device.id);
            }), (function() {
              $scope.inspection.processing = false;
              alertService.error('An error occurred while storing the inspection result', true);
            }));
        }
      };

      $scope.creatingRepairTicket = false;
      $scope.repairTicket = { failureReason: '' };
      $scope.onCreateRepairTicket = function() {
        $scope.creatingRepairTicket = true;
        $scope.repairTicket.deviceId = $scope.device.id;
        relianceApi.post('/repair-ticket', $scope.repairTicket)
          .then(function(res) {
            $scope.creatingRepairTicket = false;
            alertService.success("The ticket was successfully created");
            $state.go('repair-ticket-view', { id: res.data.id });
          });
      };

      $scope.refreshing = false;
      $scope.refresh = function() {
        $scope.refreshing = true;
        loadDevice($scope.device.id)
          .then(function() {
            $scope.refreshing = false;
            $scope.loadDeviceNotes(true);
            $scope.loadHistory(true);
            $scope.loadApps(true);
            $scope.loadProfileAssignments(true);
            $scope.loadRepairTickets(true);
            $scope.loadShippingTickets(true);
            $scope.loadReportIns(true);
            $scope.loadCommands(true);
            $scope.loadNetworkTestResults(true);
          });
      };

      $scope.updatingAppLogLevels = false;
      $scope.updateAppLogLevels = function() {
        $scope.updatingAppLogLevels = true;
        mobileDeviceService.updateAppLogLevels($scope.device.id, $scope.device.appLogLevels)
          .then((function() {
            $scope.updatingAppLogLevels = false;
            alertService.success('Successfully updated app log levels', true);
          }), (function() {
            $scope.updatingAppLogLevels = false;
            alertService.error('An error occurred when updating the app log levels', true);
          }));
      };

      $scope.startingNetworkTest = false;
      $scope.startNetworkTest = function(networkTest) {
        $scope.startingNetworkTest = true;
        relianceApi.post("/network-test", {stationIds: [$scope.device.station.id], options: networkTest})
          .then((function() {
            $scope.startingNetworkTest = false;
            alertService.success('Network test will start shortly', true);
          }), (function() {
            $scope.startingNetworkTest = false;
            alertService.error('An error occurred when trying to start the network test', true);
          }));
      };
    }
  ]);
