angular
  .module('relcore.call')
  .directive('callRouteSummaryTable', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        locations: '='
      },
      template: require('./call-route-summary-table.html').default
    })
  );
