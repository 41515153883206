angular
  .module('relcore.mobile-device')
  .directive('networkTestStart', () => ({
    restrict: 'E',
    scope: {
      onStart: '=',
      starting: '='
    },
    controller: ['$scope', function($scope) {
      $scope.networkTest = {protocol: 'UDP', durationSeconds: 10, direction: 'send'}

      $scope.start = () => {
        $scope.onStart($scope.networkTest)
      }
    }],
    template: require('./network-test-start.html').default
  }));
