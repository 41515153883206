import { ApiLog } from '../models/ApiLog';

export const resolveUserName = (log: ApiLog) => {
  if (log.apiCredential.staffUser) {
    return `${log.apiCredential.staffUser.firstName} ${log.apiCredential.staffUser.lastName}`;
  }

  if (log.apiCredential.facilityUser)
  {
    return log.apiCredential.facilityUser.username;
  }

  return '';
}

export const resolveEntity = (log: ApiLog) => {
  return log.entity
    .replace('Reliance\\Api\\Models\\Connect\\', '')
    .replace('Reliance\\Api\\Models\\', '')
    .replace('\\', ' ');
}