angular
  .module('relcore.mobile-device')
  .directive('shippingTicketInfo', () =>
    ({
      restrict: 'E',
      scope: {
        ticket: '=',
        saving: '=',
        direction: '=?',
        onSave: '=',
        showToolbar: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showToolbar = $scope.showToolbar !== false;
        $scope.direction = $scope.direction || 'outbound';
        return $scope.save = () =>
          $scope.onSave({
            facilityName: $scope.ticket.facilityName,
            facilityAddress: $scope.ticket.facilityAddress,
            attention: $scope.ticket.attention,
            phoneNumber: $scope.ticket.phoneNumber,
            shippingMethod: $scope.ticket.shippingMethod,
            countyRequest: $scope.ticket.countyRequest
          })
        ;
      }
      ],
      template: require('./shipping-ticket-info.html').default
    })
  );
