angular
  .module('relcore.online-user')
  .controller('OnlineUserResetPasswordController', [
    '$scope', '$uibModalInstance', 'onlineUser', 'relianceApi',
    function($scope, $uibModalInstance, onlineUser, relianceApi) {
      $scope.onlineUser = onlineUser;
      $scope.model = {
        password: ''
      };
      $scope.submitting = false;
      $scope.close = () => $uibModalInstance.close({saved: false});
      $scope.submit = function() {
        $scope.submitting = true;
        return relianceApi.put(`/onlineuser/${onlineUser.id}`, $scope.model)
          .then((function() {
            $scope.error = null;
            $scope.submitting = false;
            return $uibModalInstance.close({saved: true});
          }), (function(err) {
            if (err.status === 400) {
              $scope.error = 'The password must be at least 8 characters long';
            } else {
              $scope.error = 'An unexpected error occurred';
            }
            return $scope.submitting = false;
          }));
      };
      return $scope.generateRandom = function() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789';

        for (let i = 0; i <= 8; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return $scope.model.password = text;
      };
    }
  ]);
