angular
  .module('relcore.mobile-device')
  .directive('shippingTickets', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        facilities: '=',
        columns: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns || []).indexOf(column) > -1;
      }],
      template: require('./shipping-tickets.html').default
    })
  );
