angular
  .module('relcore.switch')
  .factory('ValidationLog', function() {
    return class ValidationLogModel {
      id;
      created;
      card;
      cardValue;
      calledNumber;
      validationStatus;
      validationReason;
      facilityName;
      facilityState;
      facilityDisplayName;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        this.id = data.id;
        this.created = data.created;
        this.card = data.card || '';
        this.cardValue = data.cardValue || 0;
        this.calledNumber = data.calledNumber || '';
        this.validationStatus = data.validationStatus || '';
        this.validationReason = data.validationReason || '';

        this.facilityName = this.facilityState = this.facilityDisplayName = '';
        if (data.facility != null) {
          this.facilityName = data.facility.name;
          this.facilityState = data.facility.state;
          this.facilityDisplayName = `${this.facilityName} (${this.facilityState})`;
        }
        return this;
      }
    };
  });