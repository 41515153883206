angular
  .module('relcore.common')
  .factory('httpInterceptor',
    ['$q', '$window', '$location', '$cookieStore',
    ($q, $window, $location, $cookieStore) =>
      ({
        responseError(rejection) {
          $cookieStore.put('lastLocation', $location.url());

          // If the request was rejected to 401 response, redirect to login for a new token (if we aren't already at a login-related view)
          if (rejection.status === 401 && !$location.url().includes('/login')) {
            $location.url('/login');
          }

          return $q.reject(rejection);
        }
      })
    ]);
