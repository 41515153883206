import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { IRelianceApi } from '../common/reliance-api-service';
import { IFacility } from '../models/facility';

type AddDeviceChargerCredentialModalProps = {
  facility: IFacility
  relianceApi: IRelianceApi
  onClose(): void
}

const AddDeviceChargerCredentialModal: React.FC<AddDeviceChargerCredentialModalProps> = (props) => {
  const { relianceApi, facility, onClose } = props;

  const [createdKey, setCreatedKey] = useState<string>(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    relianceApi
      .post(`/api-credential/device-charger`, { facilityId: facility.id })
      .then(({ data: { apiCredential } }) => {
        setCreatedKey(apiCredential.key);
        facility.apiCredentials = [...facility.apiCredentials, apiCredential];
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Body>
        {createdKey == null ?
          <p>This will create a device charger API key. After submitting, the key will display here.</p> :
          <div>
            <label>API Key:</label>
            <pre style={{ background: 'lightgray', padding: 15, textAlign: 'center', fontSize: 18 }}>
              {createdKey}
            </pre>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="default"
          type="button"
          disabled={submitting}
          onClick={onClose}>
          {createdKey ? 'Close' : 'Cancel'}
        </Button>
        {createdKey == null &&
          <Button
            onClick={onSubmit}
            variant="primary"
            disabled={submitting}
            type="submit">
            {submitting ? 'Creating...' : 'Create Key'}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default AddDeviceChargerCredentialModal;