angular
  .module('relcore.report')
  .config(['$stateProvider', ($stateProvider) =>
    $stateProvider
      .state('report-employee-timeline', {
        controller: 'EmployeeTimelineController',
        template: require('./timeline.html').default,
        url: '/report/employee/call-timeline',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Call Timeline'
        }
      }
  )])
  .controller('EmployeeTimelineController', [
    '$rootScope', '$scope', '$location', '$filter', 'moment', 'EmployeeActivityService', 'd3',
    function($rootScope, $scope, $location, $filter, moment, EmployeeActivityService, d3) {
      let updateGraph;
      $rootScope.title = 'Employee Report';
      $scope.activity = [];
      $scope.user = null;
      $scope.users = [];
      $scope.loading = false;
      $scope.heightMultiplier = $location.search().height || 10;
      $scope.startDate = moment($location.search().date).format('YYYY-MM-DD');
      // $scope.endDate = moment().format('YYYY-MM-DD')
      $scope.selectedActivity = null;

      $scope.outboundStats = { color: '#60B281', direction: 'Outbound', count: 0, duration: 0, avgDuration: 0 };
      $scope.inboundStats = { color: '#F8D785', direction: 'Inbound', count: 0, duration: 0, avgDuration: 0 };
      $scope.internalStats = { color: '#AD462E', direction: 'Internal', count: 0, duration: 0, avgDuration: 0 };

      $scope.chartCountDataSet = [];
      $scope.chartCountSchema = {};
      $scope.chartCountOptions = {
        rows: [{
          key: 'count',
          name: '# of calls',
          type: 'bar',
          color(data) {
            if (((data != null ? data.x : undefined) == null)) { return; }
            $scope.chartCountDataSet[data.x].color;
          }
        }],
        size: { height: 300 },
        xAxis: { key: 'direction' }
      };

      $scope.chartAvgDurDataSet = [];
      $scope.chartAvgDurSchema = {};
      $scope.chartAvgDurOptions = {
        rows: [{
          key: 'avgDuration',
          name: 'Average min/call',
          type: 'bar',
          color(data) {
            if (((data != null ? data.x : undefined) == null)) { return; }
            $scope.chartAvgDurDataSet[data.x].color;
          }
        }],
        size: { height: 300 },
        xAxis: { key: 'direction' }
      };

      $scope.chartDurDataSet = [];
      $scope.chartDurSchema = {};
      $scope.chartDurOptions = {
        rows: [{
          key: 'duration',
          name: 'Total duration',
          type: 'bar',
          color(data) {
            if (((data != null ? data.x : undefined) == null)) { return; }
            $scope.chartDurDataSet[data.x].color;
          }
        }],
        size: { height: 300 },
        xAxis: { key: 'direction' }
      };

      const filterToBool = function(name, defaultValue) {
        if ($location.search()[name] != null) {
          return $location.search()[name] === "true";
        } else {
          return defaultValue;
        }
      };

      // Filters
      $scope.showInboundCalls = filterToBool('show_inbound', true);
      $scope.showOutboundCalls = filterToBool('show_outbound', true);
      $scope.showInternalCalls = filterToBool('show_internal', true);
      $scope.showPayments = filterToBool('show_payments', true);
      $scope.showOpenedAccounts = filterToBool('show_accounts', true);
      $scope.showBillingInformation = filterToBool('show_bi', true);
      $scope.showCSRecords = filterToBool('show_csrecords', true);

      // Retrieve the employees
      EmployeeActivityService.getUsers()
        .then((function(response) {
          $scope.users = $filter('orderBy')(response.data.results, u => u.firstName+u.lastName);

          if ($location.search().user != null) {
            $scope.user = $scope.users.filter(u => u.id === $location.search().user).pop();
            return updateActivity();
          }
        }), (function(reason) {
          // TODO
        }));

      $scope.refreshActivity = function(user) {
        $scope.user = user;
        return updateActivity();
      };

      $scope.$watch('showInboundCalls', () => updateGraph());
      $scope.$watch('showOutboundCalls', () => updateGraph());
      $scope.$watch('showInternalCalls', () => updateGraph());
      $scope.$watch('showPayments', () => updateGraph());
      $scope.$watch('showOpenedAccounts', () => updateGraph());
      $scope.$watch('showBillingInformation', () => updateGraph());
      $scope.$watch('showCSRecords', () => updateGraph());
      $scope.$watch('heightMultiplier', () => updateGraph());
      $scope.$watch('startDate', () => updateActivity());
      // $scope.$watch 'endDate', (newVal) -> $scope.refreshActivity $scope.user

      const updateUrl = () =>
        $location.search({
          user: $scope.user.id,
          date: $scope.startDate,
          show_inbound: $scope.showInboundCalls.toString(),
          show_outbound: $scope.showOutboundCalls.toString(),
          show_internal: $scope.showInternalCalls.toString(),
          show_payments: $scope.showPayments.toString(),
          show_accounts: $scope.showOpenedAccounts.toString(),
          show_bi: $scope.showBillingInformation.toString(),
          show_csrecords: $scope.showCSRecords.toString(),
          height: $scope.heightMultiplier
        })
      ;

      var updateActivity = function() {
        if (($scope.user == null)) { return; }
        $scope.loading = true;
        return EmployeeActivityService.getByUser($scope.user.id,
          moment($scope.startDate + ' 00:00:00'),
          moment($scope.startDate + ' 23:59:59'), 1, 100)
          .then((function(results) {
            updateUrl();
            $scope.activity = results;
            $scope.loading = false;
            return updateGraph();
          }), (function(reason) {

          }));
      };

      updateGraph = function() {
        let inCount, inDuration, intCount, intDuration;
        if ($scope.activity.length === 0) { return; }

        updateUrl();

        const width = 1200;
        const height = 1000*$scope.heightMultiplier;
        const verticalMargin = 20;

        const y = d3.scale.linear()
          .range([0, height])
          .domain([780*100,1820*100]);
        const x = d3.scale.linear()
          .range([0,width]);

        const callMinHeight = 18;

        const verticalDividerX = x(.4);

        d3.select('#activityGraph svg').remove();
        const svg = d3.select('#activityGraph')
          .append('svg')
          .attr('width', `${width}px`)
          .attr('height', `${height+verticalMargin}px`);

        class TimelineActivity {
          data;
          svg;
          duration;
          date;
          time;
          endTime;
          textColor;
          color;
          position;
          icon;

          constructor(data, svg1) {
            this.data = data;
            this.svg = svg1;
            this.duration = this.data.duration;
            this.date = moment(this.data.taskDate);
            this.time = this.generateGraphTime(this.data.taskDate);
            this.endTime = this.generateGraphTime(this.data.taskEndDate);

            this.textColor = '#FFF';
            switch (this.data.taskType) {
              case 'directbill_ReceivePayments':
                this.color = '#3B9660';
                this.position = 0;
                this.icon = '\uf155';
                break;
              case 'directbill_ViewAccount':
                this.position = 1;
                this.color = '#20566D';
                this.icon = '\uf015';
                break;
              case 'directbill_UpdatedBillingInformation':
                this.position = 2;
                this.color = '#CCC';
                this.textColor = '#000';
                this.icon = '\uf07c';
                break;
              case 'directbill_AddCSRecord':
                this.position = 3;
                this.color = '#434D8F';
                this.icon = '\uf075';
                break;
            }
          }

          showDetails() {
            $scope.selectedActivity = this;
            $scope.$apply();
          }

          // return s the hour, then the minutes&seconds as a fraction of 100 instead of 60
          generateGraphTime(date) {
            date = moment(date);
            const hour = date.format('H');
            const minutes = date.format('m');
            const minSection = (`00${Math.round((minutes/60)*100)}`).slice(-2); // Hack to pad front with zeros
            const seconds = date.format('s');
            const secSection = (`00${Math.round((seconds/60)*100)}`).slice(-2); // Hack to pad front with zeros
            return `${hour}${minSection}${secSection}`;
          }
        }

        // Filter out calls from account activity
        const calls = $scope.activity.filter(a =>
          ($scope.showInboundCalls && (a.taskType === 'call_inbound')) ||
          ($scope.showOutboundCalls && (a.taskType === 'call_outbound')) ||
          ($scope.showInternalCalls && (a.taskType === 'call_internal'))
        ).map(a => new TimelineActivity(a, svg));

        // Calculate stats per call type
        let outCount = (inCount = (intCount = 0));
        let outDuration = (inDuration = (intDuration = 0));
        let c:any;
        for (c of Array.from(calls)) {
          if (c.data.taskType === 'call_outbound') {
            outDuration += c.data.duration;
            outCount++;
          } else if (c.data.taskType === 'call_inbound') {
            inDuration += c.data.duration;
            inCount++;
          } else if (c.data.taskType === 'call_internal') {
            intDuration += c.data.duration;
            intCount++;
          }
        }
        $scope.outboundStats.count = outCount;
        $scope.inboundStats.count = inCount;
        $scope.internalStats.count = intCount;
        $scope.outboundStats.duration = (outDuration / 60).toFixed(2);
        $scope.inboundStats.duration = (inDuration / 60).toFixed(2);
        $scope.internalStats.duration = (intDuration / 60).toFixed(2);
        $scope.outboundStats.avgDuration = (outDuration / 60 / outCount).toFixed(2);
        $scope.inboundStats.avgDuration = (inDuration / 60 / inCount).toFixed(2);
        $scope.internalStats.avgDuration = (intDuration / 60 / inCount).toFixed(2);

        setTimeout((function() {
          $scope.chartCountDataSet = [
            angular.copy($scope.outboundStats),
            angular.copy($scope.inboundStats),
            angular.copy($scope.internalStats)
          ];
          $scope.$apply();}), 50);
        setTimeout((function() {
          $scope.chartAvgDurDataSet = [
            angular.copy($scope.outboundStats),
            angular.copy($scope.inboundStats),
            angular.copy($scope.internalStats)
          ];
          $scope.$apply();}), 500);
        setTimeout((function() {
          $scope.chartDurDataSet = [
            angular.copy($scope.outboundStats),
            angular.copy($scope.inboundStats),
            angular.copy($scope.internalStats)
          ];
          $scope.$apply();}), 1000);

        // Filter out account activity
        const accountActivities = $scope.activity.filter(a =>
          ((a.taskType === 'directbill_ViewAccount') && $scope.showOpenedAccounts) ||
            ((a.taskType === 'directbill_AddCSRecord') && $scope.showCSRecords) ||
            ((a.taskType === 'directbill_ReceivePayments') && $scope.showPayments) ||
            ((a.taskType === 'directbill_UpdatedBillingInformation') && $scope.showBillingInformation)
        ).map(a => new TimelineActivity(a, svg));

        // Draw vertical timeline line
        svg.append('line')
          .attr('x1', d => verticalDividerX)
          .attr('x2', d => verticalDividerX)
          .attr('y1', d => 0)
          .attr('y2', d => height)
          .style('stroke', '#666')
          .style('stroke-width', '1px');

        // Add hour ticks
        const hours = [800,900,1000,1100,1200,1300,1400,1500,1600,1700,1800];
        let tickLength = width*.7;
        svg.selectAll('line.tick')
          .data(hours)
          .enter()
          .append('line')
          .attr('x1', d => x(.5)-(tickLength/2))
          .attr('x2', d => x(.5)+(tickLength/2))
          .attr('y1', d => y(d*100))
          .attr('y2', d => y(d*100))
          .style('stroke', '#DDD')
          .style('stroke-width', '1px');

        // Add hour labels
        tickLength = width*.7;
        svg.selectAll('text.timeLabel')
          .data(hours)
          .enter()
          .append('text')
          .text(function(h) {
            let hour = h/100;
            if (hour > 12) { hour -= 12; }
            return `${hour}:00`;}).attr('class', 'timeLabel')
          .attr('x', d => (x(.5)-(tickLength/2)) - 40)
          .attr('y', d => y(d*100) + 5);

        svg.selectAll('circle.call')
          .data(calls)
          .enter()
          .append('circle')
          .attr('class', t => t.data.taskType.replace('_', ' ')).attr('r', 4)
          .attr('cx', verticalDividerX)
          .attr('cy', t => y(t.time));

        svg.selectAll('line.call-marker')
          .data(calls)
          .enter()
          .append('line')
          .attr('class', 'call-marker')
          .attr('y1', a => y(a.time))
          .attr('y2', a => y(a.time))
          .attr('x1', verticalDividerX-25)
          .attr('x2', verticalDividerX-3)
          .style('stroke', '#CCC')
          .style('stroke-width', '1px');

        const callBoxWidth = 270;
        const call = svg.selectAll('g.call')
          .data(calls)
          .enter()
          .append('g')
          .attr('class', t => t.data.taskType.replace('_', ' ')).attr('x', verticalDividerX - (callBoxWidth+20))
          .attr('y', t => t.y = y(t.time)).attr('width', callBoxWidth)
          .attr('height', function(t) {
            const diff = y(t.endTime) - y(t.time);
            if (diff > callMinHeight) {
              return diff;
            } else {
              return callMinHeight;
            }
        }).on('mouseover', a => a.showDetails());

        call
          .append('rect')
          .attr('x', verticalDividerX - (callBoxWidth+20))
          .attr('y', t => y(t.time)).attr('rx', '3')
          .attr('ry', '3')
          .attr('width', callBoxWidth)
          .attr('height', function(t) {
            const diff = y(t.endTime) - y(t.time);
            if (diff > callMinHeight) {
              return diff;
            } else {
              return callMinHeight;
            }
        });

        call
          .append('text')
          .attr('x', verticalDividerX - callBoxWidth)
          .attr('y', t => y(t.time) + 12).text(function(t) {
            const start = moment(t.data.taskDate).format('h:mm:ss');
            const stop = moment(t.data.taskEndDate).format('h:mm:ss');
            const duration = $filter('dur')(t.data.duration);
            return `${start} to ${stop} (${duration})`;
        });

        call
          .append('text')
          .attr('x', verticalDividerX - (110))
          .attr('y', t => y(t.time) + 12).text(t => $filter('tel')(t.data.ani));

        const separation = 30;
        svg.selectAll('line.activity-marker')
          .remove();

        svg.selectAll('line.activity-marker')
          .data(accountActivities)
          .enter()
          .append('line')
          .attr('class', 'activity-marker')
          .attr('y1', a => y(a.time))
          .attr('y2', a => y(a.time))
          .attr('x1', verticalDividerX)
          .attr('x2', a => verticalDividerX + 15 + (a.position * separation))
          .style('stroke', '#666')
          .style('stroke-width', '1px');

        svg.selectAll('g.activity')
          .remove();
        const activity = svg.selectAll('g.activity')
          .data(accountActivities)
          .enter()
          .append('g')
          .attr('class', 'activity')
          .on('mouseover', a => a.showDetails())
          .attr("transform", function(d, i) {
            d.x = verticalDividerX + 15 + (d.position * separation);
            d.y = y(d.time) - 12;
            return `translate(${d.x},${d.y})`;
        });

        activity
          .append('rect')
          .attr('width', 26)
          .attr('height', 26)
          .attr('rx', 3)
          .attr('ry', 3)
          .attr('fill', a => a.color)
          .attr('pointer-events', 'all');

        activity
          .append('text')
          .text(a => a.icon)
          .attr('fill', a => a.textColor)
          .attr('x', 13)
          .attr('y', 13)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .attr('font-family', 'FontAwesome')
          .attr('font-size', '15px')
          .attr('pointer-events', 'none');
      };
    }
  ]);
