angular
  .module('relcore.recording')
  .directive('audioPlayer', () =>
    ({
      restrict: 'E',
      scope: {
        url: '='
      },
      template: require('./audio-player.html').default
    })
  );
