angular
  .module('relcore.schedule')
  .directive('scheduleBlock', () =>
    ({
      scope: {
        block: '=',
        editMode: '=?',
        onBlockSelect: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.editMode = $scope.editMode === true;

        $scope.cursor = 'pointer';
        if (!$scope.editMode && !$scope.block.open) {
          $scope.cursor = 'not-allowed';
        }

        let hours = Math.floor($scope.block.time / 100);
        let minutes = String((($scope.block.time % 100) / 100) * 60);
        minutes = (`00${minutes}`).slice(-2); // Pad left with 0
        const amPM = hours >= 12 ? 'PM' : 'AM';
        if (hours === 0) {
          hours = 12;
        }

        if (hours > 12) {
          hours = hours % 12;
        }

        $scope.displayTime = `${hours}:${minutes}${amPM}`;
      }
      ],
      template: require('./schedule-block.html').default
    })
  );
