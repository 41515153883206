angular
  .module('relcore.report')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('text-message-accounting-report', {
        controller: 'TextMessageAccountingReportController',
        url: '/report/text-message/accounting',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Texting Accounting Report',
          parent: 'authenticated'
        },
        template: require('./accounting.html').default
      }
    )

  ])
  .controller('TextMessageAccountingReportController',
    ['$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'moment',
    function($rootScope, $scope, relianceApi, NgTableParams, moment) {
      $rootScope.title = 'Texting Accounting Report';

      $scope.facilityIds = $rootScope.facilitiesToDisplay;
      $scope.showDebitsColumn = false;
      $scope.showCreditsColumn = false;
      $scope.dateRange = {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day')
      };

      $scope.run = () => {
        $scope.gridOptions = new NgTableParams({
          sorting: {
            'facility.name': 'asc'
          },
          filter: {
            createdDate: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
          }
          }
        }, {
          counts: [],
          getData(params) {
            if (!$scope.dateRange.startDate) { return []; }

            const queryParams = {
              startDate: $scope.dateRange.startDate.format('YYYY/MM/DD 00:00:00'),
              endDate: $scope.dateRange.endDate.format('YYYY/MM/DD 23:59:59'),
              facilityIds: null
            };

            if ($scope.facilityIds != null) {
              queryParams.facilityIds = $scope.facilityIds;
            }

            return relianceApi.get("/report/text-message/accounting", queryParams)
              .then(function(response) {
                $scope.showDebitsColumn = response.data.reduce(((prev, cur) => prev || (parseFloat(cur.otherDebits) !== 0)), false);
                $scope.showCreditsColumn = response.data.reduce(((prev, cur) => prev || (parseFloat(cur.otherCredits) !== 0)), false);

                $scope.facilityCount = response.data.length;
                $scope.rentalFeeCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.rentalFeeCount)), 0);
                $scope.inmatesCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.inmatesCount)), 0);
                $scope.rentalFeeAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.rentalFeeAmount)), 0);
                $scope.inboundCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.inboundCount)), 0);
                $scope.inboundAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.inboundAmount)), 0);
                $scope.outboundCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.outboundCount)), 0);
                $scope.outboundAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.outboundAmount)), 0);
                $scope.totalCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.inboundCount) + parseInt(cur.outboundCount)), 0);
                $scope.totalAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.inboundAmount) + parseFloat(cur.outboundAmount)), 0);
                $scope.customerLoadCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.customerLoadCount)), 0);
                $scope.customerLoadAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.customerLoadAmount)), 0);
                $scope.phoneCardLoadCount = response.data.reduce(((prev, cur) => prev + parseInt(cur.phoneCardLoadCount || 0)), 0);
                $scope.phonecardLoadAmount = response.data.reduce(((prev, cur) => prev + parseFloat(cur.phonecardLoadAmount)), 0);
                $scope.otherDebits = response.data.reduce(((prev, cur) => prev + parseFloat(cur.otherDebits)), 0);
                $scope.otherCredits = response.data.reduce(((prev, cur) => prev + parseFloat(cur.otherCredits)), 0);
                return response.data;
              });
          }
        });
      };

    }
    ]);
