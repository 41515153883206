angular
  .module('relcore.cdr')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('cdrs', {
      controller: 'CdrsController',
      template: require('./main.html').default,
      url: '/cdr',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'CDRs' }
    })
  }])
  .controller('CdrsController', [
    '$rootScope', '$scope', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, relianceApi, NgTableParams) {
      $rootScope.title = 'CDRs';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'setupDate': 'desc'
        }
      }, {
        counts: [100, 200, 500],
        getData: (params) => {
          return relianceApi.index('/cdr', params.page(), params.count(), params.filter(), params.orderBy())
            .then(response => {
              params.total(response.data.total);
              return response.data.data;
            });
          }
        })
    }]);
