import { CreditCardFilters } from '@reliance/types/filters/CreditCardFilters';
import { CreditCard } from '@reliance/types/models/CreditCard';
import React from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import BsForm from 'react-bootstrap/esm/Form';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Row from 'react-bootstrap/esm/Row';
import { Field, Form } from 'react-final-form';
import { IPaginatedResponse } from 'relcore-central';

type FiltersProps = {
  onSubmit(filters: CreditCardFilters): void,
  creditCards: UseAsyncReturn<IPaginatedResponse<CreditCard>>
}

const Filters: React.FC<FiltersProps> = (props) =>
  <Form
    onSubmit={(filters) => props.onSubmit({...filters})}
    render={({ handleSubmit, form, submitting, pristine, values }) => (
    <form onSubmit={handleSubmit}>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Field
                type="checkbox"
                value={1}
                name="cardTypes">
                {({ input, meta }) => (
                  <BsForm.Check label="MasterCard" onChange={input.onChange} />
                )}
              </Field>
              <Field
                type="checkbox"
                value={2}
                name="cardTypes">
                {({ input, meta }) => (
                  <BsForm.Check label="Visa" onChange={input.onChange} />
                )}
              </Field>
              <Field
                type="checkbox"
                value={3}
                name="cardTypes">
                {({ input, meta }) => (
                  <BsForm.Check label="American Express" onChange={input.onChange} />
                )}
              </Field>
              <Field
                type="checkbox"
                value={4}
                label="Discover"
                name="cardTypes">
                {({ input, meta }) => (
                  <BsForm.Check label="Discover" onChange={input.onChange} />
                )}
              </Field>
            </Col>
            <FormGroup as={Col}>
              <label>Cardholder</label>
              <Field
                className="form-control"
                name="nameOnCard"
                component="input"
                placeholder="Name on card" />
            </FormGroup>
            <FormGroup as={Col}>
              <label>ANI</label>
              <Field
                className="form-control"
                name="ani"
                component="input"
                placeholder="ANI" />
            </FormGroup>
            <FormGroup as={Col}>
              <label>Email Address</label>
              <Field
                className="form-control"
                name="emailAddress"
                component="input"
                placeholder="Email Address" />
            </FormGroup>
            <FormGroup as={Col}>
              <label>First 6 Digits</label>
              <Field
                className="form-control"
                name="firstSix"
                component="input"
                type="number"
                placeholder="First 6 Digits"
                max={999999} />
            </FormGroup>
            <FormGroup as={Col}>
              <label>Last 4 Digits</label>
              <Field
                className="form-control"
                name="lastFour"
                component="input"
                type="number"
                placeholder="Last 4 Digits"
                max={9999} />
            </FormGroup>
            <FormGroup as={Col}>
              <label>Source</label>
              <Field
                className="form-control"
                name="source">
                {({ input, meta }) => (
                  <BsForm.Control as="select" {...input}>
                    <option></option>
                    <option>DirectBill</option>
                    <option>Website</option>
                  </BsForm.Control>
                )}
              </Field>
            </FormGroup>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button
            type="submit"
            className="pull-right"
            disabled={props.creditCards.loading}>
            {props.creditCards.loading ? 'Searching...' : 'Search'}
          </Button>
        </Card.Footer>
      </Card>
    </form>
  )} />

export default Filters;