import { maxLength, minLength, required } from '@reliance/forms-js/lib/validation';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Form } from 'react-final-form';
import { TextInput } from '../form/TextInput';

type AddFormData = {
  englishString: string,
  language: string,
}

type AddModalProps = {
  onSave(formData: AddFormData)
  onClose(): void
}

const AddModal: React.FC<AddModalProps> = (props) => {
  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={{
          language: 'en',
          englishString: '',
        }}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <TextInput
                label="English"
                validationRules={[required, minLength(1), maxLength(5000)]}
                name="englishString" />
              <TextInput
                label="Spanish"
                validationRules={[required, minLength(1), maxLength(5000)]}
                name="translations[es]" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || hasValidationErrors}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default AddModal;