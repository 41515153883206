angular
  .module('relcore.facility')
  .service('facilityActions', ['facilityService', function(facilityService) {
    return {
      load: () => {
        return dispatch => {
          dispatch({ type: 'facility/loading' });

          return facilityService.retrieveAll((err, facilities) => {
            dispatch({ type: 'facility/loaded', facilities });
          });
        };
      }
    };
  }]);
