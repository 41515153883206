import { maxLength, minLength, required } from '@reliance/forms-js/lib/validation';
import { Facility } from '@reliance/types/models/Facility';
import { Station } from '@reliance/types/models/Station/Station';
import { VisitationBooth } from '@reliance/types/models/VisitationBooth';
import React, { useMemo } from 'react';
import Button from 'react-bootstrap/esm/Button';
import BsForm from 'react-bootstrap/esm/Form';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Modal from 'react-bootstrap/esm/Modal';
import { Field, Form } from 'react-final-form';
import { TextInput } from '../form/TextInput';
import { useStations } from '../hooks/Stations';

type AddEditFormData = {
  id?: number
  name: string
  recordVisitations: boolean
  stations: Station[]
}

type AddEditModalProps = {
  facility: Facility
  visitationBooth?: VisitationBooth
  onSave(formData: AddEditFormData)
  onClose(): void
}

const AddEditModal: React.FC<AddEditModalProps> = (props) => {
  const stationFilters = useMemo(() => ({
    facilityId: props.facility.id,
    type: 'Audio Visit'
  }), [props.facility]);
  const stations = useStations(stationFilters);

  // Sort stations by name, and remove any already assigned to a booth
  const sortedStations = (stations.result?.data?.sort((a, b) => a.name.localeCompare(b.name)) ?? [])
    .filter(s => s.visitationBooth == null || s.visitationBooth.id == props.visitationBooth?.id);

  // Map the visitation booth's stations to just their IDs
  let initialValues = props.visitationBooth ? {
    ...props.visitationBooth,
    stations: undefined,
    stationIds: props.visitationBooth.stations.map(s => s.id)
  } : {
    recordVisitations: true
  };

  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <TextInput
                label="Name"
                validationRules={[required, minLength(1), maxLength(50)]}
                name="name" />

              <Field
                type="checkbox"
                name="recordVisitations">
                {({ input }) => (
                  <BsForm.Check
                    {...input}
                    type="checkbox"
                    label={"Record Visitation"}
                  />
                )}
              </Field>

              <br/>
              <FormGroup>
                <label>Select audio visit stations{stations.loading && ' (loading...)'}:</label>
                {sortedStations.map(station =>
                  <Field
                    type="checkbox"
                    value={station.id}
                    key={`station-${station.id}`}
                    name="stationIds">
                    {({ input, meta }) => (
                      <BsForm.Check
                        {...input}
                        type="checkbox"
                        id={`station-${station.id}`}
                        label={station.name}
                      />
                    )}
                  </Field>
                )}

                {!stations.loading && sortedStations.length == 0 && <div><em>No stations found</em></div>}
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting || hasValidationErrors || stations.loading}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default AddEditModal;
