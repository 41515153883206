angular
  .module('relcore.credit-card')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('credit-card-charges', {
      controller: 'CreditCardChargesController',
      template: require('./main.html').default,
      url: '/credit-card/charges',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Credit Card Charges' }
    })
  }])
  .controller('CreditCardChargesController', [
    '$rootScope', '$scope', 'store', 'relianceApi', 'NgTableParams', 'ReduxMapToScope',
    function($rootScope, $scope, store, relianceApi, NgTableParams, ReduxMapToScope) {
      $rootScope.title = 'Credit Card Charges';
      $scope.cardNumber = '';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'date': 'desc'
        }
      }, {
        counts: [100, 200, 500],
        getData: (params) => {
          return relianceApi
            .index('/credit-card/charges', params.page(), params.count(), params.filter(), params.orderBy())
            .then((response) => {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });

      $scope.run = () => {
        $scope.gridOptions.parameters({ filter: { creditCardNumber: $scope.cardNumber }});
        $scope.gridOptions.reload();
      }
    }]
  );
