angular
  .module('relcore.note')
  .directive('note', () =>
    ({
      restrict: 'E',
      scope: {
        showAdd: '=?',
        onAdd: '=?',
        adding: '=?',
        newNote: '=?',
        headingText: '=?',
        objectNameText: '=?',

        showList: '=?',
        gridOptions: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showAdd = ($scope.showAdd === null) || ($scope.showAdd === true);
        $scope.showList = ($scope.showList === null) || ($scope.showList === true);
        $scope.headingText = $scope.headingText || 'Internal Notes';
        $scope.objectNameText = $scope.objectNameText || 'Note';
      }
      ],
      template: require('./main.html').default
    })
  );
