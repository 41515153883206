angular
  .module('relcore.inmate')
  .directive('inmateDetailBlock', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        inmateDevice: '=?'
      },
      template: require('./inmate-detail-block.html').default
    })
  );
