angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketShipController', [
    '$scope', '$uibModalInstance', 'ticket', 'relianceApi',
    function($scope, $uibModalInstance, ticket, relianceApi) {
      $scope.ticket = ticket;
      $scope.model = {
        shippingMethod: ticket.shippingMethod,
        trackingNumber: '',
        notes: ''
      };
      $scope.submitting = false;
      $scope.close = () => $uibModalInstance.close({saved: false});
      $scope.submit = function() {
        $scope.submitting = true;
        relianceApi.put(`/shipping-ticket/${ticket.id}/shipped`, $scope.model)
          .then((function() {
            $scope.submitting = false;
            $uibModalInstance.close({saved: true});
          }), (err => $scope.submitting = false));
      };
    }
  ]);
