export enum EnabledDisabled {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

export enum ActiveInactive {
  Active,
  Inactive
}

export enum CommunicationTreatmentOverride {
  ALLOWED = 'Allowed',
  BLOCKED = 'Blocked'
}

export enum CommunicationTreatment {
  STANDARD = 'Standard',
  ALLOWED_ONLY = 'Allowed Only'
}
