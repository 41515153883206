angular
  .module('relcore.credit-card')
  .directive('creditCardCharges', () => {
    return {
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      template: require('./credit-card-charges.html').default
    };
  });
