angular
  .module('relcore.note')
  .directive('notes', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        objectNameText: '=?'
      },
      template: require('./notes.html').default
    })
  );
