angular
  .module('relcore.filters')
  .filter('amDateTimezoneFormat', ['moment', (moment) => {
    return function(value, format) {
      let date;

      if (value == null) {
        return '';
      }

      if (moment.isMoment(value)) {
        date = value;
      } else if ((typeof value === 'object') && (value.date != null)) {
        date = moment.tz(value.date, value.timezone).local();
      } else {
        date = moment(value);
      }

      return date.format(format);
    };
  }]);