angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceProvisioningProfile', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      template: require('./mobile-device-provisioning-profile.html').default
    })
  );
