angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceCommands', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      controller: ['$scope', function($scope) {
        $scope.togglePayload = command => command.show = !(command.show === true);
      }],
      template: require('./mobile-device-commands.html').default
    })
  );
