import { CommunicationTreatment } from '../models/enums'

export interface IInmate {
  id: number
  firstName: string
  lastName: string
  account: number
  communicationTreatment: CommunicationTreatment
}

angular
  .module('relcore.inmate')
  .service('inmateService', ['relianceApi', '$filter', function(relianceApi, $filter) {
    return {
      getById(id) {
        return relianceApi.get(`/inmate/by-id/${id}?includeDisabled=true`)
          .then(function(response) {
            const { data } = response;
            return Object.assign({}, data, {
              fullName: `${data.firstName} ${data.middleName} ${data.lastName}`,
              facilityTitle: $filter('facilityTitle')(data.facility)
              });
            });
      },

      put(inmate) {
        return relianceApi.put(
          `/inmate/${inmate.account}?facilityId=${inmate.facility.id}`,
          inmate
        );
      },

      get(filter, orderBy) {
        if (filter == null) { filter = {}; }
        const params = {orderBy: null};
        for (let i in filter) {
          if (filter[i] != null) { params[i] = filter[i]; }
        }

        if (orderBy != null) {
          params.orderBy = orderBy;
        }

        return relianceApi.get("/inmate", params)
          .then(function(response) {
            const results = response.data;
            let data:any;
            for (data of Array.from(results.data)) {
              data.fullName = `${data.firstName} ${data.middleName} ${data.lastName}`;
              if (data.facility != null) {
                data.facilityTitle = $filter('facilityTitle')(data.facility);
              }
            }
            return results;
        });
      }
    };
  }]);
