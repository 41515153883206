import { PaginationFilters } from '@reliance/types/filters/PaginationFilters';
import { InvoiceBatch } from '@reliance/types/models/Invoice/InvoiceBatch';
import { useAsync } from 'react-async-hook';
import { IPaginatedResponse } from 'relcore-central';
import api from '../common/api';

export const useInvoiceBatches = (filters?: PaginationFilters) => {
  return useAsync<IPaginatedResponse<InvoiceBatch>>(
    () => filters ? api.get<IPaginatedResponse<InvoiceBatch>>('/invoice/batch', { params: filters }).then(r => r.data) : null,
    [filters]
  );
};