import { ITaxRate, ITaxService } from "./tax-service";

type ITaxRateEdit = Partial<ITaxRate>

interface ITaxRatesScope extends ng.IScope {
  taxRatesTableInstance: any;
  taxRatesTableOptions: any;
  taxRatesTableColumns: Array<any>;
  taxRatesTableColumnDefs: Array<any>;
  edit: (zipCode: string, stateRate: number, countyRate: number, cityRate: number,
    specialRate: number, comments: string) => void;
  onEdit: (rate: {taxRate: ITaxRateEdit}) => void;
  showHistoryModal: (id: number, zipCode: string) => void;
}

angular
  .module('relcore.tax')
  .directive('taxRates', () => ({
      restrict: 'E',
      scope: {
        onEdit: '&'
      },
      controller: 'TaxRatesListController',
      template: require('./tax-rates.html').default
    })
  )
  .controller('TaxRatesListController', ['$scope', 'TaxService', 'DTColumnBuilder',
    'DTColumnDefBuilder', 'DTOptionsBuilder', '$compile', '$uibModal',
    ($scope: ITaxRatesScope, TaxService: ITaxService, DTColumnBuilder, DTColumnDefBuilder,
      DTOptionsBuilder, $compile, $uibModal
    ) => {
      $scope.taxRatesTableInstance = {}
      $scope.taxRatesTableOptions = DTOptionsBuilder
        .fromFnPromise(() => {
          return TaxService.getTaxRates()
            .then((response: Array<ITaxRate>) => {
              return response.filter((rate: ITaxRate) => !rate.dateRemoved);
            });
        }
      )
      .withPaginationType('full_numbers')
      .withColumnFilter({
        aoColumns: [
          {
            type: 'number'
          },
          {
              type: 'text',
              bRegex: true,
              bSmart: true
          },
          {
              type: 'text',
              bRegex: true,
              bSmart: true
          },
          {
              type: 'text',
              bRegex: true,
              bSmart: true
          },
          {
              type: 'text',
              bRegex: true,
              bSmart: true
          }
        ]
      })
      .withOption('lengthChange', false)
      .withDisplayLength(100)
      .withOption('createdRow', row => {
        $compile(angular.element(row).contents())($scope);
      })
      .withBootstrap()
      .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

      const editButtonRenderer = (id: number, type: string, rate: ITaxRate) => {
        return `<button class='btn btn-default' ng-click="edit(${rate.zipCode},
          ${rate.stateRate}, ${rate.countyRate}, ${rate.cityRate},
          ${rate.specialRate}, '${rate.comments || ''}')">Edit</button>`;
      };

      const zipCodeRenderer = (id: number, type: string, rate: ITaxRate) =>
        `&nbsp; <i class="fa fa-history" style="cursor:pointer" ng-click="showHistoryModal(${rate.id}, ${rate.zipCode})"></i> &nbsp; ${rate.zipCode}`

      $scope.taxRatesTableColumns = [
        DTColumnBuilder
          .newColumn('zipCode')
          .withTitle('Zip Code')
          .renderWith(zipCodeRenderer),
        DTColumnBuilder.newColumn('state').withTitle('State'),
        DTColumnBuilder.newColumn('county').withTitle('County'),
        DTColumnBuilder.newColumn('city').withTitle('City'),
        DTColumnBuilder.newColumn('taxRegionName').withTitle('Tax Region'),
        DTColumnBuilder.newColumn('combinedRate').withTitle('Combine Rate'),
        DTColumnBuilder.newColumn('stateRate').withTitle('State Rate'),
        DTColumnBuilder.newColumn('countyRate').withTitle('County Rate'),
        DTColumnBuilder.newColumn('cityRate').withTitle('City Rate'),
        DTColumnBuilder.newColumn('specialRate').withTitle('Special Rate'),
        DTColumnBuilder
          .newColumn('id')
          .renderWith(editButtonRenderer)
      ];

      $scope.taxRatesTableColumnDefs = [
        DTColumnDefBuilder.newColumnDef([10]).withOption('className', 'text-center')
      ];

      $scope.edit = (zipCode: string, stateRate: number, countyRate: number,
        cityRate: number, specialRate: number, comments: string) => {
          $scope.onEdit({taxRate: {
              zipCode: zipCode,
              stateRate: stateRate,
              countyRate: countyRate,
              cityRate: cityRate,
              specialRate: specialRate,
              comments
            }
          });
      };

      $scope.showHistoryModal = (id: number, zipCode: string) => {
        $uibModal.open({
          size: 'lg',
          template: require('./history.html').default,
          controller: 'TaxRateHistoryController',
          resolve: {
            zipCode() { return zipCode; }
          }
        });
      };

      $scope.$on('taxRateChange', (event) => {
        $scope.taxRatesTableInstance.reloadData();
        event.preventDefault();
      });
    }
  ])