angular
  .module('relcore.report')
  .service('EmployeeActivityService', ['$http', 'relianceApi', '$q', 'EmployeeActivity',
    function($http, relianceApi, $q, EmployeeActivity) {
      class EmployeeActivityService {
        constructor() {
          this.getUsers = this.getUsers.bind(this);
          this.getByUser = this.getByUser.bind(this);
          this.getSummaryByUser = this.getSummaryByUser.bind(this);
        }

        getUsers() {
          const url = "/staff";
          return relianceApi.get(url);
        }

        getByUser(userId, startDate, endDate, page, perPage) {
          let url = `/staff/activity-report?page=${page}&perPage=${perPage}`;
          url += `&userId=${userId}`;
          url += `&startDate=${startDate.format('YYYY-MM-DD HH:mm:ss')}`;
          url += `&endDate=${endDate.format('YYYY-MM-DD HH:mm:ss')}`;

          return $q((resolve, reject) => {
            return relianceApi.get(url)
              .then((function(response) {
                const { data } = response;
                const activities = [];
                for (let activity of Array.from(data.results)) {
                  activities.push(new EmployeeActivity(activity));
                }
                return resolve(activities);
              }), (reason => reject(reason)));
          });
        }

        getSummaryByUser(userId, startDate, endDate, groupBy) {
          let url = "/staff/activity-report?";
          url += `userId=${userId}`;
          url += `&startDate=${startDate.format('YYYY-MM-DD HH:mm:ss')}`;
          url += `&endDate=${endDate.format('YYYY-MM-DD HH:mm:ss')}`;
          url += `&groupBy=${groupBy}`;

          return relianceApi.get(url);
        }
      }

      return new EmployeeActivityService;
    }
  ]);