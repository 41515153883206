import React from 'react';
import { CreditCardTypeNumeric } from '@reliance/types/enums/CreditCardType';
import { CreditCardType as CreditCardTypeEnum } from '@reliance/types/enums/CreditCardType';
import { getCreditCardType } from '../account/payment/payment-service';

export function creditCardType(cardType: CreditCardTypeNumeric) {
  switch (cardType) {
    case CreditCardTypeNumeric.AMERICANEXPRESS: return 'American Express';
    case CreditCardTypeNumeric.DISCOVER: return 'Discover';
    case CreditCardTypeNumeric.MASTERCARD: return 'MasterCard';
    case CreditCardTypeNumeric.VISA: return 'Visa';
    default: return 'Unknown';
  }
}

type CreditCardTypeProps = {
  cardType: CreditCardTypeNumeric
}

export const CreditCardType: React.FC<CreditCardTypeProps> = (props) =>
  <>{creditCardType(props.cardType)}</>

  type CreditCardTypeIconProps = {
    cardType?: CreditCardTypeNumeric
    cardNumber?: string
  }

export const CreditCardTypeIcon: React.FC<CreditCardTypeIconProps> = (props) => {
  var cardType: CreditCardTypeEnum;

  switch (props.cardType) {
    case CreditCardTypeNumeric.AMERICANEXPRESS: cardType = CreditCardTypeEnum.AMERICAN_EXPRESS;
    case CreditCardTypeNumeric.DISCOVER: cardType = CreditCardTypeEnum.DISCOVER_CARD;
    case CreditCardTypeNumeric.MASTERCARD: cardType = CreditCardTypeEnum.MASTER_CARD;
    case CreditCardTypeNumeric.VISA: cardType = CreditCardTypeEnum.VISA;
  }

  if (props.cardNumber) {
    cardType = getCreditCardType(props.cardNumber);
  }

  switch (cardType) {
    case CreditCardTypeEnum.AMERICAN_EXPRESS: return <i className="fab fa-cc-amex"></i>;
    case CreditCardTypeEnum.DISCOVER_CARD: return <i className="fab fa-cc-discover"></i>;
    case CreditCardTypeEnum.MASTER_CARD: return <i className="fab fa-cc-mastercard"></i>;
    case CreditCardTypeEnum.VISA: return <i className="fab fa-cc-visa"></i>;
    default: return <></>;
  }
}