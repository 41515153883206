import { composeValidators } from '@reliance/forms-js/lib/validation';
import React from 'react';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { Field } from 'react-final-form';

export enum InputType {
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
}

type TextInputProps = {
  name: string
  label: string
  id?: string
  placeholder?: string
  validationRules?: any[]
  type?: InputType
  helperText?: string
}

export const TextInput: React.FC<TextInputProps> = (props) =>
  <Field
    name={props.name}
    validate={composeValidators(props.validationRules || [])}>
    {({ input, meta }) => (
      <FormGroup>
        <label>{props.label}</label>
        <input
          id={props.id}
          placeholder={props.placeholder}
          className={`form-control ${meta.touched && meta.error ? 'has-error' : ''}`}
          disabled={meta.submitting}
          type={props.type}
          {...input}
        />
        {((meta.error && meta.touched) || props.helperText) &&
          <span className="help-block has-error">{meta.error && meta.touched ? meta.error : props.helperText}</span>
        }
      </FormGroup>
    )}
  </Field>