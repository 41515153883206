angular
  .module('relcore.facility')
  .service('facilityUserViewReducer', function() {
    var initialState = {
      loading: false,
      facilityUser: null
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'facility-user/view/clear':
          return Object.assign({}, state, {
            facilityUser: null,
            loading: false
          });

        case 'facility-user/view/loading':
          return Object.assign({}, state, {
            facilityUser: null,
            loading: true
          });

        case 'facility-user/view/set-facility-user':
          return Object.assign({}, state, {
            facilityUser: action.facilityUser,
            loading: false
          });
      };

      return state
    };
  });
