import { IEventNotificationService } from '../event-notification/event-notification-service';
import { IFacility } from '../models/facility';

export const state = {
  url: '/text-message/alerts',
  params: {
    facility: null
  },
  views: {
    'Text Message Alerts@facilities-view': {
      controller: 'FacilityViewTextMessageAlertsController',
      template: `
        <event-notification-text-message-alerts
          facility="facility"
          inmate-service="inmateService"
          event-notification-service="eventNotificationService">
        </event-notification-text-message-alerts>
      `
    }
  },
  ncyBreadcrumb: {
    label: 'Text Message Alerts'
  }
};

export const tab = {
  heading: 'Text Message Alerts',
  route: 'facilities-view.text-message-alerts',
  style: 'fas fa-fw fa-lg fa-bell'
};

interface IScope extends ng.IScope {
  facility: IFacility
  inmateService: any
  eventNotificationService: IEventNotificationService
}

angular
  .module('relcore.facility')
  .controller('FacilityViewTextMessageAlertsController', [
    '$scope', '$stateParams', 'eventNotificationService', 'inmateService',
    function($scope: IScope, $stateParams, eventNotificationService: IEventNotificationService, inmateService) {
      $scope.inmateService = inmateService;
      $scope.eventNotificationService = eventNotificationService;
      $scope.facility = $stateParams.facility;
    }
  ]);