angular
  .module('relcore.visitation')
  .directive('visitations', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?',
        facilities: '=?',
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }],
      template: require('./visitations.html').default
    })
  );
