angular
  .module('relcore.facility')
  .directive('facilitySummary', () =>
    ({
      restrict: 'E',
      scope: {
        facility: '='
      },
      template: require('./facility-summary.html').default
    })
  );
