angular
  .module('relcore.facility')
  .directive('facilityUserAdd', function() {
    return {
      restrict: 'E',
      scope: {
        facilities: '=',
        apiRoles: '=',
        adding: '=',
        onAdd: '='
      },
      controller: ['$scope', function($scope) {
        const newUser = {
          username: '',
          password: '',
          roles: [],
          facilities: []
        };

        $scope.facilityUser = Object.assign({}, newUser);
        $scope.add = function() {
          $scope
            .onAdd({
              username: $scope.facilityUser.username,
              password: $scope.facilityUser.password,
              roleIds: $scope.facilityUser.roles.map(r => r.id),
              facilityIds: $scope.facilityUser.facilities.map(f => f.id)
            })
            .then(function() {
              $scope.facilityUser = Object.assign({}, newUser);
            });
        };

        $scope.allFacilities = false;
        $scope.handleAllFacilitiesCheck = () => {
          if ($scope.allFacilities) {
            $scope.facilityUser = Object.assign({}, $scope.facilityUser, {
              facilityIds: []
            });
          }
        };
      }],
      template: require('./facility-user-add.html').default
    }
  })
