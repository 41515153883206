angular
  .module('relcore.user')
  .factory('User', function() {
    return class User {
      id: number;
      username: string;
      firstName: string;
      lastName: string;
      fullName: string;
      title: string;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        if (data != null) { this.loadFromData(data); }
      }

      loadFromData(data) {
        this.id = data.id;
        this.username = data.username;
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.fullName = `${this.firstName} ${this.lastName}`;
        this.title = `${this.fullName} (${this.username})`;
      }
    };
  });

