import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { ICallRoute, ICallRouteMapping } from '../models/call-route';
import { IFacility } from '../models/facility';
import { IFacilityNumber } from '../models/facility-number';

interface ICallRouteMappingCreateEditScope {
  error?: string
  facilityNumbers: Array<IFacilityNumber>,
  editing: boolean
  mapping: ICallRouteMapping
  callRoutes: Array<ICallRoute>
  saving: boolean
  save(): Promise<ICallRouteMapping>
  close(): void
}

angular
  .module('relcore.call')
  .controller('CallRouteMappingCreateEditController', [
    '$scope', 'relianceApi', '$uibModalInstance', 'facility', 'facilityNumbers', 'editRouteMapping',
    function ($scope:ICallRouteMappingCreateEditScope, relianceApi:IRelianceApi, $uibModalInstance, facility: IFacility, facilityNumbers: Array<IFacilityNumber>, editRouteMapping: ICallRouteMapping) {
      $scope.error = null;
      $scope.editing = editRouteMapping != null;
      $scope.facilityNumbers = facilityNumbers;

      const mapping: ICallRouteMapping = editRouteMapping || {
        origination: facilityNumbers && facilityNumbers.length > 0 ? facilityNumbers[0].ani.substr(0,8) : '',
        destination: '',
        billingCategory: '',
        callRoute: null
      };

      $scope.mapping = Object.assign({}, mapping);

      // Load external data for call routes
      $scope.callRoutes = [];
      relianceApi.get(`/call/route`, {
        page: null
      }).then(
        ((res: IApiResponse<IPaginatedResponse<ICallRoute>>) => {
          res.data.data.sort((a,b) => a.name > b.name ? 1 : -1);
          $scope.callRoutes = res.data.data;
        }),
        (() => $scope.error = 'Unable to load call routes')
      );

      // Handle form submission
      $scope.save = function () {
        // Massage the call route into its ID
        let data:any = $scope.mapping;
        data.callRouteId = $scope.mapping.callRoute.id;
        delete data.callRoute;

        $scope.saving = true;
        let promise = null;
        if ($scope.editing) {
          promise = relianceApi.put(`/call/route/mapping/${data.id}`, { mapping: data });
        } else {
          promise = relianceApi.post(`/call/route/mapping`, { mapping: data });
        }

        promise.then((function (res) {
          $scope.saving = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({ saved: true });
          }
        }), (function (err) {
          if (err.status === 400) {
            $scope.error = 'Please verify the inputs are valid and try again';
          } else {
            $scope.error = 'An unexpected error occurred';
          }
          $scope.saving = false;
        }));

        return promise;
      };

      $scope.close = () => $uibModalInstance.close({ saved: false })
    }
  ]);
