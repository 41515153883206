angular
  .module('relcore.sms-voice-number')
  .service('smsVoiceNumberService',
    ['relianceApi', '$filter', 'moment', function(relianceApi, $filter, moment) {
      return {
        get(filter, orderBy) {
          if (filter == null) { filter = {}; }
          const params = {orderBy: null};
          for (let i in filter) {
            if (filter[i] != null) { params[i] = filter[i]; }
          }

          if (orderBy != null) {
            params.orderBy = orderBy;
          }

          return relianceApi.get("/smsvoice-number", params)
            .then(function(response) {
              const results = response.data;
              let data:any;
              for (data of Array.from(results.data)) {
                // Add inmate and facility information
                if (data.inmate != null) {
                  data.inmateName = `${data.inmate.firstName} ${data.inmate.lastName} (${data.inmate.account})`;
                  data.facilityTitle = $filter('facilityTitle')(data.inmate.facility);
                }

                if (data.needsReturnSetDate != null) {
                  data.needsReturnSetDate = moment(data.needsReturnSetDate.date);
                }

                if (data.dateReturned != null) {
                  data.dateReturned = moment(data.dateReturned.date);
                }

                // Flag numbers that needed to be returned last month
                if (data.needsReturn && data.needsReturnSetDate.isBefore(moment().startOf('month'))) {
                  data.returnPastDue = true;
                } else {
                  data.returnPastDue = false;
                }
              }

              return results;
          });
        }
      };
    }]);
