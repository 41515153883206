import { IScope } from "angular";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Tab from 'react-bootstrap/esm/Tab';
import Tabs from 'react-bootstrap/esm/Tabs';
import { react2angular } from 'react2angular';
import { IAlertService } from '../../alert/alert-service';
import { IServiceErrorResponse } from '../../common/reliance-api-service';
import { Duration } from '../../format/Duration';
import { ICardAccount } from '../../models/card-account';
import { IRootScope } from '../../models/root-scope';
import Table from '../../sci-dyn-cdr/Table';
import { ICardAccountService } from "../card-account-service";
import CardAccountActivityTable from '../CardAccountActivityTable';
import CdrTable from './CdrTable';
import FacilityOrderSummary from './FacilityOrderSummary';
import { Header } from './Header';
import ItdsSummary from './ItdsSummary';
import OnlineOrderSummary from './OnlineOrderSummary';

interface ICardAccountScope extends IScope {
  setAccountNumberUrl(accountNumber): void
  accountNumber: string
}

angular
  .module('relcore.card')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('card-account-details', {
          template: `
            <card-account-view
              account-number="accountNumber"
              set-account-number-url="setAccountNumberUrl" />
          `,
          url: '/card/:accountNumber',
          params: {
            accountNumber: {
              value: '',
              dynamic: true
            }
          },
          parent: 'authenticated',
          controller: 'CardAccountDetailController',
          ncyBreadcrumb: {
            label: "Card Account {{accountNumber ? '/ ' + accountNumber : ''}}"
          }
        }
      )
  ])
  .controller('CardAccountDetailController',
    ['$rootScope', '$scope', '$stateParams', '$state',
      ($rootScope: IRootScope, $scope: ICardAccountScope, $stateParams, $state) => {

      $scope.setAccountNumberUrl = (accountNumber) => {
        $state.go('.', { accountNumber }, { notify: false });
        $scope.accountNumber = accountNumber;
        $rootScope.title = `Card Account ${accountNumber}`;
      }

      if ($stateParams.accountNumber) {
        $scope.accountNumber = $stateParams.accountNumber;
      }
    }
  ]);

type ViewProps = {
  $scope: ng.IScope
  accountNumber: string

  setAccountNumberUrl(accountNumber: string): void
  alertService: IAlertService
  cardAccountService: ICardAccountService
}

const View: React.FC<ViewProps> = (props) => {
  const { alertService, setAccountNumberUrl } = props;
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState<ICardAccount>(null);
  const [accountNumber, setAccountNumber] = useState(props.accountNumber);

  const loadAccount = (loadAccountNumber) => {
    alertService.clearAll();
    props.$scope.$apply(); // Needed to get the alert service to update for now

    setLoading(true);
    setAccount(null);
    setAccountNumber(loadAccountNumber);
    setAccountNumberUrl(loadAccountNumber);
    props.cardAccountService
      .getAccount(loadAccountNumber)
      .then((account: ICardAccount) => {
        setLoading(false);
        setAccount(account);
      })
      .catch((err: IServiceErrorResponse) => {
        setLoading(false);
        if (err.status == 404) {
          alertService.error('The card account was not found', true);
        } else {
          const msg = err.message ? err.message : 'Unable to load card account';
          alertService.error(msg, true);
        }
        props.$scope.$apply(); // Needed to get the alert service to update for now
        throw err;
      })
  }

  // Load the requested account number on open
  useEffect(() => {
    if (props.accountNumber) {
      loadAccount(props.accountNumber);
    }
  }, [props.accountNumber]);

  if (account == null && !loading) {
    return <div/>;
  } else if (loading) {
    return (
      <Header
        searching={loading}
        searchingNumber={accountNumber}
        cardAccount={null} />
    );
  }

  const sortedActivities = account.activities
    .sort((a,b) => moment(a.dateSource.date).valueOf() - moment(b.dateSource.date).valueOf())
    .reverse();

  const sortedLegacyCdrs = account.sciDynCdrs
    .sort((a,b) => moment(a.setupDate.date).valueOf() - moment(b.setupDate.date).valueOf())
    .reverse();
  const totalLegacyCdrsDurationInSeconds = sortedLegacyCdrs.reduce((p, c) => p + c.duration, 0);

  const sortedCdrActivities = account.activities
    .filter(a => a.cdr)
    .sort((a,b) => moment(a.cdr.setupDate.date).valueOf() - moment(b.cdr.setupDate.date).valueOf())
    .reverse();
  const totalCdrsDurationInSeconds = sortedCdrActivities.reduce((p, c) => p + c.cdr.durationSeconds, 0);

  return (
    <div>
      <Header cardAccount={account} />

      {account.phoneCard &&
        <div className="row">
          {account.phoneCard.onlineOrderItem &&
            <div className="col-md-6 mb-3">
              <OnlineOrderSummary orderItem={account.phoneCard.onlineOrderItem} />
            </div>
          }

          {account.phoneCard.facilityOrderItem &&
            <div className="col-md-6 mb-3">
              <FacilityOrderSummary orderItem={account.phoneCard.facilityOrderItem} />
            </div>
          }

          {account.itdsInmateAccount &&
            <div className="col-md-6 mb-3">
              <ItdsSummary account={account.itdsInmateAccount} />
            </div>
          }
        </div>
      }

      <div className="d-print-block">
        <Tabs>
          <Tab
            eventKey="legacyCallRecords"
            title={<><i className="fas fa-fw fa-lg fa-phone"></i>Legacy Call Records ({sortedLegacyCdrs.length})</>}>
            <Card>
              <Card.Body>
                <ul className="sparks mb-3" style={{textAlign: 'left'}}>
                  <li className="sparks-info">
                    <h5>Calls <span>{sortedLegacyCdrs.length}</span></h5>
                  </li>
                  <li className="sparks-info" ng-if="cardAccount.type == 'Phone Card'">
                    <h5>Duration <span><Duration value={totalLegacyCdrsDurationInSeconds} /></span></h5>
                  </li>
                </ul>

                <Table data={sortedLegacyCdrs} />
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="callRecords"
            title={<><i className="fas fa-fw fa-lg fa-phone"></i>Call Records ({sortedCdrActivities.length})</>}>
            <Card>
              <Card.Body>
                <ul className="sparks mb-3" style={{textAlign: 'left'}}>
                  <li className="sparks-info">
                    <h5>Calls <span>{sortedCdrActivities.length}</span></h5>
                  </li>
                  <li className="sparks-info" ng-if="cardAccount.type == 'Phone Card'">
                    <h5>Duration <span><Duration value={totalCdrsDurationInSeconds} /></span></h5>
                  </li>
                </ul>

                <CdrTable data={sortedCdrActivities} />
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="transactions"
            title={<><i className="fas fa-fw fa-lg fa-file-alt"></i>Transactions ({account.activities.length})</>}>
            <Card>
              <Card.Body>
                <ul className="sparks mb-3" style={{textAlign: 'left'}}>
                  <li className="sparks-info">
                    <h5>Transactions <span>{account.activities.length}</span></h5>
                  </li>
                </ul>

                <CardAccountActivityTable data={sortedActivities} />
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('cardAccountView', react2angular(
    View,
    ['accountNumber', 'setAccountNumberUrl'],
    ['alertService', 'cardAccountService', '$scope']
  ));