angular
  .module('relcore.online-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('online-user-search', {
        controller: 'SearchOnlineUserController',
        url: '/online-user/search',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Online Users'
        },
        template: require('./main.html').default
      }
    )

  ])
  .controller('SearchOnlineUserController',
    ['$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, NgTableParams) {
      $rootScope.title = 'Online User Search';

      $scope.criteria = {};

      $scope.results = [];
      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: { id: 'desc' }
      }, {
        total: 0,
        counts: [100,200,500],
        getData(params) {
          return relianceApi.index('/onlineuser', params.page(), params.count(), $scope.criteria, params.orderBy())
            .then((function(res) {
              params.total(res.data.total);
              return res.data.data;
            }));
        }
      });

      $scope.reset = () => $scope.criteria = {};
      $scope.search = () => $scope.gridOptions.reload();

      $scope.view = onlineUser => $state.go('online-user-view', { id: onlineUser.id });
    }
  ]);
