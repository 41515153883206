angular
  .module('relcore.inmate')
  .directive('inmateEdit', () =>
    ({
      restrict: 'E',
      scope: {
        'inmate': '=',
        'onSave': '=',
        'onReassignAni': '=',
        'reassigningAni': '='
      },
      template: require('./inmate-edit.html').default,
      controller: 'InmateEditController'
    })
  )
  .controller('InmateEditController', ['$scope', 'moment', function($scope, moment) {
    $scope.newInmate = Object.assign({}, $scope.inmate, { newAccount: $scope.inmate.account });
    if ($scope.newInmate.dateOfBirth != null) {
      $scope.newInmate.dateOfBirth = moment($scope.newInmate.dateOfBirth.date);
    }
    $scope.save = () => $scope.onSave($scope.newInmate);
    $scope.reassignAni = $scope.onReassignAni;
  }
]);
