import { RatePlan } from '@reliance/types/models/ServiceRate';
import React from 'react';
import { formatCurrency } from '../format/Currency';
import BaseHtmlTable from '../table/BaseHtmlTable';

type PlanSummaryProps = {
  ratePlan: RatePlan;
  onEdit(): any;
}

const PlanSummary: React.FC<PlanSummaryProps> = (props) => {
  const { ratePlan } = props;

  const rateColumns = ratePlan.rates.map(r => ({
    key: r.id,
    name: r.serviceType + (r.direction ? ` (${r.direction})` : '')
  }));
  const rateRows = [
    ratePlan.rates.reduce((prev, cur) => {
      prev[cur.id] = formatCurrency(cur.rate);
      return prev;
    }, {})
  ];

  const facilityColumns = [
    { key: 'name', name: 'Name' },
    { key: 'city', name: 'City' },
    { key: 'state', name: 'State' }
  ];
  const facilityRows = ratePlan.facilityServiceRatePlans.map(fRP => ({
    ...fRP.facility
  }));

  return (
    <div className="jarviswidget jarviswidget-color-blueDark mb-3">
      <div className="row">
        <div className="col-md-12">
          <h2>
            {/* Rate plan editing is not supported as it'll break the date range reporting logic
            <i className="fas fa-pen" onClick={() => props.onEdit()} style={{ cursor: 'pointer' }} title="Modify"></i> &nbsp; */}
            {ratePlan.name}
          </h2>

          <BaseHtmlTable
            columns={rateColumns}
            rows={rateRows}
            baseKey='rate-plan-rate'
            showPagination={false}
            />

          {facilityRows.length > 0 &&
            <div>
              <h5>Facilities Assigned</h5>
              <BaseHtmlTable
                columns={facilityColumns}
                rows={facilityRows}
                baseKey='rate-plan-facility'
                showPagination={false}
                />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default PlanSummary;
