import { IDateTimeObject } from './datetime';

export enum CallType {
  LOCAL = 1,
  IN_STATE = 2,
  INTERNATIONAL = 3,
  OUT_OF_STATE = 6,
  GLOBAL = 7
}

export enum BillingCategory {
  NONE = 'none',
  FREE = 'free',
  DIRECTBILL_AUDIO_CALL = 'directbill-call',
  CARD_ACCOUNT_CALL = 'card-account-call',
  WALLET_AUDIO_CALL = 'wallet-audio-call',
  WALLET_VIDEO_CALL = 'wallet-video-call'
}
export interface ICdr {
  id: string
  setupDate: IDateTimeObject
  endDate: IDateTimeObject
  durationSeconds: number
  cost: number
  usage: number
  phoneCard: string
  source: string
  sourceName: string
  destination: string
  destinationName: string
  originatingPhoneNumber: string
  category: string
  billingCategory: BillingCategory
  inmateName: string
  completion: string
  terminationReason: string
  private: boolean
  cloudProviderId: string
  cloudBucketId: string
  cloudBucketName: string
  cloudRecordingUrl: string
  callLog: string
  inboundChannel: string
  outboundChannel: string
}