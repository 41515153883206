angular
  .module('relcore.report')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('report-employee-call-summary', {
        template: require('./call-summary.html').default,
        url: '/report/employee/call-summary',
        parent: 'authenticated',
        controller: 'EmployeeCallSummaryController',
        ncyBreadcrumb: {
          label: 'Call Summary'
        }
      }
    )

    ])
  .controller('EmployeeCallSummaryController',
    ['$rootScope', '$scope', '$location', '$uibModal', '$filter', 'moment', 'EmployeeActivityService', 'NgTableParams',
      function($rootScope, $scope, $location, $uibModal, $filter, moment, EmployeeActivityService, NgTableParams) {
        let updateActivity;
        $scope.filters = {
          user: null
        };

        const processResults = function(data) {
          let date;
          let v;
          const results = {};
          const dateGrouped = $filter('groupBy')(data, 'date');
          for (date in dateGrouped) {
            const stats = dateGrouped[date];
            const outbound = stats.filter(s => s.taskType === 'call_outbound');
            const inbound = stats.filter(s => s.taskType === 'call_inbound');
            const internal = stats.filter(s => s.taskType === 'call_internal');
            const payments = stats.filter(s => s.taskType === 'directbill_ReceivePayments');
            const ccPayments = payments.filter(s => s.subTaskType === 'Credit Card Payment');
            const allCalls = outbound.concat(inbound).concat(outbound);
            const breaks = stats.filter(s => s.taskType === 'break');

            results[date] = {};
            results[date].date = moment(date);
            results[date].firstCall = allCalls.map(s => s.firstDate).reduce((function(prev, cur) {
              if ((prev == null) || (moment(cur) < moment(prev))) {
                return cur;
              } else {
                return prev;
              }
              }), null);
            results[date].lastCall = allCalls.map(s => s.lastEndDate).reduce((function(prev, cur) {
              if ((prev == null) || (moment(cur) > moment(prev))) {
                return cur;
              } else {
                return prev;
              }
              }), null);
            results[date].maxCallIn = parseFloat(inbound.map(s => s.maxDuration).reduce(((p,c) => c), 0));
            results[date].maxCallOut = parseFloat(outbound.map(s => s.maxDuration).reduce(((p,c) => c), 0));
            results[date].outboundCount = parseInt(outbound.map(s => s.total).reduce(((p,c) => c), 0));
            results[date].inboundCount = parseInt(inbound.map(s => s.total).reduce(((p,c) => c), 0));
            results[date].bothCount = results[date].outboundCount + results[date].inboundCount;
            results[date].internalInboundCount = parseInt(internal.filter(s => s.subTaskType==='inbound').map(s => s.total).reduce(((p,c) => c), 0));
            results[date].internalOutboundCount = parseInt(internal.filter(s => s.subTaskType==='outbound').map(s => s.total).reduce(((p,c) => c), 0));
            results[date].outboundDuration = parseInt(outbound.map(s => s.duration).reduce(((p,c) => c), 0));
            results[date].inboundDuration = parseInt(inbound.map(s => s.duration).reduce(((p,c) => c), 0));
            results[date].bothDuration = results[date].outboundDuration + results[date].inboundDuration;
            results[date].internalDuration = parseFloat(internal.map(s => s.duration).reduce(((p,c) => c), 0));
            results[date].outboundAvgDuration = parseFloat(outbound.map(s => s.avgDuration).reduce(((p,c) => c), 0));
            results[date].inboundAvgDuration = parseFloat(inbound.map(s => s.avgDuration).reduce(((p,c) => c), 0));
            results[date].internalAvgDuration = parseFloat(internal.map(s => s.avgDuration).reduce(((p,c) => c), 0));
            results[date].ccPaymentsCount = parseInt(ccPayments.map(s => s.total).reduce(((p,c) => c), 0));
            results[date].ccPaymentsAmount = parseFloat(ccPayments.map(s => s.amount).reduce(((p,c) => c), 0));

            let timeBetweenFirstAndLast = moment(results[date].lastCall).diff(results[date].firstCall, 'seconds');

            // Generate a list of breaks along with total time for breaks
            results[date].breaks = breaks
              .map(s => moment(s.firstDate).format('H:mm') + '-' + moment(s.lastEndDate).format('H:mm'));
            results[date].breakTime = breaks.map(s => parseInt(s.duration)).reduce(((p,c) => p+c), 0);
            if ((results[date].breakTime === 0) && (timeBetweenFirstAndLast > (4.5 * 60 * 60))) {
              results[date].breakTime = 60*60;
            }

            timeBetweenFirstAndLast -= results[date].breakTime;

            // Calculate percentage on the phone. Take time between first and last call of the day, remove lunch/break duration, divide total call durations
            const timeOnCalls = results[date].outboundDuration + results[date].inboundDuration;
            console.log("Time on calls: ", timeOnCalls);
            console.log("Time in office (minus break): ", timeBetweenFirstAndLast);
            results[date].percentOnPhone = $filter('number')((timeOnCalls / timeBetweenFirstAndLast) * 100, 2) + '%';
          }

          const values = ((() => {
            const result = [];
            for (let k in results) {
              v = results[k];
              result.push(v);
            }
            return result;
          })()).sort(r => r.date); // Return just the values

          data = {
            rows: values,
            totalOutboundCount: 0,
            totalInboundCount: 0,
            totalInternalInboundCount: 0,
            totalInternalOutboundCount: 0,
            totalOutboundDuration: 0,
            totalInboundDuration: 0,
            totalInternalDuration: 0,
            totalCcPaymentsCount: 0,
            totalCcPaymentsAmount: 0
          };
          for (v of Array.from(values)) {
            data.totalOutboundCount += v.outboundCount;
            data.totalInboundCount += v.inboundCount;
            data.totalInternalInboundCount += v.internalInboundCount;
            data.totalInternalOutboundCount += v.internalOutboundCount;
            data.totalOutboundDuration += v.outboundDuration;
            data.totalInboundDuration += v.inboundDuration;
            data.totalInternalDuration += v.internalDuration;
            data.totalCcPaymentsCount += parseInt(v.ccPaymentsCount);
            data.totalCcPaymentsAmount += parseFloat(v.ccPaymentsAmount);
          }

          return data;
        };

        $scope.summaryGridOptions = new NgTableParams({
            sorting: {
              'date': 'asc'
            }
          }, {
            total: 0,
            getData(params) {
              console.log("summaryGridOptions.reload()");

              if ($scope.loading) { // This is being refreshed because the staff member changed
                return EmployeeActivityService.getSummaryByUser($scope.user.id,
                  moment($scope.startDate + ' 00:00:00'),
                  moment($scope.endDate + ' 23:59:59'), 'day')
                  .then((function(response) {
                    params.total(response.data.results.count);
                    const results = processResults(response.data.results);
                    $scope.results = results;
                    $scope.activity = results.rows;
                    $scope.loading = false;
                    return results.rows;
                  }), (reason => []));
              } else if (params.sorting()) {
                $scope.activity = $filter('orderBy')($scope.activity, params.orderBy());
                return $scope.activity;
              }
            }
          });
        $scope.summaryGridOptions.settings().$scope = $scope;

        $rootScope.title = 'Employee Call Summary';
        $scope.activity = [];
        $scope.user = null;
        $scope.users = [];
        $scope.loading = false;
        $scope.heightMultiplier = 5;
        $scope.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        $scope.endDate = moment().format('YYYY-MM-DD');

        // Retrieve the employees
        EmployeeActivityService.getUsers()
          .then((response => $scope.users = $filter('orderBy')(response.data.results, u => u.firstName+u.lastName)), (function(reason) {
            // TODO
          }));

        $scope.refreshActivity = function(user) {
          if ((user == null)) { return; }
          $scope.user = user;
          $scope.loading = true;
          updateActivity();
        };

        // $scope.$watch 'startDate', -> $scope.refreshActivity $scope.user
        // $scope.$watch 'endDate', -> $scope.refreshActivity $scope.user
        // $scope.$watch 'endDate', (newVal) -> $scope.refreshActivity $scope.user

        updateActivity = function() {
          console.log('updateActivity()');
          if (($scope.user == null)) { return; }
          $scope.summaryGridOptions.reload();
        };
      }
  ]);
