import { IConfiguration } from "./configuration";
import { IApiResponse, IStatusResponse } from "../common/reliance-api-service";

export interface IConfigurationService {
  getByFacility(id: number): Promise<IApiResponse<IConfiguration[]>>
  getById(id: number): Promise<IApiResponse<IConfiguration>>
  postNewConfiguration(facilityId: number, key: string, value: string): Promise<IApiResponse<IStatusResponse>>
  updateConfiguration(id: number, value: object): Promise<IApiResponse<IStatusResponse>>
  deleteConfiguration(id: number): Promise<IApiResponse<IStatusResponse>>

}

angular
  .module('relcore.configuration')
  .service('configurationService',
    ['$http', '$q', 'CacheFactory', 'relianceApi', 'Configuration', 'config',
    function($http, $q, CacheFactory, relianceApi, ConfigurationModel, config) {

      const getByFacility = id =>
        relianceApi.get(`/configuration?facilityId=${id}`)
          .then(function(results) {
            const { data } = results;
            const configurations = [];
            for (let configuration of Array.from(data)) {
              configurations.push(new ConfigurationModel(configuration));
              data.results = configuration;
            }
            return data;
        })
      ;

      const getById = id =>
        relianceApi.get(`/configuration/${id}`)
          .then(result => new ConfigurationModel(result.data))
      ;

      const postNewConfiguration = (facilityId, key, value) =>
        relianceApi.post("/configuration", {
          facilityId,
          key,
          value
        })
      ;

      const updateConfiguration = (id, changes) => relianceApi.put(`/configuration/${id}`, changes);

      const deleteConfiguration = id => relianceApi.delete(`/configuration/${id}`);

      return {
        getByFacility,
        getById,
        postNewConfiguration,
        updateConfiguration,
        deleteConfiguration
      };
    }
  ]);