angular
  .module('relcore.inmate-device-blacklist')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('inmate-device-blacklist', {
        controller: 'InmateDeviceBlacklistListViewController',
        template: require('./list.html').default,
        url: '/inmates/blacklist',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Inmate Device Blacklist',
          parent: 'inmates'
        }
      })
  ])
  .controller('InmateDeviceBlacklistListViewController', [
    '$rootScope', '$scope', '$filter', '$state', '$stateParams', 'NgTableParams', 'inmateDeviceBlacklistService',
    function($rootScope, $scope, $filter, $state, $stateParams, NgTableParams, inmateDeviceBlacklistService) {
      $rootScope.title = "Inmate Device Blacklist";

      $scope.gridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            inmateLastName: 'asc',
            inmateFirstName: 'asc'
          },
          filter: {
            dateRemoved: null
          }
        }, {
          counts: [100,500,1000],
          getData(params) {
           return inmateDeviceBlacklistService.index(params.filter(), params.orderBy())
              .then(function(result) {
                params.total(result.data.total);
                $scope.selected = [];
                return result.data.data;
            });
          }
      });

      $scope.remove = entry =>
        inmateDeviceBlacklistService.delete(entry.id)
          .then(() => $scope.gridOptions.reload())
      ;
    }
  ]);
