import * as validation from '@reliance/forms-js/lib/validation';
import React from 'react';
import { Input } from '../../form/render';

export const CheckPaymentForm: React.FC = () =>
  <div>
    <div className="row">
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.bankName"
          label="Bank Name" />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.checkNumber"
          label="Check Number"
          validationRules={[
            validation.required
          ]} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.routingNumber"
          label="Routing Number" />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.accountNumber"
          label="Account Number" />
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <Input
          name="paymentMethod.info.idType"
          label="ID Type" />
      </div>
      <div className="col-md-5">
        <Input
          name="paymentMethod.info.idNumber"
          label="ID Number" />
      </div>
      <div className="col-md-3">
        <Input
          name="paymentMethod.info.idState"
          label="ID State" />
      </div>
    </div>
  </div>