require('angular');
require('bootstrap');
require('angular-ui-router');
require('angular-cookies');
require('ui-bootstrap4');
require('angular-cache');
require('angular-breadcrumbs');
require('ng-table');
require('angular-moment');
require('angular-filter');
require('checklist-model');
require('angular-chart.js'); // TODO: codesplit this so it can be loaded on demand instead of part of the big bundle
require('bootstrap-daterangepicker');
require('angular-daterangepicker');
require('ng-selectable');
require('datatables');
require('angular-datatables');
require('../../node_modules/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min');
require('../../node_modules/angular-datatables/dist/plugins/columnfilter/angular-datatables.columnfilter');

// Smart admin dependencies
require('smart-notifications');
require('jquery-validate');
require('bootstrap-slider');
