import { TextMessage } from '@reliance/types/models/TextMessage';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

type TextImageModalProps = {
  textMessage: TextMessage
  onClose(): void
}

const TextImageModal: React.FC<TextImageModalProps> = (props) => {
  return (
    <Modal show={true} onHide={props.onClose} fullscreen={true} dialogClassName="modal-full">
      <Modal.Header closeButton />
      <Modal.Body>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflowY: 'visible' }}>
          <img
            src={props.textMessage.attachmentUrl}
            style={{
              maxWidth: '100%',
              maxHeight: 1080,
              objectFit: 'contain'
            }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TextImageModal;