angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketInboundCreateViewController', [
    '$rootScope', '$scope', '$state', '$filter', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams', 'moment',
    function($rootScope, $scope, $state, $filter, relianceApi, alertService, $stateParams, NgTableParams, moment) {
      $scope.title = ($rootScope.title = 'Create Return Ticket');
      $scope.devices = [];

      const loadDevices = () =>
        relianceApi
          .get(`/mobile-device?facilityId=${$scope.facility.id}&perPage=1000`)
          .then(res => res.data.data)
          .then(devices => devices.filter(d => d.status !== 'Needs Return'))
          .then(devices => devices.map(function(d) {
            d.lastContact = moment(d.lastContact.date);
            return d;
          }) )
          .then(function(devices) {
            $scope.devices = devices;
            $scope.facilityDeviceGridOptions.reload();
          })
      ;

      $scope.facilityDeviceGridOptions = new NgTableParams({
        page: 1,
        count: 10
      }, {
        counts: [10,20,50,100],
        getData(params) {
          let { devices } = $scope;
          devices = $filter('filter')(devices, params.filter());
          devices = $filter('orderBy')(devices, params.orderBy());
          params.total(devices.length);

          return devices.slice((params.page()-1)*params.count(),
            params.page() * params.count());
        }
      });

      $scope.returnDeviceGridOptions = new NgTableParams({
        page: 1,
      }, {
        counts: null,
        getData(params) { return $scope.returnDevices; }
      });

      $scope.returnDevices = [];

      $scope.ticket = {
        facilityId: '',
        facilityName: '',
        facilityAddress: '',
        attention: '',
        phoneNumber: '',
        shippingMethod: '',
        countyRequest: ''
      };
      $scope.notes = { note: '' };

      const setFacility = function(facility) {
        $scope.facility = facility;
        $scope.ticket.facilityId = $scope.facility.id;
        $scope.ticket.facilityName = $scope.facility.name;
        $scope.ticket.facilityAddress = `${$scope.facility.address}\n${$scope.facility.city}, ${$scope.facility.state} ${$scope.facility.zip}`;
      };

      if (($scope.facility == null)) {
        relianceApi
          .get(`/facility/${$stateParams.facilityId}`)
          .then(res => setFacility(res.data))
          .then(() => loadDevices());
      } else {
        setFacility($scope.facility);
        loadDevices();
      }

      $scope.saving = false;
      $scope.save = function() {
        $scope.saving = true;
        $scope.ticket.notes = $scope.notes.note;
        $scope.returnDeviceIds = $scope.returnDevices.map(d => d.id).join(',');
        relianceApi.post("/shipping-ticket/return", {
          ticket: $scope.ticket,
          returnDeviceIds: $scope.returnDevices.map(d => d.id).join(',')
          })
          .then((function(res) {
            $scope.ticket.id = res.data.shippingTicketId;
            $scope.ticket.notes = '[]';
            $state.go('shipping-ticket-inbound-view', {
              ticket: $scope.ticket,
              id: $scope.ticket.id
            });
          }), (function() {
            $scope.saving = false;
            alertService.error('An error occurred while creating the ticket', true);
          }));
      };
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('shipping-ticket-inbound-create', {
        controller: 'ShippingTicketInboundCreateViewController',
        url: '/shipping-ticket/inbound/create/:facilityId',
        parent: 'authenticated',
        params: { facility: null
      },
        ncyBreadcrumb: {
          label: 'Create Return Ticket',
          parent: 'shipping-tickets-inbound'
        },
        template: require('./inbound-create.html').default
      }
    )

  ]);
