angular
  .module('relcore.online-user')
  .directive('onlineUserSearchResults', () =>
    ({
      restrict: 'E',
      scope: {
        showFilter: '=',
        gridOptions: '=',
        onViewDetails: '='
      },
      controller: ['$scope', function($scope) {
        $scope.showFilter = $scope.showFilter === null ? true : $scope.showFilter === true;
      }],
      template: require('./online-user-search-results.html').default
    })
  );
