angular
  .module('relcore.report')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('video-call-cost-recovery-report', {
        controller: 'VideoCallCostRecoveryReportController',
        url: '/report/video-call/cost-recovery',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Video Call Cost Recovery Report',
          parent: 'authenticated'
        },
        template: require('./cost-recovery.html').default
      })
  }])
  .controller('VideoCallCostRecoveryReportController',
    ['$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'moment',
    function($rootScope, $scope, relianceApi, NgTableParams, moment) {
      $rootScope.title = 'Video Call Cost Recovery Report';

      $scope.facilityIds = $rootScope.facilitiesToDisplay;
      $scope.facility = null;
      $scope.total = 0;
      $scope.totalSeconds = 0;
      $scope.costRecoveryFee = 0;
      $scope.dateRange = {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day')
      };
      $scope.loaded = false;

      $scope.gridOptions = new NgTableParams({
          sorting: {
            'facility.name': 'asc'
          }
        },
        {
          counts: [],
          getData: (params) => {
            if ($scope.dateRange.startDate == null || $scope.facilityIds == null) {
              return [];
            }

            let queryParams = {
              startDate: $scope.dateRange.startDate.format('YYYY/MM/DD 00:00:00'),
              endDate: $scope.dateRange.endDate.format('YYYY/MM/DD 23:59:59'),
              facilityIds: $scope.facilityIds
            };

            if ($scope.facilityIds) {
              queryParams.facilityIds = $scope.facilityIds;
            }

            return relianceApi.get("/report/video-call/cost-recovery", queryParams)
              .then((response) => {
                $scope.loaded = true;
                $scope.totalSeconds = response.data.totalSeconds;
                $scope.total = response.data.total;
                $scope.costRecoveryFee = response.data.costRecoveryFee;
                return response.data.results;
              });
            }
        }
      )

      $scope.run = () => $scope.gridOptions.reload();
      $scope.reset = () => $scope.loaded = false;
    }]);
