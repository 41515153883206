angular.module('relcore.mobile-device').directive('mobileDeviceProfile', () =>
  ({
    controllerAs: 'ctrl',
    bindToController: true,
    scope: {
      name: '=',
      data: '=',
      isSaving: '=',
      canDelete: '=',
      onSave: '&',
      onDelete: '&'
    },
    controller: ['$attrs', '$scope', function($attrs, $scope) {}

    ],
    template: require('./edit.html').default
  })
);
