angular
  .module('relcore.switch')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('switch-validations', {
        controller: 'ValidationController',
        template: require('./validation.html').default,
        url: '/switch/validation',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Validation Log'
        }
      }
    )
  ])
  .controller('ValidationController',
    ['$rootScope', '$scope',  'ValidationLogService', 'NgTableParams',
    function($rootScope, $scope, ValidationLogService, NgTableParams) {
      $rootScope.title = 'Validation Log';
      $scope.daterange = null;

      $scope.listGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            'created.date': 'desc'
          }
        }, {
          counts: [100,500,1000],
          total: 0,
          filterDelay: 1000,
          debugMode: true,
          getData(params) {
            // Wait until facilities are return ed
            if ($scope.facilities.length === 0) { return []; }

            return ValidationLogService.getAll(params.filter(), params.orderBy(), params.page(), params.count(), function(err, logs, total) {
              params.total(total);
              return logs;
            });
          }
        });
    }
  ]);
