angular
  .module('relcore.block')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('blocks', {
        controller: 'blockController',
        url: '/block',
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: {
          label: 'Block List'
        }
      }
    )
  ])
  .directive('blocksList', () => {
    return {
      restrict: 'E',
      scope: {
        facilityId: '@',
        ani: '@',
        onFetch: '&',
        onLoad: '&',
        onError: '&'
      },
      template: require('./list.html').default,
      controller: 'blockController'
    };
  })
  .controller('blockController',
    ['$scope', 'NgTableParams', 'blockService', 'eventService', 'moment',
    function($scope, NgTableParams, blockService, eventService:IEventService, moment) {
      $scope.blockTypes = [
        {id:0, name:"Override"},
        {id:1, name:"Collect Block"},
        {id:2, name:"Recording Block"},
        {id:3, name:"County Block"},
        {id:4, name:"Range Block"}
      ];

      if (typeof $scope.onFetch === 'undefined') {
        $scope.onFetch = (name: string) => {
          eventService.onFetch('Blocks');
        };
      }

      if (typeof $scope.onLoad === 'undefined') {
        $scope.onLoad = () => {
          eventService.onFetch('Blocks');
        };
      }

      if (typeof $scope.onError === 'undefined') {
        $scope.onError = () => {
          eventService.onError('Blocks');
        };
      }

      $scope.blockGridOptions = new NgTableParams({
        page: 1,
        count: 100,

        // Sort by ascending 'unblockDate' to show blocks with no unblock date first
        // Then sort by descending blockdate
        sorting: { 'unblockDate': 'asc', 'blockDate': 'desc'},
        filter: {
          blockDate: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
        },
          unblockDate: { startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')
        }
        }
      }, {
        counts: [100,500,1000],
        getData(params) {
          $scope.onFetch();
          if ($scope.ani != null) {
            return blockService.getByPhoneNumber($scope.ani, params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(data) {
                params.total(parseInt(data.total));
                return data.blocks;
            });
          } else {
            return blockService.getByFacility($scope.facilityId, params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(data) {
                params.total(parseInt(data.total));
                // Signal that fetching is complete
                $scope.onLoad();
                return data.blocks;
            },
            // Signal that error occurred
            () => {
              $scope.onError();
            });
          }
        }
      });
    }
  ]);
