import React from 'react';
import Card from 'react-bootstrap/esm/Card';

export const CPCChangelog: React.FC<void> = (props) => {
  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          1.8.2 (Built 2023/04/20)
        </Card.Header>
        <Card.Body>
          <ul>
            <li>Fixed inmate support read/unread statuses</li>
          </ul>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header>
          1.8.1 (Built 2023/03/22)
        </Card.Header>
        <Card.Body>
          <ul>
            <li>Added UI for managing inmate communication treatment</li>
            <li>Added UI for managing inmate subscriptions</li>
            <li>Treat non-processed support messages as unread, instead of queued</li>
            <li>Don't require facility dialing instructions when modifying a facility record</li>
            <li>Don't require facility billing info when modifying a facility record</li>
            <li>Converted facility edit features to use a list of checkboxes instead of a multi select</li>
            <li>Require localPath and category for reference materials</li>
            <li>Added translation management UI</li>
            <li>Support devices w/o lastContact populated. Added lastBooted tooltip to device details header</li>
            <li>Shortened inmate details' tab titles</li>
            <li>Added new permission for subscription_manage</li>
          </ul>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header>
          1.6.8 (Built 2022/02/11)
        </Card.Header>
        <Card.Body>
          <ul>
            <li>Added ability to create a device charger API key for a facility (facility page's device tab)</li>
            <li>Added ability to copy reference material between facilities</li>
            <li>Added staff user log page to view API activity by staff</li>
            <li>Force wordwrap on configuration table columns (facility page's configuration tab)</li>
            <li>Removed requirement of facility county when adding/editing</li>
          </ul>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header>
          1.6.3 (Built 2022/01/14)
        </Card.Header>
        <Card.Body>
          <ul>
            <li>Changelog page</li>
            <li>Staff user management</li>
            <li>Mobile device command UI improvements (more data inline without row expansion)</li>
            <li>Basic reference material upload functionality</li>
            <li>Text message alert management</li>
            <li>Facility configuration entries are now added/edited via a modal window instead of a standalone page</li>
            <li>Display device SIP from/to configuration on device details page</li>
            <li>Updated to Bootstrap 4 CSS library (small UI differences)</li>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};