angular
  .module('relcore.condition')
  .factory('Condition', ['moment', '$filter', function(moment, $filter) {
    return class Condition {
      id;
      created;
      ani;
      enteredBy;
      firstName;
      lastName;
      companyName;
      accountName;
      condition;
      description;
      facilityName;
      facilityState;
      facilityTitle;
      removedDescription;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        if (data == null) { data = {}; }
        this.id = data.id || null;
        if (data.created != null) { this.created = moment(data.created.date); }
        this.ani = data.ani || null;
        this.enteredBy = data.enteredBy || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.companyName = data.companyName || '';
        this.accountName = $filter('accountName')(this);
        this.condition = data.condition || '';
        this.description = data.description || '';
        this.facilityName = data.facilityName || '';
        this.facilityState = data.facilityState || '';
        this.facilityTitle = `${this.facilityName} ${this.facilityState}`;
        this.removedDescription = data.removedDescription || '';
        return this;
      }
    }
  }]);