import React, { useCallback, useState } from 'react';
import Accordion from 'react-bootstrap/esm/Accordion';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useDropzone } from 'react-dropzone';
import { Form } from 'react-final-form';
import StateGroupedCheckboxes, { toggleCheckAllForState } from '../facility/StateGroupedCheckboxes';
import { IFacility } from '../models/facility';

type UploadProps = {
  category: 'Reference Material'|'Commissary'
  facilities: IFacility[]
  onUpload(category: string, facilityIds: number[], formData: FormData): Promise<any>
}

const Upload: React.FC<UploadProps> = props => {
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState<FormData>(null);

  const onDrop = useCallback(acceptedFiles => {
    let i = 0;
    const newFormData = new FormData();
    acceptedFiles.map(file =>
      newFormData.append(`asset[${i++}]`, file)
    );
    setFormData(newFormData);
    setFiles(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, open} = useDropzone({onDrop});

  const facilitiesByState: {[state: string]: IFacility[]} = {};
  props.facilities.map(f => {
    if (facilitiesByState[f.state] == null) {
      facilitiesByState[f.state] = [];
    };
    facilitiesByState[f.state].push(f);
  })

  return (
    <Form
      initialValues={{
        category: props.category,
        facilityIds: {}
      }}
      onSubmit={data =>
        props.onUpload(
          data.name,
          Object.keys(data.facilityIds).filter(fId => data.facilityIds[fId]).map(id => Number(id)),
          formData
        )
      }
      mutators={{
        toggleCheckAllForState
      }}
      render={({ values, form }) => {
        // Retrieve facility IDs that are checked (value is true in list)
        const selectedFacilityIds = Object.keys(values.facilityIds).filter(fId => values.facilityIds[fId]);

        return (
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Accordion>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="facilities">
                        {selectedFacilityIds.length > 0 ? `${selectedFacilityIds.length} facilities selected` : 'Select facilities'}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="facilities">
                        <Card.Body>
                          <StateGroupedCheckboxes
                            fieldName={'asset.facilityIds'}
                            form={form}
                            facilities={props.facilities}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Select
                    name="category"
                    label="Category">
                    <option value="Reference Material">Reference Material</option>
                  </Select>
                </Col>
              </Row> */}
              <Row style={{marginTop: 10}}>
                <Col>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      variant="outline-secondary"
                      type="button"
                      onClick={open}>
                      Open File Dialog
                    </Button>
                  </div>
                  {files.map(f =>
                    <div key={`file-${f.name}`}>{f.name}</div>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" type="submit">
                Upload
              </Button>
            </Card.Footer>
          </Card>
        );
      }} />
  );
}

export default Upload;