const authModule = angular.module('relcore.auth', ['ui.router']);

authModule
  .config(['$stateProvider', '$httpProvider', function($stateProvider, $httpProvider) {
    $httpProvider.interceptors.push('httpInterceptor');
    $httpProvider.interceptors.push('apiTokenInterceptor');

    $stateProvider
      .state('anonymous', {
        template: require('../../tpl/layouts/anonymous.html').default
      })
      .state('authenticated', {
        template: require('../../tpl/layouts/authenticated.html').default,
        ncyBreadcrumb: { label: 'Home' }
      });
  }]);