angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('shipping-tickets-outbound', {
      controller: 'ShippingTicketsOutboundViewController',
      template: require('./outbound.html').default,
      url: '/shipping-ticket/outbound',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Outbound Tickets' }
    })
  ])
  .controller('ShippingTicketsOutboundViewController', [
    '$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, NgTableParams) {
      $rootScope.title = 'Outbound Tickets';
      $scope.adding = false;

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          dateCreated: 'desc'
        },
        filter: {
          type: 'Outbound',
          facilityIds: $rootScope.facilitiesToDisplay
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/shipping-ticket', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });

      $scope.onViewOutboundTicketDetails = ticket => $state.go('shipping-ticket-outbound-view', {id:ticket.id, ticket});
    }
  ]);
