import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import ConfirmModal from '../common/dialogs/ConfirmModal';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { ReferenceMaterial } from '../models/ReferenceMaterial';
import BaseHtmlTable from '../table/BaseHtmlTable';
import EditModal from './EditModal';

type TableProps = {
  referenceMaterials: IPaginatedResponse<ReferenceMaterial>
  selected: ReferenceMaterial[]
  onRemove(referenceMaterial: ReferenceMaterial): Promise<void>
  onEditSave(referenceMaterial: Partial<ReferenceMaterial>): Promise<void>
  onCheck(referenceMaterial: ReferenceMaterial): void
  onCheckAll(): void
}

const Table: React.FC<TableProps> = (props) => {
  const { referenceMaterials } = props;

  const [showRemove, setShowRemove] = useState<ReferenceMaterial>(null);
  const [showEdit, setShowEdit] = useState<ReferenceMaterial>(null);

  if (referenceMaterials == null) {
    return null;
  }

  const columns = [
    { key: 'facility', name: 'Facility' },
    { key: 'category', name: 'Category' },
    { key: 'name', name: 'Name' },
    // { key: 'mimeType', name: 'Type' },
    { key: 'file', name: 'File' },
    // { key: 'localPath', name: 'Local Path' },
    { key: 'sortWeight', name: 'Sort Weight' },
    { key: 'options', name: (
      <>
        <Form.Check onChange={() => props.onCheckAll()} inline /> Options
      </>
    ) }
  ];

  // Massage the reference material into applicable/usable columns
  const rows = referenceMaterials.data.map(material => ({
    id: material.id,
    facility: material.facility ? `${material.facility.name} (${material.facility.city}, ${material.facility.state})` : 'All',
    category: material.category,
    name: material.name,
    file: (
      material.localPath ?
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`reference-material-tooltip-${material.id}`}>
              {material.asset ?
                <span>Local path: {material.localPath}</span> :
                <span>Not uploaded to the cloud</span>
              }
            </Tooltip>
          }>
          <span>{(material.asset||{}).name || material.localPath}</span>
        </OverlayTrigger> :
        <span>{(material.asset||{}).name}</span>
    ),
    // mimeType: (material.asset||{}).mimeType,
    // localPath: material.localPath,
    sortWeight: material.sortWeight,
    options: (
      <div>
        <Form.Check
          onChange={() => props.onCheck(material)}
          checked={props.selected.indexOf(material) > -1}
          inline />
        <Button onClick={() => setShowRemove(material)} size="sm" variant="outline-danger">Remove</Button>
        <Button onClick={() => setShowEdit(material)} size="sm" variant="outline-info" className="ml-2">Edit</Button>
        {material.url &&
          <Button
            href={material.url}
            target="_blank"
            size="sm"
            variant="outline-info"
            className="ml-2">
            View
          </Button>
        }
        <ConfirmModal
          key={`remove-reference-material-${material.id}`}
          show={showRemove == material}
          title="Remove Reference Material"
          acceptText="Remove"
          onAccept={() => props.onRemove(material)}
          onClose={() => setShowRemove(null)}>
          Are you sure you want to remove "{material.name}"?
        </ConfirmModal>
        {showEdit == material &&
          <EditModal
            referenceMaterial={material}
            onSave={props.onEditSave}
            onClose={() => setShowEdit(null)}
          />
        }
      </div>
    )
  }));

  return (
    <BaseHtmlTable
      baseKey="reference-material"
      columns={columns}
      rows={rows}
      />
  );
}

export default Table;