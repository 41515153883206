angular
  .module('relcore.configuration-text')
  .controller('ConfigurationTextListController', ['$scope', ($scope: any) => {
    $scope.showColumn = (column: string) => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
  }])
  .directive('configurationTexts', () => ({
    restrict: 'E',
    scope: {
      gridOptions: '=',
      columns: '=?',
      onDelete: '=?',
      onEdit: '=?'
    },
    controller: 'ConfigurationTextListController',
    template: require('./list.html').default
  }));
