angular
  .module('relcore.filters')
  .filter('transactionSubType', () =>
    function(id: number): string {
      switch(id) {
        case 0:
          return 'Non-Invoiced Charge';
        case 1:
          return 'Credit Card Payment';
        case 2:
          return 'Invoice';
        case 3:
          return 'Money Transfer';
        case 4:
          return 'Check Payment';
        case 5:
          return 'Internal Debit';
        case 6:
          return 'Internal Credit';
        case 7:
          return 'Cash Payment';
        case 10:
          return 'Money Order';
        case 11:
          return 'Refund';
        case 12:
          return 'Online Credit Card Payment';
        case 13:
          return 'Reconnection Fee';
        case 14:
          return 'Reconnection Fee Refund';
        case 15:
          return 'Transaction Fee';
        case 16:
          return 'IVR Credit Card Payment';
        default: return '';
      }
    }
);