import { IAlertService } from '../../alert/alert-service';
import { IApiResponse, IRelianceApi } from '../../common/reliance-api-service';

interface IInmateBalanceAuditResult {
  id: number
  balance: number
  calculatedBalance: number
}

angular
  .module('relcore.report')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('inmate-balance-audit-report', {
        controller: 'InmateBalanceAuditController',
        url: '/report/inmate/balance-audit',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Inmate Balance Audit Report',
          parent: 'authenticated'
        },
        template: require('./balance-audit.html').default
      }
    )
  ])
  .controller('InmateBalanceAuditController',
    ['$rootScope', '$scope', 'relianceApi', 'alertService',
    function($rootScope, $scope, relianceApi: IRelianceApi, alertService:IAlertService) {
      $rootScope.title = 'Inmate Balance Audit Report';

      $scope.loading = true;
      $scope.refresh = () =>
        relianceApi.get("/inmate-account/balance-audit")
          .then(function(response:IApiResponse<IInmateBalanceAuditResult>) {
            $scope.loading = false;
            $scope.results = response.data;
          });
      $scope.refresh();

      $scope.selectAllInmates = false;
      $scope.toggleSelectAllInmates = () => {
        $scope.selectedInmates.splice(0, $scope.selectedInmates.length);

        if ($scope.selectAllInmates) {
          $scope.results.map(d => $scope.selectedInmates.push(d.id));
        }
      }

      $scope.selectedInmates = [];
      $scope.selectedInmatesChanged = () =>
        $scope.selectAllInmates = $scope.selectedInmates.length == $scope.results.length

      $scope.recalculating = false;
      $scope.recalculateBalances = () => {
        $scope.recalculating = true;
        relianceApi.post<IApiResponse<any>>('/inmate-account/recalculate-balance', {
          inmateIds: $scope.selectedInmates.join(',')
        })
        .then(() => alertService.success('The inmate balance(s) have been recalculated'))
        .then(() => $scope.recalculating = false)
        .then($scope.refresh)
        .catch(() => alertService.error('An error occurred while recalculating the balance(s)'));
      };
  }]);
