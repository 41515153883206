angular
  .module('relcore.commissary-transfer')
  .directive('commissaryTransfers', () =>
    ({
      restrict: 'E',
      scope: {
        columns: '=?',
        showFilter: '=',
        gridOptions: '=',
        facilities: '=',
        onViewDetails: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showFilters = ($scope.showFilter == null) || ($scope.showFilter === true);
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
        $scope.columnCount = function() {
          if (($scope.columns == null)) { return 8; }
          return $scope.columns.length;
        };
        $scope.total = () =>
          $scope.gridOptions.data.reduce(((prev, cur) => prev + parseFloat(cur.activity.amount)), 0)
        ;
      }
      ],
      template: require('./commissary-transfers.html').default
    })
  );
