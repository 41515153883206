angular
  .module('relcore.home')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('relcore', {
        controller: 'HomeViewController',
        template: require('./home.html').default,
        url: '/',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Relcore'
        }
      }
    )

  ])
  .controller('HomeViewController',
    ['$rootScope', '$scope', 'store', 'serverActions', 'ReduxMapToScope',
    function($rootScope, $scope, store, serverActions, ReduxMapToScope) {
      $rootScope.title = "Relcore Dashboard";
      $scope.graphCountPerFacility = {
        labels: [],
        series: ['Outbound', 'Inbound'],
        data: []
      };

      ReduxMapToScope($scope, function(state) {
        $scope.recentTexts = state.dashboard.recentTexts;

        const data = [[],[]];
        for (let k in state.dashboard.textsCountByFacility) {
          const v = state.dashboard.textsCountByFacility[k];
          data[0].push(v.Outbound);
          data[1].push(v.Inbound);
        }

        $scope.graphCountPerFacility = {
          labels: (() => {
            const result = [];
            for (let key in state.dashboard.textsCountByFacility) {
              result.push(key);
            }
            return result;
          })(),
          data,
          series: $scope.graphCountPerFacility.series
        };
      });

      store.dispatch(serverActions.subscribe('text-message/activity'));
      $scope.$on('$destroy', () => store.dispatch(serverActions.unsubscribe('text-message/activity')));
    }
  ]);
