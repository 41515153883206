angular
  .module('relcore.directives')
  .directive('languageSelection', ['languages', languages =>
    ({
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        model: '=',
        onChange: '=?'
      },
      template: require('./languages-directive.html').default,
      link: (scope:any) => {
        scope.languages = languages;
      }
    })
  ]);
