import { IConfig } from '../common/config';

angular
  .module('relcore.mobile-device')
  .service('mobileDeviceService', ['relianceApi', '$filter', 'config',
    function(relianceApi, $filter, config:IConfig) {
      return {
        retrieveAll(page, perPage, filter, orderBy) {
          if (page == null) { page = 1; }
          if (perPage == null) { perPage = 100; }
          if (filter == null) { filter = {}; }
          return relianceApi.index("/mobile-device", page, perPage, filter, orderBy)
            .then(function(response) {
              const results = response.data;
              let deviceData:any;
              for (deviceData of Array.from(results.data)) {
                if (deviceData.facility != null) {
                  deviceData.facilityTitle = $filter('facilityTitle')(deviceData.facility);
                }
                if (deviceData.appAssignments != null) {
                  deviceData.installedMessagingAppVersion = deviceData.appAssignments[config.appBundleIds.messaging] != null ? deviceData.appAssignments[config.appBundleIds.messaging].installedAppVersion : undefined;
                  deviceData.assignedMessagingAppVersion = deviceData.appAssignments[config.appBundleIds.messaging] != null ? deviceData.appAssignments[config.appBundleIds.messaging].assignedAppVersion : undefined;
                  deviceData.installedKioskAppVersion = deviceData.appAssignments[config.appBundleIds.kiosk] != null ? deviceData.appAssignments[config.appBundleIds.kiosk].installedAppVersion : undefined;
                  deviceData.assignedKioskAppVersion = deviceData.appAssignments[config.appBundleIds.kiosk] != null ? deviceData.appAssignments[config.appBundleIds.kiosk].assignedAppVersion : undefined;
                  deviceData.installedAdminAppVersion = deviceData.appAssignments[config.appBundleIds.admin] != null ? deviceData.appAssignments[config.appBundleIds.admin].installedAppVersion : undefined;
                  deviceData.assignedAdminAppVersion = deviceData.appAssignments[config.appBundleIds.admin] != null ? deviceData.appAssignments[config.appBundleIds.admin].assignedAppVersion : undefined;
                  deviceData.installedInfoAppVersion = deviceData.appAssignments[config.appBundleIds.info] != null ? deviceData.appAssignments[config.appBundleIds.info].installedAppVersion : undefined;
                  deviceData.assignedInfoAppVersion = deviceData.appAssignments[config.appBundleIds.info] != null ? deviceData.appAssignments[config.appBundleIds.info].assignedAppVersion : undefined;
                }
              }
              return results;
          });
        },

        getById(id) {
          return relianceApi.get(`/mobile-device/${id}`)
            .then(function(result) {
              const { data } = result.data;
              if (data.facility != null) {
                data.facilityTitle = $filter('facilityTitle')(data.facility);
              }
              if (data.appLogLevels.OS == null) { data.appLogLevels.OS = 'Warning'; }
              if (data.appLogLevels.Logstash == null) { data.appLogLevels.Logstash = 'Error'; }
              if (data.appLogLevels.Crashlytics == null) { data.appLogLevels.Crashlytics = 'Verbose'; }
              return data;
          });
        },

        getUsage(filter, orderBy){
          if (filter == null) { filter = {}; }
          if (orderBy == null) { orderBy = ''; }
          const params = {orderBy: null};
          for (let i in filter) {
            if (filter[i] != null) { params[i] = filter[i]; }
          }

          if (orderBy != null) {
            params.orderBy = orderBy;
          }

          return relianceApi.get("/mobile-device/usage", params);
        },

        update(ids, changes) {
          return relianceApi.put(`/mobile-device/${ids.join()}`, changes);
        },

        updateApps(ids) {
          return relianceApi.put(`/mobile-device/update/${ids.join()}`);
        },

        pushConfig(id) {
          return relianceApi.put(`/mobile-device/push-config/${id}`);
        },

        updateAppLogLevels(id, appLogLevels) {
          return relianceApi.put(`/mobile-device/${id}/app-log-levels`, { appLogLevels });
        },

        restart(id) {
          return relianceApi.put(`/mobile-device/${id}/restart`);
        },

        shutdown(id) {
          return relianceApi.put("/mobile-device/#{id}/shutdown");
        },

        updateOs(id) {
          return relianceApi.put(`/mobile-device/${id}/os-update`);
        },

        poll(id) {
          return relianceApi.put(`/mobile-device/poll/${id}`);
        },

        resetAppLock(id) {
          return relianceApi.put(`/mobile-device/${id}/reset-app-lock`);
        },

        cancelPendingCommands(id) {
          return relianceApi.post(`/mobile-device/${id}/cancel-pending-commands`);
        },

        cancelAppUpdates(id) {
          return relianceApi.post(`/mobile-device/${id}/cancel-app-updates`);
        },

        syncInfo(id) {
          return relianceApi.post(`/mobile-device/${id}/sync-info`);
        }
      };
    }]);
