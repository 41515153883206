angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceProfileAssignments', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        disableMobileDeviceProfile: '=',
        enableMobileDeviceProfile: '='
      },
      template: require('./mobile-device-profile-assignments.html').default
    })
  );
