import { OnlineUser } from '@reliance/types/models/OnlineUser';
import React from 'react';
import { react2angular } from 'react2angular';
import { formatCityStateZip } from '../../format/CityStateZip';
import { formatPhoneNumber } from '../../format/PhoneNumber';

type InformationCardProps = {
  onlineUser: OnlineUser
}

export const InformationCard: React.FC<InformationCardProps> = (props) =>
  <section className="card">
    <div className="card-body">
      <div className="row">
        <div className="col-md-6">
          <address>
            <strong>{props.onlineUser.firstName} {props.onlineUser.lastName}
              <br/>
            </strong>
            {props.onlineUser.address}
            <br/>
            {props.onlineUser.address2 &&
              <span>{props.onlineUser.address2}<br/></span>
            }
            {formatCityStateZip(props.onlineUser)}
          </address>
        </div>
        <div className="col-md-6">
          <div>
            <a href={`/account/${props.onlineUser.phoneNumber}`}>{formatPhoneNumber(props.onlineUser.phoneNumber)}</a>
          </div>
          {props.onlineUser.phoneNumber2 &&
            <div>
              <a href={`/account/${props.onlineUser.phoneNumber2}`}>{formatPhoneNumber(props.onlineUser.phoneNumber2)}</a>
            </div>
          }
          <div>
            {props.onlineUser.emailAddress}&nbsp;
            {props.onlineUser.activated ?
              <i className="fas fa-check-circle icon-success" title="email verified" /> :
              <i className="fas fa-question-circle icon-danger" title="email not verified" />
            }
          </div>
        </div>
      </div>
    </div>
  </section>

angular
  .module('relcore.online-user')
  .component('onlineUserInformationCard', react2angular(InformationCard, ['onlineUser']));