angular
  .module('relcore.inmate')
  .directive('supportMessages', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        inmateId: '=',
        refunding: '=',
        onRefund: '='
      },
      controller: ['$scope', function($scope) {
        $scope.refundActivity = {
          activityId: null,
          notifyInmate: false,
          description: 'Support message refund'
        };
        if($scope.onRefund) {
          $scope.refund = function(data) {
            $scope.refundActivity.activityId = data.activity[0].id;
            return $scope.onRefund($scope.refundActivity);
          };
        }
        $scope.refundable = function(data) {
          return data.activity.length != 0
            && !data.activity[0].reversed
            && data.activity[0].amount != 0.00
            && $scope.onRefund;
        };
      }
      ],
      template: require('./support-messages.html').default
    })
  );