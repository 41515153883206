import { RatePlan } from '@reliance/types/models/ServiceRate';
import React from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import PlanSummary from './PlanSummary';

type RatePlanTableProps = {
  ratePlans: UseAsyncReturn<RatePlan[]>
  onEdit(ratePlan: RatePlan): any;
}

const RatePlanTable: React.FC<RatePlanTableProps> = (props) =>
  <div>
    {props.ratePlans.loading ?
      <h3>Loading...</h3> :
      props.ratePlans.result.map(r =>
        <PlanSummary
          ratePlan={r}
          onEdit={() => props.onEdit(r)}
          key={`rate-plan-${r.id}`} />
      )
    }
  </div>

export default RatePlanTable;