import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';

export interface IConfigurationText {
  key: string
  content: string
  language: string
  languageName?: string
  facilityId?: number
  facilityName?: string
}

export interface IConfigurationTextService {
  getConfigurationTexts(facilityId: number, language?: string): Promise<IApiResponse<Array<IConfigurationText>>>
  add(configurationText: IConfigurationText) : Promise<IApiResponse<any>>
  update(configurationText: IConfigurationText) : Promise<IApiResponse<any>>
}

class ConfigurationTextService implements IConfigurationTextService {
   api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  getConfigurationTexts(facilityId: number, language?: string): Promise<IApiResponse<Array<IConfigurationText>>> {
    return this.api.get('/configuration-text', {facilityId: facilityId, language: language});
  }

  add(configurationText: IConfigurationText): Promise<IApiResponse<any>> {
    return this.api.post('/configuration-text', configurationText);
  }

  update(configurationText: IConfigurationText): Promise<IApiResponse<any>> {
    return this.api.put('/configuration-text', configurationText);
  }
}

angular
  .module('relcore.configuration-text')
  .service('configurationTextService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new ConfigurationTextService(relianceApi);
    }
  ]);