angular
  .module('relcore.api-role')
  .directive('apiRoleSearchResults', () =>
    ({
      restrict: 'E',
      scope: {
        showFilter: '=',
        gridOptions: '=',
        onViewDetails: '='
      },
      controller: 'ApiRoleSearchResultsController',
      template: require('./api-role-search-results.html').default
    })
  )
  .controller('ApiRoleSearchResultsController', ['$scope', function($scope) {
    $scope.showFilter = $scope.showFilter === null ? true : $scope.showFilter === true;
  }]);
