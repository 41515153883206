import { IRootScope } from '../models/root-scope';

angular
  .module('relcore.directives')
  .directive('ifPermissionShow', ['$animate', '$compile', '$rootScope', function($animate, $compile, $rootScope:IRootScope) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attr) {
        var NG_HIDE_CLASS = 'ng-hide';
        var NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';

        $scope.$watch($attr.ifPermissionShow, function ifPermissionShowWatchAction(value) {
          let oneOfPermissionsArray;
          if (typeof value === 'string') {
            oneOfPermissionsArray = value.split(',');
          } else {
            oneOfPermissionsArray = value || [];
          }

          let userHasOneOfPermissions = $rootScope.user && $rootScope.user.hasOneOfPermissions(oneOfPermissionsArray);

          if (userHasOneOfPermissions) {
            $animate[value ? 'removeClass' : 'addClass']($element, NG_HIDE_CLASS, {
              tempClasses: NG_HIDE_IN_PROGRESS_CLASS
            });
          } else {
            $animate[value ? 'addClass' : 'removeClass']($element,NG_HIDE_CLASS, {
              tempClasses: NG_HIDE_IN_PROGRESS_CLASS
            });
          }
        });
      }
    }
  }]);
