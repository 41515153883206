angular.module('relcore.filters').filter('datetimeNoSecondsFormat', ['moment', moment =>
  function(value) {
    let date;
    if ((value == null)) {
      return '';
    }

    const format = 'MM/DD/YYYY HH:mm';
    if (moment.isMoment(value)) {
      date = value;
    } else if ((typeof value === 'object') && (value.date != null)) {
      date = moment.tz(value.date, value.timezone).local();
    } else {
      date = moment(value);
    }

    return date.format(format);
  }
]);
