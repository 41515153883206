angular
  .module('relcore.cdr')
  .directive('cdrs', () => ({
    restrict: 'E',
    scope: {
      columns: '=?',
      hideColumns: '=?',
      showFilter: '=',
      gridOptions: '=',
      facilities: '=',
      onViewDetails: '=?'
    },
    controller: ['$scope', function($scope) {
      $scope.showFilters = $scope.showFilter == null || $scope.showFilter == true,
      $scope.showColumn = (column) => {
        return ($scope.columns == null || $scope.columns.indexOf(column) > -1) &&
          ($scope.hideColumns == null || $scope.hideColumns.indexOf(column) === -1);
      };
      $scope.columnCount = () => {
        if ($scope.columns == null) {
          return 8;
        }
        return $scope.columns.length;
      };
      $scope.total = () => {
        return $scope.gridOptions.data.reduce(((prev, cur) => {
          return prev + parseFloat(cur.activity.amount);
        }), 0);
      };
    }],
    template: require('./cdrs.html').default
  }));
