export interface IConfiguration {
  id: number
  key: string
  value: string
}

angular
  .module('relcore.configuration')
  .factory('Configuration', function() {
    return class Configuration implements IConfiguration {
      id: number
      key: string
      value: string

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        if (data == null) { data = {}; }
        this.id = data.id || null;
        this.key = data.key || null;
        this.value = data.value || null;
      }
    }
  });

