angular
  .module('relcore.directives')
  .directive('password', function() {
    return {
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl:any) {
        ctrl.$validators.password = function(modelValue, viewValue) {
          // Let other rules enforce required
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }

          let containsAlpha = new RegExp("[a-zA-Z]");
          let containsNumeric = new RegExp("[0-9]");
          let passes =
            viewValue.length >= 8 &&
            containsAlpha.test(viewValue) &&
            containsNumeric.test(viewValue);
          return passes;
        };
      }
    };
  });