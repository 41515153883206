angular
  .module('relcore.salt')
  .directive('saltMinions', () => ({
    restrict: 'E',
    scope: {
      gridOptions: '=',
      onViewDetails: '=?'
    },
    template: require('./salt-minions.html').default
  }));
