import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import ConfirmModal from '../common/dialogs/ConfirmModal';
import { formatPhoneNumber } from '../format/PhoneNumber';
import { IInmate } from '../inmate/inmate-service';
import { EnabledDisabled } from '../models/enums';
import { IFacility } from '../models/facility';
import BaseHtmlTable from '../table/BaseHtmlTable';
import { IEventNotificationCondition, IEventNotificationTrigger } from './event-notification-factory';
import { IEventNotificationService } from './event-notification-service';
import TextAlertAddModal from './TextMessageAlertAddModal';

type TextMessageAlertsProps = {
  facility: IFacility
  inmateService: any
  eventNotificationService: IEventNotificationService;
}

const getInmateDetails = (inmateId: number|string, inmates: IInmate[]) =>
  inmates
    .filter(i => i.id == inmateId)
    .map(i => `${i.firstName} ${i.lastName} (${i.account})`)
    .pop();

const TextMessageAlerts = (props: TextMessageAlertsProps) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loadingInmates, setLoadingInmates] = useState(true);
  const [loadingTriggers, setLoadingTriggers] = useState(true);
  const [triggers, setTriggers] = useState<IEventNotificationTrigger[]>([]);
  const [inmates, setInmates] = useState<IInmate[]>([]);
  const [removeTrigger, setRemoveTrigger] = useState<IEventNotificationTrigger>(null);

  const loadTriggers = () => {
    setLoadingTriggers(true);
    props.eventNotificationService
      .getTextMessageAlertTriggers(props.facility.id)
      .then(setTriggers)
      .then(() => setLoadingTriggers(false));
  }

  useEffect(() => {
    loadTriggers();

    props.inmateService
      .get({ facilityId: props.facility.id, perPage: 5000 })
      .then(({ data }) => {
        setInmates(data);
        setLoadingInmates(false);
      })
  }, []);

  const columns = [
    { key: 'inmate', name: 'Inmate' },
    { key: 'outsideParty', name: 'Outside Party' },
    { key: 'phrase', name: 'Phrase' },
    { key: 'actions', name: 'Actions' },
    { key: 'options', name: 'Options' }
  ];

  // Massage the triggers into applicable/usable columns
  const rows = (triggers||[]).map(trigger => ({
    id: trigger.id,

    inmate: getInmateDetails((trigger.conditions.filter(c =>
        c.status === EnabledDisabled.Enabled &&
        c.metadataPath.indexOf('_inmateId') > -1
      ).pop() || {} as IEventNotificationCondition).value, inmates) || <i>any</i>,

    outsideParty: formatPhoneNumber(
      (trigger.conditions.filter(c =>
        c.status === EnabledDisabled.Enabled &&
        c.metadataPath.indexOf('customerAni') > -1
      ).pop() || {} as IEventNotificationCondition).value
    ) || <i>any</i>,

    phrase: (trigger.conditions.filter(c =>
        c.status === EnabledDisabled.Enabled &&
        c.metadataPath.indexOf('message') > -1
      ).pop() || {} as IEventNotificationCondition).value || <i>any</i>,

    actions: trigger.actions.map(a =>
        <div key={`${trigger.id}-action-${a.id}`}>{String(a.actionType)} {a.destinationName} ({a.destination})</div>
      ),

    options: <div>
      <Button onClick={() => setRemoveTrigger(trigger)} size="sm" variant="outline-danger">Remove</Button>
    </div>
  }));

  const addAlert = (data) => {
    return props
      .eventNotificationService
      .createTextMessageAlertTrigger(data, props.facility)
      .then(response => {
        if (response && response.data && response.data.success) {
          setShowAddModal(false);
          loadTriggers();
          return null;
        }
        return { [FORM_ERROR]: Error('Unexpected error while creating new alert') };
      })
      .catch(error => ({ [FORM_ERROR]: error }));
  }

  if (loadingInmates || loadingTriggers) {
    return (
      <div className="jarviswidget jarviswidget-color-blueDark">
        <div className="row">
          <div className="col-md-12">
            <h3>Loading...</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="jarviswidget jarviswidget-color-blueDark">
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={() => setShowAddModal(true)}>Add Alert</button>
          <br/><br/>

          <BaseHtmlTable
            baseKey="text-message-alert"
            columns={columns}
            rows={rows}
            />

          {showAddModal &&
            <TextAlertAddModal
              inmates={inmates || []}
              onClose={() => setShowAddModal(false)}
              onSubmit={addAlert}
              />
          }

          {removeTrigger &&
            <ConfirmModal
              show={true}
              title="Remove Text Message Alert"
              onAccept={() =>
                props
                  .eventNotificationService
                  .deleteTrigger(removeTrigger.id)
                  .then(response => {
                    setRemoveTrigger(null);
                    loadTriggers();
                  })
                  .catch(() => setRemoveTrigger(null))
              }
              onClose={() => setRemoveTrigger(null)}
              acceptText="Remove">
              Are you sure you want to remove the text message alert?
            </ConfirmModal>
          }
        </div>
      </div>
    </div>
  )
}

export default TextMessageAlerts;

angular
  .module('relcore.event-notification')
  .component('eventNotificationTextMessageAlerts', react2angular(
    TextMessageAlerts,
    ['facility', 'inmateService', 'eventNotificationService']
  ));