import React from 'react';
import { SwitchStatus as SwitchStatusEnum } from '../../models/card-account';

type SwitchStatusProps = {
  switchNumber: number
  status: SwitchStatusEnum
}

export class SwitchStatus extends React.Component<SwitchStatusProps> {
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    let status = this.props.status || SwitchStatusEnum.Stable;
    let badgeClass = 'badge-secondary';

    switch (status) {
      case SwitchStatusEnum.Stable:
        badgeClass = 'badge-success';
        break;

      case SwitchStatusEnum.Add:
        badgeClass = 'badge-secondary';
        break;

      case SwitchStatusEnum.Update:
        badgeClass = 'badge-warning';
        break;
    }

    return (
      <span className={`badge header-small ${badgeClass}`}>
        {SwitchStatusEnum[status]}
      </span>
    )
  }
}
