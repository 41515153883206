angular
  .module('relcore.inmate-device')
  .service('inmateDeviceService', ['relianceApi', '$filter', function(relianceApi, $filter) {
    return {
      get(id) {
        return relianceApi.get(`/inmate-device/${id}`)
          .then(response => response.data);
      },

      index(page, perPage, orderBy, filter) {
        const params = {orderBy: null};
        for (let i in filter) {
          if (filter[i] != null) { params[i] = filter[i]; }
        }

        if (orderBy != null) {
          params.orderBy = orderBy;
        }

        return relianceApi.get("/inmate-device", params);
      },

      post(inmateDevice) {
        return relianceApi.post("/inmate-device", inmateDevice);
      },

      put(inmateDevice) {
        return relianceApi.put(`/inmate-device/${inmateDevice.id}`, inmateDevice);
      }
    };
  }]);
