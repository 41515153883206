angular
  .module('relcore.search')
  .directive('relSearchBar', () =>
    ({
      scope: {},
      controller: 'SearchBarController',
      template: require('./search-bar.html').default
    })
  )
  .controller('SearchBarController', ['$scope', '$location', function($scope, $location) {
    $scope.query = '';
    $scope.searchAccounts = function() {
      const queryClean = $scope.query.replace(/[^0-9]/g, '');
      if (queryClean.length === 10) {
        $location.path(`/account/${queryClean}`);
      }
    };

    $scope.cardAccountQuery = '';
    $scope.searchCardAccounts = function() {
      const queryClean = $scope.cardAccountQuery.replace(/[^0-9]/g, '');
      if (queryClean.length > 0) {
        $location.path(`/card/${queryClean}`);
      }
    };
  }]);
