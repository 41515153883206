interface IMobileDeviceFacilityProxy {
  server: string
  port: string
  username: string
  password: string
}

interface IMobileDeviceFacilityProxyCtrl {
  facilityProfile: any
  proxySettings: IMobileDeviceFacilityProxy
  saving: boolean
  deleting: boolean
  save(): void
  delete(): void
  onSave(proxySettings:IMobileDeviceFacilityProxy): void
  onDelete(): void
}

angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceFacilityProxy', ['plist', (plist) => ({
    restrict: 'E',
    scope: {
      'facilityProfile': '=?',
      'saving': '=',
      'onSave': '=',
      'deleting': '=',
      'onDelete': '='
    },
    controller: ['$scope', function($scope:IMobileDeviceFacilityProxyCtrl) {
      $scope.proxySettings = {
        server: '',
        port: '',
        username: '',
        password: ''
      };

      if ($scope.facilityProfile && $scope.facilityProfile.data) {
        let existingProfile = plist.parse($scope.facilityProfile.data);
        $scope.proxySettings.server = existingProfile['PayloadContent'][0]['ProxyServer'];
        $scope.proxySettings.port = existingProfile['PayloadContent'][0]['ProxyServerPort'];
        $scope.proxySettings.username = existingProfile['PayloadContent'][0]['ProxyUsername'];
        $scope.proxySettings.password = existingProfile['PayloadContent'][0]['ProxyPassword'];
      }

      $scope.save = () => {
        $scope.onSave($scope.proxySettings);
      };

      $scope.delete = () => {
        $scope.onDelete();
      }
    }],
    template: require('./facility-proxy.html').default
    })
  ]);
