angular
  .module('relcore.report')
  .factory('EmployeeActivity', function() {
    return class EmployeeActivity {
      taskId;
      taskDate;
      taskEndDate;
      duration;
      taskType;
      subTaskType;
      result;
      ani;
      accountName;
      accountCompany;
      direction;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        this.taskId = data.taskId;
        this.taskDate = data.taskDate;
        this.taskEndDate = data.taskEndDate;
        this.duration = data.duration;
        this.taskType = data.taskType;
        this.subTaskType = data.subTaskType;
        this.result = data.result;
        this.ani = data.ani.slice(-10);
        this.accountName = data.accountName;
        this.accountCompany = data.accountCompany;
        this.direction = this.taskType.replace('call_', '');

        return this;
      }
    };
  });