import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { resolveEntity, resolveUserName } from '../format/ApiLog';
import { formatDate } from '../format/DateTime';
import { ApiLog } from '../models/ApiLog';
import BaseHtmlTable from '../table/BaseHtmlTable';
import DetailsModal from './DetailsModal';
import EntityLink from './EntityLink';

type TableProps = {
  apiLogs: Partial<IPaginatedResponse<ApiLog>>
  showOptions?: boolean
  showPagination?: boolean
}

const Table: React.FC<TableProps> = (props) => {
  const { apiLogs } = props;
  const [detailsLog, setDetailsLog] = useState<ApiLog>(null);

  if (apiLogs == null) {
    return null;
  }

  const columns = [
    { key: 'date', name: 'Date' },
    { key: 'user', name: 'User' },
    { key: 'description', name: 'Description' },
    { key: 'entity', name: 'Entity' },
    { key: 'method', name: 'Method' },
    { key: 'endpoint', name: 'Endpoint' }
  ];

  if (props.showOptions !== false) {
    columns.push({ key: 'options', name: 'Options' });
  }

  const rows = apiLogs.data.map(log => ({
    id: log.id,
    date: formatDate(log.created.date),
    user: resolveUserName(log),
    description: <EntityLink log={log}>{log.description}</EntityLink>,
    operation: log.operation,
    entity: `${resolveEntity(log)} (${log.entityId})`,
    endpoint: log.endpoint,
    method: log.method,
    options: (
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setDetailsLog(log)}>
        Details
      </Button>
    )
  }));

  return (
    <>
      {detailsLog && <DetailsModal log={detailsLog} onClose={() => setDetailsLog(null)} />}

      <BaseHtmlTable
        baseKey="api-log"
        columns={columns}
        rows={rows}
        showPagination={props.showPagination}
        />
    </>
  );
}

export default Table;