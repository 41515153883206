import { CreditCardFilters } from '@reliance/types/filters/CreditCardFilters';
import { CreditCard } from '@reliance/types/models/CreditCard';
import { useAsync } from 'react-async-hook';
import api from '../common/api';
import { IPaginatedResponse } from 'relcore-central';

export const useCreditCards = (filters?: CreditCardFilters) => {
  return useAsync<IPaginatedResponse<CreditCard>>(
    () => filters ? api.get<IPaginatedResponse<CreditCard>>('/credit-card', { params: filters }).then(r => r.data) : null,
    [filters]
  );
};