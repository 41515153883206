import { IGenericApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { IDateTimeObject } from '../models/datetime';

export interface ITransaction {
  id: number
  date: IDateTimeObject
  amount: string
  balance?: number
  transactionType?: number
  transactionSubType?: number
  count?: number
}

export interface ITransactionService {
  getByANI(ani: string, page?: number, perPage?: number): Promise<IGenericApiResponse<ITransaction>>
  getByOnlineUserId(id: number, page?: number, perPage?: number): Promise<IGenericApiResponse<ITransaction>>
  isPaginated(response: IGenericApiResponse<ITransaction>): boolean
}

class TransactionService implements ITransactionService {
  api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  // Return non-paginated results by default so that ALL transactions are loaded. This allows for calculating running balances
  getByANI(ani: string, page = -1, perPage = 100): Promise<IGenericApiResponse<ITransaction>> {
    return this.api.get('/transaction', {ani: ani, page: page, perPage: perPage, orderBy: 'date'});
  }

  getByOnlineUserId(id: number, page = -1, perPage = 100): Promise<IGenericApiResponse<ITransaction>> {
    return this.api.get('/transaction', {onlineUserId: id, page: page, perPage: perPage, orderBy: 'date'});
  }

  isPaginated(response: IGenericApiResponse<ITransaction>): boolean {
    return this.api.isPaginated(response);
  }
}

angular
  .module('relcore.transaction')
  .service('transactionService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new TransactionService(relianceApi);
    }
  ]);