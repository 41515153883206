angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceHistories', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      template: require('./mobile-device-histories.html').default
    })
  );
