import React from 'react';
import { react2angular } from 'react2angular';
import { DateTime } from '../../format/DateTime';
import { IFacilityOrderItem } from '../../models/card-account';

type FacilityOrderSummaryProps = {
  orderItem: IFacilityOrderItem
}

const FacilityOrderSummary = (props: FacilityOrderSummaryProps) => {
  const { order, order: { facility: { directBillFacility }} } = props.orderItem;

  return (
    <div className="card card-default">
      <div className="card-header">
        Facility Order #{order.id}
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-6">
                <strong>Facility:</strong><br/>
                <address>
                  <a href={`/facilities/view/${directBillFacility.id}/details`}>{directBillFacility.name}</a><br/>
                  {directBillFacility.address}<br/>
                  {directBillFacility.city}, {directBillFacility.state} {directBillFacility.zip}
                </address>
              </div>
              <div className="col-md-6">
                <strong>Date:</strong> <br/>
                <DateTime value={order.orderDate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('facilityOrderSummary', react2angular(FacilityOrderSummary, ['orderItem']));

export default FacilityOrderSummary