import { IFacility } from '../models/facility';

export interface ICall {
  date: string
  duration: string
  fromNumber: string
  facility: IFacility
  charge: number
  cityTax: number
  countyTax: number
  stateTax: number
  toStateTax: number
  fedTax: number
  usfTax: number
  totalTaxes: number
  totalCharge: number
  invoice: string
  type: string
}

angular
  .module('relcore.call')
  .factory('Call', ['Facility', function(FacilityModel) {
    return class CallModel implements ICall{
      date: string;
      duration: string;
      fromNumber: string;
      facility: IFacility;
      charge: number;
      cityTax: number;
      countyTax: number;
      stateTax: number;
      toStateTax: number;
      fedTax: number;
      usfTax: number;
      totalTaxes: number;
      totalCharge: number;
      invoice: string;
      type: string;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        if (data == null) { data = {}; }
        this.date = data.date.date || '';
        this.duration = data.duration || '';
        this.fromNumber = data.fromNumber || '';
        this.facility = new FacilityModel(data.facility[0]);
        this.charge = data.charge;
        this.cityTax = data.cityTax || 0;
        this.countyTax = data.countyTax || 0;
        this.stateTax = data.stateTax || 0;
        this.toStateTax = data.toStateTax || 0;
        this.fedTax = data.fedTax || 0;
        this.usfTax = data.usfTax || 0;
        this.totalTaxes = data.totalTaxes;
        this.totalCharge = data.totalCharge;
        this.invoice = data.invoice ? data.invoice.id : '';
        this.type = data.callType;
      }
    }
  }]);