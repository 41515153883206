import { IDateTimeObject } from './datetime';

export interface ICallRouteMapping {
  id?: number
  dateCreated?: IDateTimeObject
  dateUpdated?: IDateTimeObject
  origination: string
  destination: string
  billingCategory: string
  callRoute: ICallRoute
}

export enum ICallRouteDialing {
  DIAL_7D = '7D',
  DIAL_10D = '10D',
  DIAL_1P10D = '1+10D',
  DIAL_011 = '011'
}

export interface ICallRoute {
  id?: number
  dateCreated?: IDateTimeObject
  dateUpdated?: IDateTimeObject
  name: string
  type: string
  route: string
  dialing: ICallRouteDialing
  mappings?: Array<ICallRouteMapping>
}