angular
  .module('relcore.online-user')
  .directive('onlineUserAppInvites', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '='
      },
      controller: ['$scope', function($scope) {} ],
      template: require('./online-user-app-invites.html').default
    })
  );

// facility - linked
// inmate - linked
// date
// ani - linked (does leading 1 work?)
