import React from 'react';
import { react2angular } from 'react2angular';
import { IAccount } from '../../models/itds';

type ItdsSummaryProps = {
  account: IAccount
}

const ItdsSummary = (props: ItdsSummaryProps) => {
  const { account } = props;

  return (
    <div className="card card-default">
      <div className="card-header">
        ITDS Account
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-6">
                <strong>Account:</strong><br/>{account.inmateId}
              </div>
              <div className="col-md-6">
                <strong>Site:</strong><br/>{account.site.name}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong>Inmate Name:</strong><br/>{account.inmateFirstName} {account.inmateLastName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('itdsSummary', react2angular(ItdsSummary, ['account']));

export default ItdsSummary;