import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import { react2angular } from 'react2angular';
import { IAlertService } from '../alert/alert-service';
import { IPaginatedApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { EnabledDisabled } from '../models/enums';
import { IFacility } from '../models/facility';
import { ReferenceMaterial } from '../models/ReferenceMaterial';
import AddModal from './AddModal';
import CopyModal from './CopyModal';
import Search, { SearchCriteria } from './Search';
import Table from './Table';

type ListProps = {
  relianceApi: IRelianceApi
  alertService: IAlertService
  facilityService: any
}

const List: React.FC<ListProps> = props => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [facilities, setFacilities] = useState<IFacility[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  const [referenceMaterials, setReferenceMaterials] = useState<IPaginatedResponse<ReferenceMaterial>>(null);
  const [selected, setSelected] = useState<ReferenceMaterial[]>([]);
  const [searching, setSearching] = useState(false);
  const hasSearchCriteria = searchCriteria.category || searchCriteria.facilityId || searchCriteria.localPath || searchCriteria.name;

  // Load the facilities needed for the add modal
  useEffect(() => {
    props.facilityService.retrieveAll().then(setFacilities);
  }, []);

  // Load the existing reference material
  useEffect(() => {
    if (hasSearchCriteria) {
      setSearching(true);
      props.relianceApi
        .index<IPaginatedApiResponse<ReferenceMaterial>>('/reference-material', 1, 1000, {status: 'Enabled', ...(searchCriteria||{})})
        .then((res) => setReferenceMaterials(res.data))
        .finally(() => setSearching(false));
    }
  }, [searchCriteria]);

  const refresh = () => {
    setSearchCriteria({...(searchCriteria||{})});
  }

  const toggleSelected = (referenceMaterial: ReferenceMaterial) => {
    const existingIndex = selected.indexOf(referenceMaterial);
    if (existingIndex > -1) {
      setSelected(selected.splice(existingIndex, 1));
    } else {
      setSelected([...selected, referenceMaterial]);
    }
  }

  return (
    <div>
      {showAddModal &&
        <AddModal
          facilities={facilities}
          onClose={() => setShowAddModal(false)}
          onSave={(formData) =>
            props
              .relianceApi
              .post('/reference-material', formData)
              .then(() => {
                setShowAddModal(false);
                props.alertService.success('The reference material was added', true);
                refresh();
              })
              .catch(error => ({ FORM_ERROR: error }))
          }
        />
      }
      <Button onClick={() => setShowAddModal(true)} variant="primary" disabled={facilities.length == 0}>
        Add Reference Material
      </Button>

      <Card className="mt-3">
        <Card.Body>
          <div className="mb-3">
            <Search
              facilities={facilities}
              onSubmit={(criteria) => setSearchCriteria({...(criteria||{})})}
              submitting= {searching}
            />
          </div>

          {selected.length > 0 &&
            <Button onClick={() => setShowCopyModal(true)} variant="primary" className="pb-1">
              Copy Reference Material
            </Button>
          }
          {showCopyModal &&
            <CopyModal
              facilities={facilities}
              referenceMaterial={selected}
              onClose={() => setShowCopyModal(false)}
              onSave={(formData) =>
                props
                  .relianceApi
                  .post('/reference-material/copy', formData)
                  .then(() => {
                    setShowCopyModal(false);
                    props.alertService.success('The reference material was copied', true);
                    refresh();
                  })
                  .catch(error => ({ FORM_ERROR: error }))
              }
            />
          }

          <Table
            referenceMaterials={referenceMaterials}
            onRemove={(referenceMaterial) =>
              props.relianceApi
                .put(`/reference-material/${referenceMaterial.id}`, {
                  status: EnabledDisabled.Disabled
                })
                .then(() => {
                  refresh();
                  props.alertService.success('The reference material was removed', true);
                })
                .catch((err) => {
                  props.alertService.error('An unexpected error occurred', true);
                })
            }
            onEditSave={(referenceMaterial) =>
              props.relianceApi
                .put(`/reference-material/${referenceMaterial.id}`, referenceMaterial)
                .then(() => {
                  refresh();
                  props.alertService.success('The reference material was saved', true);
                })
                .catch((err) => {
                  props.alertService.error('An unexpected error occurred', true);
                })
            }
            onCheck={toggleSelected}
            onCheckAll={() => setSelected(selected.length == referenceMaterials.data.length ? [] : referenceMaterials.data)}
            selected={selected}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

angular
  .module('relcore.reference-material')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('reference-material', {
        component: 'referenceMaterialList',
        url: '/reference-material',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Reference Material'
        }
      }
    )
  ])
  .component('referenceMaterialList', react2angular(
    List,
    [],
    ['alertService', 'facilityService', 'relianceApi']
  ));