angular
  .module('relcore.condition')
  .controller('ConditionCreateController',
    ['$scope', '$uibModalInstance', 'conditionService', 'ani', 'Condition', 'alertService', 'facility',
    function($scope, $uibModalInstance, conditionService, ani, Condition, alertService, facility) {
      $scope.aniSupplied = false;
      if (ani) {
        $scope.aniSupplied = true;
      }

      $scope.facilitySupplied = false;
      if (facility) {
        $scope.facilitySupplied = true;
      }

      const reset = () => {
        $scope.model = new Condition;
        $scope.model.ani = ani;
        $scope.model.facility = facility;
      }

      $scope.conditions = [
        { condition: 'free', title: 'Free Number' },
        { condition: 'excluded', title: 'Excluded Number' },
        { condition: 'allow_dtmf', title: 'Allow DTMF' },
        { condition: 'allow_multiple_calls', title: 'Allow Multiple Calls' },
        { condition: 'announcement_skip', title: 'Call Announcement - Skip' },
        { condition: 'announcement_delay_10', title: 'Call Announcement - Delay 10' }
      ];

      reset();

      $scope.cancel = function() {
        reset();
        $uibModalInstance.close();
      };

      $scope.creating_condition = false;
      $scope.create = function() {
        $scope.creating_condition = true;
        let errorHandler;
        const data = {
          ani:$scope.model.ani.replace(/[^0-9]/g, ''),
          description:$scope.model.description,
          condition:$scope.model.condition,
          facility:$scope.model.facility
        };
        conditionService.create(data)
          .then(condition => {$uibModalInstance.close(condition);
              $scope.creating_condition = false;
            }, errorHandler
          );

        errorHandler = function(err) {
          $scope.creating_condition = false;
          if (err.status === 400) {
            switch (err.data.error.error) {
              case 'condition_invalid': alertService.error('The condition selected is invalid', true); break;
              case 'facility_not_found': alertService.error("The facility selected is invalid", true); break;
              case 'account_not_found': alertService.error("The phone number supplied is invalid", true); break;
            }
            return;
          } else if (err.data === 'duplicate') {
            alertService.warning("This condition was created previously", true);
          }

          // TODO: Log to errbit
          alertService.error('Something broke! The creation failed', true);
        };
      };
    }
  ]);