import { FormApi } from 'final-form';
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import { Field } from 'react-final-form';
import { IFacility } from '../models/facility';

type StateGroupedCheckboxesProps = {
  fieldName: string
  form: FormApi<any>
  facilities: IFacility[]
}

const groupByState = (facilities: IFacility[]) => {
  const facilitiesByState: {[state: string]: IFacility[]} = {};
  facilities.map(f => {
    if (facilitiesByState[f.state] == null) {
      facilitiesByState[f.state] = [];
    };
    facilitiesByState[f.state].push(f);
  })
  return facilitiesByState;
}

export const toggleCheckAllForState = (args, formState, utils) => {
  const state = args[0];
  const checked = args[1];
  const facilities = args[2];
  const fieldName = args[3];

  const facilityIds = formState.formState.values[fieldName] || [];
  const facilityIdsInState = facilities
    .filter(f => f.state === state)
    .map(f => f.id);

  let newValue = facilityIds;

  // If we unchecked, remove all of the facilities from this state. Otherwise, merge the selected ones in, and remove duplicates
  if (!checked) {
    newValue = newValue.filter(i => !facilityIdsInState.includes(i))
  } else {
    newValue = facilityIds.concat(facilityIdsInState.filter((item) => facilityIds.indexOf(item) < 0));
  }

  utils.changeValue(formState, fieldName, () => newValue);
};

const StateGroupedCheckboxes: React.FC<StateGroupedCheckboxesProps> = props => {
  const facilitiesByState = groupByState(props.facilities);

  return (
    <Row>
      {Object.keys(facilitiesByState).sort().map(state =>
        <Col lg={3} key={`facility-${state}`} style={{paddingBottom: 5}}>
          <Form.Check
            id={`facility-state-${state}`}
            label={<strong>{state}</strong>}
            onChange={e => props.form.mutators.toggleCheckAllForState(state, (e.target as any).checked, props.facilities, props.fieldName)} />

          {facilitiesByState[state].map(facility =>
            <Field
              type="checkbox"
              value={facility.id}
              key={`facility-${state}-${facility.id}`}
              name={props.fieldName}>
              {({ input, meta }) => (
                <Form.Check
                  {...input}
                  type="checkbox"
                  id={`facility-${facility.id}`}
                  label={facility.name}
                />
              )}
            </Field>
          )}
        </Col>
      )}
    </Row>
  );
}

export default StateGroupedCheckboxes;