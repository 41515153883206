import { IConfig } from '../common/config';
import { IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { EnabledDisabled } from '../models/enums';
import { IFacility } from '../models/facility';
import { ConditionComparator, EventNotificationActionType, IEventNotificationAction, IEventNotificationCondition, IEventNotificationTrigger, newPREATrigger, newPREATriggerAction, newTextMessageAlertTrigger } from './event-notification-factory';
import { AddAlertData } from './TextMessageAlertAddModal';

export interface IEventNotificationTriggerUpdate {
  name?: string;
  eventType?: string;
  status?: string;
  maxEventDateTimePeriod?: string;
}

export interface IEventNotificationActionUpdate {
  actionType?: EventNotificationActionType;
  destination?: string;
  destinationName?: string;
  includeRecording?: boolean;
}

export interface IEVentNotificationConditionUpdate {
  metadataPath?: string;
  comparator?: string;
  value?: string;
  status?: string;
}

export interface IEventNotificationService {
  getTriggers(facilityId: number): Promise<IApiResponse<IEventNotificationTrigger[]>>;
  getEmailActions(trigger: IEventNotificationTrigger): Array<IEventNotificationAction>;
  createTrigger(trigger: IEventNotificationTrigger): Promise<IApiResponse<IStatusResponse>>;
  deleteAction(triggerId: number, actionId: number): Promise<IApiResponse<IStatusResponse>>;
  deleteTrigger(triggerId: number): Promise<IApiResponse<IStatusResponse>>;

  getPREATrigger(facilityId: number): Promise<IEventNotificationTrigger>
  createPREATrigger(triggerConfig: IPREAConfig, facility?: IFacility): Promise<IApiResponse<IStatusResponse>>;
  createPREAAction(triggerConfig: IPREAConfig): Promise<IApiResponse<IStatusResponse>>;
  updatePREAAction(actionConfig: IPREAConfig): Promise<IApiResponse<IStatusResponse>>;
  createPREATriggerAndOrAction(config: IPREAConfig, facility?: IFacility): Promise<IApiResponse<IStatusResponse>>;

  getTextMessageAlertTriggers(facilityId: number): Promise<IEventNotificationTrigger[]>
  createTextMessageAlertTrigger(triggerConfig: AddAlertData, facility: IFacility): Promise<IApiResponse<IStatusResponse>>;
}

interface IPREAConfig {
  triggerId?: number
  templateId?: number
  emailAction: IEmailAction
}

interface IEmailAction {
  actionId?: number
  name: string
  address: string
}

export function hasPREACondition(conditions: IEventNotificationCondition[]): boolean
{
  return conditions.reduce((hasCondition, condition) => (
    hasCondition || (
      condition.metadataPath == 'variable_call_type' &&
      condition.comparator == ConditionComparator.EQ &&
      condition.value == 'prea-report' &&
      condition.status == 'Enabled'
    )
  ), false);
}

export function getPREATrigger(triggers: IEventNotificationTrigger[])
{
  return triggers.filter((trigger) =>
    trigger.eventType == 'cdr.processed' &&
    hasPREACondition(trigger.conditions)
  ).pop();
}

export function hasTextMessageAlertCondition(conditions: IEventNotificationCondition[]): boolean
{
  return conditions.reduce((hasCondition, condition) => (
    hasCondition || (
      condition.metadataPath == 'message' &&
      condition.comparator == ConditionComparator.CONTAINS &&
      condition.status == 'Enabled'
    )
  ), false);
}

export function getTextMessageAlertTriggers(triggers: IEventNotificationTrigger[])
{
  return triggers.filter((trigger) =>
    trigger.eventType == 'reliancetelephone.connect.text-message' &&
    hasTextMessageAlertCondition(trigger.conditions)
  );
}

class EventNotificationService implements IEventNotificationService {
   api: IRelianceApi
   appConfig: IConfig

  constructor(
    api: IRelianceApi,
    appConfig: IConfig
  ) {
    this.api = api;
    this.appConfig = appConfig;
  }

  async getTriggers(facilityId: number): Promise<IApiResponse<IEventNotificationTrigger[]>>
  {
    return this.api.get('/event-notification/trigger', {facilityId, status: 'Enabled', page: -1});
  }

  async getPREATrigger(facilityId: number): Promise<IEventNotificationTrigger>
  {
    const response = await this.getTriggers(facilityId);
    return getPREATrigger(response.data);
  }

  async getTextMessageAlertTriggers(facilityId: number): Promise<IEventNotificationTrigger[]>
  {
    const response = await this.getTriggers(facilityId);
    return getTextMessageAlertTriggers(response.data);
  }

  getEmailActions(trigger: IEventNotificationTrigger): Array<IEventNotificationAction>
  {
    return trigger ? trigger.actions.filter((action) => {
      return action.actionType == EventNotificationActionType.Email;
    }) : [];
  }

  protected hasPREACondition(conditions: Array<IEventNotificationCondition>): boolean
  {
    for (let item of conditions) {
      if (
        item.metadataPath == 'variable_call_type' &&
        item.comparator == '=' &&
        item.value == 'prea-report' &&
        item.status == 'Enabled'
      ) {
        return true;
      }
    }
    return false;
  }

  protected hasEmailAction(actions: Array<IEventNotificationAction>) : boolean
  {
    for (let item of actions) {
      if (item.actionType == EventNotificationActionType.Email)
        return true;
    }
    return false;
  }

  async createTrigger(trigger: IEventNotificationTrigger): Promise<IApiResponse<IStatusResponse>>
  {
    return this.api.post('/event-notification/trigger', {trigger: trigger});
  }

  async createPREATriggerAndOrAction(config: IPREAConfig, facility?: IFacility): Promise<IApiResponse<IStatusResponse>>
  {
    return config.triggerId ?
      this.createPREAAction(config) : this.createPREATrigger(config, facility);
  }

  async createPREATrigger(triggerConfig: IPREAConfig, facility?: IFacility): Promise<IApiResponse<IStatusResponse>>
  {
    const trigger = newPREATrigger();

    if (facility) {
      trigger.facilityId = facility.id;
      trigger.name = facility.name ? `${facility.name} (${facility.city}, ${facility.state}) PREA Report` : 'PREA Report';
    }

    const action = newPREATriggerAction();

    action.destination = triggerConfig.emailAction.address;
    action.destinationName = triggerConfig.emailAction.name;
    action.newActionTemplateName = this.appConfig.eventNotification.templates['prea_report']['name'];
    action.newActionTemplateContent = this.appConfig.eventNotification.templates['prea_report']['template'];

    trigger.actions[0] = action;

    return this.api.post('/event-notification/trigger', {
      trigger: trigger
    });
  }

  async createTextMessageAlertTrigger(config: AddAlertData, facility: IFacility): Promise<IApiResponse<IStatusResponse>>
  {
    const trigger = newTextMessageAlertTrigger();

    if (facility) {
      trigger.facilityId = facility.id;
      trigger.name = facility.name ? `${facility.name} (${facility.city}, ${facility.state}) Text Alert` : 'Text Alert';
    }

    // Add applicable conditions
    trigger.conditions.push({
      metadataPath: 'message',
      value: config.message,
      comparator: ConditionComparator.CONTAINS,
      status: EnabledDisabled.Enabled
    });
    trigger.conditions.push({
      metadataPath: '_operation',
      value: 'persist',
      comparator: ConditionComparator.EQ,
      status: EnabledDisabled.Enabled
    });
    if (config.inmateId) {
      trigger.conditions.push({
        metadataPath: '_inmateId',
        value: config.inmateId,
        comparator: ConditionComparator.EQ,
        status: EnabledDisabled.Enabled
      });
    }
    if (config.outsideParty) {
      trigger.conditions.push({
        metadataPath: 'customerAni',
        value: config.outsideParty.replace(/[^0-9]/g, ''),
        comparator: ConditionComparator.CONTAINS,
        status: EnabledDisabled.Enabled
      });
    }

    // Add all of the configured actions
    config.actions.map(actionData => {
      trigger.actions.push({
        actionType: actionData.type,
        destination: actionData.destination,
        destinationName: actionData.destinationName,
        actionTemplateId: this.appConfig.eventNotification.textMessageAlertTemplateIds[actionData.type],
        includeRecording: false
      });
    });

    return this.api.post('/event-notification/trigger', { trigger });
  }

  async createPREAAction(actionConfig: IPREAConfig): Promise<IApiResponse<IStatusResponse>>
  {
    const action = newPREATriggerAction();

    action.actionTemplateId = actionConfig.templateId;
    action.destination = actionConfig.emailAction.address;
    action.destinationName = actionConfig.emailAction.name;

    return this.api.post(`/event-notification/${actionConfig.triggerId}/action`, {
      action: action
    });
  }

  async updatePREAAction(actionConfig: IPREAConfig): Promise<IApiResponse<IStatusResponse>>
  {
    const action = newPREATriggerAction();

    action.destinationName = actionConfig.emailAction.name;
    action.destination = actionConfig.emailAction.address;

    return this.api.put(`/event-notification/${actionConfig.triggerId}/action/${actionConfig.emailAction.actionId}`, {
      updates: action
    });
  }

  async deleteAction(triggerId: number, actionId: number): Promise<IApiResponse<IStatusResponse>>
  {
    return this.api.delete(`/event-notification/${triggerId}/action/${actionId}`);
  }

  async deleteTrigger(triggerId: number): Promise<IApiResponse<IStatusResponse>>
  {
    return this.api.put(`/event-notification/trigger/${triggerId}`, {
      updates: { status: EnabledDisabled.Disabled }
    });
  }
}

angular
  .module('relcore.event-notification')
  .service('eventNotificationService', ['relianceApi', 'config',
    (relianceApi: IRelianceApi, config: IConfig) => {
      return new EventNotificationService(relianceApi, config);
    }
  ]);