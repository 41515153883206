angular
  .module('relcore.inmate')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('inmate-account-payments', {
      controller: 'InmateAccountPaymentsController',
      template: require('./main.html').default,
      url: '/inmate-account-payment',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Inmate Account Payments' }
    })
  ])
  .controller('InmateAccountPaymentsController', [
    '$rootScope', '$scope', 'store', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, store, relianceApi, NgTableParams) {
      $rootScope.title = 'Inmate Account Payments';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        filter: {
          includeCreditCard: true,
          facilityIds: $rootScope.facilitiesToDisplay
        },
        sorting: {
          'createdDate': 'desc'
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/inmate-account-payment', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });
    }
  ]);
