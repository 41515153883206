import { maxLength, numeric, required } from '@reliance/forms-js/lib/validation';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import { TextInput } from '../form/TextInput';
import { ReferenceMaterial } from '../models/ReferenceMaterial';

type EditModalProps = {
  referenceMaterial: ReferenceMaterial
  onSave(referenceMaterial: Partial<ReferenceMaterial>)
  onClose(): void
}

const EditModal: React.FC<EditModalProps> = (props) => {
  const {referenceMaterial} = props;

  return (
    <Modal show={true} onHide={props.onClose}>
      <Form
        initialValues={{
          id: referenceMaterial.id,
          name: referenceMaterial.name,
          category: referenceMaterial.category,
          sortWeight: referenceMaterial.sortWeight,
          localPath: referenceMaterial.localPath
        }}
        keepDirtyOnReinitialize={true}
        onSubmit={props.onSave}
        render={({ handleSubmit, values, submitting }) =>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="mt-4">
                <Col lg={6}>
                  <TextInput
                    label="Name"
                    validationRules={[required, maxLength(50)]}
                    name="name" />
                </Col>
                <Col lg={6}>
                  <TextInput
                    label="Category"
                    validationRules={[required, maxLength(20)]}
                    name="category" />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <TextInput
                    label="Local Path"
                    name="localPath"
                    validationRules={[required, maxLength(250)]} />
                </Col>
                <Col lg={6}>
                  <TextInput
                    label="Sort Weight"
                    validationRules={[required, numeric]}
                    name="sortWeight" />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="default"
                type="button"
                disabled={submitting}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={submitting}
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </form>
        } />
    </Modal>
  );
}

export default EditModal;