import { IAlertService } from '../../alert/alert-service';
import { IRelianceApi } from '../../common/reliance-api-service';

angular
  .module('relcore.inmate')
  .directive('inmateContacts', ['relianceApi', 'alertService', (relianceApi: IRelianceApi, alertService: IAlertService) =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        onRefresh: '=',
        inmate: '='
      },
      controller: ['$scope', function($scope) {
        $scope.relianceApi = relianceApi;

        $scope.showingAddDialog = false;
        $scope.showAddDialog = function() {
          $scope.showingAddDialog = true;
        };

        $scope.editingContact = null;
        $scope.showEditContact = function(contact) {
          $scope.editingContact = contact.contact;
        };

        $scope.onAddEditClose = function(saved) {
          $scope.showingAddDialog = false;
          $scope.editingContact = null;

          try {
            $scope.$digest();
          } catch {}

          if (saved) {
            alertService.success('The contact was successfully saved', true);
            $scope.onRefresh(true);
          }
        };
      }],
      template: require('./inmate-contacts.html').default
    })
  ]);
