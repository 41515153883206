angular
  .module('relcore.facility-equipment')
  .factory('FacilityEquipment', function() {
    return class FacilityEquipment {
      id;
      facility;
      name;
      serialNumber;
      description;
      type;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        this.id = data.id;
        this.facility = data.facility || null;
        this.name = data.name || '';
        this.serialNumber = data.serialNumber || '';
        this.description = data.description || '';
        this.type = data.type || '';
      }
    };
  });
