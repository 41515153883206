angular
  .module('relcore.api-role')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('api-role-search', {
        controller: 'SearchAPIRoleController',
        url: '/api-role/search',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'API Roles'
        },
        template: require('./main.html').default
      }
    )

  ])
  .controller('SearchAPIRoleController',
    ['$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', 'alertService', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, alertService, NgTableParams) {
      $rootScope.title = 'API Role';

      $scope.showAdd = () => {
        const dialog = $uibModal.open({
          template: require('./api-role-add.html').default,
          windowTopClass: 'modal-full',
          controller: 'ApiRoleAddController',
          resolve: { onAdd: () => $scope.onAdd }
        });
        dialog.result.then((result) => {
          if (result.saved) {
            $scope.search();
            alertService.success('The API role was successfully added', true);
          }
        });
      };

      $scope.onAdd = function(role) {
        $scope.adding = true;
        return relianceApi.post("/api-role", role)
          .then((function(res) {
            $scope.reset();
            alertService.success('The api role was added', true);
          }), function(err) {
            alertService.error('An error occurred while adding', true);
          });
      };

      $scope.criteria = {};

      $scope.results = [];
      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: { id: 'desc' }
      }, {
        total: 0,
        counts: [100,200,500],
        getData(params) {
          $scope.searching = true;
          return relianceApi.index('/api-role', params.page(), params.count(), $scope.criteria, params.orderBy())
            .then((function(res) {
              $scope.searching = false;
              params.total(res.total);
              return res.data;
            }), err => $scope.searching = false);
        }
      });

      $scope.reset = () => $scope.criteria = {};
      $scope.search = () => $scope.gridOptions.reload();

      $scope.view = apiRole => $state.go('api-role-view', {id: apiRole.id});
    }
  ]);
