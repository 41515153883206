angular
  .module('relcore.mobile-device')
  .directive('mobileDevices', function() {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        gridOptions: '=',
        columns: '=?',
        selected: '=?',
        facilities: '=?',
        onViewDetails: '=?',
        highlightRow: '=?',
        showCheckboxes: '@?',
        showOptions: '@?',
        showFilters: '@?',
        showShippingOptions: '@?'
      },
      controller: ['$scope', function($scope) {
        $scope.showCheckboxes = $scope.showCheckboxes || false;
        $scope.showOptions = $scope.showOptions || false;
        $scope.showFilters = $scope.showFilters || false;
        $scope.showShippingOptions = $scope.showShippingOptions || false;
        $scope.options = { allSelected: false };
        $scope.selected = $scope.selected || [];
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);

        $scope.onRowClick = device => typeof $scope.onViewDetails === 'function' ? $scope.onViewDetails(device) : undefined;

        $scope.onSelected = function() {
          $scope.options.allSelected = false;
          event.stopPropagation();
        };

        return $scope.selectAll = function() {
          if (!$scope.options.allSelected) {
            return $scope.selected.length = 0;
          } else {
            return Array.from($scope.gridOptions.data).map((device) => $scope.selected.push(device));
          }
        };
      }
      ],
      template: require('./mobile-devices.html').default
    }
  });
