angular
  .module('relcore.alert')
  .factory('Alert', function() {
    return class Alert {
      message;
      severity;

      constructor(message, severity) {
        this.message = message;
        this.severity = severity;
      }
    };
  });