import { IApiErrorResponse, IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { ICardAccount, ICardAccountActivity } from '../models/card-account';

export interface ICardAccountService {
  getAll(): ICardAccount[]
  getAccount(account: string): Promise<ICardAccount>
  createAccount(account: ICardAccount): Promise<IStatusResponse>
  createAccountActivity(activity: ICardAccountActivity): Promise<IStatusResponse>
}

class CardAccountService implements ICardAccountService {
  api: IRelianceApi;

  constructor(relianceApi: IRelianceApi) {
    this.api = relianceApi;
  }

  getAll(): ICardAccount[] {
    // TODO
    throw new Error("Method not implemented.");
  }

  async getAccount(account: string): Promise<ICardAccount> {
    return this.api.get(
      `/card-account/${account}`,
      {
        includeActivity: true,
        includePhonecardSalesData: true
      })
      .then((result: IApiResponse<ICardAccount>) => {
        return result.data;
      })
      .catch((err: IApiErrorResponse) => {
        return Promise.reject({
          status: err.data.error.code,
          message: err.data.error.message
        });
      });
  }

  createAccount(account: ICardAccount): Promise<IStatusResponse> {
    // TODO
    throw new Error("Method not implemented.");
  }

  createAccountActivity(activity: ICardAccountActivity): Promise<IStatusResponse> {
    // TODO
    throw new Error("Method not implemented.");
  }

}

angular
  .module('relcore.card')
  .service('cardAccountService', ['relianceApi',
    (relianceApi: IRelianceApi) => {
      return new CardAccountService(relianceApi);
    }
  ]);