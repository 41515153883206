angular
  .module('relcore.mobile-device')
  .directive('repairTickets', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?',
        onViewDetails: '='
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
        return $scope.lastNote = function(ticket) {
          const notes = JSON.parse(ticket.notes);
          const lastNote = notes[notes.length-1];
          if ((lastNote == null)) { return ''; }
          return lastNote.note;
        };
      }
      ],
      template: require('./repair-tickets.html').default
    })
  );
