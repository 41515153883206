import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { IMobileDeviceGroup } from '../mobile-device-group/mobile-device-group';

angular
  .module('relcore.housing-unit')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('housing-units-view', {
        controller: 'HousingUnitViewController',
        template: require('./view.html').default,
        url: '/housing-units/view/:id',
        parent: 'authenticated',
        params: {
          housingUnit: null
        },
        ncyBreadcrumb: {
          label: '{{housingUnit.name}}',
          parent: 'housing-units'
        }
      })
  ])
  .controller('HousingUnitViewController', [
    '$rootScope', '$scope', '$filter', '$state', 'housingUnitService', 'inmateService', 'alertService', 'stationService', '$stateParams', 'NgTableParams', 'moment', 'relianceApi',
    function($rootScope, $scope, $filter, $state, housingUnitService, inmateService, alertService, stationService, $stateParams, NgTableParams, moment, relianceApi: IRelianceApi) {
      const loadInmates = function() {
        if ($scope.inmateGridOptions) {
          return $scope.inmateGridOptions.reload();
        }

        return $scope.inmateGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            dateAdded: 'desc'
          },
          filter: {
            housingUnitId: $scope.housingUnit.id,
            dateAdded: {
              startDate: moment().subtract(10, 'year'),
              endDate: moment().endOf('day')
            }
          }
        }, {
          counts: [50,100,500],
          getData(params) {
            return inmateService.get(params.filter(), params.orderBy())
              .then(function(result) {
                params.total(result.total);
                $scope.selected = [];
                return result.data;
                });
          }
        });
      };

      const loadStations = function() {
        if ($scope.stationGridOptions) {
          return $scope.stationGridOptions.reload();
        }

        $scope.stationGridOptions = new NgTableParams({
          page: 1,
          sorting: {
            'created.date': 'desc'
          }
        }, {
          total: 0,
          counts: [],
          getData(params) {
            return stationService.getByHousingUnit($scope.housingUnit.id)
              .then(function(data) {
                params.total(data.length);

                if (params.filter()) {
                  data = $filter('filter')(data, params.filter());
                }
                if (params.sorting()) {
                  data = $filter('orderBy')(data, params.orderBy());
                }

                return data.slice((params.page()-1)*params.count(),
                  params.page() * params.count());
            });
          }
        });
      };

      const setHousingUnit = function(housingUnit) {
        $rootScope.title = housingUnit.name;
        $scope.housingUnit = housingUnit;
        loadInmates();
        loadStations();
        loadMobileDeviceGroups();
      };

      const loadHousingUnit = id =>
        housingUnitService.get(id)
          .then(setHousingUnit);

      if ($stateParams.housingUnit) {
        setHousingUnit($stateParams.housingUnit);
      } else {
        loadHousingUnit($stateParams.id);
      }

      const loadMobileDeviceGroups = function() {
        $scope.loadingMobileDeviceGroups = true;
        $scope.mobileDeviceGroups = [];
        relianceApi
          .index<IApiResponse<IPaginatedResponse<IMobileDeviceGroup>>>('/mobile-device/group', 1, 200, {
            facilityId: $scope.housingUnit.facility.id
          })
          .then(res => $scope.mobileDeviceGroups = res.data.data)
          .then(() => $scope.loadingMobileDeviceGroups = false);
      }

      $scope.saving = false;
      $scope.onSave = function(newUnit) {
        const onSuccess = response =>
          loadHousingUnit(newUnit.id)
            .then(function() {
              $scope.saving = false;
              alertService.success('The housing unit was successfully saved', true);
            });

        const onError = function(response) {
          $scope.saving = false;
          if (response.status === 409) {
            alertService.error('Enabled inmates are still assigned to this housing unit', true);
          } else {
            alertService.error('An error occured while saving', true);
          }
        };

        $scope.saving = true;
        housingUnitService.put({
          ...newUnit,
          mobileDeviceGroupId: newUnit.mobileDeviceGroup ? newUnit.mobileDeviceGroup.id : null,
          mobileDeviceGroup: undefined
        }).then(onSuccess, onError);
      };

      $scope.onInmateDetails = inmate =>
        $state.go('inmates-view', {
          inmateId:inmate.id,
          inmate
        });

      $scope.onStationDetails = station =>
        $state.go('stations-view', {
          stationId:station.id,
          station
        });
    }
  ]);
