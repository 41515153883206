angular
  .module('relcore.facility')
  .service('facilityUserListReducer', function() {
    var initialState = {
      apiRoles: []
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'api-roles/loaded':
          return Object.assign({}, state, {apiRoles: action.apiRoles});
      };

      return state
    };
  });
