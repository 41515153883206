import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { IMobileDeviceGroup } from '../mobile-device-group/mobile-device-group';

angular
  .module('relcore.facility')
  .controller('FacilityViewDeviceGroupsController',
    ['$scope', 'alertService', 'relianceApi', 'NgTableParams', '$stateParams', '$uibModal',
    function($scope, alertService, relianceApi:IRelianceApi, NgTableParams, $stateParams, $uibModal) {
      $scope.editDeviceGroup = (deviceGroup) => {
        const dialog = $uibModal.open({
          template: require('../mobile-device-group/edit-modal.html').default,
          controller: 'DeviceGroupEditCtrl',
          resolve: {
            mobileDeviceGroup() { return deviceGroup; }
          }
        });
        dialog.result.then(() => $scope.deviceGroupsGridOptions.reload());
      };

      $scope.deleteDeviceGroup = (deviceGroup) => {
        const dialog = $uibModal.open({
          template: require('../mobile-device-group/delete-modal.html').default,
          controller: 'DeviceGroupDeleteCtrl',
          resolve: {
            mobileDeviceGroup() { return deviceGroup; }
          }
        });
        dialog.result.then(() => $scope.deviceGroupsGridOptions.reload());
      };

      $scope.addDeviceGroup = () => {
        const dialog = $uibModal.open({
          template: require('../mobile-device-group/add-modal.html').default,
          controller: 'DeviceGroupAddCtrl',
          resolve: {
            facilityId() { return $stateParams.id; }
          }
        });
        dialog.result.then(() => $scope.deviceGroupsGridOptions.reload());
      };

      $scope.loadDeviceGroups = (refresh: boolean = false) => {
        if (!refresh && $scope.deviceGroupsGridOptions) { return; }

        $scope.deviceGroupsGridOptions = new NgTableParams(
          {},
          {
            getData() {
              $scope.onFetch('Device Groups');
              return relianceApi.index<IApiResponse<IPaginatedResponse<IMobileDeviceGroup>>>('/mobile-device/group', 1, 500, { facilityId: $stateParams.id })
                .then(res => {
                  $scope.onFetched('Device Groups');
                  return res.data.data;
                },
                () => {
                  $scope.onError('Device Groups');
                  alertService.error('An error occurred while loading device groups', true);
                }
              );
            }
          }
        );
      };

      $scope.loadDeviceGroups();
    }
  ]);
