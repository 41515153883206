import { IApiRole } from '../models/auth';

interface IApiCredentialChanges {
  password?: string
  roleIds?: number[]
  roleNames?: string[]
  isEnabled?: boolean
}

interface ISearchApiUserController {
  apiRoles: Array<IApiRole>

  adding: boolean
  onAdd(user): Promise<void>

  criteria: any
  searching: boolean

  results: Array<any>
  gridOptions: any

  reset(): void
  search(): void

  view(apiUser): void

  updating: boolean
  usersChangingStatus: number[]
  onChangeStatus(apiUser, isEnabled: boolean): Promise<any>
  onUpdate(apiUser, changes: IApiCredentialChanges): Promise<any>
}

angular
  .module('relcore.api-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('api-user-search', {
        controller: 'SearchApiUserController',
        url: '/api-user/search',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'API Users'
        },
        template: require('./main.html').default
      }
    )
  ])
  .controller('SearchApiUserController',
    ['$rootScope', '$scope', '$state', 'store', 'ReduxMapToScope', 'apiRolesActions', 'relianceApi', 'alertService', 'apiUserService', 'NgTableParams',
    function($rootScope, $scope: ISearchApiUserController, $state, store, ReduxMapToScope, rolesActions, relianceApi, alertService, apiUserService, NgTableParams) {
      $rootScope.title = 'API User Search';
      $scope.apiRoles = [];

      ReduxMapToScope($scope, state => $scope.apiRoles = state.facilityUser.list.apiRoles);

      // Load API roles
      store.dispatch(rolesActions.load());

      $scope.adding = false;
      $scope.onAdd = function(user) {
        $scope.adding = true;
        return relianceApi.post("/api-credential", user)
          .then((function(res) {
            $scope.adding = false;
            $scope.gridOptions.reload();
            alertService.success('The credential was added', true);
          }), function(err) {
            $scope.adding = false;
            var message = err.data.error.message;
            alertService.error(`An error occurred while adding: ${message}`, true);
          });
      };

      $scope.criteria = {};
      $scope.searching = false;

      $scope.results = [];
      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: { id: 'desc' }
      }, {
        total: 0,
        counts: [100,200,500],
        getData(params) {
          $scope.searching = true;
          const criteria = {...$scope.criteria };
          criteria.userType = $scope.criteria.userType || {};
          criteria.userType = Object.keys(criteria.userType).filter(t => criteria.userType[t]).join(',');
          return relianceApi.index('/api-credential', params.page(), params.count(), criteria, params.orderBy())
            .then((function(res) {
              $scope.searching = false;
              params.total(res.data.total);
              return res.data.data;
            }), err => $scope.searching = false);
        }
      });

      $scope.reset = () => $scope.criteria = {};
      $scope.search = () => $scope.gridOptions.reload();

      $scope.view = apiUser => $state.go('api-user-view', { id: apiUser.id });

      $scope.usersChangingStatus = [];
      $scope.onChangeStatus = (apiUser, isEnabled: boolean) => {
        if ($scope.usersChangingStatus.indexOf(apiUser.id) === -1) {
          $scope.usersChangingStatus = [apiUser.id];
        }

        return $scope
          .onUpdate(apiUser, {isEnabled})
          .then(() =>
            $scope.usersChangingStatus = $scope.usersChangingStatus.filter(id => id != apiUser.id)
          );
      };

      $scope.onUpdate = (apiUser, changes: IApiCredentialChanges) => {
        $scope.updating = true;
        return apiUserService.updateUser(apiUser, changes)
                             .then(() => {$scope.updating = false; $scope.gridOptions.reload()},
                                   () => $scope.updating = false
                             );
      }
    }
  ]);
