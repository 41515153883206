import { composeValidators } from '@reliance/forms-js/lib/validation';
import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { Field } from 'react-final-form';

type CheckboxProps = {
  name: string;
  label: string;
  validationRules?: any[];
  helperText?: string;
}

const Checkbox: React.FC<CheckboxProps> = (props) =>
  <Field
    name={props.name}
    validate={composeValidators(props.validationRules || [])}>
    {({ input, meta }) =>
      <FormGroup>
        <Form.Check
          {...input}
          checked={input.value as any == true || input.value == 'true'}
          label={props.label}
          type="checkbox" />
        {((meta.error && meta.touched) || props.helperText) &&
          <span className="help-block has-error">{meta.error && meta.touched ? meta.error : props.helperText}</span>
        }
      </FormGroup>
    }
  </Field>

export default Checkbox;