import { IAlertService } from '../alert/alert-service';
import { IConfig } from '../common/config';
import { IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { IUtils } from '../common/utils';
import { IInmate } from "../inmate/inmate-service";
import { IAccount, IAccountLinkStatus, IBilling, ISimilarAccount } from "../models/account";
import { ITextMessage, TextMessageSentBy } from "../text-message/text-message-service";
import { IAcccountLinkStatusService, IAccountService } from "./account-service";
import { IPaymentService } from './payment/payment-service';

interface IAccountViewScope extends ng.IScope {
  ani: string
  account: IAccount

  // Constants
  invoiceFrequency: number[]
  unblockAmounts: number[]
  onlineMonthlyLimits: number[]
  statusValues: IAccountLinkStatus[]

  fetching: boolean
  loaded: boolean
  saving: boolean
  savingOnlineAccount: boolean
  removing: boolean
  error: boolean
  loading: boolean
  isFetching(name: string): boolean
  onFetch(name: string): void
  onFetched(name: string): void

  loadAccount(): Promise<IAccount>
  updateAccount(): void
  updateOnlineAccount(): void
  saveNotes(): void
  hasName(account: IAccount): boolean

  onlinePaymentHistoryInstance: any
  onlinePaymentHistoryOptions: any
  onlinePaymentHistoryColumns: any

  additionalAccountsInstance: any
  additionalAccountsOptions: any
  additionalAccountsColumns: any

  message: {text: string}
  showNewMessageView(): void

  textHistoryTableInstance: any
  textHistoryOptions: any
  textHistoryColumns: any

  toggleStaffMessagesOnly(): void
  onlyStaff: boolean

  updateOnlineAccountOptions(): void
  removeLink(): void

  paymentService: IPaymentService
  showAccountPaymentView: boolean
  openAccountPaymentView(): void
  closeAccountPaymentView(success: boolean): void

  showDebitsCreditsView: boolean
  openDebitsCreditsView(): void
  closeDebitsCreditsView(success: boolean): void

  similarAccountsInstance: any
  similarAccountsColumns: Array<any>
  similarAccountsOptions: any

  similarAccountsFilterFlags: object
  similarAccountsFilter: object
  similarAccounts: ISimilarAccount[]
  updateFilter(): void
  loadSimilarAccounts(): void
  isEmpty(obj: object): boolean
}

angular
  .module('relcore.account')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('accounts', {
        url: '/account',
        parent: 'authenticated',
        abstract: true
      }).state('accounts-view', {
        controller: 'AccountViewController',
        template: require('./view.html').default,
        url: '/account/:ani',
        params: {ani: null},
        parent: 'authenticated',
        ncyBreadcrumb: {
          label:'Account / {{ani | tel}}'
        }
      })
  ])
  .controller('AccountViewController',
    ['$scope', '$rootScope', '$stateParams', '$interval', 'accountService',
     'eventService', 'telFilter', 'Account', 'alertService', 'relianceApi',
     'config', 'textMessageService', 'DTOptionsBuilder', 'DTColumnBuilder',
     '$filter', '$compile', '$uibModal', 'AccountLinkStatusValues',
     'AccountLinkStatusService', 'PaymentService', 'Utils',

    function($scope: IAccountViewScope, $rootScope, $stateParams, $interval,
      accountService: IAccountService, eventService: IEventService, telFilter,
      Account, alertService: IAlertService, relianceApi: IRelianceApi, config: IConfig,
      textMessageService, DTOptionsBuilder, DTColumnBuilder, $filter, $compile,
      $uibModal, AccountLinkStatusValues: Array<IAccountLinkStatus>,
      AccountLinkStatusService: IAcccountLinkStatusService, paymentService: IPaymentService,
      Utils: IUtils) {

      $scope.paymentService = paymentService;
      $scope.loaded = false;
      $scope.ani = $stateParams.ani;
      if ($scope.ani.length > 10) {
        $scope.ani = $scope.ani.substring(1);
      }
      $rootScope.title = telFilter($scope.ani);

      $scope.invoiceFrequency = config.account.invoiceFrequency;
      $scope.unblockAmounts = config.account.unblockAmounts;
      $scope.onlineMonthlyLimits = config.account.onlineMonthlyLimits;
      $scope.statusValues = AccountLinkStatusValues;

      $scope.isFetching = eventService.isFetching;
      $scope.onFetch = eventService.onFetch;
      $scope.onFetched = eventService.onFetched;

      $scope.saveNotes = () =>
        relianceApi.put(`/account/notes?ani=${$scope.ani}&notes=${$scope.account.notes}`)
          .then((function() {}), function() {
            alertService.error("Notes failed to save.", true);
          });

      const updateCustomerTime = function() {
        if (!$scope.account) { return; }
        accountService.updateCustomerTime($scope.account);
      };

      const updateTimeTimer = $interval(updateCustomerTime, 1000);
      $scope.$on('$destroy', () => $interval.cancel(updateTimeTimer));

      $scope.hasName = function(account: IAccount) {
        return account && (account.firstName.length > 0 || account.lastName.length > 0);
      }

      $scope.updateAccount = async () => {
        $scope.saving = true;
        alertService.clearAll();

        try {
          const result: IStatusResponse = await accountService
            .updateAccount($scope.ani, $scope.account);
          if (result.success) {
            alertService.success("Account updated successfully", true);
          } else {
            alertService.error(`Problem encountered while updating: ${result.error}`, true);
          }
        } catch (error) {
          alertService.error("Account update failed", true);
        } finally {
          $scope.saving = false;
        }
      };

      $scope.updateOnlineAccount = async () => {
        $scope.savingOnlineAccount = true;

        try {
          alertService.clearAll();
          await relianceApi
            .put(`/onlineuser/settings`, {
              onlineUserId: $scope.account.onlineUser.id,
              profileChange: $scope.account.onlineUser
            });
          alertService.success('The online user details were successfully updated', true);
        } catch (error) {
          alertService.error('An error occurred while saving the online user', true);
        } finally {
          $scope.savingOnlineAccount = false;
        }
      };

      $scope.loading = false;
      $scope.loadAccount = async () => {
        try {
          $scope.loading = true;

          const account: IAccount = await accountService.openAccount($scope.ani);
          $scope.account = account;
          $scope.error = false;
          $scope.loading = false;

          $scope.additionalAccountsOptions = DTOptionsBuilder
            .fromFnPromise(Promise.resolve(account.additionalAccounts))
            .withOption('lengthChange', false)
            .withDisplayLength(100)
            .withBootstrap()
            .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

          $scope.onlinePaymentHistoryOptions = DTOptionsBuilder
            .fromFnPromise(Promise.resolve(account.transactions))
            .withOption('lengthChange', false)
            .withDisplayLength(100)
            .withBootstrap()
            .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

          return $scope.account;
        } catch (error) {
          alertService.error("Unable to load account", true);
          $scope.error = true;
          $scope.loading = false;
          return null;
        }
      };

      $scope.onlinePaymentHistoryInstance = {};

      const filterRenderer = (name: string) => (data: any) => $filter(name)(data);
      const amountRenderer = (amount: number) => {
        if (amount === null) return '';
        return amount < 0 ? `($${Math.abs(amount).toFixed(2)})` : `$${Number(amount).toFixed(2)}`;
      };
      const accountLinkRenderer = (ani: string) =>
        `<a href="/account/${ani}">${$filter('tel')(ani)}</a>`;

      $scope.onlinePaymentHistoryColumns = [
          DTColumnBuilder.newColumn('date').withTitle('Date').renderWith(filterRenderer('datetimeFormat')),
          DTColumnBuilder.newColumn('transactionType').withTitle('Type').renderWith(filterRenderer('transactionSubType')),
          DTColumnBuilder.newColumn('amount').withTitle('Amount').renderWith(amountRenderer),
      ];

      $scope.additionalAccountsInstance = {};
      $scope.additionalAccountsColumns = [
        DTColumnBuilder.newColumn('ani').withTitle('Ani').renderWith(accountLinkRenderer),
        DTColumnBuilder.newColumn('billing.firstName').withTitle('First Name').withOption('defaultContent', ''),
        DTColumnBuilder.newColumn('billing.lastName').withTitle('Last Name').withOption('defaultContent', ''),
        DTColumnBuilder.newColumn('accountLinkStatus').withTitle('Link Status').renderWith(filterRenderer('accountLinkStatus')),
        DTColumnBuilder.newColumn('balance').withTitle('Balance').renderWith(amountRenderer)
      ];

      $scope.message = {text: ""};
      $scope.showNewMessageView = () => {
        const dialog = $uibModal.open({
          size: 'md',
          template: require('./new-text-message-modal.html').default,
          controller: 'TextMessageDialogController',
          resolve: {
            destination() {return $scope.ani},
            message() {return $scope.message}
          }
        });
        dialog.result.then(() => {alertService.success("Message sent")});
      };

      $scope.textHistoryTableInstance = {};
      $scope.textHistoryOptions = DTOptionsBuilder
        .fromFnPromise(async () => {
          let filter = {customers: [$scope.ani]};
          if ($scope.onlyStaff) {
            filter["sentBy"] = TextMessageSentBy.STAFF;
          }

          $scope.fetching = true;
          try {
            const response: IApiResponse<Array<ITextMessage>> = await textMessageService
              .getTextMessages(filter);
            return response.data;
          } catch (error) {
            alertService.error('Unable to load text history');
            return null;
          } finally {
            $scope.fetching = false;
          }
        })
        .withOption('lengthChange', false)
        .withDisplayLength(100)
        .withOption('createdRow', row => {
          $compile(angular.element(row).contents())($scope);
        })
        .withBootstrap()
        .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

      const inmateRenderer = (inmate: IInmate) => {
        return `<a ui-sref="inmates-view({id:${inmate.id}})">`
          + `${inmate.firstName} ${inmate.lastName} (${inmate.account})</a>`;
      };

      $scope.textHistoryColumns = [
          DTColumnBuilder.newColumn('createdDate.date').withTitle('Date').renderWith(filterRenderer('datetimeFormat')),
          DTColumnBuilder.newColumn('inmate').withTitle('Inmate').renderWith(inmateRenderer),
          DTColumnBuilder.newColumn('direction').withTitle('Direction'),
          DTColumnBuilder.newColumn('status').withTitle('Status'),
          DTColumnBuilder.newColumn('message').withTitle('Message')
      ];

      $scope.onlyStaff = false;
      $scope.toggleStaffMessagesOnly = () => {
        $scope.onlyStaff = !$scope.onlyStaff;
        $scope.textHistoryTableInstance.rerender();
      }

      $scope.removeLink = async () => {
        $scope.removing = true;
        alertService.clearAll();
        try {
          const response: IStatusResponse = await accountService
            .removeLink($scope.ani, $scope.account.onlineUser.id);
          if (response.success) {
            $scope.account.accountLinkStatus = AccountLinkStatusService.getAccountLinkStatus();
            alertService.success("Account link removed successfully", true);
          } else {
            alertService.error(`Problem encountered while updating: ${response.error}`, true);
          }
        } catch (error) {
          alertService.error("Account link remove failed", true);
        } finally {
          $scope.removing = false;
        }
      }

      // Load the account
      $scope.loadAccount();

      $scope.openAccountPaymentView = () => {
        $scope.showAccountPaymentView = true;
      };

      $scope.closeAccountPaymentView = (success: boolean) => {
        $scope.showAccountPaymentView = false;
        if (success) {
          alertService.success('The payment was successfully applied', true);
          $scope.loadAccount();
        }
      };

      $scope.openDebitsCreditsView = () => {
        $scope.showDebitsCreditsView = true;
      };

      $scope.closeDebitsCreditsView = (success: boolean) => {
        $scope.showDebitsCreditsView = false;
        if (success) {
          alertService.success('The debit/credit was successfully applied', true);
          $scope.loadAccount();
        }
      };

      $scope.similarAccounts = [];
      $scope.loadSimilarAccounts = async () => {
        $scope.onFetch('Similar Accounts');
        alertService.clearAll();
        try {
          const result: ISimilarAccount[] = await accountService
            .getSimilarAccounts($scope.ani, $scope.similarAccountsFilter);

          $scope.similarAccounts = result;
          $scope.similarAccountsOptions = DTOptionsBuilder
            .fromFnPromise(() => Promise.resolve(result))
            .withOption('lengthChange', false)
            .withOption('paging', false)
            .withOption('searching', false)
            .withOption('info', false)
            .withDisplayLength(100)
            .withBootstrap()
            .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");
        } catch (error) {
          alertService.error("Similar accounts failed to load", true);
        } finally {
          $scope.onFetched('Similar Accounts');
          $scope.$apply();
        }
      };

      const customerNameRenderer = (billing: IBilling) => {
        return `${billing.firstName} ${billing.lastName}`;
      };

      $scope.similarAccountsInstance = {};
      $scope.similarAccountsColumns = [
        DTColumnBuilder.newColumn('ani').withTitle('ANI'),
        DTColumnBuilder.newColumn('billing').withTitle('Customer').renderWith(customerNameRenderer),
        DTColumnBuilder.newColumn('billing.address1').withTitle('Address'),
        DTColumnBuilder.newColumn('billing.city').withTitle('City'),
        DTColumnBuilder.newColumn('billing.state').withTitle('State'),
        DTColumnBuilder.newColumn('balance.balance').withTitle('Balance').renderWith(amountRenderer)
      ];
      $scope.similarAccountsOptions = DTOptionsBuilder
        .fromFnPromise(Promise.resolve($scope.similarAccounts))
        .withOption('lengthChange', false)
        .withOption('paging', false)
        .withOption('searching', false)
        .withOption('info', false)
        .withDisplayLength(100)
        .withBootstrap()
        .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

      $scope.similarAccountsFilterFlags = {};
      $scope.similarAccountsFilter = {};
      $scope.updateFilter = () => {
        for (const [key, value] of Object.entries($scope.similarAccountsFilterFlags)) {
          if (value == true) {
            $scope.similarAccountsFilter[key] = $scope.account[key];
          } else {
            delete $scope.similarAccountsFilter[key];
          }
        }
      };

      $scope.isEmpty = (obj: object): boolean => {
        return Utils.isEmpty(obj);
      };
    }
  ]);
