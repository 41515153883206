angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketInboundViewController', [
    '$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, alertService, $stateParams, NgTableParams) {
      $scope.devicesNeedingReturn = [];
      $scope.ticket = {};
      $scope.closingNotes = { notes: '' };
      $scope.closedWithoutNotes = false;

      $scope.noteListGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          const notes = JSON.parse($scope.ticket.notes||'[]');
          return notes;
        }
      });

      $scope.deviceGridOption = new NgTableParams({
        page: 1
      }, {
        counts: null,
        getData(params) {
          return $scope.ticket.shippingTicketDevices;
        }
      });

      $scope.devicesReturned = function() {
        if (($scope.ticket.shippingTicketDevices == null)) {
          return [];
        }
        return $scope.ticket
          .shippingTicketDevices
          .filter(d => d.status === 'Received')
          .map(d => d.id);
      };

      $scope.devicesNotReceived = function() {
        const returned = $scope.devicesReturned();
        if (($scope.ticket.shippingTicketDevices == null)) {
          return [];
        }
        return $scope.ticket.shippingTicketDevices
          .filter(device => (returned.indexOf(device.mobileDevice.id) === -1) && (device.status === 'Created'));
      };

      const setTicket = function(ticket) {
        $scope.ticket = ticket;
        $rootScope.title = `Inbound Ticket #${$scope.ticket.id}`;
        $scope.devicesNeedingReturn = ($scope.ticket.shippingTicketDevices || [])
          .filter(d => (d.status !== 'Not Received') && (d.status !== 'Received'))
          .map(d => d.id);
        $scope.noteListGridOptions.reload();
        $scope.deviceGridOption.reload();
      };

      const loadTicket = id =>
        relianceApi
          .get(`/shipping-ticket/${id}`)
          .then(res => setTicket(res.data))
      ;

      if ($stateParams.ticket) {
        setTicket($stateParams.ticket);
        loadTicket($scope.ticket.id);
      } else {
        loadTicket($stateParams.id);
      }

      const resetNote = () => $scope.newNote = { note: '' };
      resetNote();

      $scope.addNote = note =>
        relianceApi.put(`/shipping-ticket/${$scope.ticket.id}`, { request: {notes: note.note} })
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('The note was successfully added', true);
            resetNote();
          }), (() => alertService.error('An error occurred while adding the note', true)))
      ;

      $scope.savingInfo = false;
      $scope.saveInfo = function(info) {
        $scope.savingInfo = true;
        relianceApi.put(`/shipping-ticket/${$scope.ticket.id}`, info)
          .then((function() {
            $scope.savingInfo = false;
            loadTicket($scope.ticket.id);
            alertService.success('The information was successfully saved', true);
          }), (function() {
            $scope.savingInfo = false;
            alertService.error('An error occurred while saving the ticket information', true);
          }));
      };

      $scope.cancelNotes = '';
      $scope.cancelTicket = function() {
        const dialog = $uibModal.open({
          template: require('./cancel/cancel.html').default,
          controller: 'shippingTicketCancelController',
          resolve: {
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.canceled) {
            loadTicket($scope.ticket.id);
          }
        });
      };

      $scope.inspectDevice = function(ticketDevice) {
        ticketDevice.inspecting = true;
        if (ticketDevice.status !== 'Received') {
          relianceApi.put(`/shipping-ticket/${$scope.ticket.id}/devices-received`, {
            returnedDeviceIds: ticketDevice.id.toString()
            })
            .then(function() {
              ticketDevice.mobileDevice.status = 'Needs Inspection';
              promptInspection(ticketDevice);
            });
        } else {
          promptInspection(ticketDevice);
        }
      };

      var promptInspection = function(ticketDevice) {
        const dialog = $uibModal.open({
          template: require('./../mobile-device/inspect/inspect.html').default,
          controller: 'MobileDeviceInspectController',
          resolve: {
            device() { return ticketDevice.mobileDevice; }
          }
        });
        dialog.result.then(function(result) {
          ticketDevice.inspecting = false;

          if (result.passed === null) {
            return;
          }

          loadTicket($scope.ticket.id);
        });
      };

      $scope.saving = false;
      $scope.markDevicesReceived = function() {
        $scope.saving = true;
        relianceApi.put(`/shipping-ticket/${$scope.ticket.id}/devices-received`, {
          returnedDeviceIds: $scope.devicesReturned().join(',')
          })
          .then((function() {
            $scope.saving = false;
            alertService.success('The devices were marked as received', true);
            loadTicket($scope.ticket.id);
          }), (function() {
            $scope.saving = false;
            alertService.error('An error occurred while marking the devices received', true);
          }));
      };

      $scope.close = function() {
        $scope.closedWithoutNotes = $scope.closingNotes.notes === '';
        if ($scope.closedWithoutNotes) { return; }

        $scope.saving = true;
        relianceApi.delete(`/shipping-ticket/${$scope.ticket.id}?notes=${$scope.closingNotes.notes}`)
          .then((function() {
            $scope.saving = false;
            alertService.success('The ticket was closed', true);
            loadTicket($scope.ticket.id);
          }), (function() {
            $scope.saving = false;
            alertService.error('An error occurred while closing the ticket', true);
          }));
      };

      $scope.markTicketShipped = function() {
        const dialog = $uibModal.open({
          template: require('./ship/ship.html').default,
          controller: 'ShippingTicketShipController',
          resolve: {
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.saved) {
            loadTicket($scope.ticket.id);
          }
        });
      };
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('shipping-ticket-inbound-view', {
        controller: 'ShippingTicketInboundViewController',
        url: '/shipping-ticket/inbound/view/:id',
        parent: 'authenticated',
        params: {
          ticket: null
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'shipping-tickets-inbound'
        },
        template: require('./inbound-view.html').default
      }
    )


  ]);
