angular
  .module('relcore.auth')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('anonymous.logout', {
        url: '/logout',
        controller: 'LogoutController'
      }
    )
  ])
  .controller('LogoutController', ['auth', function(auth) {
    auth.logout();
  }]);