angular
  .module('relcore.sms-voice-number')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('sms-voice-number-usage-summary', {
      controller: 'SmsVoiceNumberUsageSummaryController',
      template: require('./usage-summary.html').default,
      url: '/sms-voice-numbers/usage-summary',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'SMS/Voice Number Usage Summary' }
    })

  ])
  .controller('SmsVoiceNumberUsageSummaryController', [
    '$rootScope', '$scope', 'relianceApi',
    function($rootScope, $scope, relianceApi) {
      $rootScope.title = 'SMS/Voice Number Usage Summary';
      $scope.years = __range__(2015, new Date().getFullYear(), true);
      $scope.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      $scope.model = {
        selectedYear: `${new Date().getFullYear()}`,
        selectedMonth: `${new Date().getMonth() + 1}`
      };

      $scope.reportData = null;
      return $scope.load = function() {
        $scope.loading = true;
        return relianceApi.get(`/smsvoice-number/usage-summary/${$scope.model.selectedYear}/${$scope.model.selectedMonth}`)
          .then(function(res) {
            $scope.loading = false;
            return $scope.reportData = res.data;
          });
      };
    }
  ]);

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}