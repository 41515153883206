angular
  .module('relcore.schedule')
  .directive('scheduleAdd', () =>
    ({
      restrict: 'E',
      scope: {
        onAdd: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.newSchedule = {
          type: ''
        };
        $scope.add = () => $scope.onAdd($scope.newSchedule);
      }
      ],
      template: require('./schedule-add.html').default
    })
  );
