angular
  .module('relcore.salt')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('salt-minion-view', {
      controller: 'SaltMinionViewController',
      url: '/salt-minions/view/:id',
      parent: 'authenticated',
      ncyBreadcrumb: {
        label: '{{id}}',
        parent: 'salt-minions'
      },
      template: require('./view.html').default
    });
  }])
  .controller('SaltMinionViewController', [
    '$rootScope', '$scope', '$state', '$stateParams', '$sce', 'relianceApi', 'alertService', 'NgTableParams', 'diff2html',
    function($rootScope, $scope, $state, $stateParams, $sce, relianceApi, alertService, NgTableParams, diff2html) {
      $scope.id = $stateParams.id;
      $rootScope.title = $scope.id;

      relianceApi.get('/salt/minion/' + $scope.id)
        .then((response) => {
          $scope.saltMinion = response.data;

          $scope.grainsGridOptions = new NgTableParams({}, {dataset: $scope.saltMinion.grains});
          $scope.updatesGridOptions = new NgTableParams({}, {dataset: $scope.saltMinion.updates});
        });

      $scope.getDiffHtml = (update) => {
        let diff = `--- a/${update.name}\n+++ b/${update.name}\n${update.changes.diff.replace(/^--- \n\+\+\+ \n/, '')}`;
        return $sce.trustAsHtml(diff2html.getPrettyHtml(diff, {inputFormat: 'diff', showFiles: false, matching: 'lines'}));
      };

      $scope.toggleUpdateDetails = (update) => {
        update.show = !update.show;
      };

      $scope.applyingStates = false;
      $scope.applyStates = () => {
        $scope.applyingStates = true;
        relianceApi.put(`/salt/minion/${$scope.id}/highstate`)
          .then((response) => {
            $scope.applyingStates = false;
            alertService.success('States will be applied shortly', true);
          }, (response) => {
            $scope.applyingStates = false;
            alertService.error('The states could not be applied at this time', true);
          });
      };
    }
  ]);
