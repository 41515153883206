import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Configure sentry for production
if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://0db50dfc285343a19583d07eec296388@o36100.ingest.sentry.io/4504571597160448',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.APP_ENV || 'development',
    release: 'relcore-central@' + process.env.APP_VERSION,
    normalizeDepth: 5,
    beforeSend(event) {
      event.breadcrumbs = (event.breadcrumbs || []).filter(
        (b) =>
          // Filter out redux logging messages
          b.data == null ||
          typeof b.data.arguments != 'object' ||
          b.data.arguments.length < 3 ||
          b.data.arguments[1].indexOf('color: #') !== 0
      );
      return event;
    }
  });
}

export default Sentry;
