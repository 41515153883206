angular
  .module('relcore.directives')
  .directive('relFormGroup', () =>
    ({
      restrict: 'EA',
      require: '^form',
      transclude: true,
      template: require('./form-group-directive.html').default,
      scope: {
        'field': '@',
        'manualError': '=manualError',
        'required': '=ngRequiredMsg',
        'minlength': '=ngMinlengthMsg',
        'min': '=ngMinMsg',
        'pattern': '=ngPatternMsg',
        'validationMessages': '=validationMessages'
      },
      link(scope:any, element, attrs, formCtrl:any) {
        scope.errorMessage = '';
        scope.groupClass = 'form-group';
        const validationMessages = scope.validationMessages || {};

        const createErrorMessage = function(rule) {
          const errorMessage = scope[rule] || validationMessages[rule];
          if (errorMessage != null) { return errorMessage; }

          if (rule === 'required') {
            return 'This is required';
          }
          if (rule === 'minlength') {
            return 'Minimum length not reached';
          }
          if (rule === 'min') {
            return 'Minimum value not reached';
          }
        };

        scope.showError = function() {
          if ((scope.field == null)) {
            console.log("Missing 'field' attribute on form group.");
          }

          const field = formCtrl[scope.field];
          if ((field == null)) { return; }

          // Check to see if the field is invalid, changed at least once (or the form was submitted)
          const show = field.$invalid && (!field.$pristine || formCtrl.$submitted);

          // If there is an error, retrieve the message based on the first rule that we find that is broken
          if (show) {
            for (let rule in field.$error) {
              const invalid = field.$error[rule];
              if (!invalid) { continue; }
              scope.errorMessage = createErrorMessage(rule);
              break;
            }
          }

          return show;
        };
      }
    })
);