import { IApiResponse, IStatusResponse } from '../common/reliance-api-service';
import { ITaxRate, ITaxService } from "./tax-service";

interface ITaxRateEditScope extends ng.IScope {
  taxRate: ITaxRate;
  saving: boolean;
  error: string;
  save: () => void;
  cancel: () => void;
}

angular
  .module('relcore.tax')
  .controller('TaxRateEditController', ['$scope', 'taxRate', 'TaxService', '$uibModalInstance',
    ($scope: ITaxRateEditScope, taxRate: ITaxRate, TaxService: ITaxService, $uibModalInstance) => {
      $scope.taxRate = taxRate;

      $scope.save = () => {
        $scope.saving = true;
        TaxService.updateTaxRate(taxRate)
          .then(resolve, reject);
      };

      const resolve = (response: IApiResponse<IStatusResponse>) => {
        $scope.saving = false;
        if (!response.data || !response.data.success) {
          $scope.error = 'An unexpected error occurred';
        } else {
          $scope.error = null;
          $uibModalInstance.close({saved: true});
        }
      };

      const reject = (err: any) => {
        if (err.status === 400) {
          $scope.error = 'Please verify the inputs are valid and try again';
        } else {
          $scope.error = 'An unexpected error occurred';
        }
        $scope.saving = false;
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    }
  ]);