import { IRelianceApi } from '../common/reliance-api-service';
import { ICallRoute } from '../models/call-route';

interface ICallRouteCreateEditScope {
  error?: string
  editing: boolean
  route: ICallRoute
  saving: boolean
  save(): Promise<ICallRoute>
  close(): void
}

angular
  .module('relcore.call')
  .controller('CallRouteCreateEditController', [
    '$scope', 'relianceApi', '$uibModalInstance', 'editRoute',
    function ($scope:ICallRouteCreateEditScope, relianceApi:IRelianceApi, $uibModalInstance, editRoute: ICallRoute) {
      $scope.error = null;
      $scope.editing = editRoute != null;

      const route: ICallRoute = editRoute || {
        name: '',
        type: null,
        dialing: null,
        route: ''
      };

      $scope.route = Object.assign({}, route);

      // Handle form submission
      $scope.save = function () {
        $scope.saving = true;
        let promise = null;
        if ($scope.editing) {
          promise = relianceApi.put(`/call/route/${$scope.route.id}`, { route: $scope.route });
        } else {
          promise = relianceApi.post(`/call/route`, { route: $scope.route });
        }

        promise.then((function (res) {
          $scope.saving = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({ saved: true });
          }
        }), (function (err) {
          if (err.status === 400) {
            $scope.error = 'Please verify the inputs are valid and try again';
          } else {
            $scope.error = 'An unexpected error occurred';
          }
          $scope.saving = false;
        }));

        return promise;
      };

      $scope.close = () => $uibModalInstance.close({ saved: false })
    }
  ]);
