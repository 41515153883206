angular
  .module('relcore.mobile-device')
  .controller('RepairTicketViewPrintController', [
    '$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, alertService, $stateParams, NgTableParams) {
      $scope.ticket = { mobileDevice: {} };
      $scope.closingNotes = { notes: '' };

      $scope.noteListGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          return JSON.parse($scope.ticket.notes||'[]');
        }
      });

      $scope.partsUsedGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          return JSON.parse($scope.ticket.partsUsed||'[]');
        }
      });

      $scope.triggeredPrint = false;
      const setTicket = function(ticket) {
        $scope.ticket = ticket;
        $rootScope.title = `Repair Ticket #${$scope.ticket.id}`;
        $scope.noteListGridOptions.reload();
        $scope.partsUsedGridOptions.reload();
        if (($stateParams.triggerPrint === true) && !$scope.triggeredPrint) {
          $scope.triggeredPrint = true;
          setTimeout((() => angular.element(document).ready(() => window.print())), 500);
        }
      };

      const loadTicket = id =>
        relianceApi
          .get(`/repair-ticket/${id}`)
          .then(res => setTicket(res.data))
      ;

      if ($stateParams.ticket) {
        setTicket($stateParams.ticket);
        loadTicket($scope.ticket.id);
      } else {
        loadTicket($stateParams.id);
      }
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('repair-ticket-print', {
        controller: 'RepairTicketViewPrintController',
        url: '/repair-ticket/print/:id',
        parent: 'authenticated',
        params: {
          ticket: null,
          triggerPrint: false
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'repair-tickets'
        },
        template: require('./print.html').default
      }
    )

  ]);
