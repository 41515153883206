angular
  .module('relcore.report')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('text-message-cost-recovery-report', {
        controller: 'TextMessageCostRecoveryReportController',
        url: '/report/text-message/cost-recovery',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Texting Cost Recovery Report',
          parent: 'authenticated'
        },
        template: require('./cost-recovery.html').default
      }
    )
  ])
  .controller('TextMessageCostRecoveryReportController',
    ['$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'moment',
    function($rootScope, $scope, relianceApi, NgTableParams, moment) {
      $rootScope.title = 'Texting Cost Recovery Report';

      $scope.facilityIds = $rootScope.facilitiesToDisplay;
      $scope.facility = null;
      $scope.total = 0;
      $scope.costRecoveryFee = 0;
      $scope.dateRange = {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day')
      };
      $scope.loaded = false;

      $scope.gridOptions = new NgTableParams({
          sorting: {
            'facility.name': 'asc'
          }
        },
        {
          counts: [],
          getData(params) {
            if (($scope.dateRange.startDate == null) || ($scope.facilityIds == null)) { return []; }

            const queryParams = {
              startDate: $scope.dateRange.startDate.format('YYYY/MM/DD 00:00:00'),
              endDate: $scope.dateRange.endDate.format('YYYY/MM/DD 23:59:59'),
              facilityIds: $scope.facilityIds
            };

            if ($scope.facilityIds != null) {
              queryParams.facilityIds = $scope.facilityIds;
            }

            return relianceApi.get("/report/text-message/cost-recovery", queryParams)
              .then(function(response) {
                $scope.loaded = true;
                $scope.total = response.data.total;
                $scope.costRecoveryFee = response.data.costRecoveryFee;
                return response.data.results;
              });
          }
        }
      );

      $scope.run = () => $scope.gridOptions.reload();
      $scope.reset = () => $scope.loaded = false;
    }
    ]);
