import { IApiResponse, IStatusResponse } from '../common/reliance-api-service';
import { IEventNotificationService } from "./event-notification-service";

angular
  .module('relcore.event-notification')
  .controller('PREAConfigurationUpdateController', ['$scope', 'eventNotificationService',
    '$uibModalInstance', 'triggerId', 'actionId', 'name', 'email',
    ($scope, eventNotificationService: IEventNotificationService, $uibModalInstance,
      triggerId: number , actionId: number, name: string, email: string
      ) => {
      $scope.configuration = {
        name: name,
        email: email
      }

      $scope.save = () => {
        $scope.saving = true;
        eventNotificationService.updatePREAAction(
          {
            triggerId: triggerId,
            emailAction: {
              actionId: actionId,
              name: $scope.configuration.name,
              address: $scope.configuration.email
            }
          }
        )
        .then(resolve, reject);
      };

      const resolve = (response: IApiResponse<IStatusResponse>) => {
        $scope.saving = false;
        if (!response.data || !response.data.success) {
          $scope.error = 'An unexpected error occurred';
        } else {
          $scope.error = null;
          $uibModalInstance.close({saved: true});
        }
      };

      const reject = (err: any) => {
        if (err.status === 400) {
          $scope.error = 'Please verify the inputs are valid and try again';
        } else {
          $scope.error = 'An unexpected error occurred';
        }
        $scope.saving = false;
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      }
    }
  ]);
