angular
  .module('relcore.configuration')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('configurations', {
        controller: 'ConfigurationListController',
        url: '/configuration',
        parent: 'authenticated',
        abstract: true
      }
    )
  ])
  .directive('configurationsList', () => {
    return {
      restrict: 'E',
      scope: {
        facilityId: '@',
        onFetch: '&',
        onLoad: '&',
        onError: '&'
      },
      template: require('./list.html').default,
      controller: 'ConfigurationListController'
    };
  })
  .controller('ConfigurationListController',
    ['$scope', '$filter', 'configurationService', 'NgTableParams', 'alertService', '$state',
    function($scope, $filter, configurationService, NgTableParams, alertService, $state) {
      $scope.key = "";
      $scope.value = "";

      $scope.configurationGridOptions = new NgTableParams({
        page: 1,
        count: 500,
        sorting: {
          'created.date': 'desc'
        }
      }, {
        total: 0,
        getData(params) {
          $scope.onFetch();
          return configurationService.getByFacility($scope.facilityId)
            .then(function(data) {
              params.total(data.length);

              if (params.filter()) {
                data = $filter('filter')(data, params.filter());
              }
              if (params.sorting()) {
                data = $filter('orderBy')(data, params.orderBy());
              }
              // Signal that fetching is complete
              $scope.onLoad();
              return data.slice((params.page()-1)*params.count(),
                params.page() * params.count());
          },
          () => {
            // Signal that error occurred
            $scope.onError();
          });
        }
      });

      $scope.details = configuration =>
        $state.go('configurations-view', {
          configId:configuration.id,
          config:configuration
        })
      ;

      $scope.add = function() {
        const result = configurationService.postNewConfiguration($scope.id, $scope.key, $scope.value);

        const successCallback = function() {
          alertService.success("New configuration added successfully", true);
          $scope.configurationGridOptions.reload();
        };

        const errorCallback = () => alertService.error("An error occurred while adding new configuration", true);

        result.then(successCallback, errorCallback);
      };
    }
  ]);
