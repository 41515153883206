angular
  .module('relcore.facility')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('facility-users', {
      controller: 'FacilityUsersController',
      template: require('./main.html').default,
      url: '/facility-users',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Facility Users' }
    })
  }])
  .controller('FacilityUsersController', [
    '$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'alertService', '$uibModal',
    function($rootScope, $scope, relianceApi, NgTableParams, alertService, $uibModal) {
      $rootScope.title = 'Facility Users';

      $scope.showAddView = () => {
        const dialog = $uibModal.open({
          template: require('./facility-user-add-modal.html').default,
          controller: 'FacilityUserAddController'
        });
        dialog.result.then(() => $scope.gridOptions.reload());
      };

      $scope.onAdd = (user) => {
        return new Promise((resolve, reject) => {
          relianceApi.post("/facility-user", user)
          .then(() => {
            alertService.success("New facility user was added successfully", true);
            $scope.gridOptions.reload();
          })
          .catch((err) => {
            alertService.error("An error occurred while adding the facility user", true);
            reject(err);
          });
        });
      }

      $scope.onDisable = (user) => {
        return new Promise((resolve, reject) => {
          relianceApi.put("/facility-user/" + user.id, { enabled: false })
            .then(() => {
              alertService.success("Facility user was disabled", true);
              $scope.gridOptions.reload();
            })
            .catch((err) => {
              alertService.error("An error occurred while disabling the facility user", true);
              reject(err);
            });
        });
      }

      $scope.onEnable = (user) => {
        return new Promise((resolve, reject) => {
          relianceApi.put("/facility-user/" + user.id, { enabled: true })
            .then(() => {
              alertService.success("Facility user was enabled", true);
              $scope.gridOptions.reload();
            })
            .catch((err) => {
              alertService.error("An error occurred while enabling the facility user", true);
              reject(err);
            });
        });
      }

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'username': 'desc'
        },
        filter: {facilityIds: $rootScope.facilitiesToDisplay}
      }, {
        counts: [100, 200, 500],
        getData: (params) => {
          return relianceApi.index('/facility-user', params.page(), params.count(), params.filter(), params.orderBy())
            .then(response => {
              params.total(response.data.total);
              return response.data.data;
            });
          }
      });
  }]);
