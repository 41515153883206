angular
  .module('relcore.api-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('api-user-edit-view', {
        controller: 'APIUserViewController',
        template: require('./api-user-edit-view.html').default,
        url: '/api-user/view/:id',
        parent: 'authenticated',
        params: { apiUser: null },
        ncyBreadcrumb: {
          label: '{{apiUser.id}}',
          parent: 'api-user-search'
        }
      })

  ])
  .controller('APIUserViewController',
    ['$rootScope', '$scope', '$stateParams', 'store', 'ReduxMapToScope', 'apiUserViewActions', 'apiUserService',
    function($rootScope, $scope, $stateParams, store, ReduxMapToScope, userActions, apiUserService) {
      $rootScope.title = 'Loading...';

      ReduxMapToScope($scope, function(state) {
        $scope.apiUser = state.apiUser.view.apiUser;

        if ($scope.apiUser != null) {
          $rootScope.title = `${$scope.apiUser.userName}`;
          // Convert array of api role objects to associative array of role ids mapped to boolean true values
          // This map is the model used by the checkbox inputs in the edit view to select and update roles
          $scope.rolesToUpdate = $scope.apiUser.roles.reduce((prev, cur) => {prev[cur.id] = true; return prev;}, {});
          $scope.model = {
            rolesListToUpdate : $scope.apiUser.rolesList
          }
        }
      });

      // Load the user
      if ($stateParams.apiUser != null) {
        store.dispatch(userActions.setAPIUser($stateParams.apiUser));
      } else {
        store.dispatch(userActions.loadAPIUser($stateParams.id));
      }

      $scope.save = function() {
        let roleNames = $scope.model.rolesListToUpdate.split(',').map((s) => {return s.trim()});
        let roleIds = [];
        // Extract role ids from roles that have been selected.
        for (var key in $scope.rolesToUpdate) {
            // Only include role ids that have been selected. Ignore de-selected.
            if($scope.rolesToUpdate[key]){
              roleIds.push(key);
            }
        }
        $scope.updating = true;
        return apiUserService.updateUser($scope.apiUser, {roleNames, roleIds})
                             .then(() => $scope.updating = false, () => $scope.updating = false);
      }
    }
 ]);
