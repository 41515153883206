angular
  .module('relcore.api-user')
  .service('apiUserViewReducer', function() {
    var initialState = {
      loading: false,
      apiUser: null,
      apiRoles: []
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'api-user/view/clear':
          return {
            ...state,
            apiUser: null,
            loading: false
          };
        break;

        case 'api-user/view/loading':
          return {
            ...state,
            apiUser: null,
            loading: true
          };
        break;

        case 'api-user/view/set-api-user':
          return {
            ...state,
            apiUser: action.apiUser,
            loading: false
          };
        break;

        case 'api-roles/loaded':
          return {
            ...state,
            apiRoles: action.apiRoles
          };
      };

      return state
    };
  });
