import React from 'react';
import Card from 'react-bootstrap/esm/Card';
import Tab from 'react-bootstrap/esm/Tab';
import { react2angular } from 'react2angular';
import { IFacility } from '../models/facility';

type FacilityBillingTabProps = {
  facility: IFacility
}

export const FacilityBillingTab: React.FC<FacilityBillingTabProps> = (props) => {
  return (
    <Tab eventKey="billing" title="Billing">
      <Card>
        <Card.Body>
          {/* <form-group-input field="newFacility.billingName" required="true" label="'Billing Name:'"></form-group-input>
          <div>
            <form-group-input field="newFacility.billingAddress1" required="required" label="'Address:'"></form-group-input>
            <form-group-input field="newFacility.billingAddress2" required="required" label="''"></form-group-input>
            <form-group-input field="newFacility.billingCity" required="required" label="'City:'"></form-group-input>
            <form-group-states field="newFacility.billingState" required="required" label="'State:'"></form-group-states>
            <form-group-input field="newFacility.billingZip" max-length="5" required="required" label="'Zip:'"></form-group-input>
          </div> */}
        </Card.Body>
      </Card>
    </Tab>
  );
};

angular
  .module('relcore.facility')
  .component('facilityBillingTab', react2angular(FacilityBillingTab, ['facility']));

export default FacilityBillingTab;