import React from 'react';
import { react2angular } from 'react2angular';
import { DateTime } from '../../format/DateTime';
import { IOnlineOrderItem } from '../../models/card-account';

type OnlineOrderSummaryProps = {
  orderItem: IOnlineOrderItem
}

const OnlineOrderSummary = (props: OnlineOrderSummaryProps) => {
  const { order } = props.orderItem;

  return (
    <div className="card card-default">
      <div className="card-header">
        Online Order #{order.id}
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-6">
                <strong><a href={`/online-user/view/${order.user.id}`}>Customer:</a></strong><br/>
                <address>
                  {order.user.emailAddress}<br/>
                  {order.user.firstName} {order.user.lastName}<br/>
                  {order.user.address} {order.user.address2}<br/>
                  {order.user.city}, {order.user.state} {order.user.zip}
                </address>
              </div>
              <div className="col-md-6">
                <strong>Date:</strong> <br/>
                <DateTime value={order.date} />
                <br/>
                <strong>Facility:</strong> <br/>
                {order.facility.directBillFacility.name} ({order.facility.directBillFacility.city}, {order.facility.directBillFacility.state})
                <br/>
                <strong>Inmate:</strong> <br/>
                {order.inmateFirstName} {order.inmateLastName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

angular
  .module('relcore.card')
  .component('onlineOrderSummary', react2angular(OnlineOrderSummary, ['orderItem']));

export default OnlineOrderSummary