angular
  .module('relcore.auth')
  .factory('apiTokenInterceptor', () =>
    ({
      request(req) {
        if (localStorage.getItem('token') != null) {
          req.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }
        return req;
      }
    })
  );