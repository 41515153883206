import { IRatePlanService } from './rate-plan-service';

angular
  .module('relcore.rating')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('rate-plans', {
      controller: 'RatePlanController',
      template: require('./main.html').default,
      url: '/rate-plans',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Rate Plans' }
    })
  }])
  .controller('RatePlanController', [
    '$rootScope', '$scope', 'ratePlanService', 'NgTableParams',
    function($rootScope, $scope, ratePlanService:IRatePlanService, NgTableParams) {
      $rootScope.title = 'Rate Plans';

      $scope.fetching = true;
      ratePlanService.retrieveAll(true).then(function(ratePlans) {
        $scope.fetching = false;
        $scope.ratePlans = ratePlans;
      });

      $scope.ratePlansGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            'id': 'desc'
          }
        },
        {
         counts: [100, 200, 500]
        }
      );
    }]);
