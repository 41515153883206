
export interface IPaginatedResponse<T> {
  page: number
  perPage: number
  total: number
  totalPages: number
  data: T[]
  supportingData: any
}

export interface IApiResponse<T> {
  data: T
}

export interface IRelianceApi {
  get<T>(url:string, data?:object): Promise<T>
  post<T>(url:string, data?:object): Promise<T>
  put<T>(url:string, data?:object): Promise<T>
  delete<T>(url:string, data?:object): Promise<T>
  index<T>(url:string, page?:number, perPage?:number, filter?:object, orderBy?:string): Promise<T>
  getBlob<T>(url:string, page:number, perPage:number, filter:object, orderBy:string): Promise<T>
  convertObjToCSV(convertObjToCSV:object): [string, string]
  isPaginated<T>(response:IGenericApiResponse<T> | IResultsResponse<T>): boolean
}

export interface IResultsResponse<T> {
  page: number
  perPage: number
  total: number
  totalPages: number
  results: T[]
}

export interface IStatusResponse {
  success: boolean
  error?: string
  message?: string
}
export interface IApiErrorResponse {
  data: {
    error: {
      code: number
      message: string
    }
  }
}

export interface IServiceErrorResponse {
  status: number
  message?: string
}

export type IApiResultsResponse<T> = IApiResponse<IResultsResponse<T>>;

export type IPaginatedApiResponse<T> = IApiResponse<IPaginatedResponse<T>>;

export type IGenericApiResponse<T> = IPaginatedApiResponse<T> | IApiResultsResponse<T> | IApiResponse<T> | IApiResponse<IStatusResponse>;

angular
  .module('relcore.common')
  .service('relianceApi', ['$http', '$q', 'auth', 'config', 'moment',
    function($http, $q, auth, config, moment): IRelianceApi {
      const buildUrl = function(fragment) {
        return `${config.api.url}${fragment}`;
      }

      const post = function(url, data) {
        let options = {};
        if (data instanceof FormData) {
          options = {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
          };
        }
        return auth.refreshToken()
          .then(function() {
            return $http.post(buildUrl(url), data, options)
          });
      }

      const get = function(url, data) {
        return auth.refreshToken()
          .then(function() {
            let query = '';
            for (let k in data) {
              const v = data[k];

              if (v == null) {
                continue;
              }

              if (query.length > 0) { query += '&'; }
              if (query.length === 0) { query += '?'; }

              // Deal with dates separately
              let startDate = k == 'startDate' ? v : v.startDate;
              let endDate = k == 'endDate' ? v : v.endDate;
              if (startDate !== undefined || endDate !== undefined) {
                if (startDate != null) {
                  startDate = moment(startDate).tz(localStorage.getItem('systemTimezone'));
                  query += `startDate=${encodeURIComponent(startDate.format('YYYY-MM-DD HH:mm:ss'))}`;
                  query += '&';
                }

                if (endDate != null) {
                  endDate = moment(endDate).tz(localStorage.getItem('systemTimezone'));
                  query += `endDate=${encodeURIComponent(endDate.format('YYYY-MM-DD HH:mm:ss'))}`;
                }
              } else if ((v != null) && (v !== '')) {
                query += k+'='+encodeURIComponent(v);
              }
            }

            return $http.get(buildUrl(url)+query);
        })
      }

      const put = function(url, data) {
        return auth.refreshToken()
          .then(function() {
            return $http.put(buildUrl(url), data)
          })
      }

      const deleteFunc = function(url, data) {
        return auth.refreshToken()
          .then(function() {
            return $http.delete(buildUrl(url), data)
          });
      }

      // Convenience methods
      const index = function(url, page = null, perPage = null, filter, orderBy) {
        if (page == null) { page = 1; }
        if (perPage == null && perPage != -1) { perPage = 100; }
        if (filter == null) { filter = {}; }
        if (orderBy == null) { orderBy = ''; }
        const params = { orderBy, page, perPage: perPage > -1 ? perPage : null };

        for (let i in filter) {
          if (filter[i] != null) { params[i] = filter[i]; }
        }

        return get(url, params);
      };

      const getBlob = function(url, page, perPage, filter, orderBy) {
        return index(url, page, perPage, filter, orderBy)
          .then(res => {return new Blob([res.data], {type: 'text/csv'});});
      };

      const convertObjToCSV = function(obj: object): [string, string] {
        let mapped = new Array();
        // Flatten the object
        getPropertiesAsMap(obj, null, mapped);

        let keys = Object.keys(mapped);
        // Extract the first header and value
        let header = keys.shift();
        let values = mapped[header];

        // Append additional property name/value
        // to respective strings. The header string
        // will be the first row of the CSV. The
        // values string will be the second.
        keys.forEach(key => {
          header = header.concat(',', key);
          values = values.concat(',', mapped[key]);
        });

        return [header, values];
      }

      const getPropertiesAsMap = function (obj: object, name: string, mapped: Array<any>) {
        // Iterate through the properties of the object and
        // map them to key value pairs in a hash
        Object.keys(obj).forEach(key => {
          // Ignore Angular object tracking key
          if(key == '$$hashKey') return;

          // Prepend any existing name to the current object key
          // as an existing name indicates that there is a parent
          // object and the name represents the name of a property
          // of the parent. This is how we flatten properties
          let new_name = name ? `${name}.${key}` : key;

          // If the value of the property is an object
          // then flatten it's properties as well
          if(obj[key] && typeof(obj[key]) == 'object') {
            getPropertiesAsMap(obj[key], new_name, mapped);
          }
          else {
            mapped[new_name] = obj[key] ? obj[key] : '';
          }
        });
      };

      // Type guard utility function to check if API response is paginated or not
      const isPaginated = function<T>(response: IGenericApiResponse<T> | IResultsResponse<T>): response is IPaginatedApiResponse<T> {
        const paginatedData = (response as IPaginatedApiResponse<T>);
        const paginatedResults = (response as IResultsResponse<T>);
        return paginatedData.data.data !== undefined || paginatedResults.results !== undefined;
      }

      return {
        post,
        get,
        put,
        delete: deleteFunc,
        index,
        getBlob,
        convertObjToCSV,
        isPaginated
      };
    }
  ]);
