angular
  .module('relcore.mobile-device')
  .service('mobileDeviceHistoryService', ['relianceApi', '$filter',
    function(relianceApi, $filter) {
      return {
        index(filter, orderBy, page, perPage) {
          if (filter == null) { filter = {}; }
          const params = {orderBy: null, page: null, perPage: null};
          for (let i in filter) {
            if (filter[i] != null) { params[i] = filter[i]; }
          }

          if (orderBy != null) {
            params.orderBy = orderBy;
          }
          if (page != null) {
            params.page = page;
          }
          if (perPage != null) {
            params.perPage = perPage;
          }

          return relianceApi.get("/mobile-device-history", params);
        }
      };
    }]);
