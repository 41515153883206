import React from 'react';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { formatCurrency } from '../format/Currency';
import { formatDate } from '../format/DateTime';
import { formatDuration } from '../format/Duration';
import { formatPhoneNumber } from '../format/PhoneNumber';
import { ISciDynCdr } from '../models/scidyncdr';
import BaseHtmlTable from '../table/BaseHtmlTable';

type TableProps = {
  data?: ISciDynCdr[]
  paginatedData?: IPaginatedResponse<ISciDynCdr>
}

const Table = (props: TableProps) => {
  const data = props.data ? props.data : props.paginatedData.data;

  const columns = [
    { key: 'date', name: 'Date', style: {width: 130} },
    { key: 'facility', name: 'Facility' },
    { key: 'source', name: 'Source' },
    { key: 'calledNumber', name: 'Called Number' },
    { key: 'terminateReason', name: 'Termination Reason' },
    { key: 'charge', name: 'Charge', cellStyle: (row) => row.refund ? {textDecoration: 'line-through'} : {} },
    { key: 'duration', name: 'Duration' },
    { key: 'balance', name: 'Ending Balance' }
  ];

  const rows = data.map(d => ({
    id: d.id,
    refund: d.refund,
    date: formatDate(d.setupDate.date),
    facility: d.facility ? `${d.facility.name}-${d.facility.state}-${d.ani}` : `Unknown-${d.ani}`,
    source: `${d.source.name} (${d.trunk})`,
    calledNumber: formatPhoneNumber(d.calledNumber),
    terminateReason: d.terminateReason,
    charge: formatCurrency(d.iCharge / 100),
    duration: formatDuration(d.iTalkDur),
    balance: d.balance !== null ? formatCurrency(parseFloat(d.balance) / 100) : '',
  }))

  return (
    <BaseHtmlTable
      baseKey="card-account-scidyn-cdrs"
      columns={columns}
      rows={rows}
      />
  )
}

export default Table;
