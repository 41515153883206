import { ServiceRatePlanFilters } from '@reliance/types/filters/ServiceRatePlanFilters';
import { RatePlan } from '@reliance/types/models/ServiceRate';
import { useAsync } from 'react-async-hook';
import api from '../common/api';

export const useRatePlans = (filters?: ServiceRatePlanFilters) => {
  return useAsync<RatePlan[]>(
    () => filters ? api.get<RatePlan[]>('/service-rate/plan', { params: filters }).then(r => r.data) : null,
    [filters]
  );
};