angular
  .module('relcore.inmate-device-blacklist')
  .service('inmateDeviceBlacklistService', ['relianceApi', function(relianceApi) {
    return {
      index(filter, orderBy) {
        const params = {orderBy: null};
        for (let i in filter) {
          if (filter[i] != null) { params[i] = filter[i]; }
        }

        if (orderBy != null) {
          params.orderBy = orderBy;
        }

        return relianceApi.get("/inmate-device-blacklist", params);
      },

      getIsBlacklisted(inmateId) {
        return relianceApi.get(`/inmate-device-blacklist/is-blacklisted/${inmateId}`);
      },

      post(blacklistEntry) {
        return relianceApi.post("/inmate-device-blacklist", blacklistEntry);
      },

      delete(id) {
        return relianceApi.delete(`/inmate-device-blacklist/${id}`);
      },

      deleteByInmate(inmateId) {
        return relianceApi.delete(`/inmate-device-blacklist/by-inmate/${inmateId}`);
      }
    };
  }]);
