import { IAlertService } from '../alert/alert-service';
import api from '../common/api';
import { IConfig } from '../common/config';
import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { TaxTable, TaxTableEntityType } from '../models/TaxTable';
import { IRatePlanService } from '../rate-plan/rate-plan-service';

angular
  .module('relcore.facility')
  .directive('facilityEdit', () => ({
    restrict: 'E',
    scope: {
      'facility': '=',
      'saving': '=',
      'onSave': '=',
      'onRefresh': '='
    },
    controller: ['$scope', 'config', 'ratePlanService', 'relianceApi', 'alertService', function($scope, config: IConfig, ratePlanService: IRatePlanService, relianceApi: IRelianceApi, alertService: IAlertService) {
      $scope.relianceApi = relianceApi;
      $scope.alertService = alertService;

      $scope.inmateImportOptions = config.inmateImportModes;
      $scope.facilityTypes = config.facilityTypes;
      $scope.featureCalling = config.featureCalling;

      $scope.newFacility = {
        ...$scope.facility,
        cityTaxId: ($scope.facility.cityTax || {}).id,
        stateTaxId: ($scope.facility.stateTax || {}).id,
        fedTaxId: ($scope.facility.fedTax || {}).id,
        usfTaxId: ($scope.facility.usfTax || {}).id,
        itdsMode: $scope.facility.itdsMode.toString(), // Otherwise the UI won't select the current value
        serviceRatePlan: $scope.facility.serviceRatePlans[0] || null
      };

      $scope.features = [
        'Account Summary',
        'Audio Visitation',
        'Commissary Ordering',
        'Inmate Communication Policy',
        'Inmate Requests',
        'Job Search',
        'Kiosk Audio Calls',
        'Law Library',
        'Prepaid Collect Calls',
        'Reference Material',
        'Video Calls',
        'Video Visitation',
        'Voicemail',
        'Wireless Audio Calls',
        'Wireless Video Calls',
        'Wireless Video Visitations'
      ];

      $scope.toggleFeature = (feature) => {
        var idx = $scope.newFacility.features.indexOf(feature);
        if (idx > -1) {
          $scope.newFacility.features.splice(idx, 1);
        } else {
          $scope.newFacility.features.push(feature);
        }
      }

      $scope.ratePlans = [];
      ratePlanService.retrieveAll().then(ratePlans => {
        $scope.ratePlans = ratePlans;
      });

      $scope.serviceRatePlans = [];
      api.get('/service-rate/plan').then((rates) => {
        $scope.serviceRatePlans = rates.data;
        $scope.$digest();
      });

      if ($scope.newFacility.inmateImportMode == null) {
        $scope.newFacility.inmateImportMode = Object.keys($scope.inmateImportOptions)[0];
      }

      $scope.save = () => {
        $scope.newFacility.ratePlanId = ($scope.newFacility.ratePlan||{}).id;
        $scope.newFacility.serviceRatePlanId = ($scope.newFacility.serviceRatePlan||{}).id;

        $scope.newFacility.cityTaxId = ($scope.newFacility.cityTax || {}).id;
        $scope.newFacility.stateTaxId = ($scope.newFacility.stateTax || {}).id;
        $scope.newFacility.fedTaxId = ($scope.newFacility.fedTax || {}).id;
        $scope.newFacility.usfTaxId = ($scope.newFacility.usfTax || {}).id;

        $scope.onSave($scope.newFacility);
      };

      $scope.taxTables = [];
      $scope.cityTaxes = [];
      $scope.stateTaxes = [];
      $scope.fedTaxes = [];
      $scope.fusfTaxes = [];
      $scope.loadTaxTables = () => {
        if ($scope.taxTables.length == 0) {
          relianceApi
            .index<IApiResponse<TaxTable[]>>('/tax/table')
            .then((res) => {
              const taxTables = res.data;
              $scope.taxTables = taxTables.sort((a,b) => a.entityName.localeCompare(b.entityName));
              $scope.cityTaxes = taxTables.filter(r => r.entityType == TaxTableEntityType.City);
              $scope.stateTaxes = taxTables.filter(r => r.entityType == TaxTableEntityType.State);
              $scope.fedTaxes = taxTables.filter(r => r.entityType == TaxTableEntityType.Fed);
              $scope.fusfTaxes = taxTables.filter(r => r.entityType == TaxTableEntityType.FUSF);
            });
        }
      };

      $scope.showMigrateItdsModal = false;
      $scope.migrateItds = () => {
        $scope.showMigrateItdsModal = true;
      };
      $scope.onMigrateItdsClose = (migrated: boolean = false) => {
        $scope.showMigrateItdsModal = false;
        $scope.$digest();

        if (migrated) {
          $scope.facility.itdsMode = 2;
          $scope.onRefresh();
        }
      };
    }],
    template: require('./facility-edit.html').default
  }));
