angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('repair-ticket-view', {
        controller: 'RepairTicketViewController',
        url: '/repair-ticket/view/:id',
        parent: 'authenticated',
        params: {
          ticket: null
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'repair-tickets'
        },
        template: require('./view.html').default
      }
    )
  ])
  .controller('RepairTicketViewController', [
    '$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, alertService, $stateParams, NgTableParams) {
      $scope.ticket = { mobileDevice: {} };
      $scope.closingNotes = { notes: '' };
      $scope.noPurchase = null;

      $scope.noteListGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          return JSON.parse($scope.ticket.notes||'[]');
        }
      });

      $scope.partsUsedGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          return JSON.parse($scope.ticket.partsUsed||'[]');
        }
      });

      const loadReportIns = () => {
        $scope.reportInGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            dateReported: 'desc'
          },
          filter: {
            deviceId: $scope.ticket.mobileDevice.id
          }
        }, {
          counts: [100, 200, 500],
          getData(params) {
            return relianceApi.index("/mobile-device/report-in", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        });
      }

      const setTicket = function(ticket) {
        $scope.ticket = ticket;
        $rootScope.title = `Repair Ticket #${$scope.ticket.id}`;
        $scope.noteListGridOptions.reload();
        $scope.partsUsedGridOptions.reload();
        loadPurchase();
        loadReportIns();
      };

      const loadTicket = id =>
        relianceApi
          .get(`/repair-ticket/${id}`)
          .then(res => setTicket(res.data))
      ;

      var loadPurchase = () =>
        relianceApi.get(`/mobile-device-purchase/by-device/${$scope.ticket.mobileDevice.id}`)
          .then((function(response) {
            $scope.purchase = response.data;
            $scope.noPurchase = false;
          }), function(err) {
            if (err.status === 404) {
              $scope.noPurchase = true;
            } else {
              alertService.error('An error occurred while loading purchase information', true);
            }
          })
      ;

      if ($stateParams.ticket) {
        setTicket($stateParams.ticket);
        loadTicket($scope.ticket.id);
      } else {
        loadTicket($stateParams.id);
      }

      const resetNote = () => $scope.newNote = { note: '' };
      resetNote();

      $scope.addNote = note =>
        relianceApi.put(`/repair-ticket/${$scope.ticket.id}`, { notes: note.note })
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('The note was successfully added', true);
            resetNote();
          }), (() => alertService.error('An error occurred while adding the note', true)))
      ;


      const resetPartsUsed = () => $scope.partsUsed = { note: '' };
      resetPartsUsed();

      $scope.addPartsUsed = parts =>
        relianceApi.put(`/repair-ticket/${$scope.ticket.id}`, { partsUsed: parts.note })
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('The parts used were successfully added', true);
            resetPartsUsed();
          }), (() => alertService.error('An error occurred while adding the parts used', true)))
      ;

      $scope.settingResolution = false;
      $scope.setResolution = function(resolution) {
        $scope.settingResolution = true;
        relianceApi.put(`/repair-ticket/${$scope.ticket.id}/resolve`, { resolution })
          .then((function() {
            $scope.settingResolution = false;
            loadTicket($scope.ticket.id);
            alertService.success('The repair ticket was marked resolved', true);
            resetPartsUsed();
          }), (function() {
            $scope.settingResolution = false;
            alertService.error('An error occurred while resolving ticket', true);
          }));
      };

      $scope.reopen = () =>
        relianceApi.put(`/repair-ticket/${$scope.ticket.id}/reopen`)
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('The ticket is reopened', true);
          }), (() => alertService.error('An unexpected error occurred while reopening ticket', true)))
      ;
    }
  ]);
