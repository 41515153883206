import { SpeedDialFilters } from '@reliance/types/filters/SpeedDialFilters';
import { Destination } from '@reliance/types/models/SpeedDial';
import { useAsync } from 'react-async-hook';
import { IPaginatedResponse } from 'relcore-central';
import api from '../common/api';

export const useSpeedDialDestinations = (filters?: SpeedDialFilters) => {
  return useAsync<IPaginatedResponse<Destination>>(
    () => filters ? api.get<IPaginatedResponse<Destination>>('/speed-dial', { params: filters }).then(r => r.data) : null,
    [filters]
  );
};