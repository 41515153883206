angular
  .module('relcore.office-call')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('office-call-import', {
        controller: 'OfficeCallImportController',
        template: require('./import.html').default,
        url: '/call-record/import',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Import Records'
        }
      }
    )
  ])
  .controller('OfficeCallImportController', ['$rootScope', '$scope',
    function($rootScope, $scope) {
      $rootScope.title = 'Import Office Calls';
      $scope.errorMessage = '';

      $scope.onError = function(err, errorMessage, xhr) {
        // Show error message directly if the server didn't even get the file
        if (!errorMessage.error) {
          $scope.errorMessage = errorMessage;
        } else {
          $scope.errorMessage = errorMessage.error.message;
        }
        $scope.$apply();
      };
      $scope.clearError = () => $scope.errorMessage = '';
    }
  ]);
