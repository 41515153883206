import * as React from 'react';

export function formatCurrency(value) {
  if (value >= 0) {
    return '$' + parseFloat(value).toFixed(2);
  } else {
    return '-$' + Math.abs(parseFloat(value)).toFixed(2);
  }
}

export const Currency = (props) =>
  <span>{formatCurrency(props.value)}</span>