import * as validation from '@reliance/forms-js/lib/validation';
import React from 'react';
import { Input } from '../../form/render';

export const MoneyOrderPaymentForm: React.FC = (props: any) =>
  <div>
    <div className="row">
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.firstName"
          label="First Name"
          validationRules={[
            validation.required
          ]} />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.lastName"
          label="Last Name"
          validationRules={[
            validation.required
          ]} />
      </div>
    </div>
  </div>