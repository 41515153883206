import { Facility } from '@reliance/types/models/Facility';
import { VisitationBooth } from '@reliance/types/models/VisitationBooth';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import { IAlertService } from '../alert/alert-service';
import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import AddEditModal from './AddEditModal';
import Table from './Table';

type ListProps = {
  facility: Facility
  alertService: IAlertService
  relianceApi: IRelianceApi
  onLoading(type: string): void
  onLoaded(type: string): void
  onLoadError(type: string): void
}

const List: React.FC<ListProps> = (props) => {
  const { facility, alertService, relianceApi, onLoading, onLoaded, onLoadError } = props;
  const [visitationBooths, setVisitationBooths] = useState<IPaginatedResponse<VisitationBooth>>(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editVisitationBooth, setEditVisitationBooth] = useState<VisitationBooth>(null);

  const loadVisitationBooths = () => {
    onLoading('Visit Booths');
    return relianceApi.index<IApiResponse<IPaginatedResponse<VisitationBooth>>>('/visitation-booth', 1, 500, { facilityId: facility.id })
      .then(res => {
        setVisitationBooths(res.data);
        onLoaded('Visit Booths');
        return res.data;
      },
      () => {
        onLoadError('Visit Booths');
        alertService.error('An error occurred while loading visitation booths', true);
      }
    );
  }

  useEffect(() => {
    loadVisitationBooths();
  }, []);

  const saveVisitationBooth = (visitationBoothData) => {
    visitationBoothData = {...visitationBoothData, facilityId: editVisitationBooth?.facility?.id ?? facility.id}
    if (visitationBoothData.id) {
      return relianceApi.put(`/visitation-booth/${visitationBoothData.id}`, visitationBoothData);
    }
    return relianceApi.post('/visitation-booth', visitationBoothData);
  }

  return (
    <div className="jarviswidget">
      <div className="row">
        <div className="col-md-12 mb-3">
          <Button onClick={() => setShowAddEditModal(true)} variant="primary">
            Add Visitation Booth
          </Button>

          <Table
            facility={facility}
            visitationBooths={visitationBooths}
            onEdit={(booth) => {
              setEditVisitationBooth(booth);
              setShowAddEditModal(true);
            }}
            />

          {showAddEditModal &&
            <AddEditModal
              facility={editVisitationBooth?.facility ?? facility}
              visitationBooth={editVisitationBooth}
              onSave={(data) => {
                return saveVisitationBooth(data).then(() => {
                  setEditVisitationBooth(null);
                  setShowAddEditModal(false);
                  loadVisitationBooths();
                });
              }}
              onClose={() => {
                setEditVisitationBooth(null);
                setShowAddEditModal(false);
              }}
              />
          }
        </div>
      </div>
    </div>
  )
}

angular
  .module('relcore.visitation-booth')
  .component('visitationList', react2angular(List, [
    'facility',
    'alertService',
    'relianceApi',
    'onLoading',
    'onLoaded',
    'onLoadError'
  ]));

export default List;
