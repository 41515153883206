export interface IConfig {
  appBundleIds: IAppBundleIds
  api: IApiConfig
  connectService: IConnectServiceConfig
  inmateImportModes: any,
  apiCredentialSearchUserTypes: IApiCredentialSearchUserTypes,
  featureCalling: boolean,
  eventNotification: IEventNotificationConfig
  account: IAccountConfig,
  facilityTypes: string[]
}

interface IAppBundleIds {
  messaging?:string
  info?:string
  admin?:string
  kiosk?:string
}

interface IApiCredentialSearchUserTypes {
  inmate?:string
  mobile_device?:string
  online_user?:string
  facility_user?:string
  staff_user?:string
}

interface IApiConfig {
  url:string
  client:any
}

interface IConnectServiceConfig {
  url:string
}

interface IEventNotificationConfig {
  templates: IEventNotificationTemplate
  textMessageAlertTemplateIds: {
    'Email': number,
    'Text': number,
    'Call': number
  }
}

interface IEventNotificationTemplate {
  [key: string]: object
}

interface IAccountConfig {
  invoiceFrequency:Array<number>
  unblockAmounts:Array<number>
  onlineMonthlyLimits:Array<number>
}

let inmateImportModes:any = {
  'null': 'N/A',
  'ITDS': 'ITDS',
  'Generic': 'Generic',
  'GenericNumericTransformAccount': 'GenericNumericTransformAccount',
  'JailTracker': 'JailTracker',
  'TurnKey': 'TurnKey',
  'CPC': 'CPC'
};

let domain = window.location.host; // Contains port
let apiHost = 'https://api.reliancetelephone.com';
let socket = 'https://socket.reliancetelephone.com';
let appBundleIds:IAppBundleIds = {
  'messaging': 'com.reliancetelephone.connect-messaging',
  'kiosk': 'com.reliancetelephone.connect-kiosk',
  'admin': 'com.reliancetelephone.ConnectAdmin',
  'info': 'com.reliancetelephone.ConnectInfo'
};
let apiCredentialSearchUserTypes: IApiCredentialSearchUserTypes = {
  inmate: 'Inmate',
  mobile_device: 'Mobile Device',
  online_user: 'Online User',
  facility_user: 'Facility User',
  staff_user: 'Staff User'
};
let featureCalling = true;
let textMessageAlertTemplateIds = {
  'Email': 1,
  'Text': 2,
  'Call': 3
}
let facilityTypes = [];

switch (window.location.hostname) {
  case '10.44.0.3':
    apiHost = 'http://10.44.0.3:4567'
  break;

  case 'localhost':
    // apiHost = 'https://api-test.reliancetelephone.com';
    socket = 'https://test.socket.reliancetelephone.com';
    apiHost = 'http://localhost:4567'
    // apiHost = 'https://test.api.cpctexting.com';
    // socket = 'https://test.socket.cpctexting.com';
    // inmateImportModes = { 'CPC': 'CPC' };


    // apiHost = 'https://api-test.reliancetelephone.com';

    // apiHost = 'https://test.api.cpctexting.com';
    // socket = 'https://test.socket.cpctexting.com';
    // inmateImportModes = { 'CPC': 'CPC' };
    // featureCalling = false;
    // apiCredentialSearchUserTypes = {
    //   inmate: 'Inmate',
    //   mobile_device: 'Mobile Device',
    //   staff_user: 'Staff User'
    // };

    // appBundleIds = {
    //   'messaging': 'com.cpctexting.chirping',
    //   'info': 'com.cpctexting.ChirpingInfo'
    // };

    // TODO: Populate with actual values once ready
    textMessageAlertTemplateIds = {
      'Email': 1,
      'Text': 2,
      'Call': 3
    };

    facilityTypes = ['reliance', 'amtel', 'demo'];
  break;

  case 'test.admin.cpctexting.com':
    apiHost = 'https://test.api.cpctexting.com';
    socket = 'https://test.socket.cpctexting.com';
    inmateImportModes = { 'CPC': 'CPC' };
    featureCalling = false;
    apiCredentialSearchUserTypes = {
      inmate: 'Inmate',
      mobile_device: 'Mobile Device',
      staff_user: 'Staff User'
    };

    appBundleIds = {
      'messaging': 'com.cpctexting.chirping',
      'info': 'com.cpctexting.ChirpingInfo'
    };

    // TODO: Populate with actual values once ready
    textMessageAlertTemplateIds = {
      'Email': 1,
      'Text': 2,
      'Call': 3
    };
  break;

  case 'admin.cpctexting.com':
  case 'chirping.combinedpublic.net':
    apiHost = 'https://api.cpctexting.com';
    socket = 'https://socket.cpctexting.com';
    inmateImportModes = { 'CPC': 'CPC' };
    featureCalling = false;
    apiCredentialSearchUserTypes = {
      inmate: 'Inmate',
      mobile_device: 'Mobile Device',
      staff_user: 'Staff User'
    };

    appBundleIds = {
      'messaging': 'com.cpctexting.chirping',
      'info': 'com.cpctexting.ChirpingInfo'
    };

    // TODO: Populate with actual values once ready
    textMessageAlertTemplateIds = {
      'Email': 1,
      'Text': 2,
      'Call': 3
    };
  break;

  case 'relcore-test.reliancetelephone.com':
    apiHost = 'https://api-test.reliancetelephone.com';
    socket = 'https://test.socket.reliancetelephone.com';

    // TODO: Populate with actual values once ready
    textMessageAlertTemplateIds = {
      'Email': 1,
      'Text': 2,
      'Call': 3
    };

    facilityTypes = ['reliance', 'amtel', 'demo'];
  break;

  case 'relcore-stage.reliancetelephone.com':
    apiHost = 'https://api-stage.reliancetelephone.com';

    facilityTypes = ['reliance', 'amtel', 'demo'];
  break;

  case 'relcore.reliancetelephone.com':
    facilityTypes = ['reliance', 'amtel', 'demo'];
  break;
}

if (domain !== 'localhost') {
  let domainParts = domain.split('.');
  let suffix = domainParts.pop();
  let name = domainParts.pop();
  domain = `${name}.${suffix}`;
}

const eventNotificationTemplates = {};

eventNotificationTemplates['prea_report'] = {
  name: "PREA Report",
  template: "<h2>PREA Inmate Report<h2/>\
<h1>{{ facility.name }} ({{ facility.city }}, {{ facility.state }})<h1/> \
Report timestamp: {{ variable_start_stamp|date('Y-m-d H:i') }} \
<br/>An inmate has submitted a PREA report via audio message on the Reliance \
Telephone system. The message recording is attached. The inmate was instructed to \
include any relevant details in the message including their name unless they wanted \
to remain anonymous."
}

let config:IConfig = {
  featureCalling,
  api: {
    url: apiHost,
    client: {
      id: 'relcore'
    }
  },
  connectService: {
    url: socket
  },
  eventNotification: {
    templates: eventNotificationTemplates,
    textMessageAlertTemplateIds
  },
  account: {
    invoiceFrequency: [7,14,30],
    unblockAmounts: [-0.25],
    onlineMonthlyLimits: [-150,-250,-350,-500]
  },
  inmateImportModes,
  appBundleIds,
  apiCredentialSearchUserTypes,
  facilityTypes
};

angular
  .module('relcore.common')
  .value('config', config);

export default config;
