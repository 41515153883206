import { TextMessage } from '@reliance/types/models/TextMessage';
import React from 'react';
import { react2angular } from 'react2angular';
import TextImageModal from './TextImageModal';

type TextImageProps = {
  textMessage: TextMessage
}

const TextImage: React.FC<TextImageProps> = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);

  return (
    <>
      <img
        src={props.textMessage.attachmentUrl}
        style={{ maxWidth: 200, maxHeight: 200, cursor: 'pointer' }}
        onClick={openModal} />
      {modalOpen &&
        <TextImageModal
          textMessage={props.textMessage}
          onClose={() => setModalOpen(false)} />
      }
    </>
  );
};

angular
  .module('relcore.text-message')
  .component('textMessageImage', react2angular(TextImage, ['textMessage'], []));

export default TextImage;