angular
  .module('relcore.note')
  .directive('noteAdd', () =>
    ({
      restrict: 'E',
      scope: {
        onAdd: '=?',
        adding: '=?',
        newNote: '=',
        showToolbar: '=?',
        objectNameText: '=?'
      },
      controller: ['$scope', function($scope) {
        $scope.showToolbar = $scope.showToolbar !== false;
        $scope.objectNameText = $scope.objectNameText || 'Note';
        $scope.buttonText = `Add ${$scope.objectNameText}s`;
        $scope.add = () => $scope.onAdd($scope.newNote);
      }
      ],
      template: require('./note-add.html').default
    })
  );
