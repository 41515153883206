angular
  .module('relcore.block')
  .service('blockService',
    ['$http', '$q','CacheFactory', 'relianceApi', 'Block', 'config', 'User',
    function($http, $q, CacheFactory, relianceApi, blockModel, config, UserModel) {

      const getByFacility = function(facilityId, page, perPage, filter, orderBy) {
        let url = `/block/search?typeId=-1&facilityId=${facilityId}&includeUnblocked=true&page=${page}&perPage=${perPage}`;

        if (orderBy != null) {
          url += `&orderBy=${encodeURIComponent(orderBy)}`;
        }

        if ((filter.blockDate != null ? filter.blockDate.startDate : undefined) != null) {
          url += `&startDate=${filter.blockDate.startDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.blockDate != null ? filter.blockDate.endDate : undefined) != null) {
          url += `&endDate=${filter.blockDate.endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.unblockDate != null ? filter.unblockDate.startDate : undefined) != null) {
          url += `&startUnblockDate=${filter.unblockDate.startDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.unblockDate != null ? filter.unblockDate.endDate : undefined) != null) {
          url += `&endUnblockDate=${filter.unblockDate.endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if (filter.description != null) {
          url += `&description=${filter.description}`;
        }
        if (filter.requestedBy != null) {
          url += `&requestedBy=${filter.requestedBy}`;
        }
        if (filter.typeId != null) {
          url += `&typeId=${filter.typeId}`;
        }

        return relianceApi.get(url)
          .then(function(response) {
            const { data } = response;
            const blocks = [];
            for (let block of Array.from(data.blocks)) {
              blocks.push(new blockModel(block));
            }
            data.blocks = blocks;
            return data;
        });
      };

      const getByPhoneNumber = function(phoneNumber, page, perPage, filter, orderBy) {
        let url = `/block/search?phoneNumber=${phoneNumber}&page=${page}&perPage=${perPage}&includeUnblocked=true`;

        if (orderBy != null) {
          url += `&orderBy=${encodeURIComponent(orderBy)}`;
        }

        if ((filter.blockDate != null ? filter.blockDate.startDate : undefined) != null) {
          url += `&startDate=${filter.blockDate.startDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.blockDate != null ? filter.blockDate.endDate : undefined) != null) {
          url += `&endDate=${filter.blockDate.endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.unblockDate != null ? filter.unblockDate.startDate : undefined) != null) {
          url += `&startUnblockDate=${filter.unblockDate.startDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter.unblockDate != null ? filter.unblockDate.endDate : undefined) != null) {
          url += `&endUnblockDate=${filter.unblockDate.endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if (filter.description != null) {
          url += `&description=${filter.description}`;
        }
        if (filter.facility != null) {
          url += `&facilityId=${filter.facility.id}`;
        }
        if (filter.requestedBy != null) {
          url += `&requestedBy=${filter.requestedBy}`;
        }
        if (filter.typeId != null) {
          url += `&typeId=${filter.typeId}`;
        }

        return relianceApi.get(url)
          .then(function(block) {
            const { data } = block;
            const blocks = [];
            for (block of Array.from(data.blocks)) {
              blocks.push(new blockModel(block));
            }
            data.blocks = blocks;
            return data;
        });
      };


      return {
        getByPhoneNumber,
        getByFacility
      };
    }
    ]);








