angular.module('relcore.mobile-device').config(['$stateProvider', $stateProvider =>
  $stateProvider.state('mobile-device-profiles', {
    url: '/profiles',
    parent: 'authenticated',
    ncyBreadcrumb: { label: 'Profiles' },
    controller: ['$rootScope', '$scope', 'relianceApi', 'alertService', '$state',
      function($rootScope, $scope, relianceApi, alertService, $state) {
        $rootScope.title = 'Mobile Device Profiles';
        $scope.newProfile = { name: '', data: '' };
        $scope.profiles = [];

        const refresh = function() {
          $scope.loading = true;
          relianceApi.get("/mobile-device-profile", {status:'Active'})
            .then(function(response) {
              $scope.loading = false;
              $scope.profiles = response.data;
            });
        }
        refresh();

        // Handle profile save requests
        $scope.onSave = function(profile) {
          profile.isSaving = true;

          if (profile.id != null) {
            relianceApi.put(`/mobile-device-profile/${profile.id}`, profile).then((function() {
              alertService.success('The profile was successfully updated', true);
              refresh();
              profile.isSaving = false;
            }), (function() {
              alertService.error('An error occurred while updating the profile', true);
              profile.isSaving = false;
            })
            );
          } else {
            return relianceApi.post("/mobile-device-profile", $scope.newProfile).then((function() {
              alertService.success('The profile was successfully created', true);
              refresh();
              $scope.newProfile = { name: '', data: '', isSaving: false };
            }), (function() {
              alertService.error('An error occurred while creating the profile', true);
              $scope.newProfile.isSaving = false;
            })
            );
          }
        };

        // Handle profile delete requests
        $scope.onDelete = function(profile) {
          profile.isSaving = true;

          relianceApi.delete(`/mobile-device-profile/${profile.id}`)
            .then((function() {
              alertService.success('The profile was successfully removed', true);
              refresh();
              profile.isSaving = false;
            }), (function() {
              alertService.error('An error occurred while removing the profile', true);
              profile.isSaving = false;
            })
          );
        };
      }
    ],
    template: require('./main.html').default
  }
  )

  ]);
