angular
  .module('relcore.inmate')
  .directive('inmateAccountPaymentSummaries', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        summaryType: '='
      },
      template: require('./inmate-account-payment-summaries.html').default
    })
  );
