import { IPhoneTrackerProcess } from '../../models/phone-tracker';

export interface IPhoneTrackerListState {
  runningProcesses: Array<IPhoneTrackerProcess>
}

angular
  .module('relcore.reducers')
  .service('phoneTrackerListReducer', function() {
    var initialState: IPhoneTrackerListState = {
      runningProcesses: []
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'starting':
          return {
            ...state,
            runningProcesses: [
              ...state.runningProcesses,
              action.data
            ]
          };

        case 'processed':
          let processes = state.runningProcesses;
          let matches = processes.filter(p => p.importId == action.data.importId);
          if (matches.length > 0) {
            let process = matches[0];
            process.count = action.data.count;
            process.processedCount = action.data.processedCount;
            return state;
          } else {
            return {
              ...state,
              runningProcesses: [
                ...state.runningProcesses,
                action.data
              ]
            };
          }
      };

      return state
    };
  });
