angular
  .module('relcore.facility')
  .service('facilityUserReducer', [
    'facilityUserListReducer',
    'facilityUserViewReducer',
    'Redux',
    function(facilityUserListReducer, facilityUserViewReducer, Redux) {
      return Redux.combineReducers({
        list: facilityUserListReducer,
        view: facilityUserViewReducer
      });
    }]);
