angular
  .module('relcore.mobile-device')
  .controller('MobileDeviceInspectController', [
    '$scope', '$uibModalInstance', 'device', 'relianceApi',
    function($scope, $uibModalInstance, device, relianceApi) {
      $scope.device = device;
      $scope.model = {
        result: 'Passed',
        failureReason: ''
      };

      $scope.startingInspection = device.status !== 'Needs Inspection';
      if ($scope.startingInspection) {
        relianceApi.put(`/mobile-device/${device.id}/start-inspection`)
          .then(() => $scope.startingInspection = false);
      }

      $scope.processing = false;
      $scope.close = () => $uibModalInstance.close({passed: null});
      $scope.submit = function(passed) {
        $scope.model.result = passed ? 'Passed' : 'Failed';
        $scope.processing = true;
        relianceApi.put(`/mobile-device/${device.id}/inspection`, $scope.model)
          .then((function() {
            $scope.processing = false;
            $uibModalInstance.close({passed});
          }), (err => $scope.processing = false));
      };
    }
  ]);
