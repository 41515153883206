angular
  .module('relcore.text-message')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('text-messages-status', {
      controller: 'TextMessageStatusController',
      template: require('./status.html').default,
      url: '/text-messages-status',
      parent: 'authenticated',
      ncyBreadcrumb: { label: '{{title}}' }
    })
  ])
  .controller('TextMessageStatusController', [
    '$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'moment',
    function($rootScope, $scope, relianceApi, NgTableParams, moment) {
      $rootScope.title = 'Text Messages Status';

      $scope.textMessageStatusGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: { date: 'desc' },
          filter: {
            date: {
              startDate: moment().subtract(1, 'days').startOf('day'),
              endDate: moment().endOf('day')
            }
          }
        },
        {
          counts: [100, 200, 500],
          getData(params) {
            return relianceApi.index('/text-message/status', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        }
      );
    }
  ]);
