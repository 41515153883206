import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import AddDeviceChargerCredentialModal from '../api-credential/AddDeviceChargerCredentialModal';
import { IRelianceApi } from '../common/reliance-api-service';
import { IFacility } from '../models/facility';

type AddDeviceChargerCredentialProps = {
  facility: IFacility
  relianceApi: IRelianceApi
}

const AddDeviceChargerCredential: React.FC<AddDeviceChargerCredentialProps> = (props) => {
  const { facility, relianceApi } = props;

  const [showModal, setShowModal] = useState(false);

  const hasCredential = (facility.apiCredentials||[]).filter(c => c.userType == 'device_charger').length > 0;

  return (
    <>
      <button
        className="btn btn-secondary"
        onClick={() => setShowModal(!showModal)}
        disabled={hasCredential}>
        {hasCredential ? 'Charger Credential Exists' : 'Add Charger Credential'}
      </button>
      {showModal &&
        <AddDeviceChargerCredentialModal
          relianceApi={relianceApi}
          onClose={() => setShowModal(false)}
          facility={facility} />
      }
    </>
  );
}

export default AddDeviceChargerCredential;

angular
  .module('relcore.facility')
  .component('addDeviceChargerCredential', react2angular(
    AddDeviceChargerCredential,
    ['facility'],
    ['relianceApi']
  ));