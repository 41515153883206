angular
  .module('relcore.facility')
  .directive('facilityAnalogGateways', () => ({
    restrict: 'E',
    scope: {
      gridOptions: '=',
      onViewDetails: '=?',
      onDelete: '=?',
      onConfigure: '=?'
    },
    template: require('./list.html').default
  }));
