angular
  .module('relcore.search')
  .service('SearchService', ['relianceApi', 'moment', function(relianceApi, moment) {
    const search = (firstName, lastName, email, state, creditCard, dob) =>
      relianceApi.get('/search/customer', {
        firstName,
        lastName,
        email,
        state: state !== '?' ? state : '',
        creditCard,
        dob: dob !== '' ? moment(dob).format('MM/DD/YYYY') : ''
      })
    ;

    return {search};
  }]);