angular
  .module('relcore.directives')
  .directive('smartDatepicker', ['moment', function(moment) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link(scope, element:any, attrs, ngModelCtrl:any) {
        element.removeAttr('smartDatepicker');

        const onSelectCallbacks = [];
        if (attrs.minRestrict) {
          onSelectCallbacks.push(selectedDate => $(attrs.minRestrict).datepicker('option', 'minDate', selectedDate));
        }
        if (attrs.maxRestrict) {
          onSelectCallbacks.push(selectedDate => $(attrs.maxRestrict).datepicker('option', 'maxDate', selectedDate));
        }

        const options = {
          prevText: '<i class="fas fa-chevron-left"></i>',
          nextText: '<i class="fas fa-chevron-right"></i>',
          numberOfMonths: null,
          dateFormat: null,
          defaultDate: null,
          changeMonth: null,
          onSelect(selectedDate) {
            ngModelCtrl.$setViewValue(selectedDate);
            scope.$apply();
            angular.forEach(onSelectCallbacks, function(callback) {
                callback(selectedDate);
            });
          }
        };

        if (attrs.numberOfMonths) {
          options.numberOfMonths = parseInt(attrs.numberOfMonths);
        }
        if (attrs.dateFormat) {
          options.dateFormat = attrs.dateFormat;
        }
        if (attrs.defaultDate) {
          options.defaultDate = attrs.defaultDate;
        }
        if (attrs.changeMonth) {
          options.changeMonth = attrs.changeMonth === "true";
        }

        element.datepicker(options);

        scope.$watch(attrs.defaultDate, function(value) {
          let defaultDate = null;
          if (value != null) {
            defaultDate = moment(value).toDate();
          }
          element.datepicker('setDate', defaultDate);
        });
      }
    };
  }]);
