import moment from 'moment';
import { IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { IUtils } from '../common/utils';
import { IAccount, IAccountLinkStatus, ISimilarAccount } from '../models/account';

export interface IAccountService {
  openAccount: (ani: string) => Promise<IAccount>
  closeAccount: (account: string) => Promise<IApiResponse<any>>
  getAccount: (ani: string) => Promise<IAccount>
  updateAccount: (ani: string, account: IAccount) => Promise<IStatusResponse>
  removeLink: (ani: string, onlineUserId: number) => Promise<IStatusResponse>
  updateCustomerTime: (account: IAccount) => void
  getSimilarAccounts: (ani: string, filter: object) => Promise<ISimilarAccount[]>
}

class AccountService implements IAccountService {
  api: IRelianceApi;
  accountFactory: new (data: object) => IAccount;
  utils: IUtils;

  constructor(api: IRelianceApi, Account: new (data: object) => IAccount, utils: IUtils) {
    this.api = api;
    this.utils = utils;

    this.accountFactory = Account;
  }

  openAccount(ani: string): Promise<IAccount> {
    // TODO: Duplicate DirectBill-style logging/tracking?
    return this.getAccount(ani);
  }

  closeAccount(account: string) {
    // TODO: Duplicate DirectBill-style logging/tracking?
    return null;
  }

  async getAccount(ani: string): Promise<IAccount> {
    const result: IApiResponse<IAccount> = await this.api
      .get(`/account/${ani}?includeLocationData=true&includeBlockData=true&createNew=true`)

    return result.data;
  }

  async updateAccount(ani: string, account: IAccount): Promise<IStatusResponse> {
    let changed: object = { ...account };
    const linkStatus = changed['accountLinkStatus'];

    if (linkStatus) {
      changed = {...changed, accountLinkStatus: linkStatus['id']};
    }

    const result: IApiResponse<IStatusResponse> = await this.api
      .put(`/account/${ani}`, {account: changed});

    return result.data;
  }

  async removeLink(ani: string, onlineUserId: number): Promise<IStatusResponse> {
    const result: IApiResponse<IStatusResponse> = await this.api
      .delete(`/account/${ani}/link?onlineUserId=${onlineUserId}`);
    return result.data;
  }

  async getSimilarAccounts(ani: string, filter: object): Promise<ISimilarAccount[]> {
    // Return empty results if no filter given to avoid returning all accounts
    if (this.utils.isEmpty(filter)) {
      return Promise.resolve([]);
    }

    const result: IApiResponse<ISimilarAccount[]> = await this.api
      .index(`/account/${ani}/similar`, 0, 0, filter);

    if (typeof result.data != 'object') {
      throw new Error('Error fetching similar results');
    }

    return result.data;
  }

  updateCustomerTime(account: IAccount) {
    if (account) {
      const currentTime = this.getCurrentTimeForZone(account.timezone || '0');
      account.formattedCurrentTime = currentTime.format('h:mm:ss a');
    }
  }

  getCurrentTimeForZone(timezone: string) {
    // Start with Greenwich Mean Time
    let time = moment().utc();
    // Adjust for time zone
    switch (timezone) {
      case '1':
        time = time.add(10, 'hours'); // Guam and CNMI
        break;
      case '2':
        time = time.add(-10, 'hours'); // Hawaii
        break;
      case '3':
        time = time.add(-9, 'hours'); // Alaska
        break;
      case '4':
        time = time.add(-8, 'hours'); // Pacific
        break;
      case '5':
        time = time.add(-7, 'hours'); // Mountain
        break;
      case '6':
        time = time.add(-6, 'hours'); // Central
        break;
      case '7':
        time = time.add(-5, 'hours'); // Eastern
        break;
      case '8':
        time = time.add(-4, 'hours'); // Atlantic
        break;
      case '9':
        time = time.add(-3.5, 'hours'); // Newfoundland
        break;
      default:
        break;
    }

    // Adjust for daylight savings except for Guam and Hawaii as they do not observe it
    // Arizona doesn't either, although the rest of the states within Mountain time zone
    // do, so this does not correctly address that as we are only given time zone.
    let isDaylightSavings = moment().isDST();
    if (isDaylightSavings && timezone != '1' && timezone != '2' && timezone != '0') {
      time = time.add(1, 'hours');
    }
    return time;
  };
}

angular
  .module('relcore.account')
  .service('accountService', ['relianceApi', 'Account', 'Utils',
    function(api: IRelianceApi,
      accountFactory: new (data: object) => IAccount,
      utils: IUtils) {

      return new AccountService(api, accountFactory, utils);
    }
  ]);

export interface IAcccountLinkStatusService {
  getAccountLinkStatus(id?: number): IAccountLinkStatus
}

class AccountLinkStatusService implements IAcccountLinkStatusService {
  values: IAccountLinkStatus[];

  constructor(values: IAccountLinkStatus[]) {
    this.values = values;
  }

  getAccountLinkStatus(id: number = 0): IAccountLinkStatus {
    let status = this.values.find((status: IAccountLinkStatus) => status.id === id);
    return status ? status : {id: 0, name: "No Link"};
  }
}

angular
  .module('relcore.account')
  .value('AccountLinkStatusValues', [
    { name: 'No Link', id: 0},
    { name: 'Open Link', id: 2},
    { name: 'Payment Hold', id: 3},
    { name: 'Disabled', id: 4},
    { name: 'Probation', id: 6},
  ])
  .service('AccountLinkStatusService', ['AccountLinkStatusValues', (values: IAccountLinkStatus[]) => {
    return new AccountLinkStatusService(values);
  }]);