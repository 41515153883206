import { ILegacyUserAction } from '../actions/legacy-user';
import { IApiRole, IUser } from '../models/auth';
import { IStaffUserService, IStaffUserUpdate, IStaffUserUpdateResponse, IUpdateErrorResponse } from "./staff-user-service";

interface IStaffUserAddCtrl extends ng.IScope {
  apiRoles: IApiRole[]
  legacyUsers: IUser[]
  staffUser: IStaffUserUpdate
  legacyUser?: IUser
  legacyUsersLoading: boolean
  submitting: boolean
  error?: string
  close(): void
  submit(): void
}

angular
  .module('relcore.staff-user')
  .controller('StaffUserAddController', [
    '$scope', '$uibModalInstance', 'store', 'ReduxMapToScope',
    'apiRolesActions', 'legacyUserActions', 'StaffUserService',

    function($scope: IStaffUserAddCtrl, $uibModalInstance, store, ReduxMapToScope,
      apiRolesActions, legacyUserActions: ILegacyUserAction, StaffUserService: IStaffUserService) {

      $scope.apiRoles = [];

      ReduxMapToScope($scope, state => $scope.apiRoles = state.facilityUser.list.apiRoles);
      store.dispatch(apiRolesActions.load());

      $scope.legacyUsersLoading = false;
      ReduxMapToScope($scope, state => {
        $scope.legacyUsers = state.legacyUser.list.users;
        $scope.legacyUsersLoading = state.legacyUser.list.loading;
      });
      store.dispatch(legacyUserActions.load());

      $scope.staffUser = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        status: 'Enabled'
      };

      $scope.submitting = false;
      $scope.close = () => $uibModalInstance.close({saved: false});
      $scope.submit = function() {
        $scope.submitting = true;
        const newUser: IStaffUserUpdate = $scope.legacyUser ?
          {...$scope.staffUser, legacyUserId: $scope.legacyUser.id} : $scope.staffUser;
        StaffUserService.addStaffUser(newUser)
          .then((res: IStaffUserUpdateResponse) => {
            $scope.submitting = false;
            $scope.error = null;
            if (res.success) {
              $scope.error = null;
              $uibModalInstance.close({saved: true});
            }
            else {
              $scope.error = 'An unexpected error occurred';
              $scope.$apply();
            }
          })
          .catch((errResponse: IUpdateErrorResponse) => {
            $scope.submitting = false;
            if (errResponse.status === 400) {
              const error = errResponse.data.error;
              let message = 'Please verify the inputs are valid and try again';
              if (error.message) {
                message = error.message;
              }
              if (error.details) {
                message += `: ${error.details}`;
              }
              $scope.error = message;
            } else {
              $scope.error = 'An unexpected error occurred';
            }
            $scope.$apply();
          });
      };
    }
  ]);
