import React from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { react2angular } from 'react2angular';
import { IInmate } from '../inmate/inmate-service';
import { CommunicationTreatment, CommunicationTreatmentOverride } from '../models/enums';
import { InmateContact } from '@reliance/types/models/InmateContact';
import { CdrCategory } from '@reliance/types/enums/CdrCategory';

type InmateContactTreatmentProps = {
  inmate: IInmate;
  contact: InmateContact;
  countyBlocked: string | boolean;
  treatmentOverride: CommunicationTreatmentOverride | null;
  cdrCategory: CdrCategory;
}

const InmateContactTreatment: React.FC<InmateContactTreatmentProps> = (props) => {
  const { inmate, contact, treatmentOverride, cdrCategory } = props;
  const countyBlocked = props.countyBlocked == true || props.countyBlocked == '1';

  if (treatmentOverride == CommunicationTreatmentOverride.BLOCKED) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Always Blocked</Tooltip>}>
        <i className="fas fa-times" style={{ color: 'red' }} />
      </OverlayTrigger>
    );
  }

  if (treatmentOverride == CommunicationTreatmentOverride.ALLOWED) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Always Allowed</Tooltip>}>
        <i className="fas fa-check" style={{ color: 'green' }} />
      </OverlayTrigger>
    );
  }

  if (
    countyBlocked &&
    treatmentOverride != CommunicationTreatmentOverride.ALLOWED
  ) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Facility Blocked</Tooltip>}>
        <i className="fas fa-times" style={{ color: 'red' }} />
      </OverlayTrigger>
    );
  }

  // Check for facility blocks
  if (
    cdrCategory &&
    (contact.customerAccount?.facilityBlocks || []).filter(b => b.category == cdrCategory).length > 0
  ) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Facility Blocked</Tooltip>}>
        <i className="fas fa-times" style={{ color: 'red' }} />
      </OverlayTrigger>
    );
  }

  if (inmate.communicationTreatment == CommunicationTreatment.ALLOWED_ONLY) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Not Allowed</Tooltip>}>
        <i className="fas fa-times" />
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`inmate-contact-treatment-${Math.random()}`}>Not Restricted</Tooltip>}>
      <i className="fas fa-check" />
    </OverlayTrigger>
  );
}

angular
  .module('relcore.inmate-contact')
  .component('inmateContactTreatment', react2angular(InmateContactTreatment, ['inmate', 'contact', 'countyBlocked', 'treatmentOverride', 'cdrCategory']));

export default InmateContactTreatment;