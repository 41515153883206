angular
  .module('relcore.online-user')
  .controller('OnlineUserResendActivationController', [
    '$scope', '$uibModalInstance', 'onlineUser', 'relianceApi',
    function($scope, $uibModalInstance, onlineUser, relianceApi) {
      $scope.onlineUser = onlineUser;
      $scope.submitting = false;
      $scope.close = () => $uibModalInstance.close({sent: false});
      return $scope.submit = function() {
        $scope.submitting = true;
        return relianceApi.put(`/onlineuser/resend-activation?id=${onlineUser.id}`)
          .then((function() {
            $scope.error = null;
            $scope.submitting = false;
            return $uibModalInstance.close({sent: true});
          }), (function(err) {
            $scope.error = 'An unexpected error occurred';
            return $scope.submitting = false;
          }));
      };
    }
  ]);
