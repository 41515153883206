angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceReportIns', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?',
        onViewDetails: '='
      },
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }],
      template: require('./mobile-device-report-ins.html').default
    })
  );
