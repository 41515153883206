import { IFacility } from '../models/facility';

interface IFacilityListCtrl extends ng.IScope {
  facilities: IFacility[]
  activeOnly: boolean
  loading: boolean
  refresh: boolean
  listGridOptions: any
  details(facilityId: number): void
  showAddView(): void
  setActive(facilityId: number, flag: boolean) : void
  reload() : void
}

angular
  .module('relcore.facility')
  .config(['$stateProvider', ($stateProvider) =>
    $stateProvider
      .state('facilities', {
        controller: 'FacilityController',
        template: require('./main.html').default,
        url: '/facilities',
        parent: 'authenticated',
        ncyBreadcrumb: { label: 'Facilities' }
      })
  ])
  .controller('FacilityController',
    ['$rootScope', '$scope', '$filter', 'facilityService', 'NgTableParams', '$state', '$uibModal', 'relianceApi', 'alertService',
    function($rootScope, $scope: IFacilityListCtrl, $filter, facilityService, NgTableParams, $state, $uibModal, relianceApi, alertService) {
      $rootScope.title = 'Facility List';
      $scope.facilities = [];

      $scope.refresh = false;
      $scope.listGridOptions = new NgTableParams({
          page: 1,
          count: 5000,
          sorting: {
            'created.date': 'desc'
          },
          filter:{status:"StatusActive"}
        }, {
          debugMode: true,
          counts: null,
          getData: (params) => {
            $scope.loading = true;
            return new Promise(resolve => facilityService.retrieveAll((err, facilities, total) => {
              $scope.loading = false;
              params.total(total);
              if (params.filter()) {
                facilities = $filter('filter')(facilities, params.filter());
              }
              if (params.sorting()) {
                facilities = $filter('orderBy')(facilities, params.orderBy());
              }

              let result = facilities.slice((params.page()-1)*params.count(), params.page() * params.count());
              resolve(result);
              $scope.refresh = false;
          }, $scope.refresh))
        }
      });

      $scope.reload = () => {
        $scope.refresh = true;
        $scope.listGridOptions.reload();
      }

      $scope.showAddView = () => {
        const dialog = $uibModal.open({
          size: 'lg',
          template: require('./facility-add-modal.html').default,
          controller: 'FacilityAddController'
        });
        dialog.result.then(() => $scope.listGridOptions.reload());
      };
    }
  ]);
