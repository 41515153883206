angular
  .module('relcore.report')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('inmate-account-payment-summary-report', {
        controller: 'InmateAccountPaymentSummaryReport',
        url: '/report/inmate-account-payment/purchases',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'authenticated'
        },
        template: require('./purchases.html').default
      });
  }])
  .controller('InmateAccountPaymentSummaryReport',
    ['$rootScope', '$scope', '$state', 'relianceApi', 'facilityService', 'NgTableParams', '$stateParams', 'moment',
    function($rootScope, $scope, $state, relianceApi, facilityService, NgTableParams, $stateParams, moment) {
      $rootScope.title = 'Texting/Video Inmate Purchase Report';

      $scope.dateRange = {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day')
      }

      $scope.facilityIds = $rootScope.facilitiesToDisplay;
      $scope.runLock = true;
      $scope.loaded = false;
      $scope.run = () => {
        $scope.runLock = false

        let queryParams = {
          startDate: $scope.dateRange.startDate.format('YYYY/MM/DD 00:00:00'),
          endDate: $scope.dateRange.endDate.format('YYYY/MM/DD 23:59:59'),
          facilityIds: null
        };

        if ($scope.facilityIds) {
          queryParams.facilityIds = $scope.facilityIds;
        }

        $scope.loading = true;
        relianceApi.get("/report/inmate-account-payment/purchase-report", queryParams)
          .then((response) => {
            $scope.loading = false
            $scope.loaded = true
            $scope.reportData = response.data;
          });
      };

      $scope.reset = () => $scope.loaded = false;
  }]);
