angular
  .module('relcore.configuration')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('configurations-view', {
        controller: 'ConfigurationViewController',
        template: require('./view.html').default,
        url: '/configuration/view/:configId',
        parent: 'authenticated',
        params: {config: null},
        ncyBreadcrumb: {
          label: '{{configuration.facility.name}} / {{configuration.key}}',
        }
      }
    )
  ])
  .controller('ConfigurationViewController',
    ['$rootScope', '$scope', '$state', '$filter', 'configurationService', 'NgTableParams', '$stateParams', 'alertService', 'relianceApi',
    function($rootScope, $scope, $state, $filter, configurationService, NgTableParams, $stateParams, alertService, relianceApi) {
      $scope.configId = $stateParams.configId;

      if ($stateParams.config != null) {
        $scope.configuration = $stateParams.config;
        $rootScope.title = $scope.configuration.key;
      } else {
        configurationService.getById($stateParams.configId)
          .then(function(configuration) {
            $scope.configuration = configuration;
            $rootScope.title = configuration.key;
        });
      }

      $scope.delete = function() {
        const onSuccess = function() {
          alertService.success("Configuration deleted successfully", true);
          $state.go('facilities-view', {id:$stateParams.facilityId});
        };
        const onError = () => alertService.error("Configuration failed to be deleted", true);

        configurationService.deleteConfiguration($stateParams.configId)
          .then(onSuccess, onError);
      };

      $scope.save = function() {
        const onSuccess = function() {
          alertService.success("Configuration updated successfully", true);
          $rootScope.title = $scope.configuration.key;
        };
        const onError = () => alertService.error("Changes failed to save.", true);

        const changes =
          {value: $scope.configuration.value};

        configurationService.updateConfiguration($scope.configId, changes)
          .then(onSuccess, onError);
      };
    }
  ]);
