import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { ICallRoute } from '../models/call-route';

interface ICallRouteMainController {
  loading: boolean
  refresh(): Promise<Array<ICallRoute>>
  callRoutes: Array<ICallRoute>

  showAddEditView(callRoute?: ICallRoute): void
  adding: boolean
  onAdd(user): Promise<void>

  results: Array<ICallRoute>
  gridOptions: any
}

angular
  .module('relcore.call')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('call-route-main', {
        controller: 'CallRouteMainController',
        url: '/call/route',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Call Routes'
        },
        template: require('./call-route-main.html').default
      }
    )
  ])
  .controller('CallRouteMainController',
    ['$rootScope', '$scope', '$uibModal', 'relianceApi', 'NgTableParams', '$filter',
    function($rootScope, $scope: ICallRouteMainController, $uibModal, relianceApi:IRelianceApi, NgTableParams, $filter) {
      $rootScope.title = 'Call Routes';
      $scope.callRoutes = [];

      // Load the call routes
      $scope.refresh = () => {
        $scope.loading = true;
        return relianceApi
          .get('/call/route', { page: null})
          .then((res: IApiResponse<IPaginatedResponse<ICallRoute>>) => {
            $scope.loading = false;
            res.data.data.sort((a,b) => a.name > b.name ? 1 : -1);
            $scope.callRoutes = res.data.data;
            $scope.gridOptions.reload();
            return res.data.data;
          });
      };
      $scope.refresh();

      $scope.showAddEditView = (editRoute) => {
        const dialog = $uibModal.open({
          template: require('../call/call-route-create-edit-modal.html').default,
          controller: 'CallRouteCreateEditController',
          resolve: {
            editRoute() { return editRoute; }
          }
        });
        dialog.result.then(() => $scope.refresh());
      };

      $scope.adding = false;
      $scope.onAdd = function(user) {
        $scope.adding = true;
        return Promise.resolve();
      };

      $scope.gridOptions = new NgTableParams({}, {
        counts: null,
        getData(params) {
          return $filter('filter')($scope.callRoutes, params.filter());
        }
      });
    }
  ]);
