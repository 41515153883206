import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { react2angular } from 'react2angular';
import { IAlertService } from '../alert/alert-service';
import { IPaginatedApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { ApiLog } from '../models/ApiLog';
import { IFacility } from '../models/facility';
import Search, { SearchCriteria } from './FacilityUserSearch';
import Table from './Table';

type ListProps = {
  relianceApi: IRelianceApi
  alertService: IAlertService
  facilityService: any
};

const List: React.FC<ListProps> = (props) => {
  const [facilities, setFacilities] = useState<IFacility[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  const [apiLogs, setApiLogs] = useState<IPaginatedResponse<ApiLog>>(null);
  const [searching, setSearching] = useState(false);
  const hasSearchCriteria = searchCriteria.facilityId || searchCriteria.startDate || searchCriteria.endDate || searchCriteria.facilityUserId;

  // Load the facilities needed for the search criteria
  useEffect(() => {
    props.facilityService.retrieveAll().then(setFacilities);
  }, []);

  // Load the logs
  useEffect(() => {
    if (hasSearchCriteria) {
      setSearching(true);
      props.relianceApi
        .index<IPaginatedApiResponse<ApiLog>>('/log/facility-user', 1, 1000, searchCriteria)
        .then((res) => setApiLogs(res.data))
        .finally(() => setSearching(false));
    }
  }, [searchCriteria]);

  return (
    <Card className="mt-3">
      <Card.Body>
        <div className="mb-3">
          <Search
            facilities={facilities}
            onSubmit={(criteria) => setSearchCriteria({...(criteria||{})})}
            submitting= {searching}
          />
        </div>

        <Table
          apiLogs={apiLogs}
        />
      </Card.Body>
    </Card>
  )
};

angular
  .module('relcore.api-log')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('facility-user-log', {
        component: 'facilityUserLogList',
        url: '/facility-user/log',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Facility User Log'
        }
      }
    )
  ])
  .component('facilityUserLogList', react2angular(
    List,
    [],
    ['alertService', 'facilityService', 'relianceApi']
  ));