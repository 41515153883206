angular
  .module('relcore.inmate')
  .service('inmateActivityService', ['relianceApi', '$filter', function(relianceApi, $filter) {
    return {
      index(page, perPage, orderBy, filter) {
        const params = {orderBy: null, page: null, perPage: null};
        for (let i in filter) {
          if ((filter[i] != null) && (filter[i] !== '')) { params[i] = filter[i]; }
        }

        if (orderBy != null) {
          params.orderBy = orderBy;
        }

        params.page = page;
        params.perPage = perPage;

        return relianceApi.get("/inmate-account/activity", params)
          .then(response => response.data);
      },

      post(data) {
        return relianceApi.post("/inmate-account/activity", data);
      },

      refund(data) {
        return relianceApi.post("/inmate-account/activity/refund", data);
      }
    };
  }]);
