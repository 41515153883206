angular
  .module('relcore.mobile-device')
  .controller('MobileDevicePurchaseViewController', [
    '$rootScope', '$scope', '$state', '$filter', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams', '$q',
    function($rootScope, $scope, $state, $filter, relianceApi, alertService, $stateParams, NgTableParams, $q) {
      $scope.assignedGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData(params) {
          if (($scope.purchase.devices == null)) {
            return [];
          }
          params.total($scope.purchase.devices.length);
          return $scope.purchase.devices;
        }
      });

      $scope.needAssigningGridOptions = new NgTableParams({
        page: 1,
        count: 10,
        sorting: {
          dateAdded: 'desc'
        }
      }, {
        counts: [10,50,100],
        getData(params) {
          return relianceApi
            .index('/mobile-device', params.page(), params.count(), { purchaseId: -1 }, params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.pendingAssignmentDevices = [];
      $scope.pendingAssignmentGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          return $scope.pendingAssignmentDevices;
        }
      });

      $scope.addDevices = function() {
        const deviceIds = $scope.pendingAssignmentDevices.map(d => d.id).join(',');
        return relianceApi
          .put(`/mobile-device-purchase/${$scope.purchase.id}/devices`, { deviceIds })
          .then((function() {
            alertService.success('The device(s) were successfully added to the purchase');
            $scope.needAssigningGridOptions.reload();
            loadPurchase($scope.purchase.id);
            $scope.pendingAssignmentDevices = [];
          }), (() => alertService.error('An error occurred while adding the device(s) to the purchase')));
      };

      $scope.noteListGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          const notes = JSON.parse($scope.purchase.notes || "[]");
          return notes;
        }
      });

      const resetNote = () => $scope.newNote = { note: '' };

      $scope.addNote = note =>
        relianceApi
          .post(`/mobile-device-purchase/${$scope.purchase.id}/note`, note)
          .then((function() {
            loadPurchase($scope.purchase.id);
            alertService.success('The note was successfully added');
            resetNote();
          }), (() => alertService.error('An error occurred while adding the note')))
      ;

      const setPurchase = function(purchase) {
        $rootScope.title = `${purchase.vendor} (${$filter('amDateFormat')(purchase.dateOrdered.date, 'MM/DD/YYYY')})`;
        $scope.purchase = purchase;
        $scope.assignedGridOptions.reload();
        $scope.noteListGridOptions.reload();
      };

      var loadPurchase = id =>
        relianceApi
          .get(`/mobile-device-purchase/${id}`)
          .then(res => setPurchase(res.data))
      ;

      if ($stateParams.purchase) {
        setPurchase($stateParams.purchase);
        loadPurchase($scope.purchase.id);
      } else {
        loadPurchase($stateParams.id);
      }

      $scope.selectedDevices = [];

      $scope.removingDevices = false;
      $scope.removeDevices = function() {
        $scope.removingDevices = true;
        const allPromises = [];
        let device:any
        for (device of Array.from($scope.selectedDevices)) {
          allPromises.push(
            relianceApi.delete(`/mobile-device-purchase/${$scope.purchase.id}/device/${device.id}`)
          );
        }
        return $q.all(allPromises)
          .then(function() {
            loadPurchase($scope.purchase.id);
            $scope.removingDevices = false;
            alertService.success("Devices were removed from the purchase");
          });
      };

      resetNote();
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('mobile-device-purchase-view', {
        controller: 'MobileDevicePurchaseViewController',
        url: '/mobile-device-purchase/view/:id',
        parent: 'authenticated',
        params: {
          purchase: null
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'mobile-device-purchases'
        },
        template: require('./view.html').default
      }
    )
  ]);
