import { maxLength, required, usPhoneNumber } from '@reliance/forms-js/lib/validation';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import { react2angular } from 'react2angular';
import { IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { Select } from '../form/Select';
import { TextInput } from '../form/TextInput';
import { formatPhoneNumber } from '../format/PhoneNumber';
import { IInmate } from '../inmate/inmate-service';
import { CommunicationTreatmentOverride } from '../models/enums';

type AddEditModalProps = {
  inmate: IInmate;
  inmateContact?: any | null;
  open: boolean;
  onClose(saved: boolean): void;
  relianceApi: IRelianceApi;
};

type AddEditInmateContactData = {
  inmateId?: number;
  id?: number;
  name?: string;
  textingTreatmentOverride?: CommunicationTreatmentOverride;
  audioCallTreatmentOverride?: CommunicationTreatmentOverride;
  videoCallTreatmentOverride?: CommunicationTreatmentOverride;
};

const AddEditModal = (props: AddEditModalProps) => {
  if (!props.open) {
    return null;
  }

  const [initialValues] = useState<Partial<AddEditInmateContactData>>({
    ...props.inmateContact,
    inmateId: props.inmate.id
  });

  const save = (data: AddEditInmateContactData) => {
    if (data.id) {
      return props.relianceApi.put(`/inmate-contact/by-id/${data.id}`, data).catch((err) => {
        return { [FORM_ERROR]: err };
      });
    } else {
      return props.relianceApi.post('/inmate-contact', data).catch((err) => {
        return { [FORM_ERROR]: err };
      });
    }
  };

  return (
    <Modal show={true} onHide={() => props.onClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.inmateContact ? (
            <>
              Edit {formatPhoneNumber(props.inmateContact.ani)}
              {props.inmateContact.name && (
                <div>
                  <small>{props.inmateContact.name}</small>
                </div>
              )}
            </>
          ) : (
            'Add Contact'
          )}
        </Modal.Title>
      </Modal.Header>
      <Form
        initialValues={initialValues}
        onSubmit={(data: AddEditInmateContactData) =>
          save(data).then((response: IApiResponse<IStatusResponse>) => {
            if (response?.data?.success) {
              if (props.inmateContact) {
                props.inmateContact.name = data.name;
                props.inmateContact.textingTreatmentOverride =
                  data.textingTreatmentOverride;
                props.inmateContact.audioCallTreatmentOverride =
                  data.audioCallTreatmentOverride;
                props.inmateContact.videoCallTreatmentOverride =
                  data.videoCallTreatmentOverride;
              }
              props.onClose(true);
            }
          })
        }>
        {({ handleSubmit, form, submitting, hasValidationErrors, pristine, submitError }) => (
          <form onSubmit={handleSubmit} method='post'>
            <Modal.Body style={{ minWidth: 500 }}>
              {submitError && (
                <div className='alert alert-error'>{submitError}</div>
              )}

              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        {props.inmateContact == null &&
                          <Col md={6}>
                            <TextInput
                              name="ani"
                              label="Phone Number"
                              placeholder={'NNN-NNN-NNNN'}
                              validationRules={[
                                required,
                                maxLength(11),
                                usPhoneNumber
                              ]} />
                          </Col>
                        }
                        <Col md={6}>
                          <TextInput
                            name="name"
                            label="Name"
                            validationRules={[
                              maxLength(50)
                            ]} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={12}>
                  <br/>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={4}>
                          <Select
                            name="textingTreatmentOverride"
                            label="Texting Override">
                            <option value=''>No Override</option>
                            <option value='Allowed'>Always Allow</option>
                            <option value='Blocked'>Always Block</option>
                          </Select>
                        </Col>
                        <Col md={4}>
                          <Select
                            name="audioCallTreatmentOverride"
                            label="Audio Call Override">
                            <option value=''>No Override</option>
                            <option value='Allowed'>Always Allow</option>
                            <option value='Blocked'>Always Block</option>
                          </Select>
                        </Col>
                        <Col md={4}>
                          <Select
                            name="videoCallTreatmentOverride"
                            label="Video Call Override">
                            <option value=''>No Override</option>
                            <option value='Allowed'>Always Allow</option>
                            <option value='Blocked'>Always Block</option>
                          </Select>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => props.onClose(false)} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting || pristine || hasValidationErrors}>
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};

angular
  .module('relcore.inmate-contact')
  .component('inmateContactAddEditModal', react2angular(AddEditModal, ['inmate', 'inmateContact', 'onClose', 'open', 'relianceApi']));

export default AddEditModal;
