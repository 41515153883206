angular
  .module('relcore.mobile-device')
  .directive('mobileDevicePurchases', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        onViewDetails: '='
      },
      template: require('./mobile-device-purchases.html').default
    })
  );
