angular
  .module('relcore.filters')
  .filter('dur', () =>
    function(start, end) {
      // Convert strings to date objects
      let seconds;
      if (end == null) { end = 0; }
      if ((end !== null) && !(end instanceof Date) && isNaN(parseFloat(start))) {
        end = Date.parse(end);
      }
      if ((start !== null) && !(start instanceof Date) && isNaN(parseFloat(start))) {
        start = Date.parse(start);
      }

      // If given start and end, calculate number of seconds between
      if ((start !== null) && (end !== null)) {
        seconds = Math.abs(end - start);
        // If original start date was a date object or string, assume seconds is milliseconds
        if (isNaN(parseFloat(start))) {
          seconds = seconds / 1000;
        }
      } else {
        seconds = start;
      }

      if (seconds === 0) { return '0s'; }

      const hr = Math.floor(seconds/3600);
      const m = Math.floor((seconds%3600)/60);
      const s = seconds%60;
      return (
        (hr > 0 ? parseInt(String(hr)) + "hr " : "") + // Hours
        (m > 0 ? parseInt(String(m)) + "m " : "") + // Minutes
        (s > 0 ? parseInt(String(s)) + "s" : "") // Seconds
      ).trim();
    }
  );