angular
  .module('relcore.facility-equipment')
  .service('facilityEquipmentService',
  	['$q', 'CacheFactory', 'relianceApi', 'FacilityEquipment', 'config',
    function($q, CacheFactory, relianceApi, FacilityEquipmentModel, config) {

      const getByFacility = id =>
        relianceApi.get(`/facility-equipment?facilityId=${id}`)
          .then(function(results) {
            let { data } = results;
            const equipments = [];
            for (let equipment of Array.from(data.data)) {
              equipments.push(new FacilityEquipmentModel(equipment));
            }
            data = equipments;
            return data;
        })
      ;

      const getById = id =>
        relianceApi.get(`/facility-equipment/${id}`)
          .then(result => new FacilityEquipmentModel(result.data))
      ;

      const postNewFacilityEquipment = (facilityId, name, serialNumber, description, type) =>
        relianceApi.post("/facility-equipment",{
          facilityId,
          name,
          serialNumber,
          description,
          type
        })
      ;

      const updateFacilityEquipment = (id, changes) => relianceApi.put(`/facility-equipment/${id}`, changes);


      return {
        getByFacility,
        getById,
        postNewFacilityEquipment,
        updateFacilityEquipment
      };
    }
    ]);
