import { IAlertService } from '../alert/alert-service';
import { IApiRole } from '../models/auth';
import { IStaffUser } from '../models/staff-user';
import { IStaffUserService, IStaffUserUpdate, IStaffUserUpdateResponse } from './staff-user-service';

interface IStaffUserViewCtrl extends ng.IScope {
  apiRoles: IApiRole[]
  id: number
  staffUser: IStaffUser
  refreshing: boolean
  saving: boolean
  resettingPassword: boolean
  refresh(): void
  save(edits: IStaffUserUpdate): void
  resetPassword(): Promise<void>
  enable(): void
  disable(): void
}

angular
  .module('relcore.staff-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('staff-user-view', {
        controller: 'StaffUserViewController',
        template: require('./view.html').default,
        url: '/staff-user/view/:id',
        parent: 'authenticated',
        params: {
          staffUser: null
        },
        ncyBreadcrumb: {
          label: '{{staffUser|fullName}}',
          parent: 'staff-user-search'
        }
      })
  ])
  .controller('StaffUserViewController',
    ['$rootScope', '$scope', '$stateParams', 'alertService', 'ReduxMapToScope',
     'store', 'apiRolesActions', 'StaffUserService',

    function($rootScope, $scope: IStaffUserViewCtrl, $stateParams,
      alertService: IAlertService, ReduxMapToScope, store, apiRolesActions,
      StaffUserService: IStaffUserService) {

      $scope.id = $stateParams.id;
      $scope.staffUser = $stateParams.staffUser;

      $scope.refreshing = false;
      $scope.refresh = async () => {
        $scope.refreshing = true;
        return StaffUserService.getStaffUser($scope.id)
          .then((staffUser: IStaffUser) => {
              $scope.staffUser = staffUser;
              $rootScope.title = `${$scope.staffUser.firstName} ${$scope.staffUser.lastName}`;
              $scope.$apply();
          })
          .catch(() => {
            alertService.error('An error occurred while loading the staff user', true);
              $scope.$apply();
          });
      };

      if ($scope.staffUser == null) {
        $scope.refresh();
      } else {
        $rootScope.title = `${$scope.staffUser.firstName} ${$scope.staffUser.lastName}`;
      }

      $scope.saving = false;
      $scope.save = async (edits: IStaffUserUpdate) => {
        $scope.saving = true;
        return StaffUserService.editStaffUser($scope.id, edits)
          .then((res: IStaffUserUpdateResponse) => {
              $scope.saving = false;
              $scope.staffUser = res.staffUser;
              $rootScope.title = `${$scope.staffUser.firstName} ${$scope.staffUser.lastName}`;
              alertService.success('The staff user was successfully updated', true);
              $scope.$apply();
          })
          .catch(() => {
              $scope.saving = false;
              alertService.error('An error occurred while updating the staff user', true);
              $scope.$apply();
          });
      };

      $scope.enable = () => $scope.save({ status: 'Enabled' });
      $scope.disable = () => $scope.save({ status: 'Disabled' });

      $scope.resettingPassword = false;
      $scope.resetPassword = async () => {
        $scope.resettingPassword = true;
        return StaffUserService.resetPassword($scope.staffUser.emailAddress)
          .then(() => {
              $scope.resettingPassword = false;
              alertService.success("An email was sent to reset the user's password", true)
              $scope.$apply();
          })
          .catch(() => {
              $scope.resettingPassword = false;
              alertService.error('An error occurred while requesting a password reset email', true);
              $scope.$apply();
          });
      };
    }
  ]);
