import * as validation from '@reliance/forms-js/lib/validation';
import { FormApi } from 'final-form';
import * as React from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { Field } from 'react-final-form';
import { Input } from '../../form/render';
import { CreditCardTypeIcon } from '../../format/CreditCardType';

type NewCreditCardFormProps = {
  form: FormApi<any>
  currentValues
}

export const NewCreditCardForm: React.FC<NewCreditCardFormProps> = (props) =>
  <div>
    <div className="row">
      <div className="col-md-12">
        <Input
          name="paymentMethod.info.nameOnCard"
          label={
            <span>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="load-from-billing">Load Fields From Billing</Tooltip>}>
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  style={{ marginRight: 10 }}
                  onClick={props.form.mutators.loadBilling}>
                  <i className="fas fa-exchange-alt" />
                </button>
              </OverlayTrigger>
              Name on Card
            </span>
          }
          validationRules={[
            validation.required
          ]} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Input
          name="paymentMethod.info.address"
          label="Address"
          validationRules={[
            validation.required
          ]} />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.city"
          label="City"
          validationRules={[
            validation.required
          ]} />
      </div>
      <div className="col-md-2">
        <Input
          name="paymentMethod.info.state"
          label="State"
          validationRules={[
            validation.required
          ]} />
      </div>
      <div className="col-md-3">
        <Input
          name="paymentMethod.info.zip"
          label="Zip"
          validationRules={[
            validation.required,
            validation.postalCode
          ]} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.cardNumber"
          label={<>Card Number <CreditCardTypeIcon cardNumber={props.currentValues.cardNumber} /></>}
          validationRules={[
            validation.required,
            validation.creditCard
          ]} />
      </div>
      <div className="col-md-3">
        <Input
          name="paymentMethod.info.cardSecurityCode"
          label="CVC"
          validationRules={[
            validation.required,
            validation.numeric,
            validation.minLength(3),
            validation.maxLength(4)
          ]} />
      </div>
      <div className="col-md-3">
        <Input
          name="paymentMethod.info.cardExpiration"
          label="Exp"
          placeholder="MM/YY"
          validationRules={[
            validation.required,
            validation.creditCardExpiration
          ]} />
      </div>
      <div className="col-md-12">
        <label>
          <Field
            name="paymentMethod.info.save"
            component="input"
            type="checkbox"
          /> Save Card
        </label>
      </div>
    </div>
  </div>
