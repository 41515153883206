import { IApiErrorResponse, IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service'

export type IPhoneCard = Partial<IPhoneCardInventory>

export type IPhoneCardTypeValue = Partial<IPhoneCardInventory>

export type ILocation = {
  id: number
  name: string
}

export type IType = {
  id: number
  description: string
  location: ILocation
}

export type IValue = {
  id: number
  description: string
  unitPrice: number
}

export type ITypeValue = {
  id: number
  type: IType
  value: IValue
}

export interface IPhoneCardInventory {
  typeValue: ITypeValue,
  count: number
}

interface ICache<T> {
  put(key: string, value?: T[]):T[]
  get(key: string): T[] | undefined
  setOptions(options: {
    maxAge: number
    deleteOnExpire: string
  }): void
}

export interface IPhoneCardService {
  getAllCardTypeValues(): Promise<ITypeValue[]>
  generateInventory(typeValueId: number, quantity: number): Promise<IStatusResponse>
  getAvailableInventory(typeID?: number): Promise<IPhoneCardInventory[]>
}

class PhoneCardService implements IPhoneCardService {
  api: IRelianceApi;
  cache: ICache<ITypeValue>;

  constructor(relianceApi: IRelianceApi, CacheFactory) {
    this.api = relianceApi;
    this.cache = CacheFactory('typeValuesCache');
    this.cache.setOptions({
      maxAge: 60 * 30 * 1000, // 30 minutes
      deleteOnExpire: 'aggressive'
    });
  }

  async getAllCardTypeValues(): Promise<ITypeValue[]> {
    const typeValues = this.cache.get('all');
    if (typeValues) {
      return typeValues;
    }

    return this.api.get('/phone-card/type-values')
      .then((result: IApiResponse<ITypeValue[]>) => {
        this.cache.put('all', result.data);
        return result.data;
      })
      .catch((err: IApiErrorResponse) => {
        return Promise.reject({
          status: err.data.error.code,
          message: err.data.error.message
        });
      });
  }

  async generateInventory(typeValueId: number, quantity: number): Promise<IStatusResponse> {
    return this.api
      .post(
        '/phone-card/generate-inventory',
        {typeValueId: typeValueId, quantity: quantity}
      )
      .then((result: IApiResponse<IStatusResponse>) => {
        debugger; // Verify that this comes with .data
        return result.data;
      })
      .catch((err: IApiErrorResponse) => {
        return Promise.reject({
          status: err.data.error.code,
          message: err.data.error.message
        });
      });
  }

  async getAvailableInventory(typeID: number = null): Promise<IPhoneCardInventory[]> {
    return this.api.get('/phone-card/inventory', {typeID: typeID})
      .then((result: IApiResponse<IPhoneCardInventory[]>) => {
        return result.data;
      })
      .catch((err: IApiErrorResponse) => {
        return Promise.reject({
          status: err.data.error.code,
          message: err.data.error.message
        });
      });
  }
}

angular
  .module('relcore.card')
  .service('phoneCardService', ['relianceApi', 'CacheFactory',
    (relianceApi: IRelianceApi, CacheFactory) => {
      return new PhoneCardService(relianceApi, CacheFactory);
    }
  ]);