import { IMobileDeviceGroup } from './mobile-device-group';

interface IMobileDeviceGroupAdd {
  name: string
  facilityId: number
}

interface IGroupAddCtrl extends ng.IScope {
  error?: string
  adding: boolean
  newGroup: IMobileDeviceGroupAdd
  reset(): void
  add(): Promise<IMobileDeviceGroupAdd>
  onAdd?(group:IMobileDeviceGroup): void
  close(): void
}

angular
  .module('relcore.mobile-device-group')
  .controller('DeviceGroupAddCtrl', ['$scope', 'relianceApi', '$uibModalInstance', 'facilityId', function($scope:IGroupAddCtrl, relianceApi, $uibModalInstance, facilityId) {
    $scope.newGroup = {
      name: '',
      facilityId
    };

    $scope.add = function() {
      $scope.adding = true;
      return relianceApi
        .post(`/mobile-device/group`, {
          name: $scope.newGroup.name,
          facilityId: $scope.newGroup.facilityId
        })
        .then((function(res) {
          $scope.adding = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({saved: true});
          }
        }), (function(err) {
          if (err.status === 400) {
            $scope.error = 'Please verify the inputs are valid and try again';
          } else {
            $scope.error = 'An unexpected error occurred';
          }
          $scope.adding = false;
        }));
    };

    $scope.close = () => $uibModalInstance.close({saved: false});
  }]);
