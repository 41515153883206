angular
  .module('relcore.support')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('inmate-texting-support-inbox', {
        controller: 'InmateTextingSupportInboxController',
        url: '/support/inmate-texting',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Inmate Texting Support Inbox',
          parent: 'authenticated'
        },
        template: require('./inmate-texting.html').default
      }
    )
  ])
  .controller('InmateTextingSupportInboxController',
    ['$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams', '$stateParams',
    function($rootScope, $scope, $state, relianceApi, NgTableParams, $stateParams) {
      $rootScope.title = 'Inmate Texting Support Inbox';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'oldestUnreadSupportMessage': 'asc'
        },
        filter: {}
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index("/text-message/support-inbox", params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
          });
        }
      });

      $scope.onViewDetails = data => $state.go('inmates-view', {id: data.id, defaultToSupportTab: true});
    }
  ]);
