angular
  .module('relcore.schedule')
  .directive('scheduleDay', ['_', function(_) {
    return {
      restrict: 'E',
      scope: {
        day: '=',
        blocks: '=',
        editMode: '=',
        onBlockSelect: '='
      },
      controller: ['$scope', 'moment', function($scope, moment) {
        $scope.editMode = $scope.editMode === true;

        $scope.blockList = [];
        for (let k in $scope.blocks) { const v = $scope.blocks[k]; $scope.blockList.push(v); }

        const filterAvailability = (currentDay, availability) =>
          availability.filter(a => moment(currentDay).startOf('day').isSame(moment(a.reservationStartDate.date).startOf('day')))
        ;

        const groupByTimeOfDay = availability =>
          _.groupBy(availability, function(a) {
            const aDate = moment(a.reservationStartDate.date);
            return parseInt(`${aDate.hour()}${(`00${Math.floor((aDate.minute()/60)*100)}`).slice(-2)}`);
          })
        ;

        if ($scope.editMode) {
          $scope.title = $scope.day;
        } else {
          $scope.title = $scope.day.format('ddd, MMM D');

          const blockAvailability = groupByTimeOfDay(
            filterAvailability($scope.day, $scope.availability)
          );

          $scope.openSlot = time => blockAvailability[time];
        }

        $scope.selectedBlocks = [];
        $scope.$watchCollection('selectedBlocks', newList =>
          (() => {
            const result = [];
            let block:any;
            for (block of Array.from($scope.blockList)) {
              const isSelected = newList.indexOf(block) > -1;
              if (block.selected !== isSelected) {
                result.push($scope.onBlockSelect(block));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })()
        );

        $scope.handleSelect = (event, ui, selected, list) => {
          let blockScope:any = angular.element(ui.selecting).scope();
          $scope.onBlockSelect(blockScope.block);
        }
      }
      ],
      template: require('./schedule-day.html').default
    };
  }]);
