export interface ICache<T> {
  setOptions(options: object): void
  put(key: string, value: T): void
  get(key: string): T
  remove(key: string): void
}

export interface IUtils {
  isEmpty(obj: object): boolean
}

class Utils implements IUtils {
  isEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

}

angular
  .module('relcore.common')
  .service('Utils', [() => {
    return new Utils()
  }]);
