import { IApiResponse, IRelianceApi } from '../../common/reliance-api-service';
import { IDateTimeObject } from '../../models/datetime';

export interface IInmateHistoryService
{
  getLogs(inmateId: number): Promise<IApiResponse<Array<IInmateHistory>>>
}

export interface IInmateHistory
{
  dateChanged: IDateTimeObject
  status: string
  json: IChangesObject
  housingUnitId: number
}

export interface IChangesObject
{
  changes?: IInmateChanges
}

export interface IInmateChanges
{
  personId?: string;
  bookingId?: string;
  accountChanged?: boolean;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
  pin?: string;
  videoStatus?: string;
  voicemailStatus?: string;
  recordRestrictions?: number;
  facility?: number;
  restrictedToHousingUnit?: boolean;
  communicationTreatment?: string;
}

class InmateHistoryService implements IInmateHistoryService
{
  api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  getLogs(inmateId: number): Promise<IApiResponse<Array<IInmateHistory>>> {
    return this.api.get('/inmate-history', {inmateId: inmateId});
  }
}

angular
  .module('relcore.inmate')
  .service('InmateHistoryService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new InmateHistoryService(relianceApi);
    }
  ]);