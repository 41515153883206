import { ITokenResponse, IUser } from '../models/auth';
import { IRootScope } from '../models/root-scope';

export interface IAuthService {
  loadFromCookieStore():void
  login(emailAddress:string, password: string):Promise<ITokenResponse>
  refreshToken():Promise<void>
  storeLogin(tokenResponse:ITokenResponse):void
  clearLogin():void
  logout():void
  currentUser():IUser
  loggedIn():boolean
}

angular
  .module('relcore.auth')
  .service('auth',
    ['$http', '$rootScope', '$state', '$q', 'config', 'moment', '$timeout',
    function($http, $rootScope:IRootScope, $state, $q, config, moment, $timeout: ng.ITimeoutService) {
      class Auth implements IAuthService {
        refreshing: boolean = false

        constructor() {
          this.loadFromCookieStore = this.loadFromCookieStore.bind(this);
          this.login = this.login.bind(this);
          this.refreshToken = this.refreshToken.bind(this);
          this.storeLogin = this.storeLogin.bind(this);
          this.clearLogin = this.clearLogin.bind(this);
          this.logout = this.logout.bind(this);
          this.currentUser = this.currentUser.bind(this);
          this.loggedIn = this.loggedIn.bind(this);
          this.consolidatePermissions = this.consolidatePermissions.bind(this);
          this.loadFromCookieStore();
        }

        loadFromCookieStore() {
          if ((localStorage.getItem('client') != null) && localStorage.getItem('token')) {
            try {
              let user = JSON.parse(localStorage.getItem('client')) as IUser;
              this.consolidatePermissions(user);
              $rootScope.user = user;
            } catch(e) {
              console.log('AuthService: Failed to parse stored auth token', e);
            }
          }
        }

        login(emailAddress:string, password:string) {
          console.log(`AuthService: Attempting login for ${emailAddress}`);

          const self = this;
          this.clearLogin();
          return $http.post(`${config.api.url}/auth/token`, {
            grant_type: 'password',
            client_id: config.api.client.id,
            client_secret: config.api.client.secret,
            username: `StaffUser|${emailAddress}`,
            password
          }).then(function processLoginResponse(token:ITokenResponse) {
            if (token.data.staffUser) {
              self.consolidatePermissions(token.data.staffUser);
            }
            return token;
          }.bind(this))
        }

        refreshToken() {
          const deferred = $q.defer();

          let self = this;

          const currentExpiry = localStorage.getItem('tokenExpiry');
          if ((currentExpiry != null) && (moment(new Date(currentExpiry)) > moment())) {
            deferred.resolve();
          } else {
            // If we are already refreshing, don't attempt a second refresh (as the refresh_token will be invalid)
            if (self.refreshing) {
              $timeout(function() { self.refreshToken().then(deferred.resolve) }, 100)
              return deferred.promise;
            }

            self.refreshing = true;

            $http.post(`${config.api.url}/auth/token`, {
              grant_type: 'refresh_token',
              client_id: 'relcore',
              refresh_token: localStorage.getItem('refreshToken')
            }).then(function processTokenResponse(response:ITokenResponse) {
              self.refreshing = false;

              if (response.data.error != null) {
                self.logout();
              }
              self.consolidatePermissions(response.data.staffUser);
              self.storeLogin(response); //data.data.access_token, moment().add('seconds', data.data.expires_in), data.data.refresh_token, data.data.client);

              return deferred.resolve();
            }.bind(this)).catch(function() {
              self.refreshing = false;
              self.logout();
            });
          }

          return deferred.promise;
        }

        consolidatePermissions(user:IUser) {
          let permissions: {[id:string]:boolean} = user.roles
            .map(r => {
              let permissions = r.permissions;
              // Remove any "false" permissions to avoid them potentially overwriting roles with "true" permissions
              for (const k in permissions) {
                if (permissions[k] == false) {
                  delete permissions[k];
                }
              }
              return permissions;
            })
            .reduce((prev, cur) => Object.assign({}, prev, cur), {});

          user.permissions = permissions;
          user.hasPermission = function(permission) {
            return this.permissions[permission] == true;
          };
          user.hasOneOfPermissions = function(permissions:string[]) {
            return permissions.reduce((prev,cur) => prev || this.hasPermission(cur), false);
          };
          return user;
        }

        storeLogin(tokenResponse:ITokenResponse) {
          console.log("AuthService: Storing login");
          localStorage.setItem('token', tokenResponse.data.access_token);
          localStorage.setItem('tokenExpiry', moment().add('seconds', tokenResponse.data.expires_in));
          localStorage.setItem('refreshToken', tokenResponse.data.refresh_token);
          localStorage.setItem('client', JSON.stringify(tokenResponse.data.staffUser));
          localStorage.setItem('tenantTimezone', tokenResponse.data.tenantTimezone);
          localStorage.setItem('systemTimezone', tokenResponse.data.systemTimezone);
          $rootScope.user = tokenResponse.data.staffUser;
        }

        clearLogin() {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiry');
          localStorage.removeItem('client');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('systemTimezone');
          localStorage.removeItem('tenantTimezone');
          $rootScope.user = null;
        }

        logout() {
          console.log("AuthService: Logging out");
          this.clearLogin();
          $state.go('login');
        }

        currentUser() {
          return $rootScope.user;
        }

        loggedIn() {
          return (localStorage.getItem('token') != null);
        }
      }

      return new Auth;
    }
  ]);