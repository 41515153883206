angular
  .module('relcore.mobile-device')
  .directive('inspectionResult', () =>
    ({
      restrict: 'E',
      scope: {
        device: '=',
        onPass: '=',
        onFail: '=',
        failureReason: '=',
        processing: '='
      },
      template: require('./inspection-result.html').default
    })
  );
