import React from 'react';
import { IPaginatedResponse } from '../../common/reliance-api-service';
import { formatCurrency } from '../../format/Currency';
import { formatDate } from '../../format/DateTime';
import { formatDuration } from '../../format/Duration';
import { formatPhoneNumber } from '../../format/PhoneNumber';
import { ICardAccountActivity } from '../../models/card-account';
import BaseHtmlTable from '../../table/BaseHtmlTable';

type CdrTableProps = {
  data?: ICardAccountActivity[]
  paginatedData?: IPaginatedResponse<ICardAccountActivity>
}

const CdrTable = (props: CdrTableProps) => {
  const data = props.data ? props.data : props.paginatedData.data;

  const columns = [
    { key: 'date', name: 'Date', style: {width: 130} },
    { key: 'facility', name: 'Facility' },
    { key: 'source', name: 'Source' },
    { key: 'calledNumber', name: 'Called Number' },
    { key: 'terminateReason', name: 'Termination Reason' },
    { key: 'charge', name: 'Charge', cellStyle: (row) => row.refund ? {textDecoration: 'line-through'} : {} },
    { key: 'duration', name: 'Duration' }
  ];

  const rows = data.map(d => {
    const cdr = d.cdr;
    const originatingPhoneNumber = cdr.originatingPhoneNumber.replace('+', '');
    return {
      id: d.id,
      date: formatDate(cdr.setupDate.date),
      facility: d.facility ? `${d.facility.name}-${d.facility.state}-${originatingPhoneNumber}` : `Unknown-${originatingPhoneNumber}`,
      source: cdr.source,
      calledNumber: formatPhoneNumber(cdr.destination),
      terminateReason: cdr.terminationReason,
      charge: formatCurrency(cdr.cost),
      duration: formatDuration(cdr.durationSeconds)
    };
  });

  return (
    <BaseHtmlTable
      baseKey="card-account-cdrs"
      columns={columns}
      rows={rows}
      />
  )
}

export default CdrTable;
