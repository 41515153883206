interface IEventService {
  isFetching(name: string): boolean
  isSaving(name: string): boolean
  isAdding(name: string): boolean
  isDeleting(name: string): boolean
  isError(name: string): boolean
  onFetch(name: string): void
  onFetched(name: string): void
  onSave(name: string): void
  onSaved(name: string): void
  onAdd(name: string): void
  onAdded(name: string): void
  onDelete(name: string): void
  onDeleted(name: string): void
  onError(name: string): void
}

angular
  .module('relcore.common')
  .service('eventService', [function(): IEventService {

      const Events = {
        fetch: {},
        save: {},
        add: {},
        delete: {},
        error: {}
      };

      const isFetching = (name: string) => {
        return Events.fetch[name] && !Events.error[name];
      };

      const isSaving = (name: string) => {
        return Events.save[name] && !Events.error[name];
      };

      const isAdding = (name: string) => {
        return Events.add[name] && !Events.error[name];
      };

      const isDeleting = (name: string) => {
        return Events.delete[name] && !Events.error[name];
      };

      const isError = (name: string) => {
        return Events.error[name];
      };

      const onFetch = (name: string) => {
        Events.fetch[name] = true;
        Events.error[name] = false;
      };

      const onFetched = (name: string) => {
        Events.fetch[name] = false;
      };

      const onSave = (name: string) => {
        Events.save[name] = true;
        Events.error[name] = false;
      };

      const onSaved = (name: string) => {
        Events.save[name] = false;
      };

      const onAdd = (name: string) => {
        Events.add[name] = true;
        Events.error[name] = false;
      };

      const onAdded = (name: string) => {
        Events.add[name] = false;
      };

      const onDelete = (name: string) => {
        Events.delete[name] = true;
        Events.error[name] = false;
      };

      const onDeleted = (name: string) => {
        Events.delete[name] = false;
      };

      const onError = (name: string) => {
        Events.error[name] = true;
        Events.fetch[name] = false;
        Events.delete[name] = false;
        Events.add[name] = false;
        Events.save[name] = false;
      };

      return {
        isFetching,
        isSaving,
        isAdding,
        isDeleting,
        isError,
        onFetch,
        onFetched,
        onSave,
        onSaved,
        onAdd,
        onAdded,
        onDelete,
        onDeleted,
        onError
      };
    }
  ]);