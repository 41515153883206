import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import ConfirmModal from '../common/dialogs/ConfirmModal';
import { IPaginatedResponse } from '../common/reliance-api-service';
import { formatCurrency } from '../format/Currency';
import { formatDate } from '../format/DateTime';
import { IInmate } from '../inmate/inmate-service';
import { InmateSubscription } from '../models/InmateSubscription';
import BaseHtmlTable from '../table/BaseHtmlTable';

type TableProps = {
  subscriptions: IPaginatedResponse<InmateSubscription>
  inmate?: IInmate
  onStop(subscription: InmateSubscription): Promise<void>
}

const Table: React.FC<TableProps> = (props) => {
  const { subscriptions, inmate } = props;

  const [showCancel, setShowCancel] = useState<InmateSubscription>(null);

  if (subscriptions == null) {
    return null;
  }

  let columns = [
    { key: 'inmate', name: 'Inmate' },
    { key: 'subscription', name: 'Subscription' },
    { key: 'cost', name: 'Cost' },
    { key: 'dateStarted', name: 'Started' },
    { key: 'dateFinished', name: 'Stopped' },
    { key: 'dateLastCharged', name: 'Last Charge' },
    { key: 'dateNextCharge', name: 'Next Charge' },
    { key: 'options', name: 'Options' }
  ];

  // If we were sent a specific inmate, we don't need that column
  if (inmate) {
    columns = columns.filter(c => c.key != 'inmate');
  }

  // Massage the reference material into applicable/usable columns
  const rows = subscriptions.data.map(subscription => ({
    id: subscription.id,
    inmate: `${subscription.inmate.firstName} ${subscription.inmate.lastName} (#${subscription.inmate.account})`,
    dateStarted: formatDate(subscription.dateStarted.date),
    dateFinished: subscription.dateFinished ? formatDate(subscription.dateFinished.date) : '',
    dateLastCharged: subscription.dateLastCharged ? formatDate(subscription.dateLastCharged.date) : '',
    dateNextCharge: subscription.dateFinished == null ? formatDate(subscription.dateNextCharge.date) : '',
    subscription: subscription.subscription.type,
    cost: `${formatCurrency(subscription.subscription.cost)} (Interval: ${subscription.subscription.interval})`,
    options: (
      <div>
        {subscription.dateFinished == null &&
          <Button onClick={() => setShowCancel(subscription)} size="sm" variant="outline-danger">Cancel Subscription</Button>
        }
        <ConfirmModal
          key={`cancel-subscription-${subscription.id}`}
          show={showCancel == subscription}
          title="Cancel inmate subscription"
          acceptText="Cancel Subscription"
          onAccept={() => props.onStop(subscription)}
          onClose={() => setShowCancel(null)}>
          Are you sure you want to cancel the "{subscription.subscription.type}" subscription?
        </ConfirmModal>
      </div>
    )
  }));

  return (
    <BaseHtmlTable
      baseKey="reference-material"
      columns={columns}
      rows={rows}
      />
  );
}

angular
  .module('relcore.inmate-subscription')
  .component('inmateSubscriptionTable', react2angular(Table, ['subscriptions', 'inmate', 'onStop']));

export default Table;