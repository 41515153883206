angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketOutboundViewController', [
    '$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, alertService, $stateParams, NgTableParams) {
      $scope.noteListGridOptions = new NgTableParams({
        page: 1,
        count: 500
      }, {
        counts: null,
        getData() {
          const notes = JSON.parse($scope.ticket.notes||'[]');
          return notes;
        }
      });

      $scope.deviceGridOption = new NgTableParams({
        page: 1
      }, {
        counts: null,
        getData(params) {
          return $scope.ticket.shippingTicketDevices;
        }
      });

      const setTicket = function(ticket) {
        $scope.ticket = ticket;
        $rootScope.title = `Outbound Ticket #${$scope.ticket.id}`;
        $scope.noteListGridOptions.reload();
        $scope.deviceGridOption.reload();
      };

      const loadTicket = id =>
        relianceApi
          .get(`/shipping-ticket/${id}`)
          .then(res => setTicket(res.data))
      ;

      if ($stateParams.ticket) {
        setTicket($stateParams.ticket);
        loadTicket($scope.ticket.id);
      } else {
        loadTicket($stateParams.id);
      }

      const resetNote = () => $scope.newNote = { note: '' };
      resetNote();

      const resetCloseNote = () => $scope.closingNote = { note: ''};
      resetCloseNote();

      $scope.addNote = note =>
        relianceApi.put(`/shipping-ticket/${$scope.ticket.id}`, { request: {notes: note.note} })
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('The note was successfully added', true);
            resetNote();
          }), (() => alertService.error('An error occurred while adding the note', true)))
      ;

      $scope.savingInfo = false;
      $scope.saveInfo = function(info) {
        $scope.savingInfo = true;
        relianceApi.put(`/shipping-ticket/${$scope.ticket.id}`, info)
          .then((function() {
            $scope.savingInfo = false;
            loadTicket($scope.ticket.id);
            alertService.success('The information was successfully saved', true);
          }), (function() {
            $scope.savingInfo = false;
            alertService.error('An error occurred while saving the ticket information', true);
          }));
      };

      $scope.closeTicket = note =>
        relianceApi.delete(`/shipping-ticket/${$scope.ticket.id}?notes=${note.note}`)
          .then((function() {
            loadTicket($scope.ticket.id);
            alertService.success('Ticket successfully closed', true);
            resetCloseNote();
          }), (() => alertService.error('An error occurred while closing ticket', true)))
      ;

      $scope.cancelNotes = '';
      $scope.cancelTicket = function() {
        const dialog = $uibModal.open({
          template: require('./cancel/cancel.html').default,
          controller: 'shippingTicketCancelController',
          resolve: {
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.canceled) {
            loadTicket($scope.ticket.id);
          }
        });
      };

      $scope.markDeviceNotShipped = function(device) {
        const dialog = $uibModal.open({
          template: require('./device-not-shipped/device-not-shipped.html').default,
          controller: 'shippingTicketDeviceNotShippedController',
          resolve: {
            device() { return device; },
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.saved) {
            loadTicket($scope.ticket.id);
          }
        });
      };

      $scope.markDeviceReceived = function(device) {
        const dialog = $uibModal.open({
          template: require('./device-received/device-received.html').default,
          controller: 'shippingTicketDeviceReceivedController',
          resolve: {
            device() { return device; },
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.saved) {
            loadTicket($scope.ticket.id);
          }
        });
      };

      $scope.markTicketShipped = function() {
        const dialog = $uibModal.open({
          template: require('./ship/ship.html').default,
          controller: 'ShippingTicketShipController',
          resolve: {
            ticket() { return $scope.ticket; }
          }
        });
        dialog.result.then(function(result) {
          if (result.saved) {
            loadTicket($scope.ticket.id);
          }
        });
      };
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('shipping-ticket-outbound-view', {
        controller: 'ShippingTicketOutboundViewController',
        url: '/shipping-ticket/outbound/view/:id',
        parent: 'authenticated',
        params: {
          ticket: null
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'shipping-tickets-outbound'
        },
        template: require('./outbound-view.html').default
      }
    )

  ]);
