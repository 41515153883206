angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('mobile-device-usage', {
        controller: 'MobileDeviceUsageViewController',
        template: require('./usage.html').default,
        url: '/mobile-device/usage',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Mobile Device Statuses',
          parent: 'mobile-devices'
        }
      })

  ])
  .controller('MobileDeviceUsageViewController', [
    '$rootScope', '$scope', '$filter', '$state', '$stateParams', 'NgTableParams', 'facilityService', 'mobileDeviceService',
    function($rootScope, $scope, $filter, $state, $stateParams, NgTableParams, facilityService, mobileDeviceService) {
      $rootScope.title = "Mobile Device Statuses";

      // Load facilities for filter
      $scope.facilities = [];
      $rootScope.$watch('facilities', function(newList) {
        $scope.facilities = [{ id: 'Assigned', name: 'Assigned' }, { id: 'Unassigned', name: 'Unassigned' }];
        $scope.facilities = $scope.facilities.concat(newList).filter(f => f.featureTexting !== false);
      });

      $scope.gridOptions = new NgTableParams({
          filter: { facilityIds: ['Assigned'] },
          page: 1,
          count: 500
        }, {
          counts: [100,500,1000],
          getData(params) {
           return mobileDeviceService.getUsage(params.filter(), params.orderBy())
              .then(function(result) {
                params.total(result.data.total);
                $scope.selected = [];
                return result.data.data;
            });
          }
      });
    }
  ]);
