angular
  .module('relcore.api-role')
  .controller('ApiRoleAddController', ['$scope', '$uibModalInstance', 'onAdd', function($scope, $uibModalInstance, onAdd) {
    const newRole = {
      name: '',
      permissions: {}
    };

    $scope.adding = false;
    $scope.apiRole = Object.assign({}, newRole);
    $scope.add = function() {
      $scope.adding = true;
      onAdd($scope.apiRole)
        .then(function() {
          $scope.adding = false;
          $scope.apiRole = Object.assign({}, newRole);
          $uibModalInstance.dismiss({ saved: true });
        })
        .catch(function(e) {
          $scope.adding = false;
          throw e;
        })
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss({ saved: false });
    };
  }]);
