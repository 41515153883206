angular
  .module('relcore.api-user')
  .service('apiUserService', ['relianceApi', 'alertService',
    function(relianceApi, alertService) {
      const updateUser = function (apiUser, changes) {
        return relianceApi.put(`/api-credential/${apiUser.id}`, changes)
            .then(function(res) {
                    alertService.success('The credential was updated', true);
                  },
                  function(err) {
                    alertService.error('An error occurred while updating the credential', true);
                  }
            );
      }

      return {
        updateUser
      }
    }
  ]);