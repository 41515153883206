import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/esm/Card';
import { react2angular } from 'react2angular';
import { IAlertService } from '../alert/alert-service';
import { IPaginatedApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { ApiLog } from '../models/ApiLog';
import { IStaffUser } from '../models/staff-user';
import Search, { SearchCriteria } from './StaffUserSearch';
import Table from './Table';

type ListProps = {
  relianceApi: IRelianceApi
  alertService: IAlertService
};

const List: React.FC<ListProps> = (props) => {
  const [staffUsers, setStaffUsers] = useState<IStaffUser[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  const [apiLogs, setApiLogs] = useState<IPaginatedResponse<ApiLog>>(null);
  const [searching, setSearching] = useState(false);
  const hasSearchCriteria = searchCriteria.staffUserId || searchCriteria.startDate || searchCriteria.endDate;

  // Load the staff users needed for the search criteria
  useEffect(() => {
    props.relianceApi
      .index<IPaginatedApiResponse<IStaffUser>>('/staff-user', 1, 1000)
      .then((res) => setStaffUsers(res.data.data));
  }, []);

  // Load the logs
  useEffect(() => {
    if (hasSearchCriteria) {
      setSearching(true);
      props.relianceApi
        .index<IPaginatedApiResponse<ApiLog>>('/log/staff-user', 1, 1000, searchCriteria)
        .then((res) => setApiLogs(res.data))
        .finally(() => setSearching(false));
    }
  }, [searchCriteria]);

  return (
    <Card className="mt-3">
      <Card.Body>
        <div className="mb-3">
          <Search
            staffUsers={staffUsers}
            onSubmit={(criteria) => setSearchCriteria({...(criteria||{})})}
            submitting= {searching}
          />
        </div>

        <Table
          apiLogs={apiLogs}
        />
      </Card.Body>
    </Card>
  )
};

angular
  .module('relcore.api-log')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('staff-user-log', {
        component: 'staffUserLogList',
        url: '/staff-user/log',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Staff User Log'
        }
      }
    )
  ])
  .component('staffUserLogList', react2angular(
    List,
    [],
    ['alertService', 'relianceApi']
  ));