import { IFacility } from '../models/facility';
import { IPhoneTrackerState } from './phone-tracker';

export interface IAppState {
  root?: any
  facilities?: Array<IFacility>
  facilitiesById?: { [id: string]: IFacility },
  phoneTracker?: IPhoneTrackerState
}

angular
  .module('relcore.reducers')
  .service('rootReducer', [
    'dashboardReducer',
    'mobileDeviceReducer',
    'onlineUserReducer',
    'facilityUserReducer',
    'apiUserReducer',
    'legacyUserReducer',
    'apiRoleReducer',
    'phoneTrackerReducer',
    'Redux',
    function(
      dashboardReducer,
      mobileDeviceReducer,
      onlineUserReducer,
      facilityUserReducer,
      apiUserReducer,
      legacyUserReducer,
      apiRoleReducer,
      phoneTrackerReducer,
      Redux
    ) {
    var initialState: IAppState = {
      facilities: [],
      facilitiesById: {}
    };

    const rootReducer = (state = initialState, action) => {
      switch (action.type) {
        case 'facility/loaded':
         var facilitiesById = {};
         let facility:any;
         for (facility in action.facilities) {
           facilitiesById[facility.id] = facility;
         }
         return Object.assign({}, state, {
           facilities: action.facilities,
           facilitiesById
         });
        break;
      }
      return state;
    };

    return Redux.combineReducers({
      root: rootReducer,
      dashboard: dashboardReducer,
      mobileDevice: mobileDeviceReducer,
      onlineUser: onlineUserReducer,
      facilityUser: facilityUserReducer,
      apiUser: apiUserReducer,
      legacyUser: legacyUserReducer,
      apiRole: apiRoleReducer,
      phoneTracker: phoneTrackerReducer
    });
  }]);
