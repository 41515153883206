import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/esm/ButtonToolbar';
import ReactDataGrid from 'react-data-grid';

// Recursively search an object for an arbitrary string
const search = (data, val) => {
  const isObject = o => o && typeof o === 'object';
  const find = o =>
    o && (
      o === val ||
      o.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1 ||
      isObject(o) && Object.values(o).some(find)
    );

  return data.filter(find);
};

// Retrieve the data for the requested page
const retrievePage = (data: any[], page, perPage) => {
  const start = (page - 1) * perPage;
  return data.slice(
    start,
    start + perPage
  );
}

type BaseTableProps = {
  page?: number
  perPage?: number
  rows
  columns
}

const BaseTable = (props: BaseTableProps) => {
  const { rows, columns, page, perPage } = props;
  const [filtered, setFiltered] = useState(rows);
  const [currentPage, setCurrentPage] = useState(page || 1);

  // On search change, update the filtered rows. Reset pagination
  const onSearch = searchText => {
    setFiltered(search(rows, searchText))
    setCurrentPage(1);
  }

  // If pagination is requested, split up the data
  const displayData = retrievePage(filtered, currentPage, perPage || 500);

  // Update the pagination data
  const pages = Math.ceil(filtered.length / (perPage || 500));
  const startRow = ((currentPage - 1) * (perPage || 500)) + 1;
  const endRowMax = (currentPage * (perPage || 500));

  // Dynamically set the height of the table based on the data count, up to a limit of 750px
  let tableHeight = displayData.length * 20;
  if (tableHeight < 150) {
    tableHeight = 150;
  } else if (tableHeight > 750) {
    tableHeight = 750;
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-2 mb-2">
          <input type="text" className="form-control" placeholder="Search" onChange={e => onSearch(e.target.value)} />
        </div>
      </div>

      {/*
      // @ts-ignore */}
      <ReactDataGrid
        {...props}
        columns={columns}
        // @ts-ignore
        rows={displayData}
        rowsCount={displayData.length}
        style={{minHeight: tableHeight}}
        />

      {displayData.length > 0 &&
        <div className="mt-3">
          <span>
            {startRow} - {filtered.length > endRowMax ? endRowMax : filtered.length} of {filtered.length}
          </span>
          {pages > 1 &&
            <ButtonToolbar className="pull-right">
              <ButtonGroup>
                {Array(pages).fill(null).map((_, i) => i+1).map(p =>
                  <Button key={`page-${p}-${Math.random()*100}`} onClick={() => setCurrentPage(p)} variant={currentPage == p ? 'primary' : 'secondary'}>
                    {p}
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          }
        </div>
      }
    </div>
  )
}

export default BaseTable;
