angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketOutboundCreateViewController', [
    '$rootScope', '$scope', '$state', '$filter', 'relianceApi', 'alertService', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $filter, relianceApi, alertService, $stateParams, NgTableParams) {
      $scope.title = ($rootScope.title = 'Create Outbound Ticket');

      $scope.facility = $stateParams.facility || {};
      if (($scope.facility.id == null)) {
        relianceApi
          .get(`/facility/${$stateParams.facilityId}`)
          .then(res => $scope.facility = res.data);
      }

      $scope.ticket = {
        facilityId: $stateParams.facilityId,
        facilityName: $scope.facility.name,
        facilityAddress: '',
        attention: '',
        phoneNumber: '',
        shippingMethod: '',
        countyRequest: '',
        application: 'Connect - Messaging'
      };
      $scope.notes = { note: '' };

      if ($scope.facility.address != null) {
        $scope.ticket.facilityAddress = `${$scope.facility.address}\n${$scope.facility.city}, ${$scope.facility.state} ${$scope.facility.zip}`;
      }

      $scope.saving = false;
      $scope.save = function() {
        $scope.saving = true;
        $scope.ticket.notes = $scope.notes.note;
        relianceApi.post("/shipping-ticket/shipping", { ticket: $scope.ticket })
          .then((function(res) {
            $scope.ticket.id = res.data.shippingTicketId;
            $scope.ticket.notes = '[]';
            return $state.go('shipping-ticket-outbound-view', {
              ticket: $scope.ticket,
              id: $scope.ticket.id
            });
          }), (function() {
            $scope.saving = false;
            alertService.error('An error occurred while creating the ticket', true);
          }));
      };
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('shipping-ticket-outbound-create', {
        controller: 'ShippingTicketOutboundCreateViewController',
        url: '/shipping-ticket/outbound/create/:facilityId',
        parent: 'authenticated',
        params: { facility: {}
      },
        ncyBreadcrumb: {
          label: 'Create Outbound Ticket',
          parent: 'shipping-tickets-outbound'
        },
        template: require('./outbound-create.html').default
      }
    )

  ]);
