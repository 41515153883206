angular
  .module('relcore.facility-equipment')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('facility-equipments', {
        controller: 'facilityEquipmentController',
        url: '/facility-equipments',
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: {
          label: 'Facility Equipment'
        }
      }
    )

  ])
  .directive('facilityEquipmentList', () => {
    return {
      restrict: 'E',
      scope: {
        facilityId: '@',
        onFetch: '&',
        onLoad: '&',
        onError: '&'
      },
      template: require('./list.html').default,
      controller: 'facilityEquipmentController'
    };
  })
  .controller('facilityEquipmentController',
    ['$scope', '$state', '$uibModal', '$filter', 'NgTableParams', 'relianceApi', 'facilityEquipmentService', 'alertService','FacilityEquipment',
    function($scope, $state, $uibModal, $filter, NgTableParams, relianceApi, facilityEquipmentService, alertService, FacilityEquipmentModel) {
      $scope.equipmentGridOptions = new NgTableParams({
        page: 1,
        sorting: { 'created.date': 'desc' }
      }, {
        total: 0,
        counts: [],
        getData(params) {
          $scope.onFetch();
          return facilityEquipmentService.getByFacility($scope.facilityId)
            .then(function(data) {
              params.total(data.length);

              if (params.filter()) {
                data = $filter('filter')(data, params.filter());
              }
              if (params.sorting()) {
                data = $filter('orderBy')(data, params.orderBy());
              }
              $scope.onLoad();
              return data.slice((params.page()-1)*params.count(),
                params.page() * params.count());
          });
        }
      });


      $scope.add = function() {
        const result = facilityEquipmentService.postNewFacilityEquipment($scope.facilityId, $scope.name, $scope.serialNumber, $scope.description, $scope.type);

        const successCallback = function() {
          alertService.success("New Equipment was added successfully", true);
          $scope.equipmentGridOptions.reload();
        };

        const errorCallback = () => {
          alertService.error("An error occured while adding new equipment", true);
          $scope.onError();
        }

        result.then(successCallback, errorCallback);
      };

      $scope.details = equipment =>
        $state.go('facility-equipments-view', {
          equipmentId:equipment.id,
          equipment
        })
    }
  ]);
