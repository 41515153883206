angular
  .module('relcore.directives')
  .directive('formGroupInput', () =>
    ({
      restrict: 'E',
      scope: {
        field: '=',
        label: '=',
        required: '=?'
      },
      link: ($scope:any) => {
        $scope.required = $scope.required === true;
      },
      template: require('./form-group-input.html').default
    })
  );
