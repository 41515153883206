angular
  .module('relcore.facility-equipment')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('facility-equipments-view', {
        controller: 'facilityEquipmentViewController',
        template: require('./view.html').default,
        url: '/facility-equipment/view/:equipmentId',
        parent: 'authenticated',
        params: { equipment: null
      },
        ncyBreadcrumb: {
          label: '{{equipment.name}}',
          parent: 'facilities-view'
        }
      }
    )
  ])
  .controller('facilityEquipmentViewController',
    ['$rootScope', '$scope', '$state', '$filter', 'NgTableParams', '$stateParams', 'alertService', 'facilityEquipmentService',
    function($rootScope, $scope, $state, $filter, NgTableParams, $stateParams, alertService, facilityEquipmentService) {
      $scope.equipment = $stateParams.equipment;
      $scope.equipmentId = $stateParams.equipmentId;

      if ($stateParams.equipment != null) {
        $scope.equipment = $stateParams.equipment;
        $rootScope.title = $scope.equipment.name;
      } else {
        facilityEquipmentService.getById($stateParams.equipmentId)
          .then(function(equipment) {
            $scope.equipment = equipment;
            return $rootScope.title = equipment.name;
        });
      }

      return $scope.save = function() {
        const onSuccess = () => alertService.success("Equipment updated successfully", true);
        const onError = () => alertService.error("Changes failed to save", true);

        const changes = {
          id: $scope.equipment.id,
          name: $scope.equipment.name,
          serialNumber: $scope.equipment.serialNumber,
          description: $scope.equipment.description,
          type: $scope.equipment.type
        };

        return facilityEquipmentService.updateFacilityEquipment($scope.equipmentId, changes)
          .then(onSuccess, onError);
      };
  }]);
