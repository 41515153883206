import { IApiResponse } from '../common/reliance-api-service';
import { ITextMessage, ITextMessageService } from './text-message-service';

interface ITextMessageStatusScope {
  modalTextMessageStatusGridOptions: object
  statusLoading: boolean
  close(): void
}

angular
  .module('relcore.text-message')
  .controller('ModalTextMessageStatusController', ['$scope', 'textMessage', 'textMessageService', 'NgTableParams', '$uibModalInstance', ($scope: ITextMessageStatusScope,  textMessage: ITextMessage, textMessageService: ITextMessageService, NgTableParams, $uibModalInstance) => {
      $scope.modalTextMessageStatusGridOptions = new NgTableParams(
        {}, {
        getData(params) {
          $scope.statusLoading = true;
          return textMessageService.getTextMessageStatus(textMessage.id)
            .then(function(res: IApiResponse<any>) {
              $scope.statusLoading = false;
              return res.data.data;
            });
        }
      });

      $scope.close = () => {$uibModalInstance.close({saved: false})};

    }
  ]);
