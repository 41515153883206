angular
  .module('relcore.api-user')
  .directive('apiUserSearchResults', () =>
    ({
      restrict: 'E',
      scope: {
        showFilter: '=',
        gridOptions: '=',
        onViewDetails: '=',
        changingStatus: '=',
        onChangeStatus: '=',
        usersChangingStatus: '='
      },
      controller: ['$scope', function($scope) {
        $scope.showFilter = $scope.showFilter === null ? true : $scope.showFilter === true;

        $scope.enable = (user) => $scope.onChangeStatus(user, true);
        $scope.disable = (user) => $scope.onChangeStatus(user, false);
      }],
      template: require('./api-user-search-results.html').default
    })
  );
