angular
  .module('relcore.mobile-device')
  .controller('ShippingTicketOutboundPrintController', [
    '$rootScope', '$scope', '$state', '$uibModal', 'relianceApi', '$stateParams', 'NgTableParams',
    function($rootScope, $scope, $state, $uibModal, relianceApi, $stateParams, NgTableParams) {
      $scope.deviceGridOption = new NgTableParams({
        page: 1
      }, {
        counts: null,
        getData(params) {
          return $scope.ticket.shippingTicketDevices.filter(d => d.status !== 'Not Shipped');
        }
      });

      const setTicket = function(ticket) {
        $scope.ticket = ticket;
        $rootScope.title = `Outbound Ticket #${$scope.ticket.id}`;
        $scope.deviceGridOption.reload();
        if ($stateParams.triggerPrint === true) {
          setTimeout((() => angular.element(document).ready(() => window.print())), 500);
        }
      };

      const loadTicket = id =>
        relianceApi
          .get(`/shipping-ticket/${id}`)
          .then(res => setTicket(res.data))
      ;

      if ($stateParams.ticket) {
        setTicket($stateParams.ticket);
        loadTicket($scope.ticket.id);
      } else {
        loadTicket($stateParams.id);
      }
    }
  ])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('shipping-ticket-outbound-print', {
        controller: 'ShippingTicketOutboundPrintController',
        url: '/shipping-ticket/outbound/print/:id',
        parent: 'authenticated',
        params: {
          ticket: null,
          triggerPrint: false
        },
        ncyBreadcrumb: {
          label: '{{title}}',
          parent: 'shipping-tickets-outbound'
        },
        template: require('./outbound-print.html').default
      }
    )

  ]);
