export enum FacilityNumberType {
  Standard = 1,
  Voicemail = 2
}

export interface IFacilityNumber {
  id: number
  ani: string
  type: FacilityNumberType
  active: boolean
}