angular
  .module('relcore.facility')
  .directive('facilityUsers', function() {
    return {
      restrict: 'E',
      scope: {
        gridOptions: '=',
        facilities: '=',
        onViewDetails: '=',
        onDisable: '=',
        onEnable: '='
      },
      template: require('./facility-users.html').default
    };
  });
