angular
  .module('relcore.filters')
  .filter('cityStateZip', () =>
    function(value:any) {
      if (!value || (value.city == null && value.state == null && value.zip == null)) {
        return '';
      }
      return `${value.city}, ${value.state} ${value.zip}`;
    }
  );
