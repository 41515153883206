angular
  .module('relcore.facility')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('facility-user-view', {
        controller: 'FacilityUserViewController',
        template: require('./facility-user-edit-view.html').default,
        url: '/facility-user/view/:id',
        parent: 'authenticated',
        params: {id: null, facilityUser: null },
        ncyBreadcrumb: {
          label: '{{facilityUser.id}}',
          parent: 'facility-users'
        }
      })
  ])
  .controller('FacilityUserViewController',
    ['$rootScope', '$scope', '$state', '$stateParams', 'store', 'apiRolesActions', 'ReduxMapToScope', 'facilityUserViewActions', 'relianceApi', 'alertService',
    function($rootScope, $scope, $state, $stateParams, store, apiRolesActions, ReduxMapToScope, userActions, relianceApi, alertService) {
      $rootScope.title = 'Loading...';

      ReduxMapToScope($scope, function(state) {
        $scope.apiRoles = state.facilityUser.list.apiRoles;

        $scope.facilityUser = state.facilityUser.view.facilityUser;
        if ($scope.facilityUser) {
          $scope.newValues = {
            enabled: $scope.facilityUser.apiCredential.enabled,
            username: $scope.facilityUser.username,
            password: null,
            roleIds: $scope.facilityUser.apiCredential.roles.map(r => r.id),
            facilityIds: $scope.facilityUser.facilities.map(f => f.id)
          };
          $scope.model.allFacilities = $scope.facilityUser.facilities.length == 0;

          $rootScope.title = `${$scope.facilityUser.username}`;
        }
      });

      store.dispatch(apiRolesActions.load());

      // Load the user
      if ($stateParams.facilityUser != null) {
        store.dispatch(userActions.setFacilityUser($stateParams.facilityUser));
      } else {
        store.dispatch(userActions.loadFacilityUser($stateParams.id));
      }

      // Using object property for allFacilities checkbox model as using primitive
      // boolean value was not updating on value change of checkbox for some reason
      $scope.model = {allFacilities: false};
      $scope.handleAllFacilitiesCheck = () => {
        // Clear all facility qualifiers on user if allFacilities is checked
        if ($scope.model.allFacilities) {
            $scope.newValues = {
              ...$scope.newValues,
              facilityIds: []
          }
        }
      };

      $scope.onFacilityListChange = () => {
        $scope.model.allFacilities = $scope.newValues.facilityIds.length == 0;
      };

      $scope.saving = false;
      $scope.save = () => {
        $scope.saving = true;
        return new Promise((resolve, reject) => {
          relianceApi.put("/facility-user/" + $scope.facilityUser.id, $scope.newValues)
            .then(() => {
              $scope.saving = false;
              alertService.success("Facility user updated", true);
              store.dispatch(userActions.loadFacilityUser($scope.facilityUser.id));
              $scope.facilityUser = null;
            })
            .catch((err) => {
              $scope.saving = false;
              alertService.error("An error occurred while updating the facility user", true);
            });
        });
      }
    }
]);