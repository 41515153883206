import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';

export class ApiError extends Error {
  code: number
  error?: string
  field?: string
}

interface IErrorMessageProps {
  message?: string|JSX.Element // If supplied, this will be displayed always
  httpGenericMessage?: string
  httpCodeMessages?: {[code: number]: string}
  error?: ApiError
  severity?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
}

const defaultErrorMessage = 'An error occurred';

const ErrorMessage: React.FC<IErrorMessageProps> = props => {
  const { message, error, httpGenericMessage } = props;

  if (!message && !error) { return <noscript/> }

  const httpCode = error?.code;

  let messageText: string|JSX.Element;
  if (typeof error == 'string') {
    messageText = error;
  } else if (typeof message == 'string' || (message && React.isValidElement(message))) {
    messageText = message;
  } else if (httpCode && props.httpCodeMessages && props.httpCodeMessages[httpCode]) {
    messageText = props.httpCodeMessages[httpCode];
  } else if (error && httpGenericMessage) {
    messageText = httpGenericMessage;
  } else {
    messageText = defaultErrorMessage;
  }

  // Replace any requested tokens from the error class
  if (typeof messageText === 'string') {
    const tokens = messageText.match(/\{(.+?)\}/g) || [];
    tokens.forEach(token => {
      token = token.replace('{', '').replace('}', '');
      if (error[token]) {
        messageText = String(messageText).replace('{' + token + '}', error[token]);
      }
    });
  }

  return (
    <Alert variant={props.severity || 'danger'}><i className="fa fa-flag" /> {messageText}</Alert>
  );
};

export default ErrorMessage;
