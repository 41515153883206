import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import Row from 'react-bootstrap/esm/Row';
import { Form } from 'react-final-form';
import { Select } from '../form/Select';
import { IFacility } from '../models/facility';
import { FacilityUser } from '../models/FacilityUser';

export type SearchCriteria = {
  facilityId?: number
  facilityUserId?: number
  startDate?: string
  endDate?: string
}

type SearchProps = {
  facilities: IFacility[]
  onSubmit(criteria: SearchCriteria): void
  submitting: boolean
}

const Search: React.FC<SearchProps> = (props) => {
  // TODO: Load the facility users for the selected facility
  const [facilityUsers] = useState<FacilityUser[]>([]);

  const initialStartDate = moment().subtract(1, 'month').startOf('day');
  const initialEndDate = moment().endOf('day');

  return (
    <Form
      initialValues={{
        facilityId: '',
        facilityUserId: '',
        startDate: initialStartDate.format(),
        endDate: initialEndDate.format()
      }}
      onSubmit={props.onSubmit}
      render={({ handleSubmit, values, form, submitting, hasValidationErrors, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <FormLabel>Date Range</FormLabel>
                    <DateRangePicker
                      onApply={(e, picker) => {
                        form.change('startDate', picker.startDate.startOf('day').format());
                        form.change('endDate', picker.endDate.endOf('day').format());
                      }}
                      initialSettings={{ startDate: initialStartDate, endDate: initialEndDate }}>
                      <input
                        className="form-control"
                        name="dateRange"
                      />
                    </DateRangePicker>
                  </Col>
                  <Col>
                    <Select
                      name="facilityId"
                      label="Facility">
                      <option></option>
                      {(props.facilities||[]).map((facility) =>
                        <option
                          value={facility.id}
                          key={`api-log-facility-${facility.id}`}>
                          {facility.name} ({facility.city}, {facility.state})
                        </option>
                      )}
                    </Select>
                  </Col>
                  {facilityUsers && facilityUsers.length > 0 &&
                    <Col>
                      <Select
                        name="facilityUserId"
                        label="Facility User">
                        <option></option>
                        {(facilityUsers||[]).map((user) =>
                          <option
                            value={user.id}
                            key={`api-log-facility-user-${user.id}`}>
                            {user.username}
                          </option>
                        )}
                      </Select>
                    </Col>
                  }
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button
                  type="submit"
                  className="pull-right"
                  disabled={props.submitting}>
                  {props.submitting ? 'Searching...' : 'Search'}
                </Button>
              </Card.Footer>
            </Card>
          </form>
        );
      }}
    />
  );
};

export default Search;
