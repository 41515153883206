angular
  .module('relcore.api-user')
  .directive('apiUserRolesCheckbox', ['apiRoleService', apiRoleService =>
    ({
      restrict: 'E',
      replace: true,
      template: require('./api-user-roles-checkbox.html').default,
      link: (scope:any) => {
        scope.fetching = true;
        apiRoleService.retrieveAll((roles) => {scope.fetching = false; scope.apiUserRoles = roles});
      }
    })
  ]);