import { AxiosResponse } from 'axios';
import { useAsync } from 'react-async-hook';
import api from '../common/api';
import { StringTranslation } from '../models/StringTranslation';

export const useStringTranslations = (filters?: object) => {
  return useAsync<AxiosResponse<StringTranslation[], any>>(
    () => api.get<StringTranslation[]>('/translation'),
    [filters]
  );
};