import { IAuthService } from '../auth/auth-service';

interface IPasswordResetForm {
  newPassword: string
  verifyNewPassword: string
}

interface IPasswordResetController {
  code: string
  formData: IPasswordResetForm
  emailAddress: string
  submitting: boolean
  submit(): void
}

angular
  .module('relcore.auth')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('password-reset', {
        template: require('./password-reset.html').default,
        url: '/login/password/reset?emailAddress&code',
        parent: 'anonymous',
        controller: 'PasswordResetController'
      }
    )
  ])
  .controller('PasswordResetController',
    ['$scope', '$rootScope', 'auth', 'alertService', '$http', '$stateParams', 'config',
    function($scope: IPasswordResetController, $rootScope, auth:IAuthService, alertService, $http, $stateParams, config) {
      $rootScope.title = 'Reset Password';
      $scope.emailAddress = $stateParams.emailAddress;
      $scope.formData = {
        newPassword: '',
        verifyNewPassword: ''
      };
      $scope.submitting = false;

      // If we didn't receive an email address or code, redirect to login
      if ($scope.emailAddress == null || $scope.emailAddress == '' || $stateParams.code == null || $stateParams.code == '') {
        auth.logout();
        return;
      }

      $scope.submit = () => {
        $scope.submitting = true;

        $http.put(`${config.api.url}/staff-user/password/reset`, {
          emailAddress: $stateParams.emailAddress,
          code: $stateParams.code,
          newPassword: $scope.formData.newPassword
        }).then(((res) => {
          $scope.submitting = false;

          if (res.data.success) {
            $scope.formData = {
              newPassword: '',
              verifyNewPassword: ''
            };
            auth.logout();
            alertService.success('Your password was successfully changed', true);
          } else {
            alertService.error('An error occurred while resetting your password', true);
          }
        }), () => {
          $scope.submitting = false;
          alertService.error('An error occurred while resetting your password', true);
        });
      };
    }
  ]);
