import { ILegacyUserAction } from '../actions/legacy-user';
import { IApiRole, IUser } from '../models/auth';
import { IStaffUser } from '../models/staff-user';
import { IStaffUserUpdate } from './staff-user-service';

interface IEditStaffUserViewCtrlScope {
  apiRoles: IApiRole[]
  legacyUsers: IUser[]
  legacyUser?: Partial<IUser>
  staffUser?: IStaffUser
  staffUserChanges?: IStaffUserUpdate
  save(): void
  onSave(changes: {}): void
}

angular
  .module('relcore.staff-user')
  .directive('staffUserEdit', () => ({
    restrict: 'E',
    scope: {
      staffUser: '=',
      onSave: '&',
      saving: '='
    },
    template: require('./edit.html').default,
    controller: [
      '$scope', 'ReduxMapToScope', 'store', 'apiRolesActions', 'legacyUserActions',

      ($scope: IEditStaffUserViewCtrlScope, ReduxMapToScope, store, apiRolesActions,
       legacyUserActions: ILegacyUserAction) => {

        $scope.legacyUser = $scope.staffUser.apiCredential.user
        $scope.staffUserChanges = {
          ...$scope.staffUser,
          apiRoles: $scope.staffUser.apiCredential.roles.map(r => r.id),
          legacyUser: $scope.legacyUser
        };

        $scope.apiRoles = [];

        ReduxMapToScope($scope, state => $scope.apiRoles = state.facilityUser.list.apiRoles);
        store.dispatch(apiRolesActions.load());

        ReduxMapToScope($scope, state => $scope.legacyUsers = state.legacyUser.list.users);
        store.dispatch(legacyUserActions.load());

        $scope.save = () => {
          $scope.onSave({changes: $scope.staffUserChanges});
        };
      }
    ]
  }));
