import { IPromise } from "angular"

export interface IDialogResult {
  saved: boolean
}

interface IModalService {
  open(options: object): IModal
}

interface IConfirmDialog {
  show(): IModal
}

interface IModal {
 result: IPromise<any>
}

enum ModalSize {
  small = 'sm',
  medium = 'md',
  large = 'lg'
}

class ConfirmDialog implements IConfirmDialog {
  service: IModalService
  promptText: string
  confirmText: string
  cancelText: string
  size: string

  constructor(service: IModalService, prompt: string,
              confirm: string = 'Ok', cancel: string = 'Cancel',
              size: string = ModalSize.medium) {

    this.service = service;
    this.promptText = prompt;
    this.confirmText = confirm;
    this.cancelText = cancel;
    this.size = size;
  }

  show() {
    const self = this;
    const modal = this.service.open({
      size: self.size,
      template: require('./confirm-dialog.html').default,
      controller: 'ConfirmDialogController',
      resolve: {
        promptText() {return self.promptText},
        confirmText() {return self.confirmText},
        cancelText() {return self.cancelText}
      }
    });
    return modal;
  }
}

export interface IDialogService {
  getConfirmDialog(prompt: string, confirm?: string, cancel?: string, size?: string): IConfirmDialog
}

class DialogService implements IDialogService {
  modalService: IModalService

  constructor(service: IModalService) {
    this.modalService = service;
  }

  getConfirmDialog(prompt: string, confirm: string, cancel: string, size: string): IConfirmDialog {
    return new ConfirmDialog(this.modalService, prompt, confirm, cancel, size);
  }
}

angular
  .module('relcore.common')
  .service('DialogService', ['$uibModal', ($uibModal: IModalService) => {
      return new DialogService($uibModal);
    }
  ])
  .controller('ConfirmDialogController', ['$scope', '$uibModalInstance', 'promptText', 'confirmText', 'cancelText', ($scope, $uibModalInstance, promptText, confirmText, cancelText) => {
      $scope.title = 'Warning';
      $scope.promptText = promptText;
      $scope.confirmText = confirmText;
      $scope.cancelText = cancelText;

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      }

      $scope.confirm = () => {
        $uibModalInstance.close();
      }
    }
  ]);