angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('repair-tickets', {
      controller: 'RepairTicketListController',
      template: require('./main.html').default,
      url: '/repair-ticket',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Repair Tickets' }
    })

  ])
  .controller('RepairTicketListController', [
    '$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, NgTableParams) {
      $rootScope.title = 'Repair Tickets';

      $scope.gridOptions = new NgTableParams({
        page: 1
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/repair-ticket', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.onViewDetails = ticket => $state.go('repair-ticket-view', { id: ticket.id, ticket });
    }
  ]);
