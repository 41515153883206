import * as validation from '@reliance/forms-js/lib/validation';
import React from 'react';
import { Input, Select } from '../../form/render';

export const MoneyTransferPaymentForm: React.FC = () =>
  <div>
    <div className="row">
      <div className="col-md-6">
        <Select
          name="paymentMethod.info.transferAgent"
          label="Transfer Agent"
          validationRules={[
            validation.required
          ]}
          options={[
            { text: 'MoneyGram', value: '1' },
            { text: 'Western Union', value: '2' }
          ]} />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.transferNumber"
          label="Transfer Number"
          validationRules={[
            validation.required
          ]} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.firstName"
          label="First Name"
          validationRules={[
            validation.required
          ]} />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.lastName"
          label="Last Name"
          validationRules={[
            validation.required
          ]} />
      </div>
    </div>
  </div>