import { IApiResponse, IRelianceApi, IStatusResponse } from '../common/reliance-api-service';
import { IDateTimeObject } from '../models/datetime';

export interface ITaxRate {
  id?: number;
  dateRemoved?: IDateTimeObject;
  zipCode: string;
  state: string;
  county?: string;
  normalizedCounty?: string;
  city?: string;
  normalizedCity?: string;
  taxRegionName?: string;
  normalizedTaxRegionName?: string;
  combinedRate?: number;
  stateRate?: number;
  countyRate?: number;
  cityRate?: number;
  specialRate?: number;
  comments?: string
}

type ITaxRateQuery = Partial<ITaxRate>

export interface ITaxService {
  getTaxRates(query?: ITaxRateQuery): Promise<Array<ITaxRate>>
  updateTaxRate(update:ITaxRateQuery): Promise<IApiResponse<IStatusResponse>>
}

class TaxService implements ITaxService {
  api: IRelianceApi;

  constructor(api: IRelianceApi) {
    this.api = api;
  }

  async getTaxRates(query: ITaxRateQuery = {}): Promise<Array<ITaxRate>> {
    return this.api.get('/tax/rate', query ? query : {})
      .then((response: IApiResponse<Array<ITaxRate>>) => {
        return response.data;
      });
  }

  async updateTaxRate(update:ITaxRateQuery): Promise<IApiResponse<IStatusResponse>> {
    return this.api.put(`/tax/rate/${update.zipCode}`, update);
  }
}

angular
  .module('relcore.tax')
  .service('TaxService', ['relianceApi', (relianceApi: IRelianceApi) => {
      return new TaxService(relianceApi);
    }
  ]);