angular
  .module('relcore.mobile-device')
  .directive('mobileDevicePurchaseAdd', () =>
    ({
      restrict: 'E',
      scope: {
        adding: '=',
        onAdd: '='
      },
      controller: ['$scope', function($scope) {
        $scope.dateOrdered = '';
        $scope.warrantyEndDate = '';
        $scope.purchase = {
          vendor: '', vendorOrderId: '', notes: '', dateOrdered: '',
          warrantyEndDate: '', warrantyDescription: ''
        };
        $scope.add = function() {
          $scope.purchase.dateOrdered = $scope.dateOrdered;
          $scope.purchase.warrantyEndDate = $scope.warrantyEndDate;
          $scope.onAdd($scope.purchase);
        };
      }
      ],
      template: require('./mobile-device-purchase-add.html').default
    })
  );
