angular
  .module('relcore.commissary-transfer')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('commissary-transfers', {
      controller: 'CommissaryTransfersController',
      template: require('./list.html').default,
      url: '/inmate-account/commissary-transfer',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Texting/Video Purchases' }
    })
  ])
  .controller('CommissaryTransfersController', [
    '$rootScope', '$scope', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, relianceApi, NgTableParams) {
      $rootScope.title = 'Texting/Video Purchases';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'dateEntered': 'desc'
        },
        filter: {facilityIds: $rootScope.facilityIds}
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/inmate-account/commissary-transfer', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });
    }
  ]);
