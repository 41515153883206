import { ICdr } from './cdr';
import { IDateTimeObject } from './datetime';
import { IDebitCardFacility, IFacility } from './facility';
import { IAccount } from './itds';
import { IOnlineUser } from './online-user';
import { ISciDynCdr } from './scidyncdr';

export enum CardType {
  'Phone Card' = 1,
  'ITDS' = 2
}

export enum SwitchStatus {
  'Add' = 1,
  'Update' = 2,
  'Stable' = 3
}

export interface IOnlineOrderItem {
  order: IOnlineOrder
}

export interface IOnlineOrder {
  id: number
  date: IDateTimeObject
  inmateFirstName: string
  inmateLastName: string
  user: IOnlineUser
  facility: IDebitCardFacility
}

export interface IFacilityOrderItem {
  order: IFacilityOrder
}

export interface IFacilityOrder {
  id: number
  orderDate: IDateTimeObject
  facility: IDebitCardFacility
  quantity?: number
  unitPrice?: number
}

export interface ICardAccount {
  account: string
  type: number
  currentValue: number
  initialValue: number
  switchStatus1: SwitchStatus
  switchStatus2: SwitchStatus
  switchStatus3: SwitchStatus
  switchStatus4: SwitchStatus
  activities: ICardAccountActivity[]
  sciDynCdrs: ISciDynCdr[]
  cdrs: ICdr[]
  phoneCard: IPhoneCard
  itdsInmateAccount: IAccount
}

export enum CardAccountActivityType {
  'Load' = 1,
  'Value Change' = 2,
  'Inmate Account Transfer' = 3,
  'Call' = 5,
}

export interface ICardAccountActivity {
  id: number
  dateCreated: IDateTimeObject
  dateSource: IDateTimeObject
  amount: number
  type: CardAccountActivityType
  enteredBy: string
  description: string
  sciDynCdr: ISciDynCdr
  cdr: ICdr
  facility: IFacility
}

export interface IPhoneCard {
  cardId: number
  pin: string
  dateExpires: IDateTimeObject
  onlineOrderItem?: IOnlineOrderItem
  facilityOrderItem?: IFacilityOrderItem
  facility?: IFacility
}

export interface IPhoneCardContent {
  id: number
  content: string
  active: boolean
  contentName: string
  contentType: string
}

export interface ILocation {
  id: number
  name: string
  topControlNumber: number
  active: boolean
}