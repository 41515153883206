import * as validation from '@reliance/forms-js/lib/validation';
import { CreditCardSource } from '@reliance/types/enums/CreditCardSource';
import { Account } from '@reliance/types/models/Account';
import { CreditCard } from '@reliance/types/models/CreditCard';
import * as React from 'react';
import { useMemo } from 'react';
import { Input, Select } from '../../form/render';
import { creditCardType } from '../../format/CreditCardType';
import { useCreditCards } from '../../hooks/CreditCard';

type SavedCreditCardFormProps = {
  account: Account
}

const getDropdownText = (c: CreditCard) => {
  return (
    creditCardType(c.cardType) +
    ': *********' +
    c.lastFour +
    ` (${c.expMonth}/${c.expYear}) [${(c.address + c.address2).trim()}, ${c.city}, ${c.state}]`
  );
}

export const SavedCreditCardForm: React.FC<SavedCreditCardFormProps> = (props) => {
  const filters = useMemo(() => ({
    accountId: props.account.id,
    source: CreditCardSource.DIRECTBILL,
    saved: true
  }), [props.account]);
  const creditCards = useCreditCards(filters);

  return (
    <div className="row">
      <div className="col-md-12">
        {creditCards.error &&
          <div className='alert alert-error'>An unexpected error occurred while loading saved cards</div>
        }

        <Select
          name="paymentMethod.info.savedCreditCardId"
          label="Credit Card"
          disabled={creditCards.loading}
          validationRules={[
            validation.required
          ]}
          options={
            (creditCards.result?.data || []).map(c => (
              {
                text: getDropdownText(c),
                value: c.id.toString().replace('DirectBill_', '')
              }
            ))
          } />
      </div>
      <div className="col-md-6">
        <Input
          name="paymentMethod.info.cardSecurityCode"
          label="Security Code"
          validationRules={[
            validation.required,
            validation.numeric
          ]} />
      </div>
    </div>
  );
}