declare const $:any;

// Hacky legacy migration from original app.js
angular
  .module('relcore.auth')
  .directive('relLogout', ['auth', auth =>
    ({
      link: (scope, element, attrs) => {
        element.on('click', e => {
          let jq = ($ as any);
          jq.SmartMessageBox({
            title : `<i class='fas fa-sign-out-alt txt-color-orangeDark'></i> Log out <span class='txt-color-orangeDark'><strong>${jq('#show-shortcut').text()}</strong></span> ?`,
            content : "Do you wish to log out?",
            buttons : '[No][Yes]'
          }, ButtonPressed => {
            if (ButtonPressed === "Yes") {
              // $.root_.addClass('animated fadeOutUp');
              setTimeout(auth.logout, 100);
            }
          });
          e.preventDefault();

          document.getElementById('bot2-Msg1').focus();
        });
      }
    })
  ]);