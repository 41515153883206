angular
  .module('relcore.online-user')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('online-user-view', {
        controller: 'OnlineUserViewController',
        url: '/online-user/view/:id',
        parent: 'authenticated',
        params: { id: null, onlineUser: null },
        ncyBreadcrumb: {
          label: '{{onlineUser.emailAddress}}',
          parent: 'online-user-search'
        },
        template: require('./online-user-view.html').default
      }
    )
  ])
  .controller('OnlineUserViewController',
    ['$rootScope', '$scope', '$stateParams', 'store', 'NgTableParams', 'onlineUserViewActions', 'ReduxMapToScope', 'relianceApi', '$uibModal', 'alertService',
    function($rootScope, $scope, $stateParams, store, NgTableParams, actions, ReduxMapToScope, relianceApi, $uibModal, alertService) {
      $rootScope.title = 'Loading...';

      ReduxMapToScope($scope, function(state) {
        $scope.onlineUser = state.onlineUser.view.onlineUser;

        if ($scope.onlineUser != null) {
          $rootScope.title = `${$scope.onlineUser.firstName} ${$scope.onlineUser.lastName}`;
        }
      });

      // Load the user
      if ($stateParams.onlineUser != null) {
        store.dispatch(actions.setOnlineUser($stateParams.onlineUser));
      } else {
        store.dispatch(actions.loadOnlineUser($stateParams.id));
      }

      // Clear this view's state after leaving
      $scope.$on('$destroy', () => store.dispatch(actions.clear()));

      // Load inmate account payment history
      $scope.inmateAccountPaymentGridOptions = new NgTableParams(
        {
          count: 100,
          sorting: { createdDate: 'desc' },
          filter: { onlineUserId: $stateParams.id }
        },
        {
          counts: [100,200,500],
          getData(params) {
            return relianceApi.index('/inmate-account-payment', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        }
      );

      $scope.onlineOrderGridOptions = new NgTableParams({
        count: 100,
        sorting: { date: 'desc' },
        filter: { onlineUserId: $stateParams.id, includeCards: true }
      }, {
        getData(params) {
          return relianceApi.index('/online-order', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.accountGridOptions = new NgTableParams({
        count: 100,
        sorting: { ani: 'asc' },
        filter: { onlineUserId: $stateParams.id }
      }, {
        getData(params) {
          return relianceApi.index('/account', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.onlinePaymentGridOptions = new NgTableParams({
        count: 100,
        sorting: { date: 'desc' },
        filter: { onlineUserId: $stateParams.id }
      }, {
        getData(params) {
          return relianceApi.index('/transaction', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.loadLinkedNumbers = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.linkedNumbersGridOptions != null)) { return; }

        return $scope.linkedNumbersGridOptions = new NgTableParams({}, {
          getData(params) {
            return relianceApi.index(`/onlineuser/${$stateParams.id}/account`, params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.length);
                return res.data;
              });
          }
        });
      };

      $scope.unlinkNumber = number =>
        relianceApi.delete(`/onlineuser/${$stateParams.id}/account/${number.id}`)
          .then(() => $scope.linkedNumbersGridOptions.reload())
      ;

      $scope.loadDevices = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.deviceGridOptions != null)) { return; }

        return $scope.deviceGridOptions = new NgTableParams({
          count: 100,
          filter: { onlineUserId: $stateParams.id }
        }, {
          getData(params) {
            return relianceApi.index('/onlineuser/device', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        });
      };

      $scope.loadAppInvites = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.appInvitesGridOptions != null)) { return; }

        return $scope.appInvitesGridOptions = new NgTableParams({
          count: 100,
          sorting: { createdDate: 'desc' }
        }, {
          getData(params) {
            return relianceApi.index(`/onlineuser/${$stateParams.id}/app-invite`, params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        });
      };

      $scope.loadVideoCalls = function(refresh) {
        if (refresh == null) { refresh = true; }
        if (!refresh && ($scope.videoCallGridOptions != null)) { return; }

        return $scope.videoCallGridOptions = new NgTableParams({
          count: 100,
          filter: {
            callTypes: 'video-call',
            onlineUserId: $stateParams.id
          },
          sorting: { setupDate: 'desc' }
        }, {
          getData(params) {
            return relianceApi.index('/cdr', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data;
              });
          }
        });
      };

      $scope.resetPassword = function() {
        const dialog = $uibModal.open({
          template: require('./reset-password/reset-password.html').default,
          controller: 'OnlineUserResetPasswordController',
          resolve: {
            onlineUser() { return $scope.onlineUser; }
          }
        });
        return dialog.result.then(function(result) {
          if (result.saved) {
            alertService.success('The password was successfully changed', true);
          }
        });
      };

      return $scope.resendActivation = function() {
        const dialog = $uibModal.open({
          template: require('./resend-activation/resend-activation.html').default,
          controller: 'OnlineUserResendActivationController',
          resolve: {
            onlineUser() { return $scope.onlineUser; }
          }
        });
        return dialog.result.then(function(result) {
          if (result.sent) {
            alertService.success('The email was successfully sent', true);
          }
        });
      };
    }
  ]);
