import { IOnlineUser } from '../models/online-user';
import { ITransaction } from "../transaction/transaction-service";
import { IAccount, IAccountLinkStatus } from "../models/account"
import { IAcccountLinkStatusService } from "./account-service";

angular
  .module('relcore.account')
  .factory('Account', ['AccountLinkStatusService',
    (AccountLinkStatusService: IAcccountLinkStatusService) => {
    return class AccountModel implements IAccount {
      id: number;
      ani: string;
      conditions?: number[];
      firstName?: string;
      lastName?: string;
      dob?: string;
      companyName?: string;
      isCompany?: boolean;
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      zip?: string;
      balance?: number;
      notes?: string;
      timezone?: string;
      formattedCurrentTime?: string;
      isBlocked?: boolean;
      blockType?: number;
      password?: string;
      badAddress?: boolean;
      badDebt?: boolean;
      customerOK?: boolean;
      isHostile?: boolean;
      bondingNonBlock?: boolean;
      noPhoneTree?: boolean;
      invoiceFrequency?: number;
      balanceThreshold?: number;
      blockThreshold?: number;
      federalTaxExempt?: boolean;
      federalTaxExemptId?: string;
      stateTaxExempt?: boolean;
      stateTaxExemptId?: string;
      toStateTaxExempt?: boolean;
      toStateTaxExemptId?: string;
      countyTaxExempt?: boolean;
      countyTaxExemptId?: string;
      cityTaxExempt?: boolean;
      cityTaxExemptId?: string;
      onlineMonthlyLimit?: number;
      onlineUser?: IOnlineUser;
      transactions?: ITransaction[];
      additionalAccounts?: IAccount[]
      accountLinkStatus?: IAccountLinkStatus

      changed: object = {};

      constructor(data: any) {
        this.conditions = [];
        this.ani = data.ani.substring(-10);
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.dob = data.dob ? data.dob : '';
        this.isCompany = data.isCompany || false;
        this.companyName = data.companyName || '';
        this.address1 = data.address1 || '';
        this.address2 = data.address2 || '';
        this.city = data.city || '';
        this.state = data.state || '';
        this.zip = data.zip || '';
        this.balance = data.balance || '';
        this.notes = data.notes || '';
        this.timezone = data.timezone || '0';
        this.password = data.password || '';
        this.badAddress = data.badAddress;
        this.badDebt = data.badDebt;
        this.customerOK = data.customerOK;
        this.isHostile = data.isHostile;
        this.bondingNonBlock = data.bondingNonBlock;
        this.noPhoneTree = data.noPhoneTree;
        this.invoiceFrequency = data.invoiceFrequency;
        this.balanceThreshold = data.balanceThreshold;
        this.blockThreshold = data.blockThreshold;
        this.federalTaxExempt = data.federalTaxExempt;
        this.federalTaxExemptId = data.federalTaxExemptId || '';
        this.stateTaxExempt = data.stateTaxExempt;
        this.stateTaxExemptId = data.stateTaxExemptId || '';
        this.toStateTaxExempt = data.toStateTaxExempt;
        this.toStateTaxExemptId = data.toStateTaxExemptId || '';
        this.countyTaxExempt = data.countyTaxExempt;
        this.countyTaxExemptId = data.countyTaxExemptId || '';
        this.cityTaxExempt = data.cityTaxExempt;
        this.cityTaxExemptId = data.cityTaxExemptId || '';
        this.onlineMonthlyLimit = parseFloat(data.onlineMonthlyLimit);
        this.onlineUser = data.onlineUser;
        this.accountLinkStatus = AccountLinkStatusService.getAccountLinkStatus(data.accountLinkStatus);
        this.transactions = data.transactions;
        this.additionalAccounts = (data.additionalAccounts||[]).map(a => ({...a, balance: Number(a.balance)}));

        this.changed = {};

        if (data.block != null) {
          this.isBlocked = data.block.isBlocked;
          this.blockType = data.block.type;
        }

        const proxy = new Proxy(this, {
          set(target, property, value) {
            target[property] = value;
            if (property != "formattedCurrentTime" && property != "changed") {
              target.changed[property] = value;
            }
            return true;
          }
        });

        return proxy;
      }

      getChanged() {
        return this.changed;
      }

      clearChanged() {
        this.changed = {};
      }
    }
  }]);
