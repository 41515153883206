angular
  .module('relcore.actions')
  .service('onlineUserViewActions', ['relianceApi', function(relianceApi) {
    const clear = () => {
      return { type: 'online-user/view/clear' }
    };

    const setOnlineUser = (onlineUser) => {
      return { type: 'online-user/view/set-online-user', onlineUser };
    };

    const loadOnlineUser = (id) => {
      return dispatch => {
        dispatch({ type: 'online-user/view/loading' });

        return relianceApi.get(`/onlineuser/${id}`)
          .then(
            (res => dispatch(setOnlineUser(res.data))),
            (err => dispatch({ type: 'online-user/view/loading-failed', error: err }))
          );
      }
    };

    return {
      clear,
      setOnlineUser,
      loadOnlineUser
    };
  }]);
