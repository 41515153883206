import axios from 'axios';
import config from './config';

const api = axios.create({
  baseURL: config.api.url
});

api.interceptors.request.use((requestConfig) => {
  let token = localStorage.getItem('token');
  if (token) {
    requestConfig.headers['Authorization'] = `Bearer ${token}`;
  }
  return requestConfig;
});

export default api;
