angular
  .module('relcore.filters')
  .filter('tel', () =>
    function(tel) {
      if (!tel) { return ''; }

      let val = tel.toString().trim().replace(/^\+/, '');

      if (!val.match(/^[0-9]{10,11}$/)) { return tel; }

      let prefix = '';
      if (val.length === 11) {
        prefix = val.slice(0,1) + '-';
        val = val.slice(1,11);
      }
      return `${prefix}${val.slice(0,3)}-${val.slice(3,6)}-${val.slice(6,10)}`;
    }
);
