angular
  .module('relcore.card')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('card-transfer-value', {
        template: require('./transfer-value.html').default,
        url: '/card/transfer-value',
        parent: 'authenticated',
        controller: 'CardTransferValueController',
        ncyBreadcrumb: {
          label: 'Transfer Card Balance'
        }
      }
    )
  ])
  .controller('CardTransferValueController',
    ['$scope', '$rootScope', '$q', 'auth', 'relianceApi', 'config', 'alertService',
    function($scope, $rootScope, $q, auth, relianceApi, config, alertService) {
      let errorHandler;
      const reset = function(form = null) {
        $scope.model = {
          description: '',
          fromPins: [''],
          toPin: '',
          value: -1
        };

        if (form != null) {
          form.$setPristine();
          form.$submitted = false;
        }
      };

      $rootScope.title = 'Transfer Value';
      reset();

      $scope.addSourceCard = () => $scope.model.fromPins.push('');

      $scope.removeSourceCard = function(i) {
        if ($scope.model.fromPins.length <= 1) { return; }
        return $scope.model.fromPins.splice(i, 1);
      };

      $scope.transferValue = function(form) {
        let pin;
        if (!form.$valid) { return; }

        const name = auth.currentUser().firstName + ' ' + auth.currentUser().lastName;

        const promises = [];
        for (pin of Array.from($scope.model.fromPins)) {
          const fullDescription = `${pin} transferred to ${$scope.model.toPin} ${$scope.model.description}`;
          promises.push(relianceApi.put(`/phone-card/transfer-value.json/${pin}/${$scope.model.toPin}/${name}?value=${$scope.model.value}&description=${fullDescription}`));
        }

        $scope.transferring = true;
        return $q.all(promises)
          .then(results => {
            $scope.transferring = false;
            alertService.clearAll();
            let result:any;
            for (result of Array.from(results)) {
              if (result.status !== 200) {
                alertService.error(`Something broke! The transfer failed between ${result.data.cardFrom.pin} and ${result.data.cardTo.pin}`);
              } else {
                alertService.success(`The transfer was successful from ${result.data.cardFrom.pin} to ${result.data.cardTo.pin}`);
              }
            }
            return reset(form);
          },
          result => {
            $scope.transferring = false;
            errorHandler(result.data, result.success);
          }
        );
      };

      return errorHandler = function(data, status) {
        if (status === 400) {
          switch (data.error.error) {
            case 'value_invalid': alertService.error('The value supplied is invalid', true); break;
            case 'card_invalid': alertService.error(`The card, ${data.error.card}, supplied is invalid`, true); break;
            case 'card_not_found': alertService.error(`The card, ${data.error.card}, does not exist`, true); break;
          }
          return;
        }
         else {
          // TODO: Log to errbit
          return alertService.error('Something broke! The transfer failed', true);
        }
      };
    }

      // runTest: =>
      //   @clearAlerts()
      //   @fromPins = ['2893635228', '123456789', '3432734762', '9998887777']
      //   @toPin = '12345678'

      //   name = @auth.currentUser().firstName + ' ' + @auth.currentUser().lastName
      //   @relianceApi.put "/phone-card/change-value.json/3432734762/3?name=#{name}&description=&fixed=true"
      //     .then (results) =>
      //       @relianceApi.put "/phone-card/change-value.json/9998887777/4?name=#{name}&description=&fixed=true"
      //         .then (results) =>
      //           @relianceApi.put "/phone-card/change-value.json/2893635228/10?name=#{name}&description=&fixed=true"
      //             .then (results) =>
      //               @relianceApi.put "/phone-card/change-value.json/12345678/0?name=#{name}&description=&fixed=true"
      //                 .then (results) =>
      //                   @relianceApi.put "/phone-card/change-value.json/123456789/5?name=#{name}&description=&fixed=true"
      //                     .then (results) =>
      //                       promises = []
      //                       for pin in @fromPins
      //                         description = "#{pin} transferred to #{@toPin} #{@description}"
      //                         promises.push @relianceApi.put "/phone-card/transfer-value.json/#{pin}/#{@toPin}/#{name}?value=#{@value}&description=#{description}"

      //                       @$q.all(promises)
      //                         .then ((results) =>
      //                           @clearAlerts()
      //                           for result in results
      //                             if result.status != 200
      //                               alertService.error "Something broke! The transfer failed between #{result.data.cardFrom.pin} and #{result.data.cardTo.pin}"
      //                             else
      //                               alertService.success "The transfer was successful from #{result.data.cardFrom.pin} to #{result.data.cardTo.pin}. New value #{result.data.cardTo.value}"
      //                           @reset form
      //                         ), (result) => @errorHandler result.data, result.success
  ]);
