angular
  .module('relcore.mobile-device')
  .directive('mobileDeviceWarrantyInfo', () =>
    ({
      restrict: 'E',
      scope: {
        purchase: '='
      },
      template: require('./mobile-device-warranty-info.html').default
    })
  );
