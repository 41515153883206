import React from 'react';

type Entity = {
  entity: string
  entityId: string|number
}

type EntityLinkProps = {
  log: Entity
}

const entityLinkMap = {
  'Reliance\\Api\\Models\\Facility': '/facilities/view/{entityId}/details'
}

const EntityLink: React.FC<EntityLinkProps> = (props) => {
  const link = entityLinkMap[props.log.entity];
  if (link) {
    return (
      <a href={link.replace('{entityId}', props.log.entityId)}>{props.children}</a>
    );
  }

  return <>{props.children}</>;
}

export default EntityLink;