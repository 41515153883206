import { IApiResponse, IRelianceApi } from '../common/reliance-api-service';
import { IFacility } from '../models/facility';

interface IBuyInfoCenterSearch {
  areaCode
  zipCode
  city
  state
}

interface IBuyInfoCenterScope {
  error?: Error
  searchOptions: IBuyInfoCenterSearch
  searching: boolean
  searchResults?: string[]
  search(): Promise<string[]>
  purchasing: boolean
  purchase(number:any): void
  close(): void
}

angular
  .module('relcore.facility')
  .controller('FacilityNumberBuyInfoController', [
    '$scope', 'relianceApi', '$uibModalInstance', 'facility', 'basisNumber',
    function ($scope: IBuyInfoCenterScope, relianceApi: IRelianceApi, $uibModalInstance, facility: IFacility, basisNumber) {
      $scope.error = null;

      const initialSearch = {
        areaCode: basisNumber ? basisNumber.ani.substr(0, 3) : null,
        zipCode: null,
        city: null,
        state: null
      };

      $scope.searchOptions = initialSearch;
      $scope.searching = false;
      $scope.searchResults = null;

      $scope.search = () => {
        $scope.searching = true;
        $scope.error = null;
        return relianceApi
          .get('/smsvoice-number/available', $scope.searchOptions)
          .then((res: IApiResponse<string[]>) => {
            $scope.searching = false;
            $scope.searchResults = res.data;
            return $scope.searchResults;
          })
          .catch(err => {
            $scope.searching = false;
            $scope.error = err;
            return [];
          });
      };

      $scope.purchasing = false;
      $scope.purchase = (number: string) => {
        $scope.purchasing = true;
        relianceApi
          .post('/facility-ani', {
            facilityId: facility.id,
            name: 'Info Center',
            ani: number,
            purchaseNumber: true
          })
          .then((res:IApiResponse<any>) => {
            $scope.purchasing = false;
            if (!res.data || !res.data.success) {
              $scope.error = Error('An unexpected error occurred');
              return;
            } else {
              $scope.error = null;
              $uibModalInstance.close({ saved: true });
            }

            $scope.purchasing = false;
            $uibModalInstance.close({ saved: true });
          })
          .catch((err) => {
            $scope.error = err;
          });
      };

      $scope.close = () => $uibModalInstance.close({ saved: false })
    }
  ]);
