angular
  .module('relcore.text-message')
  .directive('textMessagesStatus', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        columns: '=?'
      },
      template: require('./text-messages-status.html').default,
      controller: ['$scope', function($scope) {
        $scope.showColumn = column => ($scope.columns == null) || ($scope.columns.indexOf(column) > -1);
      }]
    })
  );