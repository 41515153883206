angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('mobile-device-needs-inspection', {
      controller: 'MobileDeviceNeedsInspectionController',
      template: require('./needs-inspection.html').default,
      url: '/mobile-device/needs-inspection',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Devices Needing Inspection' }
    })

  ])
  .controller('MobileDeviceNeedsInspectionController', [
    '$rootScope', '$scope', '$state', 'relianceApi', 'facilityService', 'alertService', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, facilityService, alertService, NgTableParams) {
      $rootScope.title = 'Devices Needing Inspection';
      $scope.adding = false;

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          lastStatusChange: 'asc'
        },
        filter: {
          status: 'Needs Inspection'
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/mobile-device', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });

      $scope.onViewDetails = device => $state.go('mobile-device-view', {id:device.id, device});
    }
  ]);
