import { ITextMessage, ITextMessageService } from "./text-message-service";

angular
  .module('relcore.text-message')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('text-messages', {
      controller: 'TextMessageListController',
      template: require('./main.html').default,
      url: '/text-message',
      parent: 'authenticated',
      ncyBreadcrumb: { label: '{{title}}' }
    })
  ])
  .controller('TextMessageListController', [
    '$rootScope', '$scope', 'relianceApi', 'NgTableParams', 'moment', '$uibModal',
    function($rootScope, $scope, relianceApi, NgTableParams, moment, $uibModal) {
      $rootScope.title = 'Text Messages';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: { createdDate: 'desc' },
        filter: {
          createdDate: {
            startDate: moment().subtract(1, 'days').startOf('day'),
            endDate: moment().endOf('day'),
            facilityIds: $rootScope.facilitiesToDisplay
          }
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/text-message', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(res) {
              params.total(res.data.total);
              return res.data.data;
            });
        }
      });

      $scope.showProviderStatus = (textMessage: ITextMessage) => {
        $uibModal.open({
          size: 'lg',
          template: require('./provider-status-modal.html').default,
          controller: 'ModalTextMessageStatusController',
          resolve: {
            textMessage() { return textMessage }
          }
        });
      };
  }])
  .controller('TextMessageDialogController', ['$scope', '$uibModalInstance', 'textMessageService', 'destination', 'message', function ($scope, $uibModalInstance, textMessageService: ITextMessageService, destination, message) {
      $scope.destination = destination;
      $scope.message = message;
      $scope.sending = false;

      $scope.cancelMessage = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.confirmMessage = () => {
        $scope.sending = true;
        textMessageService.sendTextMessage($scope.destination, $scope.message.text).then(() => {$scope.sending = false; $uibModalInstance.close()});
      }
  }]);
