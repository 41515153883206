import { IPhoneTrackerProcess } from '../models/phone-tracker';
import { IAppState } from '../reducers/root';

interface IPhoneTrackerPendingListControllerScope extends ng.IScope {
  runningProcesses: Array<IPhoneTrackerProcess>
}

angular
  .module('relcore.phone-tracker')
  .component('phoneTrackerProcessList', {
    template: require('./process-list.html').default,
    controller: [
      '$scope', 'store', 'serverActions', 'ReduxMapToScope',
      function($scope: IPhoneTrackerPendingListControllerScope, store, serverActions, ReduxMapToScope) {
        ReduxMapToScope($scope, function(state: IAppState) {
          $scope.runningProcesses = state.phoneTracker.list.runningProcesses;
        });

        store.dispatch(serverActions.subscribe('phone-tracker/process'));
        $scope.$on('$destroy', () => store.dispatch(serverActions.unsubscribe('phone-tracker/process')));
      }
    ]
  });