import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import { useCreditCards } from '../hooks/CreditCard';
import Filters from './Filters';
import { CreditCardFilters } from '@reliance/types/filters/CreditCardFilters';
import List from './List';

type SearchProps = {}

const Search: React.FC<SearchProps> = () => {
  const [filters, setFilters] = useState<CreditCardFilters>(null);
  const creditCards = useCreditCards(filters);

  return (
    <>
      <Filters onSubmit={setFilters} creditCards={creditCards} />
      {filters && !creditCards.loading &&
        <div className="mt-1">
          <List creditCards={creditCards} />
        </div>
      }
    </>
  )
};

angular
  .module('relcore.credit-card')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('credit-cards', {
      url: '/credit-card',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Credit Cards' },
      component: 'creditCardSearch'
    })
  }])
  .component('creditCardSearch', react2angular(
    Search,
    // []
  ));