angular
  .module('relcore.inmate')
  .directive('inmateActivities', () =>
    ({
      restrict: 'E',
      controller: ['$scope', '$uibModal', 'ngTableEventsChannel', function($scope, $uibModal, ngTableEventsChannel) {
        $scope.showRunningBalance = false;

        const calculateRunningBalance = function(params) {
          const filter = params.filter();
          const sorting = params.sorting();
          $scope.showRunningBalance = (
            (Object.keys(sorting).length === 1) &&
            (sorting['dateCreated'] === 'asc') &&
            ((Object.keys(filter).length === 0) || ((Object.keys(filter).length === 2) && (filter['dateCreated'] != null) && (filter['inmateId'] != null)))
          );
          if ($scope.showRunningBalance) {
            let balance = params.previousBalance;
            let activity:any;
            for (activity of Array.from(params.data)) {
              balance += parseFloat(activity.amount);
              activity.runningBalance = balance;
            }
          }
        };

        $scope.$watch('gridOptions', function() {
          if ($scope.gridOptions) {
            ngTableEventsChannel.onAfterReloadData(calculateRunningBalance, $scope, $scope.gridOptions);
          }
        });

        $scope.onReverse = function(activity) {
          const dialog = $uibModal.open({
            template: require('./phone-card-reverse.html').default,
            controller: 'InmateActivityPhoneCardReverseController',
            resolve: {
              inmateActivity() { return activity; }
            }
          });
          dialog.result.then(() => $scope.gridOptions.reload());
        };
      }
      ],
      template: require('./inmate-activities.html').default,
      scope: {
        gridOptions: '=',
        inmate: '=',
        previousBalance: '='
      }
    })
  );
