enum Status {
  StatusActive = "StatusActive",
  StatusInactive = "StatusInactive"
}

angular
  .module('relcore.facility')
  .service('facilityService', ['CacheFactory', 'relianceApi', 'Facility',
    function(CacheFactory, relianceApi, Facility) {
      let facilityCache = CacheFactory('facilityCache');
      facilityCache.setOptions({
        maxAge: 60 * 30 * 1000, // 30 minutes
        deleteOnExpire: 'aggressive'
      });

      // Default is to retrieve all facilities, not just active
      const retrieveAll = (callback, refresh, active = false) => {
        if (!refresh) {
          let facilities = facilityCache.get('all');
          if (facilities && callback) {
            callback(null, facilities);
            return Promise.resolve(facilities);
          }
        }

        return relianceApi.get(`/facility${active === false ? '?active=false' : ''}`)
          .then((results) => {
            const data = results.data;
            let facilities = [];
            data.forEach(facilityData => {
              let facility = new Facility(facilityData);
              facility.location = facility.city + ", " + facility.state;
              facility.status = facility.active ? Status.StatusActive : Status.StatusInactive;
              facilities.push(facility);
              facilityCache.put(facility.id, facility);
            });

            facilityCache.put('all', facilities);

            if (callback) {
              callback(null, facilities);
            }

            return facilities;
          });
      };

      const getById = (id) =>
        relianceApi.get(`/facility/${id}`)
          .then((result) => new Facility(result.data));

      return {
        retrieveAll,
        getById
      }
    }
  ]);