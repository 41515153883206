import './sentry';
const createLogger = require('redux-logger');
const reduxSocketIO = require('redux-socket.io');
const ReduxThunk = require('redux-thunk').default;
const moment = require('moment-timezone');
require('./styles');

// Load all of the files in the src directory, starting with module definitions
function importAll (r) { r.keys().forEach(r); }

importAll(require.context('./', true, /^.+\/_module.ts/));
importAll(require.context('./', true, /^.+\/((?!index\.d).)((?!_module).).+.ts/));

// Load all of the ng-table filter template files
importAll(require.context('../tpl/ng-table/', true, /\.html$/));

const relcoreApp = angular.module('relcoreApp', [
  'ui.router',
  'ui.bootstrap',
  'ngCookies',
  'angular-cache',
  'ngTable',
  'angularMoment',
  'angular.filter',
  'ncy-angular-breadcrumb',
  'checklist-model',
  'chart.js',
  'ngSelectable',
  'daterangepicker',
  'datatables',
  'datatables.bootstrap',
  'datatables.columnfilter',
  'relcore.account',
  'relcore.actions',
  'relcore.alert',
  'relcore.api-log',
  'relcore.api-role',
  'relcore.api-user',
  'relcore.auth',
  'relcore.authorizenet',
  'relcore.block',
  'relcore.call',
  'relcore.card',
  'relcore.changelog',
  'relcore.cdr',
  'relcore.commissary-transfer',
  'relcore.common',
  'relcore.condition',
  'relcore.configuration',
  'relcore.configuration-text',
  'relcore.credit-card',
  'relcore.directives',
  'relcore.cs-record',
  'relcore.event-notification',
  'relcore.facility',
  'relcore.facility-equipment',
  'relcore.filters',
  'relcore.form',
  'relcore.housing-unit',
  'relcore.home',
  'relcore.inmate',
  'relcore.inmate-contact',
  'relcore.inmate-device',
  'relcore.inmate-device-blacklist',
  'relcore.inmate-subscription',
  'relcore.invoice',
  'relcore.mobile-device',
  'relcore.mobile-device-group',
  'relcore.note',
  'relcore.office-call',
  'relcore.online-order',
  'relcore.online-user',
  'relcore.phone-tracker',
  'relcore.reducers',
  'relcore.reference-material',
  'relcore.rating',
  'relcore.recording',
  'relcore.report',
  'relcore.salt',
  'relcore.schedule',
  'relcore.search',
  'relcore.service-rate',
  'relcore.sms-voice-number',
  'relcore.speed-dial',
  'relcore.station',
  'relcore.staff-user',
  'relcore.support',
  'relcore.switch',
  'relcore.tax',
  'relcore.text-message',
  'relcore.transaction',
  'relcore.translation',
  'relcore.user',
  'relcore.visitation',
  'relcore.visitation-booth'
]);

relcoreApp
  .factory('plist', function() { return require('plist'); });

relcoreApp
  .factory('xmlButPrettier', function() { return require('xml-but-prettier'); });

relcoreApp
  .factory('dropzone', function() { return require('dropzone'); });

relcoreApp
  .factory('d3', function() { return require('d3'); });

relcoreApp
  .factory('diff2html', function() { return require('diff2html'); });

relcoreApp
  .factory('_', function() { return require('underscore'); });

relcoreApp
  .factory('Redux', function() { return require('redux'); });

// Override the default moment to include timezones. Also put it on the global namespace as 3rd party plugins expect it
relcoreApp
  .constant('moment', moment);
(<any>window).moment = moment;

relcoreApp
  .config([
    '$urlRouterProvider', '$httpProvider', '$locationProvider', '$sceDelegateProvider', '$sceProvider', 'moment',
    function($urlRouterProvider, $httpProvider, $locationProvider, $sceDelegateProvider, $sceProvider, moment) {
    $httpProvider.interceptors.push('httpInterceptor');
    $httpProvider.interceptors.push('apiTokenInterceptor');

    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise("/");

    moment.tz.guess();

    $sceProvider.enabled(false);

    return $sceDelegateProvider.resourceUrlWhitelist([
      // Allow same origin
      'self',
      // Allow backblaze
      'https://*.backblazeb2.com/**'
    ]);
}]);

relcoreApp
  .run(['$rootScope', '$location', '$state', '$cookieStore', 'auth', 'alertService', '$templateCache', '$transitions',
    function($rootScope, $location, $state, $cookieStore, auth, alertService, $templateCache, $transitions) {
      // Override the pager template for ng-table
      $templateCache.put('ng-table/pager.html', require('../tpl/ng-table/pager.html').default);

      $transitions.onStart({}, transition => {
        const to = transition.to();
        const from = transition.from();

        console.log(`Considering transition from ${from.name} -> ${to.name}`);

        if (to !== from) {
          alertService.clearAll();
        }

        // Return if already at the login state or logged in
        if (to.parent === 'anonymous' || auth.loggedIn()) {
          return true;
        }

        console.log(`Not logged in. Attempted to visit ${to.name} from ${(from != null ? from.name : undefined)}`);

        $cookieStore.put('lastState', to.name);
        auth.logout();
        return false;
      });
  }]);

// Set up breadcrumb to be bootstrap 4 compatible
relcoreApp
  .config(['$breadcrumbProvider', ($breadcrumbProvider) => {
    $breadcrumbProvider.setOptions({
      template: require('../tpl/breadcrumb.html').default
    });
  }]);

declare var io:any;
relcoreApp
  .service('socket', ['config', function(config) {
    const socket = io(config.connectService.url);
    socket.on('connect', () => socket.emit('authentication', {token:localStorage.getItem('token')}));

    // Add a helper around authentication
    socket.authenticate = (token) => {
      if (!socket.connected) {
        socket.connect();
      } else {
        socket.emit('authentication', {token});
      }
    };

    return socket;
  }]);

// Initialize Redux store
relcoreApp
  .service('store', ['rootReducer', 'socket', 'Redux', function(rootReducer, socket, Redux) {
    const logger = createLogger();
    const socketMiddleware = reduxSocketIO.default(socket, 'server/');

    const middleware = Redux.applyMiddleware(
      socketMiddleware,
      ReduxThunk,
      logger
    );

    return Redux.createStore(
      rootReducer,
      middleware
    );
  }])
  .service('ReduxMapToScope', ['store', '$timeout', function(store, $timeout) {
    return ($scope, changeHandler) => {
      const stateUnsubscribe = store.subscribe(() => $timeout(() => changeHandler(store.getState())));

      return $scope.$on('$destroy', () => stateUnsubscribe());
    }
  }]);

// Load facilities
relcoreApp
  .run(['$rootScope', 'store', 'facilityActions', 'ReduxMapToScope', 'auth',
    function($rootScope, store, facilityActions, ReduxMapToScope, auth) {
      $rootScope.facilities = [];
      $rootScope.facilitiesTexting = [];
      $rootScope.facilitiesVideoCall = [];

      if (auth.loggedIn()) {
        store.dispatch(facilityActions.load());
      }

      ReduxMapToScope($rootScope, function(state) {
        $rootScope.facilitiesToDisplay = state.root.facilities.filter((f) => f.name.indexOf('Demo') == -1).map((f) => f.id);
        $rootScope.facilities = state.root.facilities;
        $rootScope.facilitiesTexting = state.root.facilities.filter(f => f.featureTexting);
        $rootScope.facilitiesVideoCall = state.root.facilities.filter((f) => f.features.indexOf('Video Calls') > -1);
        $rootScope.$apply();
      });
    }
  ]);
