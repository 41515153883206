angular
  .module('relcore.inmate')
  .directive('inmateSummary', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        showDetails: '='
      },
      template: require('./inmate-summary.html').default
    })
  );
