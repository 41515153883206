angular
  .module('relcore.housing-unit')
  .service('housingUnitService', ['relianceApi', '$filter', function(relianceApi, $filter) {
    return {
      get(id) {
        return relianceApi.get(`/housing-unit/${id}`)
          .then(response => response.data);
      },

      index(page, perPage, orderBy, filter) {
        const params = {orderBy: null};
        for (let i in filter) {
          if (filter[i] != null) { params[i] = filter[i]; }
        }

        if (orderBy != null) {
          params.orderBy = orderBy;
        }

        return relianceApi.get("/housing-unit", params);
      },

      post(housingUnit) {
        return relianceApi.post("/housing-unit", housingUnit);
      },

      put(housingUnit) {
        return relianceApi.put(`/housing-unit/${housingUnit.id}`, housingUnit);
      }
    };
  }]);
