import { IFacility } from '../models/facility';

export const state = {
  url: '/speed-dial/destinations',
  params: {
    facility: null
  },
  views: {
    'Speed Dial@facilities-view': {
      controller: 'FacilityViewSpeedDialDestinationsController',
      template: `
        <speed-dial-destinations
          facility="facility">
        </speed-dial-destinations>
      `
    }
  },
  ncyBreadcrumb: {
    label: 'Speed Dial'
  }
};

export const tab = {
  heading: 'Speed Dial',
  route: 'facilities-view.speed-dial-destinations',
  style: 'fas fa-fw fa-lg fa-bolt'
};

interface IScope extends ng.IScope {
  facility: IFacility
}

angular
  .module('relcore.facility')
  .controller('FacilityViewSpeedDialDestinationsController', [
    '$scope', '$stateParams', 'eventNotificationService', 'inmateService',
    function($scope: IScope, $stateParams) {
      $scope.facility = $stateParams.facility;
    }
  ]);