import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/esm/ButtonToolbar';

// Recursively search an object for an arbitrary string
// const search = (data, val) => {
//   const isObject = o => o && typeof o === 'object';
//   const find = o =>
//     o && (
//       o === val ||
//       o.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1 ||
//       isObject(o) && Object.values(o).some(find)
//     );

//   return data.filter(find);
// };

// Retrieve the data for the requested page
const retrievePage = (data: any[], page, perPage) => {
  const start = (page - 1) * perPage;
  return data.slice(
    start,
    start + perPage
  );
}

type BaseHtmlTableProps = {
  baseKey: string
  page?: number
  perPage?: number
  rows
  columns
  showPagination?: boolean
}

const BaseHtmlTable = (props: BaseHtmlTableProps) => {
  const { rows, columns, page, perPage, baseKey } = props;

  const [currentPage, setCurrentPage] = useState(page || 1);

  // On search change, update the filtered rows. Reset pagination
  // const onSearch = searchText => {
  //   setFiltered(search(rows, searchText))
  //   setCurrentPage(1);
  // }

  // If pagination is requested, split up the data
  const displayData = retrievePage(rows, currentPage, perPage || 500);

  // Update the pagination data
  const pages = Math.ceil(rows.length / (perPage || 500));
  const startRow = ((currentPage - 1) * (perPage || 500)) + 1;
  const endRowMax = (currentPage * (perPage || 500));

  // Dynamically set the height of the table based on the data count, up to a limit of 750px
  let tableHeight = displayData.length * 20;
  if (tableHeight < 150) {
    tableHeight = 150;
  } else if (tableHeight > 750) {
    tableHeight = 750;
  }

  return (
    <div>
      {/* <div className="row">
        <div className="col-lg-2 mb-2">
          <input type="text" className="form-control" placeholder="Search" onChange={e => onSearch(e.target.value)} />
        </div>
      </div> */}

      <table className="table table-sm">
        <thead>
          <tr>
            {columns.map(c =>
              <th key={`${baseKey}-col-${c.key}`} style={c.style}>
                {c.name}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map(r =>
            <tr key={`${baseKey}-row-${r.id}`}>
              {columns.map(c => {
                let cellStyle = r._meta && r._meta.style ? r._meta.style : {};
                if (c.cellStyle && typeof c.cellStyle == 'function') {
                  cellStyle = {...cellStyle, ...c.cellStyle(r)};
                } else {
                  cellStyle = {...cellStyle, ...c.cellStyle};
                }

                // Force wrapping in all cells
                cellStyle['wordBreak'] = 'break-all';

                return (
                  <td
                    key={`${baseKey}-cell-${c.key}-${r.id}`}
                    style={cellStyle}>
                    {Array.isArray(r[c.key]) ?
                      r[c.key].map(i => i) :
                      r[c.key]
                    }
                  </td>
                )
              })}
            </tr>
          )}
        </tbody>
      </table>

      {props.showPagination !== false && rows.length > 0 &&
        <div className="mt-3">
          <span>
            {startRow} - {rows.length > endRowMax ? endRowMax : rows.length} of {rows.length}
          </span>
          {pages > 1 &&
            <ButtonToolbar className="pull-right">
              <ButtonGroup>
                {Array(pages).fill(null).map((_, i) => i+1).map(p =>
                  <Button key={`${baseKey}-page-${p}-${Math.random()*100}`} onClick={() => setCurrentPage(p)} variant={currentPage == p ? 'primary' : 'secondary'}>
                    {p}
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          }
        </div>
      }
    </div>
  )
}

export default BaseHtmlTable;