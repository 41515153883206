angular
  .module('relcore.mobile-device')
  .controller('shippingTicketCancelController', [
    '$scope', '$uibModalInstance', 'relianceApi', 'ticket', function($scope, $uibModalInstance, relianceApi, ticket) {
      $scope.ticket = ticket;
      $scope.model = { notes: '' };
      $scope.cancelingTicket = false;
      $scope.close = () => $uibModalInstance.close({canceled: false});
      $scope.onCancel = function() {
        $scope.cancelingTicket = true;
        relianceApi.delete(`/shipping-ticket/${$scope.ticket.id}?notes=${$scope.model.notes}`)
          .then((function() {
            $scope.cancelingTicket = false;
            $uibModalInstance.close({canceled: true});
          }), (err => $scope.cancelingTicket = false));
      };
    }
  ]);
