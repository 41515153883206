angular
  .module('relcore.api-role')
  .service('apiRoleService', ['relianceApi',
    function(relianceApi) {
      const retrieveAll = (callback) => {
        relianceApi.index('/api-role')
          .then((response) => {
            callback(response.data);
          }
        );
      };

      return {
        retrieveAll
      }
    }
  ]);