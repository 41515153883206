angular
  .module('relcore.inmate')
  .controller('InmateActivityPhoneCardReverseController', [
    '$scope', '$uibModalInstance', 'inmateActivity', 'relianceApi',
    function($scope, $uibModalInstance, inmateActivity, relianceApi) {
      $scope.inmateActivity = inmateActivity;
      $scope.model = {
        description: ''
      };

      $scope.reversing = false;
      $scope.close = () => $uibModalInstance.close({reversed: false});
      $scope.submit = function() {
        $scope.reversing = true;
        relianceApi.delete(`/inmate-account/activity/${inmateActivity.id}?description=${$scope.model.description}`)
          .then((function() {
            $scope.reversing = false;
            $uibModalInstance.close({true: false});
          }), (err => $scope.reversing = false));
      };
    }
  ]);
