import { ITaxRate } from "./tax-service";

interface ITaxRateScope extends ng.IScope {
  showModalEdit: (taxRate: ITaxRate) => void;
}

angular
  .module('relcore.tax')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('tax-rates', {
        controller: 'TaxRatesController',
        template: require('./main.html').default,
        url: '/tax/rates',
        parent: 'authenticated',
        ncyBreadcrumb: { label: 'Tax Rates' }
      })
    }
  ])
  .controller('TaxRatesController', ['$scope', '$uibModal', ($scope: ITaxRateScope, $uibModal) => {
    $scope.showModalEdit = (taxRate: ITaxRate) => {
      const dialog = $uibModal.open({
        size: 'md',
        template: require('./tax-rate-edit.html').default,
        controller: 'TaxRateEditController',
        resolve: {
          taxRate() { return taxRate; }
        }
      });
      dialog.result.then(() => {
        $scope.$broadcast('taxRateChange');
      });
    };
  }]);