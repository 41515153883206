angular
  .module('relcore.inmate')
  .directive('inmateActivityAdd', () =>
    ({
      restrict: 'E',
      scope: {
        inmate: '=',
        adding: '=',
        onAdd: '='
      },
      controller: ['$scope', function($scope) {
        $scope.activity = {
          inmateId: $scope.inmate.id,
          amount: '',
          chargeRental: true,
          notifyInmate: false
        };
        $scope.add = () => $scope.onAdd($scope.activity);
      }
      ],
      template: require('./inmate-activity-add.html').default
    })
  );
