import { Facility } from '@reliance/types/models/Facility/Facility';
import { VisitationBooth } from '@reliance/types/models/VisitationBooth/VisitationBooth';
import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { react2angular } from 'react2angular';
import { IPaginatedResponse } from '../common/reliance-api-service';
import BaseHtmlTable from '../table/BaseHtmlTable';

type TableProps = {
  facility?: Facility
  visitationBooths: IPaginatedResponse<VisitationBooth>
  onEdit(booth: VisitationBooth): void
}

const Table: React.FC<TableProps> = (props) => {
  const { visitationBooths, facility } = props;

  if (visitationBooths == null) {
    return null;
  }

  let columns = [
    { key: 'facility', name: 'Facility' },
    { key: 'name', name: 'Name' },
    { key: 'stations', name: 'Stations' },
    { key: 'recordVisitations', name: 'Record Visitations' },
    { key: 'options', name: 'Options' }
  ];

  // If we were sent a specific facility, we don't need that column
  if (facility) {
    columns = columns.filter(c => c.key != 'facility');
  }

  // Massage the records into applicable/usable columns
  const rows = visitationBooths.data.map(booth => ({
    id: booth.id,
    name: booth.name,
    facility: `${(booth.facility || facility).name} (${(booth.facility || facility).city} (#${(booth.facility || facility).state})`,
    stations: (
      <div>
        {booth.stations.map(s =>
         <span key={`station-${s.id}`}><a href={`/station/view/${s.id}`}>{s.name}</a> </span>
        )}
      </div>
    ),
    recordVisitations: booth.recordVisitations ? 'Yes': 'No',
    options: (
      <div>
        <Button onClick={() => props.onEdit(booth)} size="sm" variant="outline-primary">Edit</Button>
      </div>
    )
  }));

  return (
    <BaseHtmlTable
      baseKey="visitation-booth"
      columns={columns}
      rows={rows}
      />
  );
}

angular
  .module('relcore.visitation-booth')
  .component('visitationBoothTable', react2angular(Table, ['facility', 'visitationBooths']));

export default Table;