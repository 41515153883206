angular
  .module('relcore.switch')
  .service('ValidationLogService',
    ['$http', 'relianceApi', 'ValidationLog', function($http, relianceApi, ValidationLog) {
      const getAll = function(filter, orderBy, page, perPage, callback) {
        let url = `/switch/log?page=${page}&perPage=${perPage}`;

        if (orderBy != null) {
          url += `&orderBy=${encodeURIComponent(orderBy)}`;
        }

        if (filter.calledNumber != null) {
          url += `&ani=${filter.calledNumber}`;
        }
        if (filter.card != null) {
          url += `&card=${filter.card}`;
        }
        if (filter.facility != null) {
          url += `&facility=${filter.facility.id}`;
        }
        if (filter.validationStatus != null) {
          url += `&status=${filter.validationStatus}`;
        }
        if (filter.validationReason != null) {
          url += `&reason=${filter.validationReason}`;
        }
        if ((filter['created.date'] != null ? filter['created.date'].startDate : undefined) != null) {
          url += `&startDate=${filter['created.date'].startDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }
        if ((filter['created.date'] != null ? filter['created.date'].endDate : undefined) != null) {
          url += `&endDate=${filter['created.date'].endDate.format('YYYY/MM/DD HH:mm:ss')}`;
        }

        return relianceApi.get(url)
          .then(function(results) {
            const { data } = results;
            const logs = [];
            for (let log of Array.from(data.results)) {
              logs.push(new ValidationLog(log));
            }
            if (callback != null) { return callback(null, logs, data.total); }
        });
      };

      return {getAll};
    }
  ]);