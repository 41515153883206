angular
  .module('relcore.housing-unit')
  .directive('housingUnitAdd', () =>
    ({
      restrict: 'E',
      template: require('./housing-unit-add.html').default,
      scope: {
        mobileDeviceGroups: '=',
        facilities: '=',
        facility: '=?',
        adding: '=',
        onAdd: '='
      },
      controller: ['$scope', function($scope) {
        $scope.unit = { status: 'Active', facilityId: ($scope.facility != null ? $scope.facility.id : undefined) };
        $scope.add = () => $scope.onAdd($scope.unit);
      }
      ]
    })
  );
