import React from 'react';

type CityStateZipData = {
  city: string
  state: string
  zip: string
}

type CityStateZipProps = {
  value: CityStateZipData
}

export function formatCityStateZip(value: CityStateZipData) {
  if (!value || (value.city == null && value.state == null && value.zip == null)) {
    return '';
  }

  return `${value.city}, ${value.state} ${value.zip}`;
}

const CityStateZip: React.FC<CityStateZipProps> = (props) => {
  return <>{formatCityStateZip(props.value)}</>;
}

export default CityStateZip;