import { IApiResponse, IStatusResponse } from '../common/reliance-api-service';
import { IConfiguration } from './configuration';
import { IConfigurationService } from './configuration-service';

interface IConfigurationScope {
  editableConfiguration: IConfiguration
  saving: boolean
  deleting: boolean
  error: string
  key: string
  value: string

  add(): void
  update(): void
  delete(): void
  close(): void
}

angular
  .module('relcore.facility')
  .controller('FacilityConfigController', ['$scope', '$uibModalInstance',
    'configurationService', 'facilityId', 'configuration',
    ($scope: IConfigurationScope, $uibModalInstance, configurationService: IConfigurationService,
     facilityId: number, configuration: IConfiguration) => {

      $scope.editableConfiguration = {...configuration};
      $scope.saving = false;
      $scope.deleting = false;

      $scope.add = () => {
        $scope.saving = true;
        configurationService.postNewConfiguration(facilityId, $scope.key, $scope.value)
          .then(resolve)
          .catch(reject);
      };

      $scope.update = () => {
        $scope.saving = true;
        const changes = {value: $scope.editableConfiguration.value, key: $scope.editableConfiguration.key};
        configurationService.updateConfiguration($scope.editableConfiguration.id, changes)
          .then(resolve)
          .catch(reject);
      };

      $scope.delete = () => {
        $scope.deleting = true;
        configurationService.deleteConfiguration($scope.editableConfiguration.id)
          .then(resolve)
          .catch(reject)
      };

      const resolve = (response: IApiResponse<IStatusResponse>) => {
        $scope.saving = false;
        $scope.deleting = false;
        if (!response.data.success) {
          $scope.error = 'An unexpected error occurred';
        } else {
          $scope.error = null;
          $uibModalInstance.close({saved: true});
        }
      };

      const reject = (err: any) => {
        $scope.saving = false;
        $scope.deleting = false;
        if (err.status === 400) {
          $scope.error = 'Please verify the inputs are valid and try again';
        } else {
          $scope.error = 'An unexpected error occurred';
        }
      };

      $scope.close = () => $uibModalInstance.close({ saved: false });
  }]);