import { IRelianceApi } from '../common/reliance-api-service';

angular
  .module('relcore.call')
  .service('callService',
    ['relianceApi', 'Call',
    function(relianceApi: IRelianceApi, callModel) {
      // A page value of -1 indicates to the backend not to send paginated results.
      const getByAni = function(ani: string, filter?: any, page: number = -1, perPage: number = 100, orderBy?: string) {
        let url = `/call/by-account/${ani}?page=${page}&perPage=${perPage}`;

        if (orderBy != null) {
          url += `&orderBy=${encodeURIComponent(orderBy)}`;
        }

        if (filter) {
          if ((filter['call.date'] != null ? filter['call.date'].startDate : undefined) != null) {
            url += `&startDate=${filter['call.date'].startDate.format('YYYY/MM/DD HH:mm:ss')}`;
          }
          if ((filter['call.date'] != null ? filter['call.date'].endDate : undefined) != null) {
            url += `&endDate=${filter['call.date'].endDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')}`;
          }
          if (filter.duration != null) {
            url += `&duration=${filter.duration}`;
          }
          if (filter.from != null) {
            url += `&from=${filter.from}`;
          }
          if (filter.facility != null) {
            url += `&facility=${filter.facility}`;
          }
          if (filter.charge != null) {
            url += `&requestedBy=${filter.requestedBy}`;
          }
          if (filter.type != null) {
            url += `&type=${filter.type}`;
          }
        }

        return relianceApi.get(url)
          .then(function(result: any) {
            const { data } = result;
            const calls = [];
            const results = data.results ? data.results : data;
            for (let call of Array.from(results)) {
              calls.push(new callModel(call));
            }
            data.results = calls;
            return data;
        });
      };

      return {
        getByAni
      };
    }
  ]);