angular
  .module('relcore.api-role')
  .service('apiRoleViewActions', ['relianceApi', function(relianceApi) {
    const clear = () => {
      return { type: 'api-role/view/clear' }
    };

    const setAPIRole = (apiRole) => {
      return { type: 'api-role/view/set-api-role', apiRole };
    };

    const loadAPIRole = (id) => {
      return dispatch => {
        dispatch({ type: 'api-role/view/loading' });
        return relianceApi.get(`/api-role/${id}`)
          .then(
            (res => {let obj = normalizeJSON(res.data); dispatch(setAPIRole(obj))}),
            (err => dispatch({ type: 'api-role/view/loading-failed', error: err }))
          );
      }
    };

    const normalizeJSON = (data) => {
      let obj = Object.assign({}, data);
      try {
        obj = JSON.parse(data);
      } catch (error) {
        let permissions = JSON.parse(data.permissions);
        obj.permissions = permissions;
      }
      return obj;
    };

    return {
      clear,
      setAPIRole,
      loadAPIRole
    };
  }]);
