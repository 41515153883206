angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('mobile-device-purchases', {
      controller: 'MobileDevicePurchasesController',
      template: require('./main.html').default,
      url: '/mobile-device-purchase',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Mobile Device Purchases' }
    })

  ])
  .controller('MobileDevicePurchasesController', [
    '$rootScope', '$scope', '$state', 'relianceApi', 'alertService', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, alertService, NgTableParams) {
      $rootScope.title = 'Mobile Device Purchases';
      $scope.adding = false;

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        sorting: {
          'dateOrdered': 'desc'
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          return relianceApi.index('/mobile-device-purchase', params.page(), params.count(), params.filter(), params.orderBy())
            .then(function(response) {
              params.total(response.data.total);
              return response.data.data;
            });
        }
      });

      $scope.onAdd = function(purchase) {
        $scope.adding = true;
        return relianceApi.post('/mobile-device-purchase', purchase)
          .then(function() {
            $scope.adding = false;
            alertService.success("The purchase was successfully recorded");
            purchase = {};
            $scope.gridOptions.reload();
          });
      };

      $scope.onViewDetails = purchase => $state.go('mobile-device-purchase-view', {id:purchase.id, purchase});
    }
  ]);
