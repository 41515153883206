import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { resolveEntity, resolveUserName } from '../format/ApiLog';
import { formatDate } from '../format/DateTime';
import { formatJson } from '../format/Json';
import { ApiLog } from '../models/ApiLog';
import BaseHtmlTable from '../table/BaseHtmlTable';
import ChangesTable from './ChangesTable';

type DetailsModalProps = {
  log: ApiLog
  onClose(): void
}

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const { log, onClose } = props;

  const columns = [
    { key: 'date', name: 'Date' },
    { key: 'user', name: 'User' },
    { key: 'ipAddress', name: 'IP Address' },
    { key: 'entity', name: 'Entity' },
    { key: 'method', name: 'Method' },
    { key: 'endpoint', name: 'Endpoint' }
  ];

  const rows = [{
    ...log,
    date: formatDate(log.created.date),
    user: resolveUserName(log),
    entity: `${resolveEntity(log)} (${log.entityId})`
  }];

  return (
    <Modal show={true} onHide={onClose} dialogClassName="modal-full">
      <Modal.Header>
        <Modal.Title>{log.description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <BaseHtmlTable
              baseKey="api-log-detail"
              columns={columns}
              rows={rows}
              showPagination={false}
              />
          </Col>
        </Row>
        {log.operation != 'delete' &&
          <Row>
            <Col>
              <ChangesTable
                log={log}
                />
            </Col>
          </Row>
        }
        {false && log.operation != 'delete' && // Disabled this after adding the above change table
          <Row>
            <Col>
              <pre className="wrap" style={{ fontSize: 10 }}>
                {formatJson(log.json)}
              </pre>
            </Col>
          </Row>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="default"
          type="button"
          onClick={props.onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetailsModal;