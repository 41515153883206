angular
  .module('relcore.actions')
  .service('serverActions', function() {
    return {
      subscribe: (room) => {
        return { type: 'server/subscribe', room }
      },

      unsubscribe: (room) => {
        return { type: 'server/unsubscribe', room }
      }
    };
  });
