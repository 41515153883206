// Hacky legacy migration from original app.js
angular
  .module('relcore.directives')
  .directive('relMenu', function() {
    const adjustNavHeight = function() {
      const contentHeight = $('ui-view').height();
      const windowHeight = $(window).height() + $.navbar_height;

      if (contentHeight > windowHeight) { // if content height exceeds actual window height and menuHeight
        $.left_panel.css('min-height', contentHeight + 'px');
        return $.root_.css('min-height', contentHeight + $.navbar_height + 'px');
      } else {
        $.left_panel.css('min-height', windowHeight + 'px');
        return $.root_.css('min-height', windowHeight + 'px');
      }
    };

    return {
      link: (scope, element, attrs) => {
        $(element).jarvismenu({
          accordion : true,
          speed : $.menu_speed,
          closedSign : '<em class="fa fa-caret-down"></em>',
          openedSign : '<em class="fa fa-caret-up"></em>'
        });

        adjustNavHeight();

        // COLLAPSE LEFT NAV
        $('.minifyme').click(function(e) {
          $('body').toggleClass("minified");
          $(element).effect("highlight", {}, 500);
          return e.preventDefault();
        });

        // HIDE MENU
        return $('#hide-menu >:first-child > a').click(function(e) {
          $('body').toggleClass("hidden-menu");
          return e.preventDefault();
        });
      }
    };
});