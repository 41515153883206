angular
  .module('relcore.directives')
  .directive('smartXEditable', ['$timeout', '$log', ($timeout, $log) =>
    ({
      restrict: 'A',
      require: "ngModel",
      scope: {
        options: "="
      },
      link(scope:any, element:any, attrs, ngModel) {
        const defaults = {};
        const initXeditable = function() {
          const options = scope.options || {};
          const initOptions = angular.extend(defaults, options);

          element.editable('destroy');
          element.editable(initOptions);
        };

        scope.$watch("options", (function(newValue) {
          if (!newValue) {
            return;
          }

          initXeditable();
        }), true);
      }
    })
  ]);