import { Action, Dispatch } from "redux";
import { IUser } from '../../models/auth';
import { IUserService } from "../../user/user-service";

export interface ILegacyUserAction extends Action {
  load(): Promise<Dispatch<ILegacyUserAction>>
  legacyUsers?: Array<IUser>
  error?: string
}

angular
  .module('relcore.user')
  .service('legacyUserActions', ['UserService', function(UserService: IUserService) {
    return {
      load: () => {
        return async (dispatch: Dispatch<ILegacyUserAction>) => {
          dispatch({ type: 'legacy-users/loading' });

          return UserService.getUsers()
            .then((users: Array<IUser>) => {
              return dispatch({ type: 'legacy-users/loaded', legacyUsers: users });
            })
            .catch((err: any) => {
              return dispatch({ type: 'legacy-users/load-failed', error: err });
            });
        };
      }
    };
  }]);
