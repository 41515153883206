import { IPhoneTrackerListState } from './list';

export interface IPhoneTrackerState {
  list: IPhoneTrackerListState
}

angular
  .module('relcore.reducers')
  .service('phoneTrackerReducer', ['phoneTrackerListReducer', 'Redux', function(phoneTrackerListReducer, Redux) {
    return Redux.combineReducers({
      list: phoneTrackerListReducer
    });
  }]);
