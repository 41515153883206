import { IApiResponse, IPaginatedResponse, IRelianceApi } from '../common/reliance-api-service';
import { IMobileDeviceGroup } from '../mobile-device-group/mobile-device-group';

angular
  .module('relcore.facility')
  .controller('FacilityViewHousingUnitsController',
    ['$scope', 'alertService', 'relianceApi', 'NgTableParams', '$stateParams', '$uibModal', '$state', 'housingUnitService',
    function($scope, alertService, relianceApi:IRelianceApi, NgTableParams, $stateParams, $uibModal, $state, housingUnitService) {
      $scope.loadMobileDeviceGroups = function() {
        $scope.loadingMobileDeviceGroups = true;
        $scope.mobileDeviceGroups = [];
        relianceApi
          .index<IApiResponse<IPaginatedResponse<IMobileDeviceGroup>>>('/mobile-device/group', 1, 200, {
            facilityId: $scope.facility.id
          })
          .then(res => $scope.mobileDeviceGroups = res.data.data)
          .then(() => $scope.loadingMobileDeviceGroups = false);
      }

      $scope.loadHousingUnits = function(refresh) {
        if (refresh == null) { refresh = false; }
        if (!refresh && ($scope.housingUnitsGridOptions != null)) { return; }

        $scope.housingUnitsGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: { 'name': 'asc' },
          filter: { facilityId: $scope.facility.id }
        }, {
          counts: [100,200,500],
          getData(params) {
            $scope.onFetch('Housing Units');
            return relianceApi.index('/housing-unit', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(res:IApiResponse<IPaginatedResponse<any>>) {
                  params.total(res.data.total);
                  $scope.onFetched('Housing Units');
                  return res.data.data;
                },
                function(err) {
                  $scope.onError('Housing Units');
                  alertService.error('An error occurred while loading housing units', true);
                }
              );
          }
        });
      };

      $scope.loadHousingUnits();
      $scope.loadMobileDeviceGroups();

      $scope.view = housingUnit => $state.go('housing-units-view', {id:housingUnit.id, housingUnit});

      $scope.adding = false;
      $scope.onAdd = function(housingUnit) {
        const onSuccess = () =>
          $scope.gridOptions.reload()
            .then(function() {
              $scope.adding = false;
              alertService.success('The housing unit was successfully added', true);
            })
        ;

        const onError = function() {
          $scope.adding = false;
          alertService.error('An error occurred while adding the housing unit', true);
        };

        $scope.adding = true;
        housingUnitService
          .post({
            ...housingUnit,
            mobileDeviceGroup: undefined,
            mobileDeviceGroupId: housingUnit.mobileDeviceGroup ? housingUnit.mobileDeviceGroup.id : null
          })
          .then(onSuccess, onError);
      };
    }
  ]);
