angular
  .module('relcore.mobile-device')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('mobile-devices', {
        controller: 'MobileDeviceListController',
        template: require('./list.html').default,
        url: '/mobile-devices',
        parent: 'authenticated',
        ncyBreadcrumb: { label: 'Mobile Devices' }
      })

  ])
  .controller('MobileDeviceListController',
    ['$rootScope', '$scope', '$filter', 'facilityService', 'mobileDeviceService', 'alertService', 'NgTableParams', '$state',
      function($rootScope, $scope, $filter, facilityService, mobileDeviceService, alertService, NgTableParams, $state) {
        $rootScope.title = 'Mobile Devices';
        $scope.selected = [];
        $scope.changes = {};
        $scope.changes.updateStatus = '';
        $scope.changes.updateFacility = '';
        $scope.changes.updateApplication = '';

        facilityService.retrieveAll((err, facilities) =>
          $scope.facilities = facilities.filter(f => f.featureTexting)
        );

        $scope.listGridOptions = new NgTableParams({
            page: 1,
            count: 100,
            sorting: {
              dateAdded: 'desc'
            },
            filter: {
              includeInmates: true,
              dateAdded: { startDate: null, endDate: null },
              facilityIds: $rootScope.facilitiesToDisplay
            }
          }, {
            getData(params) {
              return mobileDeviceService.retrieveAll(params.page(), params.count(), params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.selected = [];
                  return result.data;
              });
            }
          });

        // Update selected devices
        $scope.updateSelected = function() {
          const changes:any = {};
          if ($scope.changes.updateStatus !== '') { changes.status = $scope.changes.updateStatus; }
          if ($scope.changes.updateFacility !== '') { changes.facilityId = parseInt($scope.changes.updateFacility); }
          if ($scope.changes.updateApplication !== '') { changes.application = $scope.changes.updateApplication; }
          const ids = ($scope.selected.map(s => s.id));
          mobileDeviceService.update(ids, changes)
            .then(
              (function() {
                alertService.success('Device(s) successfully updated', true);
                $scope.listGridOptions.reload();
                $scope.updateStatus = '';
                $scope.updateFacility = '';
                $scope.updateApplication = '';
                $scope.selected = [];
              }),
              (() => alertService.error('An error occurred while updating the device(s)', true))
            );
        };

        // Go to details view
        $scope.details = device => $state.go('mobile-device-view', {id:device.id, device});

        // Updates the device's app(s)
        $scope.updateApps = device =>
          mobileDeviceService.updateApps([device.id])
            .then(
              (function(result) {
                const { data } = result;

                if (data.updatingDeviceIds.length === 1) {
                  alertService.success('Device will update momentarily', true);
                } else if (data.upToDateDeviceIds.length === 1) {
                  alertService.success('Device is up-to-date', true);
                } else {
                  alertService.error('The device could not be updated at this time', true);
                }
              }),
              (() => alertService.error('An error occurred while requesting an update', true))
            )
        ;

        // Push the config to the device again
        $scope.poll = device =>
          mobileDeviceService.pushConfig(device.id)
            .then(
              (() => alertService.success('Device will update momentarily', true)),
              (() => alertService.error('An error occurred while pushing configuration', true))
            )
        ;
      }
  ]);
