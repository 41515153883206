import { TaxTable } from '../models/TaxTable';

angular
  .module('relcore.filters')
  .filter('taxTableDescription', () =>
    function(taxTable: TaxTable) {
      if (taxTable == null) {
        return '';
      }

      return `${taxTable.entityName} (${(taxTable.rate * 100).toFixed(2)}%)`;
    }
);
