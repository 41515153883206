angular
  .module('relcore.reducers')
  .service('dashboardReducer', function() {
    var initialState = {
      recentTexts: [],
      textsCountByFacility: {}
    };

    return (state = initialState, action) => {
      switch (action.type) {
        case 'text-message/inbound':
          state.recentTexts.unshift(action.data)

          var countsForFacility = state.textsCountByFacility[action.data.facility] || {
            Inbound: 0,
            Outbound: 0
          };
          state.textsCountByFacility[action.data.facility] = countsForFacility
          state.textsCountByFacility[action.data.facility][action.data.direction]++;

          return {
            ...state,
            recentTexts: state.recentTexts.slice(0, 100),
            textsCountByFacility: state.textsCountByFacility
          };

        case 'text-message/outbound':
          state.recentTexts.unshift(action.data)

          var countsForFacility = state.textsCountByFacility[action.data.facility] || {
            Inbound: 0,
            Outbound: 0
          };
          state.textsCountByFacility[action.data.facility] = countsForFacility
          state.textsCountByFacility[action.data.facility][action.data.direction]++;

          return {
            ...state,
            recentTexts: state.recentTexts.slice(0, 100),
            textsCountByFacility: state.textsCountByFacility
          };
      };

      return state
    };
  });
