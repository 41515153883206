angular
  .module('relcore.condition')
  .controller('ConditionRemoveController',
    ['$scope', '$uibModalInstance', 'conditionService', 'condition',
    function($scope, $uibModalInstance, conditionService, condition) {
      $scope.condition = condition;
      $scope.condition.removedDescription = '';

      $scope.cancel = () => $uibModalInstance.close();

      $scope.removing_condition = false;
      $scope.remove = () => {
        $scope.removing_condition = true;
        conditionService.remove($scope.condition)
          .then(() => {
            $scope.removing_condition = false;
            // Refresh the conditions before leaving
            return conditionService.getByAni($scope.condition.ani)
              .then($uibModalInstance.close);
          });
      }
    }
  ]);