angular
  .module('relcore.station')
  .directive('stationSummary', () =>
    ({
      restrict: 'E',
      scope: {
        station: '=',
        showDetails: '='
      },
      template: require('./station-summary.html').default
    })
  );
