angular
  .module('relcore.inmate')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('inmates', {
        controller: 'InmateListController',
        template: require('./list.html').default,
        url: '/inmates',
        parent: 'authenticated',
        ncyBreadcrumb: { label: 'Inmates' }
      })
  ])
  .controller('InmateListController', ['$scope', '$rootScope', '$state', 'relianceApi', 'facilityService', 'NgTableParams', '$filter', 'moment',
    function($scope, $rootScope, $state, relianceApi, facilityService, NgTableParams, $filter, moment) {
      $rootScope.title = 'Inmates';
      $scope.inmates = [];

      $scope.listGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            dateAdded: 'desc'
          },
          filter: {
            dateAdded: {startDate: moment().subtract(10, 'year'), endDate: moment().endOf('day')},
            facilityIds: $rootScope.facilitiesToDisplay
          }
        }, {
          counts: [50,100,500],
          getData(params) {
            return relianceApi.index('/inmate', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                let data;
                const result = response.data;
                for (data of Array.from(result.data)) {
                  data.fullName = `${data.firstName} ${data.middleName} ${data.lastName}`;
                  if (data.facility != null) {
                    data.facilityTitle = $filter('facilityTitle')(data.facility);
                  }
                }
                params.total(result.total);
                $scope.selected = [];
                return result.data;
            });
          }
        });

      $scope.onDetails = inmate => $state.go('inmates-view', {id:inmate.id, inmate});
    }
  ]);
