import { composeValidators } from '@reliance/forms-js/lib/validation';
import Form from 'react-bootstrap/esm/Form';
import { Field } from 'react-final-form';
import React = require('react');

export enum InputType {
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
}

export const Input = (props: {
  name: string,
  placeholder?: string,
  label?: string|JSX.Element,
  validationRules?: any[],
  type?: InputType,
  readOnly?: boolean
}) =>
  <Form.Group>
    <label>{props.label}</label>
    <Field
      name={props.name}
      validate={composeValidators(props.validationRules || [])}>
      {({ input, meta }) => (
        <>
          <Form.Control
            {...input}
            readOnly={props.readOnly === true}
            placeholder={props.placeholder}
            isInvalid={meta.error && meta.touched} />
            {meta.error && meta.touched &&
              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            }
        </>
      )}
    </Field>
  </Form.Group>

export const Select = (props: {
  name: string,
  placeholder?: string,
  label?: string,
  validationRules?: any[],
  readOnly?: boolean,
  options: {value: string, text: string}[],
  disabled?: boolean
}) =>
  <Form.Group>
    <label>{props.label}</label>
    <Field
      name={props.name}
      validate={composeValidators(props.validationRules || [])}>
      {({ input, meta }) => (
        <>
          <Form.Control
            {...input}
            as="select"
            readOnly={props.readOnly === true}
            placeholder={props.placeholder}
            isInvalid={meta.error && meta.touched}
            disabled={props.disabled == true}
            className="form-control">
            <option />
            {props.options.map(o =>
              <option key={`${props.name}-${o.value}`} value={o.value}>{o.text}</option>
            )}
          </Form.Control>
          {meta.error && meta.touched &&
            <Form.Control.Feedback type="invalid">
              {meta.error}
            </Form.Control.Feedback>
          }
        </>
      )}
    </Field>
  </Form.Group>