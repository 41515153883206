import { composeValidators } from '@reliance/forms-js/lib/validation';
import React from 'react';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { Field } from 'react-final-form';

type SelectProps = {
  name: string
  label: string
  id?: string
  placeholder?: string
  validationRules?: any[]
  helperText?: string
}

export const Select: React.FC<SelectProps> = (props) =>
  <Field
    name={props.name}
    validate={composeValidators(props.validationRules || [])}>
    {({ input, meta }) => (
      <FormGroup>
        <label>{props.label}</label>

        <Field
          id={props.id}
          className={`form-control ${meta.touched && meta.error ? 'has-error' : ''}`}
          component="select"
          disabled={meta.submitting}
          {...input}>
          {props.children}
        </Field>
        {((meta.error && meta.touched) || props.helperText) &&
          <span className="help-block has-error">{meta.error && meta.touched ? meta.error : props.helperText}</span>
        }
      </FormGroup>
    )}
  </Field>