import { IAuthService } from '../auth/auth-service';
import { ITokenResponse } from '../models/auth';

angular
  .module('relcore.auth')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('login', {
        template: require('./login.html').default,
        url: '/login',
        parent: 'anonymous',
        controller: 'LoginController'
      }
    )
  ])
  .controller('LoginController',
    ['$scope', '$rootScope', '$state', '$location', '$cookieStore', 'auth', 'alertService', 'socket', '$http', 'store', 'facilityActions', 'config',
    function($scope, $rootScope, $state, $location, $cookieStore, auth:IAuthService, alertService, socket, $http, store, facilityActions, config) {
      $rootScope.title = 'Login';
      $scope.formData = {emailAddress: '', password: '', loggingIn: false};

      const successHandler = function(data:ITokenResponse) {
        $scope.formData.loggingIn = false;
        if (data.data.error != null) { return errorHandler(data); }

        console.log(`Login was successful for: ${data.data.staffUser.emailAddress}`);
        auth.storeLogin(data);

        // Authenticate with socket.io
        socket.authenticate(data.data.access_token);

        // Load the facility list
        store.dispatch(facilityActions.load());

        const lastLocation = $cookieStore.get('lastLocation');
        if ((lastLocation != null) && (lastLocation !== "/login")) {
          console.log(`Transferring to the last state: ${lastLocation}`);
          $cookieStore.remove('lastLocation');
          $location.path(lastLocation);
        } else {
          console.log('Transferring to the default state');
          $state.go('relcore');
        }
      };

      const errorHandler = function(err) {
        $scope.formData.loggingIn = false;

        if (err.error === 'invalid_client') {
          alertService.error('Credentials are invalid', true);
        } else {
          alertService.error('Error while logging in', true);
        }
      };

      $scope.login = function(form) {
        if (!form.$valid) { return; }

        $scope.formData.loggingIn = true;
        auth.login($scope.formData.emailAddress, $scope.formData.password)
          .then((data => successHandler(data)), errorHandler);
      };

      $scope.resettingPassword = false;
      $scope.resetPassword = (form) => {
        $scope.resettingPassword = true;

        return $http.post(`${config.api.url}/staff-user/password/reset`, { emailAddress: $scope.formData.emailAddress })
          .then(
            (res => {
              $scope.resettingPassword = false;
              alertService.success("An email was sent to reset your password", true)
            }),
            (err => {
              alertService.error('An error occurred while requesting a password reset email', true);
              $scope.resettingPassword = false;
            })
          );
      };
    }
  ]);
