import * as React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Card from 'react-bootstrap/esm/Card';
import { react2angular } from 'react2angular';
import api from '../common/api';
import { useStringTranslations } from '../hooks/StringTranslation';
import { StringTranslation } from '../models/StringTranslation';
import AddModal from './AddModal';
import EditModal from './EditModal';
import { List } from './List';

const Translations: React.FC = (props) => {
  const translations = useStringTranslations();
  const [showAddModal, setShowAddModal] = useState(false);
  const [editTranslation, setEditTranslation] = useState<StringTranslation>(null);

  return (
    <Card className="mt-3">
      <Card.Header>Translations</Card.Header>
      <Card.Body>
        <Button
          className="mb-3"
          onClick={() => setShowAddModal(true)}>
          Add Phrase
        </Button>
        {showAddModal &&
          <AddModal
            onSave={(data) =>
              api.post('/translation', {...data, languageString: data.englishString})
                .then(() => translations.execute())
                .then(() => setShowAddModal(false))
            }
            onClose={() => setShowAddModal(false)}
            />
        }
        {editTranslation &&
          <EditModal
            translation={editTranslation}
            onSave={(data) =>
              api.put(`/translation/${editTranslation.id}`, data)
                .then(() => translations.execute())
                .then(() => setEditTranslation(null))
            }
            onClose={() => setEditTranslation(null)}
            />
        }
        <List
          onEdit={setEditTranslation}
          translations={translations.result?.data || []}
          />
      </Card.Body>
    </Card>
  );
};

angular
  .module('relcore.translation', [])
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('translations', {
        component: 'translations',
        url: '/translations',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Translations'
        }
      }
    )
  ])
  .component('translations', react2angular(
    Translations
  ));