angular
  .module('relcore.station')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('stations', {
        controller: 'StationListController',
        url: '/stations',
        parent: 'authenticated',
        abstract: true,
        ncyBreadcrumb: { label: 'Stations' }
      })
  ])
  .controller('StationListController',
    ['$scope', '$uibModal', '$filter', 'stationService', 'NgTableParams','$state', 'alertService', 'Station',
    function($scope, $uibModal, $filter, stationService, NgTableParams, $state, alertService, StationModel) {
      $scope.newStation = new StationModel;
      $scope.newStation.type = "Inmate";
      $scope.newStation.status = "Active";
      $scope.newStation.tollAllow= "False";

      $scope.add = function() {
        $scope.newStation.facility = $scope.facility;
        const result = stationService.postNewStation($scope.newStation);

        const successCallback = function() {
          alertService.success("New station added successfully", true);
          $scope.stationGridOptions.reload();
        };

        const errorCallback = function(response) {
          if (response.status === 503) {
            alertService.warning("New station was added but site cache could not be cleared");
            $scope.stationGridOptions.reload();
          } else {
            alertService.error("An error occurred while adding new station", true);
          }
        };

        return result.then(successCallback, errorCallback);
      };
    }
  ]);
