import { IApiResponse } from '../../common/reliance-api-service';
import { IFacility } from '../../models/facility';

interface IFacilitySummary {
  deviceCount: number
  kioskDeviceCount: number
  iPodDeviceCount: number
  inmateCount: number
  assignedCount: number
  inventoryCount: number
  inTransitCount: number
  missingCount: number
  deviceUtilization: any
  facility: IFacility
}

angular
  .module('relcore.report')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('text-message-facility-summary-report', {
        controller: 'TextMessageFacilitySummaryController',
        url: '/report/text-message/facility-summary',
        parent: 'authenticated',
        ncyBreadcrumb: {
          label: 'Texting Facility Summary Report',
          parent: 'authenticated'
        },
        template: require('./facility-summary.html').default
      }
    )
  ])
  .controller('TextMessageFacilitySummaryController',
    ['$rootScope', '$scope', 'relianceApi', 'statesByAbbreviation',
    function($rootScope, $scope, relianceApi, statesByAbbreviation) {
      $rootScope.title = 'Texting Facility Summary Report';

      $scope.loading = true;
      return relianceApi.get("/report/text-message/facility-summary")
        .then(function(response: IApiResponse<IFacilitySummary[]>) {
          $scope.loading = false;

          let data = response.data;
          $scope.data = data;

          // Group by state
          const grouped = {};
          const states = [];
          let d:any;
          for (d of Array.from($scope.data)) {
            const state = statesByAbbreviation[d.facility.state];
            if (states.indexOf(state.name) === -1) { states.push(state.name); }
            if ((grouped[state.name] == null)) { grouped[state.name] = []; }
            grouped[state.name].push(d);
          }
          $scope.grouped = grouped;
          $scope.states = states.sort();

          // Calculate summary data
          $scope.totalFacilities = data.length;
          $scope.totalDevices = data.reduce(((p, c) => p+c.deviceCount), 0)
          $scope.totalKioskDevices = data.reduce(((p, c) => p+c.kioskDeviceCount), 0)
          $scope.totaliPodDevices = data.reduce(((p, c) => p+c.iPodDeviceCount), 0)
          $scope.totalInmates = data.reduce(((p, c) => p+c.inmateCount), 0);
          $scope.totalAssigned = data.reduce(((p, c) => p+c.assignedCount), 0);
          $scope.totalInventory = data.reduce(((p, c) => p+c.inventoryCount), 0);
          $scope.totalInTransit = data.reduce(((p, c) => p+c.inTransitCount), 0);
          $scope.totalMissing = data.reduce(((p, c) => p+c.missingCount), 0);
        });
  }]);
