angular
  .module('relcore.form')
  .directive('relFormSubmitted', () =>
    ({
      restrict: 'A',
      require: 'form',
      link(scope, element, attrs, ctrl:any) {
        // Track if the form has been submitted
        // Mark the form dirty on submission to force validation
        ctrl.$submitted = false;
        return element.on('submit', event =>
          scope.$apply(function() {
            ctrl.$submitted = true;
            return ctrl.$setDirty();
          })
        );
      }
    })
);