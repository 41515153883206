import { IApiResponse } from '../common/reliance-api-service';
import { IDateTimeObject } from '../models/datetime';

interface ISmdrView {
  id: number
  controllerID: number
  siteID: number
  siteName: string
  controllerNumber: number
  lineNumber: number
  coLineNumber: number
  originationNumber: string
  destinationNumber: string
  billingNumber: string
  callType: string
  billType: string
  dateTime: IDateTimeObject
  callLength: number
  charge: number
  collected: number
  completionCode: string
  terminationCode: string
  carrier: number
  pidNumber: string
  billingCount: number
  siteSequence: string
  equipmentType: string
  phoneType: string
  quietDetect: boolean
  debitCall: boolean
  bandError: boolean
  validated: boolean
  previouslyRetrieved: boolean
  tableName: string
  customerShortName: string
  controllerSequence: number
  smdrSequence: number
}

interface IPhoneTrackerPendingCongtrollerScope extends ng.IScope {
  filter: any
  loading: boolean
  recordCount: number
  maximumId?: number
  tableInstance: any
  tableOptions: any
  tableColumns: Array<any>
  refresh(): void
  processing: boolean
  processSmdrs(): void
}

angular
  .module('relcore.phone-tracker')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('phone-tracker-pending', {
      controller: 'PhoneTrackerPendingController',
      template: require('./pending.html').default,
      url: '/phone-tracker',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Pending SMDR Records' }
    })
  }])
  .controller('PhoneTrackerPendingController', [
    '$rootScope', '$scope', 'relianceApi', 'alertService', 'DTOptionsBuilder', 'DTColumnBuilder', '$filter',
    function($rootScope, $scope: IPhoneTrackerPendingCongtrollerScope, relianceApi, alertService, DTOptionsBuilder, DTColumnBuilder, $filter) {
      $rootScope.title = 'Pending SMDR Records';
      $scope.filter = {
        date: null
      };

      $scope.maximumId = null;
      $scope.loading = true;
      $scope.recordCount = 0;
      $scope.tableInstance = {};
      $scope.tableOptions = DTOptionsBuilder
        .fromFnPromise(function() {
          $scope.maximumId = null;
          return relianceApi.get('/phone-tracker/pending', {
            minimumDate: $scope.filter.date ? $scope.filter.date.toISOString() : null
          }).then((response: IApiResponse<Array<ISmdrView>>) => {
            $scope.loading = false;

            // Find the maximum ID returned in this result set
            $scope.maximumId = response.data.reduce((prev, cur) => prev > cur.id ? prev : cur.id, 0);

            $scope.recordCount = response.data.length;
            return response.data.map(d => ({...d, callLength: d.callLength * 60}));
          });
        })
        .withOption('lengthChange', false)
        .withDisplayLength(200)
        .withBootstrap()
        .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");

      const renderWithFilter = (filter) => (data, type, full) => $filter(filter)(data);

      $scope.tableColumns = [
        DTColumnBuilder.newColumn('siteName').withTitle('Site Name'),
        DTColumnBuilder.newColumn('dateTime.date').withTitle('Date/Time').renderWith(renderWithFilter('datetimeNoSecondsFormat')),
        DTColumnBuilder.newColumn('controllerNumber').withTitle('Controller'),
        DTColumnBuilder.newColumn('lineNumber').withTitle('Line'),
        DTColumnBuilder.newColumn('coLineNumber').withTitle('CO Line'),
        DTColumnBuilder.newColumn('originationNumber').withTitle('Origination').renderWith(renderWithFilter('tel')),
        DTColumnBuilder.newColumn('destinationNumber').withTitle('Destination').renderWith(renderWithFilter('tel')),
        DTColumnBuilder.newColumn('billingNumber').withTitle('Billing Number'),
        DTColumnBuilder.newColumn('callType').withTitle('Call Type'),
        DTColumnBuilder.newColumn('billType').withTitle('Bill Type'),
        DTColumnBuilder.newColumn('callLength').withTitle('Duration').renderWith(renderWithFilter('dur')),
        DTColumnBuilder.newColumn('charge').withTitle('Charge').renderWith(renderWithFilter('currency')),
        DTColumnBuilder.newColumn('completionCode').withTitle('Completion'),
        DTColumnBuilder.newColumn('terminationCode').withTitle('Termination'),
        DTColumnBuilder.newColumn('pidNumber').withTitle('PID Number'),
        DTColumnBuilder.newColumn('tableName').withTitle('Table')
      ];

      $scope.refresh = () => {
        $scope.loading = true;
        $scope.tableInstance.reloadData(() => $scope.loading = false, true);
      }

      $scope.processing = false;
      $scope.processSmdrs = () => {
        $scope.processing = true;
        relianceApi.post('/phone-tracker/run', {
          minimumDate: $scope.filter.date ? $scope.filter.date.toISOString() : null,
          maximumId: $scope.maximumId
        }).then((res) => {
          $scope.processing = false;
          alertService.success(res.data.processedCount + ' SMDRs are now processing', true);
          $scope.refresh();
        }, () => {
          $scope.processing = false;
          alertService.error('An error occurred while starting the processing', true);
          $scope.refresh();
        });
      };
    }]);