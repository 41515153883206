import { IAlertService } from '../../alert/alert-service';
import { IApiResponse, IPaginatedResponse, IRelianceApi, IStatusResponse } from '../../common/reliance-api-service';
import { InmateSubscription } from '../../models/InmateSubscription';
import { IRootScope } from '../../models/root-scope';
import { IChangesObject, IInmateHistory, IInmateHistoryService } from './inmate-history-service';

angular
  .module('relcore.inmate')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('inmates-view', {
        controller: 'InmateViewController',
        template: require('./view.html').default,
        url: '/inmates/view/:id',
        parent: 'authenticated',
        params: {
          inmate: null
        },
        ncyBreadcrumb: {
          label: '{{inmate.fullName}}',
          parent: 'inmates'
        }
      })
  ])
  .controller('InmateViewController',
    ['$rootScope', '$scope', '$filter', '$state', 'inmateService', 'relianceApi', 'inmateActivityService', 'InmateHistoryService', 'textMessageService', 'NgTableParams', '$stateParams', 'alertService', '$q', 'moment', 'DTOptionsBuilder', 'DTColumnBuilder',
    function($rootScope:IRootScope, $scope, $filter, $state, inmateService, relianceApi:IRelianceApi, inmateActivityService, inmateHistoryService: IInmateHistoryService, textMessageService, NgTableParams, $stateParams, alertService: IAlertService, $q, moment, DTOptionsBuilder, DTColumnBuilder) {
      $scope.id = $stateParams.id;
      $scope.$state = $state;

      $scope.loadInmateNotes = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.inmateNoteGridOptions) {
          $scope.inmateNoteGridOptions.reload();
          return;
        }

        return $scope.inmateNoteGridOptions = new NgTableParams({
          count: 100,
          sorting: { date: 'desc' },
          filter: { inmateId: $scope.inmate.id }
        }, {
          counts: [100, 200, 500],
          getData(params) {
            const orderBy = params.orderBy().map(o =>
              o
                .replace(/date$/, 'dateEntered')
                .replace(/user$/, 'userName')
            );
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>('/inmate-note', params.page(), params.count(), params.filter(), orderBy)
              .then(function(res) {
                params.total(res.data.total);
                return res.data.data.map(function(d) {
                  d.date = d.dateEntered;
                  d.user = d.userName;
                  return d;
                });
              });
          }
        });
      };

      const resetNote = () => $scope.newNote = { note: '' };
      resetNote();
      $scope.addNote = function(note) {
        note.entityId = $scope.inmate.id;
        return relianceApi.post("/inmate-note", { note })
          .then((function() {
            $scope.loadInmateNotes();
            alertService.success('The note was successfully added', true);
            return resetNote();
          }), (() => alertService.error('An error occurred while adding the note', true)));
      };

      $scope.previousBalance = 0;
      $scope.loadInmateAccountHistory = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.inmateAccountGridOptions) {
          if (refresh) { $scope.inmateAccountGridOptions.reload(); }
          return;
        }

        return $scope.inmateAccountGridOptions = new NgTableParams({
          count: 50,
          sorting: {
            'dateFinalized': 'desc'
          },
          filter: {
            inmateId: $scope.inmate.id,
            dateFinalized: { startDate: moment().subtract(5, 'year'), endDate: moment().endOf('day')
          }
          }
        }, {
          counts: [50, 100, 200, 500],
          getData(params) {
            return inmateActivityService.index(params.page(), params.count(), params.orderBy(), params.filter())
              .then(function(data) {
                params.total(data.total);
                params.previousBalance = data.supportingData.previousBalance || 0;
                return data.data;
            });
          }
        });
      };

      $scope.loadInmateAccountSummary = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.inmateAccountSummaryGridOptions) {
          if (refresh) { $scope.inmateAccountSummaryGridOptions.reload(); }
          return;
        }

        return $scope.inmateAccountSummaryGridOptions = new NgTableParams({}, {
          counts: [],
          getData(params) {
            return relianceApi
              .get<IApiResponse<Array<any>>>('/inmate-account/summary', { inmateId: $scope.inmate.id })
              .then(function(res) {
                params.count(res.data.length);
                params.total(res.data.length);
                return res.data.reverse();
              });
          }
        });
      };

      $scope.loadInmateAccountDailySummary = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.inmateAccountDailySummaryGridOptions) {
          if (refresh) { $scope.inmateAccountDailySummaryGridOptions.reload(); }
          return;
        }

        return $scope.inmateAccountDailySummaryGridOptions = new NgTableParams({}, {
          counts: [],
          getData(params) {
            return relianceApi.get<IApiResponse<Array<any>>>('/inmate-account/summary', {
              inmateId: $scope.inmate.id,
              dateGrouping: 'Daily'
              })
              .then(function(res) {
                params.count(res.data.length);
                params.total(res.data.length);
                return res.data.reverse();
              });
          }
        });
      };

      $scope.loadTextMessageHistory = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.textMessageGridOptions) {
          if (refresh) { $scope.textMessageGridOptions.reload(); }
          return;
        }

        return $scope.textMessageGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            createdDate: 'desc'
          },
          filter: {
            inmates: $scope.inmate.id,
            createdDate: { startDate: moment().subtract(1, 'day'), endDate: moment().endOf('day')
          }
          }
        }, {
          counts: [100,500,1000],
          getData(params) {
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>("/text-message", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadCdrs = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.cdrGridOptions) {
          if (refresh) { $scope.cdrGridOptions.reload(); }
          return;
        }

        return $scope.cdrGridOptions = new NgTableParams({
          page: 1,
          count: 500,
          sorting: {
            setupDate: 'desc'
          },
          filter: {
            inmateId: $scope.inmate.id
          }
        }, {
          counts: [100,500,1000],
          getData(params) {
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>("/cdr", params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadSupportMessageHistory = function(refresh, includeActivityRecord) {
        if (refresh == null) { refresh = true; }
        if ($scope.supportMessageGridOptions) {
          if (refresh) { $scope.supportMessageGridOptions.reload(); }
          return;
        }

        return $scope.supportMessageGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            createdDate: 'desc'
          },
          filter: {
            inmates: $scope.inmate.id,
            types: 'Staff Message',
            createdDate: { startDate: moment().subtract(10, 'year'),
            endDate: moment().endOf('day'),
            includeActivityRecords: false
            }
          }
        }, {
          counts: [100,500,1000],
          getData(params) {
            let filter = Object.assign({}, params.filter(), {includeActivityRecord});
            return textMessageService.index(filter, params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadDeviceHistory = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.deviceHistoryGridOptions) {
          if (refresh) { $scope.deviceHistoryGridOptions.reload(); }
          return;
        }

        return $scope.deviceHistoryGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            'unassignDate': 'asc',
            'assignDate': 'desc'
          },
          filter: {
            'inmateId': $scope.inmate.id,
            'includeUnassigned': true
          }
        }, {
          getData(params) {
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>('/inmate-device', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadContacts = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.contactGridOptions) {
          if (refresh) { $scope.contactGridOptions.reload(); }
          return;
        }

        return $scope.contactGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            'ani': 'asc'
          },
          filter: {
            'inmateId': $scope.inmate.id
          }
        }, {
          getData(params) {
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>('/inmate-contact', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.loadSmsVoiceNumbers = function(refresh) {
        if (refresh == null) { refresh = true; }
        if ($scope.smsVoiceNumberGridOptions) {
          if (refresh) { $scope.smsVoiceNumberGridOptions.reload(); }
          return;
        }

        return $scope.smsVoiceNumberGridOptions = new NgTableParams({
          page: 1,
          count: 100,
          sorting: {
            number: 'asc'
          },
          filter: {
            inmateId: $scope.inmate.id
          }
        }, {
          getData(params) {
            return relianceApi
              .index<IApiResponse<IPaginatedResponse<any>>>('/smsvoice-number', params.page(), params.count(), params.filter(), params.orderBy())
              .then(function(response) {
                params.total(response.data.total);
                return response.data.data;
              });
          }
        });
      };

      $scope.inmateSubscriptions = null;
      $scope.inmateSubscriptionsLoading = false;
      $scope.loadInmateSubscriptions = function(refresh = true) {
        $scope.inmateSubscriptionsLoading = true;
        relianceApi
          .index<IApiResponse<IPaginatedResponse<InmateSubscription>>>('/inmate-subscription', 1, 1000, { inmateId: $scope.inmate.id })
          .then(function(response) {
            $scope.inmateSubscriptions = response.data;
          })
          .finally(function() {
            $scope.inmateSubscriptionsLoading = false;
          });
      };

      $scope.inmateSubscriptionStopping = false;
      $scope.onInmateSubscriptionStop = function(inmateSubscription: InmateSubscription) {
        $scope.inmateSubscriptionStopping = true;
        return relianceApi
          .delete<IApiResponse<IStatusResponse & { subscription: InmateSubscription }>>(`/inmate-subscription/${inmateSubscription.id}`)
          .then(function() {
            $scope.loadInmateSubscriptions();
          })
          .finally(function() {
            $scope.inmateSubscriptionStopping = false;
          });
      };

      const loadInmate = id => inmateService.getById($stateParams.id).then(setInmate);

      var setInmate = function(inmate) {
        $scope.inmate = inmate;
        $rootScope.title = $filter('fullName')($scope.inmate);
      };

      if ($stateParams.inmate) {
        setInmate($stateParams.inmate);
      } else {
        loadInmate($stateParams.id);
      }

      $scope.onSave = function(newInmate) {
        const onSuccess = () =>
          loadInmate(newInmate.id)
            .then(() => alertService.success('The inmate was successfully changed', true))
        const onError = function(response) {
          if (response.status === 503) {
            loadInmate(newInmate.id)
              .then(() => alertService.warning('The inmate was saved, but we were unable to refresh the site API cache', true));
          }
          if (response.status === 409) {
            return alertService.error('An existing inmate at this facility has this account', true);
          } else {
            return alertService.error('An error occurred while saving the changes', true);
          }
        };

        return inmateService.put(newInmate).then(onSuccess, onError);
      };

      $scope.reassigningAni = false;
      $scope.onReassignAni = function() {
        $scope.reassigningAni = true;
        return relianceApi
          .post(`/inmate-device/${$scope.inmate.id}/ani`, { returnCurrent: true })
          .then(
            function onSuccess(res) {
              $scope.reassigningAni = false;
              alertService.success('The inmate\'s ANI was successfully reassigned', true);
              loadInmate($scope.inmate.id);
            },
            function onFailure(err) {
              $scope.reassigningAni = false;
              alertService.error('An error occurred while reassigning the inmate\'s ANI', true);
            }
          );
      };

      $scope.onInmateActivityAdd = function(activity) {
        let activityData;
        if (activity.addOrRemove === 'add') {
          activityData = {};
          activityData = Object.assign({}, activity, {
            amount: Math.abs(activity.amount) * -1
          });
        } else {
          activityData = Object.assign({}, activity, {
            amount: Math.abs(activity.amount)
          });
        }

        $scope.inmateActivityAdding = true;
        return inmateActivityService
          .post(activityData)
          .then(
            (function() {
              $scope.inmateActivityAdding = false;
              alertService.success('The activity record was successfully created', true);
              loadInmate($scope.inmate.id);
              activity.amount = '';
              activity.description = '';
              activity.addOrRemove = null;
              return $scope.loadInmateAccountHistory();
            }),
            (function() {
              $scope.inmateActivityAdding = false;
              return alertService.error('An error occurred while creating activity record', true);
            })
          );
      };

      $scope.inmateActivityAdding = false;

      $scope.onSupportMessageSend = function(message) {
        $scope.supportMessageSending = true;
        return textMessageService
          .postStaffMessage({
            message: $scope.supportMessage.message,
            inmateId: $scope.inmate.id
            })
          .then(
            (function() {
              $scope.supportMessageSending = false;
              $scope.supportMessage.message = '';
              alertService.success('The message was sent successfully', true);
              loadInmate($scope.inmate.id);
              return $scope.loadSupportMessageHistory(true, false);
            }),
            (function() {
              $scope.supportMessageSending = false;
              return alertService.error('An error occurred while sending the message', true);
            })
          );
      };

      $scope.supportMessageSending = false;
      $scope.supportMessage = {};

      $scope.onSupportMessageRead = function() {
        $scope.supportMessageReading = true;
        return textMessageService.putProcessUnreadSupportRequests($scope.inmate.id)
          .then(
            (function() {
              $scope.supportMessageReading = false;
              alertService.success('Messages successfully marked as read', true);
              return $scope.loadSupportMessageHistory(true, false);
            }),
            (function() {
              $scope.supportMessageReading = false;
              return alertService.error('An error occurred while marking the messages as read', true);
            })
          );
      };

      $scope.supportMessageReading = false;

      $scope.onSupportMessageRefund = function(activity) {
        let activityData = Object.assign({}, activity);

        $scope.onSupportMessageRefunding = true;
        return inmateActivityService
          .refund(activityData)
          .then(
            (function() {
              $scope.onSupportMessageRefunding = false;
              alertService.success('The refund was successfully applied', true);
              activity.amount = '';
              activity.description = '';
              return $scope.loadSupportMessageHistory(true, true);
            }),
            (function() {
              $scope.onSupportMessageRefunding = false;
              alertService.error('An error occurred while applying refund', true);
            })
          );
      };

      $scope.refreshing = false;
      $scope.refresh = function() {
        $scope.refreshing = true;
        return loadInmate($scope.inmate.id)
          .then(function() {
            $scope.loadInmateNotes(true);
            $scope.loadDeviceHistory(true);
            $scope.loadTextMessageHistory(true);
            $scope.loadInmateAccountHistory(true);
            $scope.loadInmateAccountSummary(true);
            $scope.loadInmateAccountDailySummary(true);
            $scope.loadSupportMessageHistory(true, false);
            return $scope.refreshing = false;
          });
      };

      $scope.recalculatingBalance = false;
      $scope.recalculateBalance = function() {
        if (!$rootScope.user.hasPermission('inmate_activity_manage')) {
          return;
        }

        $scope.recalculatingBalance = true;
        relianceApi
          .post('/inmate-account/recalculate-balance', { 'inmateIds': $scope.inmate.id.toString() })
          .then(() => $scope.recalculatingBalance = false)
          .then($scope.refresh)
          .catch(() => {
            $scope.recalculatingBalance = false;
            alertService.error('An error occurred while recalculating balance', true);
          });
      };

      const filterRenderer = (name: string) => (data: any) => $filter(name)(data);
      const jsonChangesRenderer = (json: IChangesObject) => {
        const changeKeys = Object.keys(json.changes);
        return changeKeys.reduce((accumulator: string, current: string) => {
          return accumulator.length != 0 ? `, ${current}=${changeKeys[current]}` : `${current}=${json.changes[current]}`
        }, "");
      };

      $scope.changeHistoryTableInstance = {};
      $scope.changeHistoryTableColumns = [
          DTColumnBuilder.newColumn('dateChanged.date').withTitle('Date').renderWith(filterRenderer('datetimeFormat')),
          DTColumnBuilder.newColumn('status').withTitle('General Status'),
          DTColumnBuilder.newColumn('inmate.phoneStatus').withTitle('Audio Calling'),
          DTColumnBuilder.newColumn('inmate.audioStatus').withTitle('Wireless Audio Calling'),
          DTColumnBuilder.newColumn('inmate.textStatus').withTitle('Texting'),
          DTColumnBuilder.newColumn('housingUnit.name').withTitle('Housing Unit'),
          DTColumnBuilder.newColumn('json').withTitle('Other Changes').renderWith(jsonChangesRenderer)
        ];

      $scope.changeHistoryTableOptions = DTOptionsBuilder
        .fromFnPromise(async () => {
          return inmateHistoryService.getLogs($scope.id)
            .then((response: IApiResponse<Array<IInmateHistory>>) => {
              return response.data;
            });
        })
        .withOption('lengthChange', false)
        .withOption('order', []) // Disable initial sorting. Displays by ordering of the data array
        .withDisplayLength(200)
        .withBootstrap()
        .withOption('dom', "<'row'<'col-sm-12'ft>><'row'<'col-6'i><'col-6'p>>");
    }
  ]);
