angular
  .module('relcore.station')
  .factory('Station', ['Facility', function(FacilityModel) {
    return class Station {
      id;
      name;
      domain;
      userContext;
      type;
      status;
      password;
      tollAllow;
      userId;
      mobileDevice;
      housingUnit;
      featureMode;
      blurVideoBackground;
      facility;
      defaultExtension;

      constructor(data) {
        this.loadFromData = this.loadFromData.bind(this);
        this.loadFromData(data);
      }

      loadFromData(data) {
        if (data == null) { data = {}; }
        this.id = data.id || '';
        this.name = data.name || '';
        this.domain = data.domain || '';
        this.userContext = data.userContext || '';
        this.type = data.type || '';
        this.status = data.status || '';
        this.password = data.password || '';
        this.tollAllow = data.tollAllow || '';
        this.userId = data.userId || '';
        this.mobileDevice = data.mobileDevice;
        this.housingUnit = data.housingUnit;
        this.featureMode = data.featureMode;
        this.blurVideoBackground = data.blurVideoBackground;
        this.defaultExtension = data.defaultExtension;

        this.facility = new FacilityModel(data.facility || {});
      }
    };
  }]);