angular
  .module('relcore.salt')
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('salt-minions', {
      controller: 'SaltMinionsController',
      url: '/salt-minions',
      parent: 'authenticated',
      ncyBreadcrumb: {label: 'Salt Minions'},
      template: require('./main.html').default
    });
  }])
  .controller('SaltMinionsController', [
    '$rootScope', '$scope', '$state', 'relianceApi', 'NgTableParams',
    function($rootScope, $scope, $state, relianceApi, NgTableParams) {
      $rootScope.title = 'Salt Minions';

      $scope.gridOptions = new NgTableParams({}, {
        getData: (params) => {
          return relianceApi.index('/salt/minion', params.page(), params.count(), params.filter(), params.orderBy())
            .then((response) => {
              return response.data;
            });
        }
      });

      $scope.onViewDetails = (saltMinion) => {
        $state.go('salt-minion-view', {id: saltMinion.id});
      };
    }
  ]);
