export enum TaxTableEntityType {
  City = 1,
  State = 2,
  Fed = 3,
  FUSF = 4,
  County = 5,
  Special = 6,
}

export type TaxTable = {
  id: number
  entityType: TaxTableEntityType
  rate: number
  entityName: string
}