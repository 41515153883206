angular
  .module('relcore.housing-unit')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider.state('housing-units', {
      controller: 'HousingUnitsController',
      template: require('./housing-units.html').default,
      url: '/housing-units',
      parent: 'authenticated',
      ncyBreadcrumb: { label: 'Housing Units' }
    })
  ])
  .directive('housingUnits', () =>
    ({
      restrict: 'E',
      scope: {
        mobileDeviceGroups: '=',
        facility: '=?',
        onFetch: '&',
        onLoad: '&',
        onError: '&'
      },
      template: require('./housing-units.html').default,
      controller: 'HousingUnitsController'
    })
  )
  .controller('HousingUnitsController', [
    '$rootScope', '$scope', '$state', 'housingUnitService', 'alertService', 'NgTableParams',
    function($rootScope, $scope, $state, housingUnitService, alertService, NgTableParams) {
      $rootScope.title = 'Housing Units';

      $scope.gridOptions = new NgTableParams({
        page: 1,
        count: 100,
        filter: {
          facilityId: ($scope.facility != null ? $scope.facility.id : undefined)
        },
        sorting: {
          'facility.name': 'asc',
          'status': 'asc',
          'name': 'asc'
        }
      }, {
        counts: [100, 200, 500],
        getData(params) {
          $scope.onFetch();
          return housingUnitService.index(params.page(), params.count(), params.orderBy(), params.filter())
            .then(function(response) {
              params.total(response.data.total);
              $scope.onLoad();
              return response.data.data;
            },
            function(err) {
              $scope.onError();
              alertService.error('An unexpected error occurred while retrieving the housing units', true);
            }
          );
        }
      });

      $scope.view = housingUnit => $state.go('housing-units-view', {id:housingUnit.id, housingUnit});

      $scope.adding = false;
      $scope.onAdd = function(housingUnit) {
        const onSuccess = () =>
          $scope.gridOptions.reload()
            .then(function() {
              $scope.adding = false;
              alertService.success('The housing unit was successfully added', true);
            })
        ;

        const onError = function() {
          $scope.adding = false;
          alertService.error('An error occurred while adding the housing unit', true);
        };

        $scope.adding = true;
        housingUnitService
          .post({
            ...housingUnit,
            mobileDeviceGroup: undefined,
            mobileDeviceGroupId: housingUnit.mobileDeviceGroup ? housingUnit.mobileDeviceGroup.id : null
          })
          .then(onSuccess, onError);
      };
    }
  ]);
