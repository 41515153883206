angular
  .module('relcore.filters')
  .filter('accountLinkStatus', () =>
    function(value: number): string {
      switch(value) {
        case 2: return 'Open Link';
        case 3: return 'Payment Hold';
        case 4: return 'Disabled';
        case 6: return 'Probation';
        default: return 'No Link';
      }
    }
);