angular
  .module('relcore.station')
  .directive('stations', () =>
    ({
      restrict: 'E',
      scope: {
        gridOptions: '=',
        onDetails: '='
      },
      template: require('./stations.html').default
    })
  );
