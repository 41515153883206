import { formatJson } from '../format/Json';

angular
  .module('relcore.filters')
  .filter('xml', ['xmlButPrettier', (xmlButPrettier) =>
    (data) => {
      if (data == null) {
        return null;
      }

      if (data.indexOf('<?xml') === 0) {
        return xmlButPrettier(data);
      }

      if (data.indexOf('{') === 0) {
        return formatJson(data);
      }

      return data;
    }
  ]);