angular
  .module('relcore.sms-voice-number')
  .config(['$stateProvider', $stateProvider =>
    $stateProvider
      .state('sms-voice-numbers', {
        controller: 'SmsVoiceNumberListController',
        template: require('./list.html').default,
        url: '/sms-voice-numbers',
        parent: 'authenticated',
        ncyBreadcrumb: { label: 'SMS/Voice Numbers' }
      })

  ])
  .controller('SmsVoiceNumberListController',
    ['$rootScope', '$scope', '$filter', 'smsVoiceNumberService', 'alertService', 'NgTableParams', '$state',
      function($rootScope, $scope, $filter, smsVoiceNumberService, alertService, NgTableParams, $state) {
        $rootScope.title = 'SMS/Voice Numbers';

        $scope.total = '';
        $scope.needingReturn = '';

        $scope.gridOptions = new NgTableParams({
            page: 1,
            count: 500,
            sorting: {
              number: 'asc'
            },
            filter: {
              includePendingReturnCount : true
            }
          }, {
            counts: [100,500,1000],
            getData(params) {
              return smsVoiceNumberService.get(params.filter(), params.orderBy())
                .then(function(result) {
                  params.total(result.total);
                  $scope.total = result.total;
                  $scope.needingReturn = result.needingReturn;
                  $scope.selected = [];
                  return result.data;
              });
            }
          });
      }
  ]);
