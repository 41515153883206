import { IAnalogGatewayPort, IFacilityAnalogGateway } from '../models/analog-gateway';
import { IFacility } from '../models/facility';

interface IFacilityAnalogGatewayEditCreateScope {
  error?: string
  editing: boolean
  gateway: IFacilityAnalogGateway
  fxoPorts(): IAnalogGatewayPort[]
  fxsPorts(): IAnalogGatewayPort[]
  facilityNumbers: any[]
  stations: any[]
  addPort(type: string): void
  togglePortStatus(port: IAnalogGatewayPort): void
  removePort(port: IAnalogGatewayPort): void
  saving: boolean
  save(): void
  close(): void
}

angular
  .module('relcore.facility')
  .controller('FacilityAnalogGatewayCreateController', [
    '$scope', 'relianceApi', '$uibModalInstance', 'facility', 'editGateway',
    function ($scope: IFacilityAnalogGatewayEditCreateScope, relianceApi, $uibModalInstance, facility: IFacility, editGateway: IFacilityAnalogGateway) {
      $scope.error = null;
      $scope.editing = editGateway != null;

      const gateway: IFacilityAnalogGateway = editGateway || {
        name: '',
        model: '',
        ipAddress: '',
        sipAuthUsername: '',
        sipAuthPassword: '',
        analogGatewayPorts: [],
        facilityId: facility.id
      };

      // Flatten the station and facility number fields on the ports
      if (editGateway) {
        gateway.analogGatewayPorts = gateway.analogGatewayPorts.map(p => ({
          ...p,
          stationId: p.station ? p.station.id : null,
          facilityNumberId: p.facilityNumber ? p.facilityNumber.id : null,
        }));
      }

      $scope.gateway = Object.assign({}, gateway);

      // Calculated properties that filter by the specific types
      $scope.fxoPorts = () => $scope.gateway.analogGatewayPorts.filter(p => p.type == 'FXO');
      $scope.fxsPorts = () => $scope.gateway.analogGatewayPorts.filter(p => p.type == 'FXS');

      // Method to add a port to the gateway
      $scope.addPort = (type: string) => {
        const maximumPort = $scope.gateway.analogGatewayPorts
          .filter(p => p.type as string == type)
          .reduce((prev, cur) => (prev > cur.hardwarePort ? prev : cur.hardwarePort), -1);
        $scope.gateway.analogGatewayPorts.push({
          name: `${type}${('0' + (maximumPort + 1)).slice(-2)}`,
          type,
          hardwarePort: maximumPort + 1,
          status: 'Active'
        });
      };

      // Toggles the port's status
      $scope.togglePortStatus = (port) => {
        port.status = port.status == 'Active' ? 'Inactive' : 'Active';
      };

      // Removes a port from the list
      $scope.removePort = (port) => {
        $scope.gateway.analogGatewayPorts.splice($scope.gateway.analogGatewayPorts.indexOf(port), 1);
      };

      // Load external data for stations and facility numbers
      $scope.facilityNumbers = [];
      $scope.stations = [];
      relianceApi.get('/station', {
        facilityId: facility.id,
        status: 'Active',
        types: ['Phone', 'Audio Visit'],
      }).then(
        (res => $scope.stations = res.data),
        (err => $scope.error = 'Unable to load phones')
      );
      relianceApi.get(`/facility/${facility.id}/number`, {
        active: true
      }).then(
        (res => $scope.facilityNumbers = res.data),
        (err => $scope.error = 'Unable to load facility numbers')
      );

      // Handle form submission
      $scope.save = function () {
        // Massage the station and facility numbers into their IDs
        let data = $scope.gateway;
        data.analogGatewayPorts = data.analogGatewayPorts.map(p => ({
          ...p,
          stationId: p.station ? p.station.id : null,
          facilityNumberId: p.facilityNumber ? p.facilityNumber.id : null
        }));

        delete data.fxsPorts;
        delete data.fxoPorts;

        $scope.saving = true;
        let promise = null;
        if ($scope.editing) {
          promise = relianceApi.put(`/facility-analog-gateway/${data.id}`, { gateway: data });
        } else {
          promise = relianceApi.post(`/facility-analog-gateway`, { gateway: data });
        }

        promise.then((function (res) {
          $scope.saving = false;
          if (!res.data || !res.data.success) {
            $scope.error = 'An unexpected error occurred';
          } else {
            $scope.error = null;
            $uibModalInstance.close({ saved: true });
          }
        }), (function (err) {
          if (err.status === 400) {
            $scope.error = 'Please verify the inputs are valid and try again';
          } else {
            $scope.error = 'An unexpected error occurred';
          }
          $scope.saving = false;
        }));
      };

      $scope.close = () => $uibModalInstance.close({ saved: false })
    }
  ]);
